import React, { useContext, useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";

import styles from "./Checklist.module.css";
import ContextShape from "./ChecklistData";

import { useTranslation } from "../../contexts/LocaleContext";

const GuideContext = React.createContext(ContextShape);
export default GuideContext;

export const useGuide = () => {
  const { t } = useTranslation();
  const [state, setState] = useState(ContextShape);

  const readFromStorage = () => {
    const storedProgress = localStorage.getItem("GUIDE_PROGRESS");
    if (storedProgress && storedProgress != "undefined") {
      console.log("Progress restored");
      setState((p) => ({ ...p, pages: JSON.parse(storedProgress) }));
    } else {
      console.log("Progress initialized");
      saveToStorage(ContextShape.pages);
    }
  };
  useEffect(() => {
    readFromStorage();
  }, []);

  const saveToStorage = (newState) => {
    localStorage.setItem("GUIDE_PROGRESS", JSON.stringify(newState));
    console.log("Progress saved");
  };

  const setPage = (pageName) => {
    if (!pageName) {
      setState((prevState) => ({
        ...prevState,
        currentPage: prevState.prevPage,
      }));
    } else if (state.pages[pageName]) {
      setState((prevState) => ({
        ...prevState,
        prevPage: prevState.currentPage,
        currentPage: pageName,
      }));
      console.log("Guide page switched to [" + pageName + "]");
    } else console.error("Invalid page name: " + pageName);
  };

  const getItems = () => {
    return state.pages[state.currentPage];
  };

  const markDone = (item = "") => {
    const page = state.currentPage;
    if (state.pages[page][item]) {
      const newState = {
        ...state,
        pages: {
          ...state.pages,
          [page]: {
            ...state.pages[page],
            [item]: {
              ...state.pages[page][item],
              done: true,
            },
          },
        },
      };
      setState(newState);
      saveToStorage(newState.pages);
    } else {
      console.error("Invalid page or item: " + page + " " + item);
    }
  };

  return { markDone, getItems, setPage };
};

export const GuideTrigger = ({ item, ...props }) => {
  const { markDone } = useContext(GuideContext);
  const children = props.children;
  const callback = () => {
    markDone(item);
    const prev = children?.props?.onClick;
    try {
      prev();
    } catch (e) {}
  };

  const ChildClone = React.cloneElement(props.children, { onClick: callback });

  return ChildClone;
};

const ListItem = ({ item, hide = true }) => {
  const [isDone, setDone] = useState(false);
  const [display, setDisplay] = useState(true);
  const { t } = useTranslation();
  const T = (string = "") => t(string.toLowerCase().replaceAll(" ", "_"));

  useEffect(() => {
    if (item.done === true)
      setTimeout(() => {
        setDone(true);
      }, 5000);
  }, [item.done]);

  const style = useSpring(
    isDone && hide
      ? {
          opacity: 0,
          marginTop: 0,
          config: {
            duration: 200,
          },
          onRest: () => {
            setTimeout(() => {
              setDisplay(false);
            }, 100);
          },
        }
      : {
          opacity: 1,
          marginTop: 16,
          config: { duration: 200 },
        }
  );

  if (!display && hide) return null;

  return (
    <animated.div style={style} className={styles.animwrapper}>
      <div className={styles.itemWrapper}>
        <div className={styles.icon}>
          <i className={item.done ? "far fa-check-circle" : "far fa-circle"} />
        </div>
        <div className={styles.texts}>
          <div className={styles.itemTitle}>{T(item.title)}</div>
          {item.hint && <div className={styles.itemHint}>{T(item.hint)}</div>}
        </div>
      </div>
    </animated.div>
  );
};

const TopItem = ({ isEmpty, onClick = () => {}, expanded = false }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.itemWrapper} onClick={onClick}>
      <div className={styles.icon}>
        <i
          className={isEmpty ? "fas fa-check-circle" : "fas fa-question-circle"}
        />
      </div>
      <div className={styles.texts}>
        <div className={styles.itemTitle}>
          {isEmpty ? t("all_done_for_this_page") : t("learn_to_use_fastboss")}
        </div>
      </div>
      <div className={styles.chevron}>
        <i
          className={!expanded ? "fas fa-chevron-up" : "fas fa-chevron-down"}
        />
      </div>
    </div>
  );
};

export const GuideChecklist = () => {
  const MAX_ACTIVE = 1;
  const [showAll, setShowAll] = useState(false);
  const [isHidden, setHidden] = useState(true);
  const { getItems } = useContext(GuideContext);
  const page = getItems();
  const anim = useSpring(
    !isHidden
      ? { left: 32, from: { left: -290 }, config: { duration: 250 } }
      : { left: -290, from: { left: 32 }, config: { duration: 250 } }
  );

  const items = Object.keys(page).map((name) => ({
    ...page[name],
  }));
  const doneItems = items.filter((item) => item.done);
  const activeItems = items.filter((item) => !item.done);

  useEffect(() => {
    if (activeItems.length === 0) setHidden(true);
  }, [activeItems.length]);

  return (
    <div className={styles.overlay}>
      <button
        id="tutorial-step-16"
        className={styles.hideButton}
        onClick={() => {
          setHidden(!isHidden);
        }}
      >
        <i className={isHidden ? "fas fa-question" : "fas fa-chevron-left"} />
      </button>
      <animated.div className={styles.list} style={anim}>
        <TopItem
          isEmpty={activeItems.length === 0}
          onClick={() => setShowAll(!showAll)}
          expanded={showAll}
        />
        {doneItems.map((item, id) => (
          <ListItem item={item} hide={!showAll} key={item.title} />
        ))}
        {activeItems.map(
          (item, id) =>
            (id < MAX_ACTIVE || showAll) && <ListItem item={item} key={id} />
        )}
      </animated.div>
    </div>
  );
};
