import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import NetworkContext from '../contexts/NetworkContext';
import DataContext from '../contexts/DataContext';
import { Loading } from './Dashboard';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { loggedIn, isReady: isNetworkReady } = useContext(NetworkContext);
  const { account, company, subscription } = useContext(DataContext);

  const isConfirmed = account?.data?.isconfirmed;
  const isSuspended = account?.data?.is_suspended;
  const isCompanyCreated = company?.data?.name;
  const isSubscriptionExpired =
    isCompanyCreated &&
    !subscription?.data?.is_subscribed &&
    !subscription?.data?.is_trial;

  if (!isNetworkReady) {
    return <Loading />;
  }

  if (!loggedIn) {
    return <Navigate to={'/login'} />;
  }

  if (!account.isLoaded || !company.isLoaded || !subscription.isLoaded) {
    return <Loading />;
  }

  // if (!isConfirmed) {
  //   return <Navigate to={'/confirm-email'} />;
  // }

  if (
    !isCompanyCreated &&
    !isSuspended &&
    !location.pathname.includes('/dashboard/company-dashboard')
  ) {
    return <Navigate to={'/dashboard/company-dashboard'} />;
  }

  if (isSuspended && !location.pathname.includes('/suspended')) {
    return <Navigate to={'/suspended'} />;
  }

  if (isSubscriptionExpired && !location.pathname.includes('/payment')) {
    return <Navigate to={'/payment'} />;
  }

  if (location.pathname === '/' && loggedIn) {
    return <Navigate to={'/dashboard/company-dashboard'} />;
  }

  return children;
};

export default PrivateRoute;
