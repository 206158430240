import React from 'react';
import { Checkbox as InformedCheckbox } from 'informed';
import cn from 'classnames';
import styles from './style.module.css';

const CheckboxWithLabel = ({
  className,
  textarea = false,
  required,
  label,
  ...rest
}) => {
  return (
    <label className={cn(styles.wrapper, className)}>
      <InformedCheckbox
        className={styles.checkbox}
        required={required}
        {...rest}
      />
      <span className={cn(styles.checkmark)}></span>
      <span className={cn(styles.label)}>{label}</span>
    </label>
  );
};

export default CheckboxWithLabel;
