import React, {useEffect, useState} from 'react'

const SlideUpScreen = ({show=true, className='', ...props}) => {

    const [isVisible, setVisible] = useState(false)

    useEffect(()=>{
        if (show)
            setTimeout(()=>setVisible(true), 100)
        else
            setVisible(false)
    }, [show])

    return (
        <div
            className={className}
            style={{
                opacity: Number(isVisible),
                transitionDuration: '.3s',
                transform: !isVisible ? 'translateY(30px)' : 'none'
            }}
        >
            {props.children}
        </div>
    )
}

export default SlideUpScreen
