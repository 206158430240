import { POST } from "../network";
import { assistantSettingsEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
    try {
      return await POST(path, data);
    } catch (e) {
      console.error(path, e);
    }
  };

const sendFile = async (path, file, fieldName) => {
    const formData = new FormData();
    formData.append(fieldName ? fieldName : "file", file);
    const res = await POST(path, formData, true);
  };

export const createAssistantData = async data => {
    return await setData(assistantSettingsEndpoints.create, data);
  };

export const updateAssistantData = async data => {
    return await setData(assistantSettingsEndpoints.update, data);
};

export const uploadAssistantAvatar = async file => {
    return await sendFile(assistantSettingsEndpoints.uploadAvatar, file, "avatar");
};