import {useEffect, useState} from 'react'
import useWaiting from "../../../../common/hooks/useWaiting";

export const REGEX = {
    EMAIL: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    PHONE: /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g,
}

const useForm = (
    {
        initialState = null,
        onCreate=()=>{},
        onUpdate=()=>{},
        requiredFieldNames=[],
        withAddress = false
    }
) => {
    const [state, setState] = useState(initialState)
    const [editMode, setEditMode] = useState(initialState === null)
    const [errors, setErrors] = useState([])

    useEffect(()=>{
        if (initialState !== null) {
            setState(initialState)
            setEditMode(false)
        } else {
            setEditMode(true)
        }
    }, [initialState])

    const onInputChange = (fieldName, validationType) => (text) => {
        fieldName === "address" ? 
        setState((prevState) => ({
            ...prevState,
            address: {
                ...prevState?.address,
                street: text
            }
        })) 
        :
        setState(prevState=>({...prevState, [fieldName]: text})) 
        validate(fieldName, validationType, text)
        
    }

    const value = (fieldName) =>(fieldName === "address" ?
        state?.[fieldName]?.street.toString() || '' : state?.[fieldName]?.toString() || ''
    )

    const onCancel = () => {
        setState(initialState)
        setEditMode(false)
    }

    const onSave = async () => {
        if (isSaveDisabled) return
        if (initialState === null)
            await onCreate(state)
        else
            await onUpdate(state)
        setEditMode(false)
    }

    const [saveWaiting, saveWithWaiting] = useWaiting(onSave)

    const isSaveDisabled = requiredFieldNames.reduce((result, fieldName)=>(!state?.[fieldName] || result), false) // || errors.length

    const validate = (fieldName, validationType, text) => {
        let regex
        switch (validationType) {
            case 'email': regex = REGEX.EMAIL; break
            case 'phone': regex = REGEX.PHONE; break
            default: return
        }
        const hasError = !regex.test(text)
        if (hasError)
            setErrors(errors=>[...errors, fieldName])
        else
            setErrors(errors=>errors.filter(error=>(fieldName !== error)))
    }
    const getInputProps = (fieldName, validationType=false) => ({
        onChange: onInputChange(fieldName, validationType),
        value: value(fieldName),
        isSaveDisabled,
        disabled: !editMode,
        onSubmit: saveWithWaiting,
        error: false, //errors.includes(fieldName),
        required: requiredFieldNames.includes(fieldName)
    })

    return {
        disabled: isSaveDisabled,
        editMode,
        _onInputChangeOf: onInputChange,
        _valueOf: value,
        save: saveWithWaiting,
        cancel: onCancel,
        isSaveWaiting: saveWaiting,
        edit: () => setEditMode(true),
        getInputProps
    }
}

export default useForm
