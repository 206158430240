import React, { useContext, useEffect, useState } from "react"
import useCountDown from "react-countdown-hook"
import cn from "classnames"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import Row from "react-bootstrap/Row"
import Modal from "react-bootstrap/Modal"

import styles from "./style.module.scss"
import Button from "../common/core/Button"
import CodeInput from "../common/core/CodeInput"
import logoImagePath from "../Dashboard/images/logo.png"
import useWaiting from "../common/hooks/useWaiting"

import NetworkContext from "../../contexts/NetworkContext"
import DataContext from "../../contexts/DataContext"
import { useTranslation } from "../../contexts/LocaleContext"
import { addDaysToDate } from "../../utils/StringUtils"
import { unsuspendByCode, requestCodeToEmail } from "../../api/Account/dataSetters"

const defaultStyleProps = {
    inputStyle: {
      margin: '5px',
      width: '40px',
      borderRadius: '3px',
      fontSize: '34px',
      fontWeight: '900',
      height: '56px',
      paddingLeft: '7px',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      background:'#FAFAFA',
      borderRadius:"3px",
      userSelect:"none",
      caretColor:"transparent",
      appearance:"textfield",
      textAlign:"center",
      padding:"0"
    },
    inputStyleActive: {
      border:"2px solid #554590"
    }
}

const initialTime = 60 * 1000; 
const interval = 1000; 

const codeFieldsLength = 6;

const SuspendModal = ({ show, open, close }) => {
    const { t } = useTranslation();
    const [codeInputStyle, setCodeInputStyle] = useState(defaultStyleProps)
    const [code, setCode] = useState('')
    const [error, setError] = useState({msg:null})
    const [isRequestDisabled, setisRequestDisabled] = useState(false)
    const [loading, setLoading] = useState(false)

    const [timeLeft, { start: startCountDown, reset: resetCountDown }] = useCountDown(initialTime, interval);

    useEffect(() => {
        if(timeLeft === 0) {
        setisRequestDisabled(false)
        }
    }, [timeLeft])

    useEffect(() => {
        const delay = setTimeout(() => {
            requestCodeToEmail()
        }, 2000);
        setisRequestDisabled(true)
        startCountDown()

        return () =>{ 
            clearTimeout(delay)
            resetCountDown()
        }
    }, [])

    const handleCodeChange = (data) => {
        setCode(data)
        if(error){
            setError({msg:null})
            setCodeInputStyle((styles) => ({...styles, inputStyle: {
              ...styles.inputStyle,
              border: '1px solid rgba(0, 0, 0, 0.1)',
            }}))
        }
    }

    const handleRecoverAccount = async () => {
        setLoading(true)
        const isRemoved = await unsuspendByCode(Number(code))
        if(isRemoved.status === 200){
          setLoading(false)
          setError({msg: null})
          setCodeInputStyle((styles) => ({...styles, inputStyle: {
              ...styles.inputStyle,
              border: '1px solid green',
          }}))
          return window.location.reload()
        }else{
          setLoading(false)
          setError({msg: isRemoved.data})
          setCodeInputStyle((styles) => ({...styles, inputStyle: {
            ...styles.inputStyle,
            border: '1px solid #f85149',
          }}))
        }
    }

    const handleResendConfirmation = async () => {
        startCountDown();
        setisRequestDisabled(true);
        await requestCodeToEmail()
    }
    
    const convertTime = (myDuration) => {
        const mm = Math.floor(myDuration/(1000*60))%60 
        const ss = Math.floor(myDuration/1000)%60;
        return (String(mm).length === 1 ? "0" + mm : mm )+ ":" + (String(ss).length === 1 ? "0" + ss : ss )
    }

    return (
        <Modal show={show} onHide={close}>
            <Modal.Body className={styles.modalBody}>
                <div className={styles.modalDescription}>{t('we_send_email_with_confirmation_code_suspend')}</div>
                <CodeInput 
                    className={styles.codeInputContainer}
                    inputClassName={styles.codeInput}
                    onChange={handleCodeChange} 
                    type='number' 
                    fields={codeFieldsLength} 
                    {...codeInputStyle} 
                />
                <div className={styles.bottom}>
                    <span 
                      onClick={!isRequestDisabled && handleResendConfirmation} 
                      className={cn(styles.resendCode, isRequestDisabled && styles.resendCodeDisabled )}>{t('resend_code')} {isRequestDisabled  && <span>{convertTime(timeLeft)}</span>}</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    title={t("recover_account")}
                    onClick={handleRecoverAccount}
                />
            </Modal.Footer>
        </Modal>
    )
}

const Suspended = () => {
    const { t } = useTranslation();
    const network = useContext(NetworkContext);
    const { account } = useContext(DataContext);
    const [showModal, setShowModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState("1")
    const [waiting, unsuspendAccount] = useWaiting(requestCodeToEmail);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    
    const [expirationDate, setExpirationDate] = useState("");
    const { updated_at } = account.data;

    useEffect(() => {
        if (updated_at) {
            const date = addDaysToDate(updated_at, 180);
            setExpirationDate(date);
        }
    }, [updated_at])

    const onSubmit = async (e) => {
        e.preventDefault();
        await unsuspendAccount()
        handleOpenModal();
    }

    const onChangeOptions = (value) => {
        setSelectedValue(value)
    }

    return (
        <>
            <div className={styles.wrapper}>
                <Row className={styles.header}>
                    <div className={styles.headerLogo}>
                        <Image height={39} src={logoImagePath} />
                    </div>
                    <div className={styles.headerButton}>
                        <Button onClick={network.onLogout} title={t("Log_out")} />
                    </div>
                </Row>  
                <Container className="pt-5">
                    <div className={styles.cardWrapper}>
                        <h3 className={styles.cardHeader}>{t("pop_up_msg_on_suspended_signup")}</h3>
                        <p className={styles.cardDate}>{t("expiration_date")}: {expirationDate}</p>
                        <form onSubmit={onSubmit}>
                            <h5>{t("I_would_like_to")}:</h5>
                            <div className={styles.formOptionsContainers}>
                                <label className={styles.cardLabel}>
                                    <input type="radio" name="option" value="1"/> {t("reactivate_my_subscription")}
                                </label>
                                <label className={styles.cardLabel}>
                                    <input type="radio" name="option" value="2"/> {t("recover_all_my_data")}
                                </label>
                            </div>
                            <Button containerClassName={styles.cardButtonContainer} title={t("submit")} type="submit" waiting={waiting}/> 
                        </form>
                    </div>
                </Container>
            </div>
            <SuspendModal
                show={showModal}
                open={handleOpenModal}
                close={handleCloseModal}
            />
        </>
    )
}

export default Suspended