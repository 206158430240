import { POST } from "../network";
import { customerActivityEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
  try {
    return await POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

export const createActivity = async data => {
    await setData(customerActivityEndpoints.create, data);
};

export const updateActivity = async data => {
    await setData(customerActivityEndpoints.update, data);
};

export const removeActivity = async id => {
    await setData(`${customerActivityEndpoints.remove}${id}`);
};