import React, {useContext} from 'react';
import styles from './style.module.css';
import cn from 'classnames';
import Input from "../../../common/core/Input";
import Col from "react-bootstrap/Col";
import DashboardTabs from "../../../common/parts/DashboardTabs";
import Row from "react-bootstrap/Row";
import ButtonsPair from "../../../common/parts/ButtonsPair";
import {isEmpty} from "../../../../utils/StringUtils";
import AutoForm from "../../../common/core/AutoForm";
import useWaiting from "../../../common/hooks/useWaiting";
import DataContext from "../../../../contexts/DataContext";
import {useTranslation} from "../../../../contexts/LocaleContext";
import GuideContext from "../../../Onboarding";
import { useRestriction } from "../../../../contexts/Restriction/RestrictionContext"

const CustomInput = ({title, ...rest}) => (
  <>
    <Col className={cn(styles.title, 'col-12 pl-2 mb-3')}>{title.toUpperCase()}</Col>
    <Col className={'col-12'}>
      <Input heigth={40} {...rest}/>
    </Col>
  </>
);

const CustomForm = ({onSubmit, close, initialValues}) => {
  const [waiting, submit] = useWaiting(onSubmit);
  const {company} = useContext(DataContext);
  const {t} = useTranslation()
  const {markDone} = useContext(GuideContext)
  return (
    <AutoForm
      onSubmit={(a)=>{
        markDone('save product')
        submit(a)
      }}
      className={styles.form}
      initialValues={{producer: company.data.name, ...initialValues}}
    >
      <Row>
        <Col className={'col-4 pr-2'}>
          <CustomInput
            field={'name'}
            title={t('name_of_product')}
          />
        </Col>
        <Col className={'col-2 px-2'}>
          <CustomInput
            field={'price'}
            title={t('price')}
          />
        </Col>
        <Col className={'col-4 pl-2'}>
          <CustomInput
            field={'unitmeasure'}
            title={t( "u.m._unit_measure")}
          />
        </Col>
      </Row>
      <Row className={'pt-2'}>
        <Col className={'col-5 pr-2'}>
          <CustomInput
            field={'producer'}
            title={t('producer')}
          />
        </Col>
        <Col className={'col-5 pl-2'}>
          <CustomInput
            field={'delivery'}
            title={t('delivery')}
          />
        </Col>
      </Row>
      <Row>
        <Col className={'col-10 pt-2'}>
          <CustomInput
            field={'generaldescription'}
            title={t('general_description')}
          />
        </Col>
        <Col className={'col-10 pt-2'}>
          <CustomInput
            field={'keywords'}
            title={t('keywords')}
            placeholder={t("enter_comma-separated_keywords")}
          />
        </Col>
      </Row>
      <Row>
        <ButtonsPair
          saveWaiting={waiting}
          onCancel={close}
          className={'mb-2'}
        />
      </Row>
    </AutoForm>
  );
};

const ModalProduct = ({
  editingValues={},
  close=()=>{},
  initWithName=null
}) => {
  const {t} = useTranslation()
  const {products} = useContext(DataContext);
  const { withRestrictions } = useRestriction();

  const createSubmit = (formState) => {
    withRestrictions(async (props) =>  await products.create(props))({...formState});
    close();
  };

  const editSubmit = (formState) => {
    withRestrictions(async (props) =>  await products.update(props))({...formState, id: editingValues.id});
    close();
  };

  const TABS = [{
    name: isEmpty(editingValues) ? t('add_new_product').toUpperCase() : `${t("edit_product").toUpperCase()} #${editingValues.id}`,
    content: (
      <CustomForm
        initialValues={initWithName ? {...editingValues, name: initWithName} : editingValues}
        onSubmit={isEmpty(editingValues) ? createSubmit : editSubmit}
        close={close}
      />
    ),
  }];

  return (
    <DashboardTabs
      tabs={TABS}
      readyContents={TABS.map(tab => tab.content)}
    />
  );
};

export default ModalProduct;
