import React, {useEffect, useContext, useCallback} from "react";
import cn from "classnames";
import Button from "../common/core/Button";
import logo from './logo.png'

import styles from "./style.module.css";
import NetworkContext from "../../contexts/NetworkContext";
import DataContext from "../../contexts/DataContext";
import {Redirect} from "react-router-dom";
import SignIn from "../SignIn";
import { useTranslation } from "../../contexts/LocaleContext";
import { POST } from "../../api/network";
import { useState } from "react";

const ConfirmEmail = () => {
    const { t } = useTranslation();
    const networkContext = useContext(NetworkContext);
    const {account} = useContext(DataContext);
    const network = useContext(NetworkContext);
    const {loggedIn, isReady: isNetworkReady} = network;

    const [time, setTime] = useState(0);

    useEffect(() => {
        account._get();
    }, []);

    const getAccData = useCallback(async () => {
        await account._get();
    }, []);

    useEffect(() => {
        if (loggedIn) {
            getAccData();
        }
    }, [loggedIn, getAccData]);

    const confirm = () => {
        window.location.reload();
    }

    const onResendConfirmClick = async () => {
      //Проверить ручку
      await POST("/api/Account/resendconfirm");
      timer();
    };

    const onLoginClick = async () => {
      await account._get()
      return;
    };

    const timer = () => {
      setTime(15);
      const intervalId = setInterval(() => {
        setTime((prev) => {
          if (prev === 0) {
            clearTimeout(intervalId);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    };

    return (
      <div className={styles.wrapper}>
        <div className={styles.btnWrapper}>
          <Button onClick={networkContext.onLogout} title={t("Log_out")} />
          <Button onClick={onLoginClick} title={t("Login")} />
          <Button onClick={confirm} title={t("Confirm")} />
        </div>
        <div className={styles.imageContainer}>
          <img src={logo} alt="" />
          <div className={styles.textWrapper}>
            <p className={styles.textBold}>{t(`confirm_your_email`)}</p>
            <p className={styles.textNormal}>{t(`please_check_your_email_to_activate_account`)}</p>
            {time ? (
              <p>{t(`resend_via_sec`)} {time}</p>
            ) : (
              <p className={styles.resendDescription}>
                {t(`resend_confirmation_link`)} {account.data.workemail}
              </p>
            )}

            <Button containerClassName={styles.resendButton} onClick={onResendConfirmClick} title={t("resend")} />

            <p>
              <svg
              className={styles.icon}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 6L12 13L2 6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </p>
          </div>
        </div>
      </div>
    );
};

export default ConfirmEmail;
