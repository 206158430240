import {GET} from "../network";
import { targetMarketEndpoints } from "../requestEndpoints";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data); 
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

const getListData = async path => {
    const data = await getData(path);
    if (data) {
        return data.items;
    }
    return [];
};

const getFile = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.data;
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

export const getMarketsData = async () => {
    return await getListData(targetMarketEndpoints.getList);
};

export const exportMarkets = async () => {
    return await getFile(targetMarketEndpoints.exportCsv, String);
};