import "react-table/react-table.css";
import React, {useContext, useEffect, useState} from "react";
import { useParams } from "react-router-dom";

import { addressToStr } from "../../../../utils/StringUtils";
import { useCvsIO } from "../../../common/parts/ImportCsvModal";
import { useLoaded } from "../../../../contexts/DataContext";
import { useRestriction } from "../../../../contexts/Restriction/RestrictionContext"
import {getPagedPartnersData} from "../../../../api/Partner/dataGetters";
import {useTranslation} from "../../../../contexts/LocaleContext";
import AddEditTableModal from "../../../common/parts/AddEditTableModal";
import DashboardTabs from "../../../common/parts/DashboardTabs";
import EditableTable from "../../../common/parts/EditableTable";
import ElectByIndex from "../../../common/core/ElectByIndex";
import Enum from "../../../../utils/enum";
import FetchLoading from "../../../common/parts/FetchLoading";
import GuideContext from "../../../Onboarding";
import PartnerForm from "./PartnerForm";
import regularTableControllers from "../../../common/parts/regularTableControllers";
import styles from "../Customers/style.module.css";

const columns = [
  {
    Header: "CODE#",
    accessor: "id",
    width: 65,
    index: 0
  },
  {
    Header: "NAME",
    accessor: "name",
    filter: true,
    width: 100,
    index: 1
  },
  {
    Header: "ADDRESS",
    accessor: "address",
    filter: true,
    width: 120,
    index: 2,
    format: addressToStr
  },
  {
    Header: "EMAIL",
    accessor: "email",
    filter: true,
    width: 150,
    index: 3
  },
  {
    Header: "PHONE",
    accessor: "phone",
    filter: true,
    width: 100,
    index: 4
  },
  {
    Header: "WHAT DOES THEIR COMPANY DO",
    accessor: "scope",
    filter: true,
    minWidth: 140,
    index: 5
  },
  {
    Header: "CONTACT PERSON",
    accessor: "contactperson",
    filter: true,
    minWidth: 100,
    index: 8
  },
  {
    Header: "KEYWORDS",
    accessor: "keywords",
    filter: true,
    width: 100,
    index: 9
  }
];

const [PARTNER_MODAL] = Enum(1);

function Partners() {
  const params = useParams();
  const { isLoaded, partners } = useLoaded("partners");
  const [modalIndex, setModalIndex] = useState(null);
  const {t} = useTranslation()
  const { withRestrictions } = useRestriction();
  const [editingValues, setEditingValues] = useState({});

  useEffect(() => {
    if (!partners.isLoaded || !params.id) return;

    partners.getItemById(params.id).then((partner) => {
      if (partner?.id) openEditModal(partner);
    });
  }, [params, isLoaded]);

  const openEditModal = product => {
    setEditingValues(product);
    setModalIndex(PARTNER_MODAL);
  };

  const closeModal = () => {
    setEditingValues({});
    setModalIndex(null);
  }

  const {setPage, markDone} = useContext(GuideContext)

  useEffect(()=>{setPage('partners')},[])

  const table = isLoaded ? (
    <EditableTable
      getter={getPagedPartnersData}
      data={partners.data}
      deleteItem={partners.remove}
      editItem={openEditModal}
      columns={columns.map((item)=>({...item, Header: t(item.Header.toLowerCase().replaceAll(' ','_')).toUpperCase()}))}
      headerClassName={styles.tableHeader}
      noWhatText={"partners"}
    />
  ) : (
    <FetchLoading />
  );

  const { importCsvModal, importCSV, exportCSV, exportWaiting } = useCvsIO(
    partners,
    "Name,Address,Email,Phone,What Does Their Company Do?,What Does Their Company Need?,Who Are Their Customers?,Business Facebook Page Link,Contact Person, Keywords"
  );

  const tabs = [
    {
      name: t("partners").toUpperCase(),
      content: table,
      controllers: regularTableControllers({
        addNew: withRestrictions(() => setModalIndex(PARTNER_MODAL)),
        importCSV: withRestrictions(importCSV),
        exportCSV,
        exportWaiting,
        t
      })
    }
  ];

  const modals = [
    <AddEditTableModal
      list={partners}
      CustomForm={PartnerForm}
      editingValues={editingValues}
      close={closeModal}
      name={t("partner")}
    />
  ];

  const contents = tabs.map(item => item.content);

  const main = (
    <DashboardTabs tabs={tabs} readyContents={contents} startTabIndex={0} />
  );

  return (
    <>
      <ElectByIndex index={modalIndex} defaultComponent={main}>
        {modals}
      </ElectByIndex>
      {importCsvModal}
    </>
  );
}

export default Partners;
