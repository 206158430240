import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import { useTranslation } from '../../../../../../../contexts/LocaleContext';
import Button from '../../../../../../common/core/Button';
import Input from '../../../../../../SignIn/Input';
import InputPlaceholder from '../../../../../../SignIn/InputPlaceholder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'informed';
import moment from 'moment';

const Modal = React.forwardRef(
  ({ className, disabled, title, buttons, children }, ref) => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isShown, setIsShown] = useState(false);

    const open = useCallback(() => {
      setIsOpen(true);
      setIsShown(true);
    }, []);
    const close = useCallback(
      ({ immediately } = {}) => {
        if (disabled && !immediately) return;
        setIsOpen(false);
      },
      [disabled]
    );
    const onBlur = useCallback(
      (event) => {
        if (event.target === event.currentTarget) close();
      },
      [close]
    );
    const onTransitionEnd = useCallback(
      (event) => {
        event.persist();
        if (event.target !== event.currentTarget) return;
        if (!isOpen) setIsShown(false);
      },
      [isOpen]
    );
    useEffect(() => {
      ref.current = { open, close };
    }, [ref, open, close]);

    return (
      <div
        className={cn(styles.modal, className, {
          [styles.modalOpen]: isOpen && isShown,
          [styles.modalShown]: isShown,
        })}
        onClick={onBlur}
        onTransitionEnd={onTransitionEnd}
      >
        <div className={cn(styles.wrapper)} onBlur={onBlur}>
          <div className={cn(styles.header)}>
            <h4 className={cn(styles.title)}>{title}</h4>

            <Button
              containerClassName={styles.closeButton}
              color="transparent"
              title={
                <>
                  <FontAwesomeIcon icon={faClose} width={16} />
                </>
              }
              onClick={close}
            />
          </div>

          <div className={styles.content}>{children}</div>

          <div className={cn(styles.footer)}>
            <Button
              className={styles.button}
              color="#E7E7E8"
              title={
                <>
                  {t('Discard')} <FontAwesomeIcon icon={faClose} width={16} />
                </>
              }
              onClick={close}
            />
            {buttons.map((button) => (
              <Button
                className={cn(styles.button, button.className)}
                {...button}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
);

export default Modal;
