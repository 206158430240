import React, { useState } from "react";
import styles from './styles.module.css'
import cn from "classnames";
import { PulseLoader } from "react-spinners";
import { useTranslation } from "../../../../../../contexts/LocaleContext";
import TouchableOpacity from "../../../../../common/core/TouchableOpacity";

const Button = ({
    title = 'Button',
    color = '#1596C0',
    transparent = false,
    onClick = () => { },
    twoStep = false,
    isLoading = false,
    ...props }) => {

    const [confirmMode, setConfirmMode] = useState(false)

    const { t } = useTranslation()

    const _onClick = () => {
        if (!twoStep) return onClick
        return confirmMode ? onClick : () => setConfirmMode(true)
    }

    const style = {
        backgroundColor: transparent ? 'transparent' : color,
        color: transparent ? color : 'white',
        fontWeight: confirmMode ? 'bold' : 'normal',
    }

    return <button className={cn(styles.button, props.className)}
        style={style}
        onClick={_onClick()}
        disabled={isLoading || !!props?.disabled}
        onMouseOut={() => setConfirmMode(false)}
        {...props}>
        {isLoading ? <PulseLoader color={transparent ? color : 'white'} size={8} /> : (confirmMode ? t('Confirm') : title)}
    </button>
}

export const RoundButton = ({ onClick = () => { }, text = '', img = '', iconCN = 'fas fa-chevron-right', color = '#e5eeff', fontColor = 'black', ...props }) => {
    return <TouchableOpacity className={cn(styles.roundButton, props.className, props.disabled && styles.disabled)}
        onClick={props.disabled ? null : onClick}
        style={{ backgroundColor: color, color: fontColor }}
        disabled={props.disabled || props.waiting}
        {...props}>
        {text}
        {img && <img style={{ width: 20, height: 20 }} src={img} alt={'..'} />}
        {iconCN && <i className={iconCN} />}
    </TouchableOpacity>
}

export default Button
