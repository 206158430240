import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import Row from 'react-bootstrap/Row';
import Flows from '../Flows';
import { useQuery } from '../../index';
import { useTranslation } from '../../../../../../contexts/LocaleContext';
import useForm from '../../common/useForm';
import CardRow from '../../common/CardRow';
import Input from '../../common/Input';
import Controllers from '../../common/Controllers';
import Button, { RoundButton } from '../../common/Button';
import CorporateContext from '../../CorporateContext';
import DepartmentView from './DepartmentView';
import EmployeeView from './EmployeeView';
import Worker, { InviteWorkerModal, WorkerSmall } from '../../common/Worker';
import useNavigation from '../../../../../common/hooks/useNavigation';
import styles from './styles.module.css';
import { SectionHeader } from '../Dashboard';
import CustomModal from '../../common/Modal';
import SlideUpScreen from '../../common/SlideUpScreen';
import plus from '../../../../images/plus.svg';

const Structures = () => {
  const query = useQuery();
  const section = query.get('section');
  const itemId = query.get('item_id');
  const department_id = query.get('department_id');
  const action = query.get('action');

  const getContent = () => {
    switch (section) {
      case 'departments': {
        if (!itemId) return <Departments />;
        else
          return (
            <DepartmentView id={itemId} inviteOnStart={action === 'invite'} />
          );
      }
      case 'employee':
        return <EmployeeView id={itemId} departmentId={department_id} />;
      case 'company':
        return <Company />;
      case 'flows':
        return <FlowsContainer />;
      default:
        return (
          <div className="p-4">
            <Company />
            <Departments />
            <FlowsContainer />
          </div>
        );
    }
  };

  return <SlideUpScreen show={true}>{getContent() || null}</SlideUpScreen>;
};

const Company = () => {
  const { t } = useTranslation();
  const { company } = useContext(CorporateContext);

  const { name, address, phone, email } = company?.data;

  return (
    <div className={styles.cardContainer}>
      <Header>{t('Company')}</Header>
      <div className={styles.companyContent}>
        <div className={styles.companyTable}>
          <div>{t('name')}:</div>
          <div>{t('address')}:</div>
          <div>{t('phone')}:</div>
          <div>{t('email')}:</div>
        </div>
        <div className={styles.companyTable}>
          <div>{name}</div>
          <div>{(address?.street || '') + ' ' + (address?.building || '')}</div>
          <div>{phone || ''}</div>
          <div>{email || ''}</div>
        </div>
      </div>
    </div>
  );
};

const FlowsContainer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.cardContainer}>
      <Header>{t('Flows')}</Header>
      <div className={styles.flowsContent}>
        <Flows />
      </div>
    </div>
  );
};

const Departments = () => {
  const { t } = useTranslation();
  const { company, departments, role } = useContext(CorporateContext);
  const [addModalShow, setAddModalShow] = useState(false);
  const [inviteModalShow, setInviteModalShow] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState({
    id: null,
    name: '',
  });

  const onSelectDepartment = (id, name) => {
    setSelectedDepartment((prev) => ({
      ...prev,
      id,
      name,
    }));
    setInviteModalShow(true);
  };

  const modalShow = () => setAddModalShow(true);
  const modalHide = () => setAddModalShow(false);

  const createDepartment = async (data) => {
    await departments.create(data);
    modalHide();
  };

  useEffect(() => {
    departments.load();
  }, []);

  const {
    edit,
    save,
    isSaveWaiting,
    cancel,
    editMode,
    disabled,
    getInputProps,
  } = useForm({
    onCreate: createDepartment,
    requiredFieldNames: ['name'],
  });
  useEffect(() => {
    if (!editMode) edit();
  }, [editMode]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Header>{t('Departments')}</Header>
        <Row>
          <DepartmentsOverview
            departments={departments}
            onSelectDepartment={onSelectDepartment}
          />
          {role?.data === 'CEO' && (
            <div className={cn(styles.noUsers, styles.addDepartment)}>
              <RoundButton
                img={plus}
                iconCN=""
                color="#CCF1F3"
                fontColor="#B11873"
                onClick={modalShow}
              />
              <div>{t('Add department')}</div>
            </div>
          )}
        </Row>
      </div>
      <CustomModal
        title={t('Add new department')}
        onClose={modalHide}
        isVisible={addModalShow}
      >
        <CardRow>
          <Input title={t('Name')} {...getInputProps('name')} />
          <Input
            title={t('Manager title')}
            {...getInputProps('title_manager')}
          />
        </CardRow>
        <Controllers>
          <Button
            title={t('Cancel')}
            transparent
            color={'black'}
            onClick={modalHide}
          />
          <Button
            title={t('Save')}
            isLoading={isSaveWaiting}
            onClick={save}
            disabled={disabled}
          />
        </Controllers>
      </CustomModal>
      <InviteWorkerModal
        isVisible={inviteModalShow}
        onClose={() => setInviteModalShow(false)}
        companyId={company.data?.id}
        departmentId={selectedDepartment.id}
        name={selectedDepartment.name}
      />
    </>
  );
};

export const DepartmentsOverview = ({
  departments = { data: [] },
  onSelectDepartment = () => {},
}) => {
  return (
    <div className={styles.mainRow}>
      {departments.data?.map(
        ({ name, staff_size, id, employees_department }) => (
          <Department
            name={name}
            staff_size={employees_department?.length}
            id={id}
            employees={employees_department}
            key={id}
            onSelectDepartment={onSelectDepartment}
          />
        )
      )}
    </div>
  );
};

export const Department = ({
  name = 0,
  staff_size = 0,
  id = 0,
  employees,
  onSelectDepartment = () => {},
}) => {
  const { nav, PATHS } = useNavigation();
  const { role } = useContext(CorporateContext);
  const { t } = useTranslation();
  const navigate = (invite = false) => {
    if (role?.data === 'Employee') return;

    nav(PATHS.STRUCTURES, 'departments', id, invite && 'invite');
  };

  const users = employees?.map((user) => ({
    name: user?.user_name || null,
    profilePicture: user?.avatar,
    id: user?.id,
    isManager: user?.is_manager,
    departmentId: id,
  }));

  return (
    <div className={styles.departmentCard}>
      <div className={styles.departmentCardHeader}>
        <div
          className={styles.departmentButton}
          onClick={() => navigate(false)}
        >
          <p>{name}</p>
          <div className={styles.seeAll}>{staff_size}</div>
        </div>
      </div>
      <div className={styles.departmentCardContent}>
        {users.map(WorkerSmall)}
        {role?.data !== 'Employee' && (
          <div className={styles.noUsers}>
            <RoundButton
              img={plus}
              iconCN=""
              color="#CCF1F3"
              fontColor="#B11873"
              onClick={() => onSelectDepartment(id, name, staff_size)}
            />
            <div>{t('Invite user')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export const Header = ({ children }) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerText}>{children}</div>
    </div>
  );
};

export default Structures;
