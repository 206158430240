import React from "react";
import EditableTable from '../../../common/parts/EditableTable';
import styles from './style.module.css';
import {useLoaded} from "../../../../contexts/DataContext";
import FetchLoading from "../../../common/parts/FetchLoading";
import {getPagedCustomersB2BData} from "../../../../api/Customer/dataGetters";
import {useTranslation} from "../../../../contexts/LocaleContext";
import { addressToStr } from "../../../../utils/StringUtils";

const columns = [{
  Header: 'CODE#',
  accessor: 'id',
  width: 65,
  index: 0,
},{
  Header: 'NAME',
  accessor: 'name',
  filter: true,
  width: 120,
  index: 1,
},{
  Header: 'ADDRESS',
  accessor: 'address',
  filter: true,
  width: 200,
  index: 2,
  format: addressToStr
},{
  Header: 'EMAIL',
  accessor: 'email',
  filter: true,
  width: 150,
  index: 3,
},{
  Header: 'PHONE',
  accessor: 'phone',
  filter: true,
  width: 100,
  index: 4,
},{
  Header: 'BANK DETAILS',
  accessor: 'bank_details',
  minWidth: 100,
  index: 5,
},{
  Header: 'WHAT DOES THEIR COMPANY DO?',
  accessor: 'directionactivity',
  filter: true,
  minWidth: 140,
  index: 6,
},{
  Header: 'PERSON WHO DECIDE',
  accessor: 'responsibleforpurchasing',
  filter: true,
  width: 110,
  index: 8,
},{
  Header: 'KEYWORDS',
  accessor: 'keywords',
  filter: true,
  width: 100,
  index: 10,
}];

function CustomersB2B ({createActivityModal, openEditModal}) {
  const {isLoaded, customersB2B} = useLoaded('customersB2B');
  const {t} = useTranslation();

  return isLoaded ? (
    <EditableTable
      getter={getPagedCustomersB2BData}
      data={customersB2B.data}
      deleteItem={customersB2B.remove}
      editItem={openEditModal}
      columns={columns.map((item)=>({...item, Header: t(item.Header.toLowerCase().replaceAll(' ','_')).toUpperCase()}))}
      headerClassName={styles.tableHeader}
      noWhatText={'customers'}
    />
  ) : (
    <FetchLoading/>
  );
}

export default CustomersB2B;
