import React, { useEffect, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { PulseLoader } from 'react-spinners/dist';
import Markdown from 'react-markdown';

import styles from './styles.module.css';

import TouchableOpacity from '../../../common/core/TouchableOpacity';
import { useTranslation } from '../../../../contexts/LocaleContext';

const Dialog = ({
  messages = [{}],
  isThinking = false,
  isPreviewAvailable = false,
  isRecognizingAudio = false,
  isReady = false,
  isResultsAvailable = false,
  onFinalPreview = () => {},
  onPreview = () => {},
  supportMessageSent = false,
  close = () => {},
}) => {
  const messagesEndRef = useRef();
  const { height, scrollView } = useResizeDetector();

  useEffect(() => {
    scrollDown();
  }, [height]);

  const scrollDown = () => {
    if (messagesEndRef.current)
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    setTimeout(scrollDown, 100);
  }, [messages.length]);

  return (
    <div className={styles.list} ref={scrollView}>
      {messages.map(({ text, sender, ...additional }, id) => (
        <Message
          text={text}
          sender={sender}
          key={id}
          showsPreview={
            isPreviewAvailable && sender == 0 && id === messages.length - 1
          }
          showsResults={
            isResultsAvailable && sender == 0 && id === messages.length - 1
          }
          showPreview={onPreview}
          isReady={isReady && sender == 0 && id === messages.length - 1}
          onFinalPreview={onFinalPreview}
          additional={additional}
          {...additional}
        />
      ))}
      {isThinking ? (
        <Message sender={0} typing={isThinking} key={'msgLoading'} />
      ) : isRecognizingAudio ? (
        <Message sender={1} typing={isRecognizingAudio} key={'speechToTextLoading'} />
      ) : null}
      {supportMessageSent && close()}
      <div ref={messagesEndRef} />
    </div>
  );
};

const Message = ({
  text,
  sender = 0,
  payload = null,
  typing = false,
  showsPreview = false,
  isReady = false,
  onFinalPreview,
  showsResults,
  showPreview = () => {},
}) => {
  const { t } = useTranslation();
  const markdown = useRef(null);

  const isAssistantMessage = !sender;
  const isTextProvided = text && typeof text === 'string';
  const isPreview = showsPreview || showsResults || isReady;
  if (!isTextProvided && !isPreview && !typing) return null;

  return (
    <div
      className={styles.messageContainer}
      style={{ alignItems: !!sender ? 'flex-end' : 'flex-start' }}
    >
      <div
        className={
          isAssistantMessage ? styles.messageIncoming : styles.messageSent
        }
      >
        {isAssistantMessage && (
          <Markdown
            ref={markdown}
            className={styles.messageMarkdown}
            style={{ color: 'white' }}
          >
            {text}
          </Markdown>
        )}
        {!isAssistantMessage && (
          <div className={styles.messageText} style={{ color: 'black' }}>
            {text}
          </div>
        )}
        {typing && (
          <PulseLoader
            color={isAssistantMessage ? 'white' : '#800080FF'}
            size={5}
            style={{ marginVertical: -12, marginHorizontal: -4 }}
          />
        )}
      </div>
      {isPreview && (
        <TouchableOpacity
          onClick={isReady ? onFinalPreview : showPreview}
          className={styles.previewWrapper}
        >
          <i className={showsResults ? 'fas fa-search' : 'fas fa-eye'} />
          {!showsResults && (
            <div className={styles.previewText}>
              {isReady ? t('Preview document') : t('Live Preview')}
            </div>
          )}
          {showsResults && (
            <div className={styles.previewText}>{t('show_results')}</div>
          )}
        </TouchableOpacity>
      )}
    </div>
  );
};

export default Dialog;
