import React from "react";
import styles from "../contents/Customers/style.module.css";
import cn from "classnames";
import Input from '../../common/core/Input'
import Col from "react-bootstrap/Col";
import DashboardTabs from "../../common/parts/DashboardTabs";
import Row from "react-bootstrap/Row";
import ButtonsPair from "../../common/parts/ButtonsPair";
import useWaiting from "../../common/hooks/useWaiting";
import AutoForm from "../../common/core/AutoForm";
import { isEmpty } from "../../../utils/StringUtils";
import moment from "moment";
import {DateInput} from "../../common/core/Input";
import {useTranslation} from "../../../contexts/LocaleContext";

const CustomInput = ({title, InputComponent=Input, ...rest}) => (
    <>
        <Col className={cn(styles.title, 'col-12 pl-2 mb-3')}>{title.toUpperCase()}</Col>
        <Col className={'col-12'}>
            <InputComponent heigth={40} {...rest}/>
        </Col>
    </>
);

const B2BForm = ({ onSubmit, close, initialValues }) => {
    const [waiting, submit] = useWaiting(onSubmit);
    const {t} = useTranslation()
    return (
        <AutoForm
            onSubmit={submit}
            className={styles.form}
            initialValues={initialValues}
        >
            <Row>
                <Col className={"col-4 pr-2"}>
                    <CustomInput field={"name"} title={t("client_name")} />
                </Col>
                <Col className={"col-6 pl-2"}>
                    <CustomInput
                        // field={'address'}
                        field={"fulladdress"}
                        title={t("address")}
                    />
                </Col>
            </Row>
            <Row className={"pt-2"}>
                <Col className={"col-4 pr-2"}>
                    <CustomInput field={"phone"} title={t("phone")} />
                </Col>
                <Col className={"col-6 pl-2"}>
                    <CustomInput field={"email"} title={t("email")} />
                </Col>
            </Row>
            <Row className={"pt-2"}>
                <Col className={"col-10 pt-2"}>
                    <CustomInput field={"bank_details"} title={t("bank_details")} />
                </Col>
            </Row>
            <Row>
                <Col className={"col-10 pt-2"}>
                    <CustomInput
                        field={"directionactivity"}
                        title={t("what_does_their_company_do")}
                    />
                </Col>
                <Col className={"col-10 pt-2"}>
                    <CustomInput
                        field={"needs"}
                        title={t("what_does_their_company_need")}
                    />
                </Col>
                <Col className={"col-10 pt-2"}>
                    <CustomInput
                        field={"responsibleforpurchasing"}
                        title={t("person_who_decide")}
                    />
                </Col>
                <Col className={"col-10 pt-2"}>
                    <CustomInput
                        field={"buyedlasttime"}
                        title={t("what_did_they_took_last_time")}
                    />
                </Col>
                <Col className={"col-10 pt-2"}>
                    <CustomInput
                        field={"keywords"}
                        title={t("keywords")}
                        placeholder={t("enter_comma-separated_keywords")}
                    />
                </Col>
            </Row>
            <Row>
                <ButtonsPair
                    saveWaiting={waiting}
                    onCancel={close}
                    className={"pb-2"}
                />
            </Row>
        </AutoForm>
    );
};

const B2PForm = ({onSubmit, close, initialValues={}}) => {
    const [waiting, submit] = useWaiting(onSubmit);
    const defaultValues = {dateofbirth: moment()};
    const {t} = useTranslation()
    return (
        <AutoForm
            onSubmit={submit}
            className={styles.form}
            initialValues={isEmpty(initialValues) ? defaultValues : initialValues}
        >
            <Row>
                <Col className={'col-4 pr-2'}>
                    <CustomInput
                        field={'name'}
                        title={t("name_of_customer")}
                    />
                </Col>
                <Col className={'col-6 pl-2'}>
                    <CustomInput
                        // field={'address'}
                        field={'fulladdress'}
                        title={t('address')}
                    />
                </Col>
            </Row>
            <Row className={'pt-2'}>
                <Col className={'col-4 pr-2'}>
                    <CustomInput
                        field={'phone'}
                        title={t('phone')}
                    />
                </Col>
                <Col className={'col-6 pl-2'}>
                    <CustomInput
                        field={'email'}
                        title={t('email')}
                    />
                </Col>
                <Col className={'col-4 pt-2 pr-2'}>
                    <CustomInput
                        InputComponent={DateInput}
                        field={'dateofbirth'}
                        title={t('date_of_birth')}
                    />
                </Col>
                <Col className={'col-6 pl-2 pt-2'}>
                    <CustomInput field={"bank_details"} title={t("bank details")} />
                </Col>
                <Col className={'col-10 pt-2'}>
                    <CustomInput
                        field={'facebookprofile'}
                        title={t('facebook_profile')}
                    />
                </Col>
            </Row>
            <Row>
                <Col className={'col-10 pt-2'}>
                    <CustomInput
                        field={'directionactivity'}
                        title={t("what_does_he/she_do_profession")}
                    />
                </Col>
                <Col className={'col-10 pt-2'}>
                    <CustomInput
                        field={'buyedlasttime'}
                        title={t("what_did_they_took_last_time")}
                    />
                </Col>
                <Col className={'col-10 pt-2'}>
                    <CustomInput
                        field={'keywords'}
                        title={t('keywords')}
                        placeholder={t('enter_comma-separated_keywords')}
                    />
                </Col>
            </Row>
            <Row>
                <ButtonsPair
                    saveWaiting={waiting}
                    onCancel={close}
                    className={'pb-2'}
                />
            </Row>
        </AutoForm>
    );
};


const CustomerForm = (
    {
        onB2BSubmit=()=>{},
        onB2CSubmit=()=>{},
        close=()=>{},
        editingValues={},
        initWithName=null}) => {
    const {t} = useTranslation()
    const TABS = [
        {
            name: isEmpty(editingValues)
                ? t("add_new_")+" B2B"
                : `EDIT B2B CUSTOMER #${editingValues.id}`,
            content: (
                <B2BForm
                    initialValues={initWithName ? {...editingValues, name: initWithName} : editingValues}
                    onSubmit={onB2BSubmit}
                    close={close}
                />
            )
        },
        {
            name: isEmpty(editingValues)
                ? t("add_new_")+" B2C"
                : `EDIT B2C CUSTOMER #${editingValues.id}`,
            content: (
                <B2PForm
                    initialValues={initWithName ? {...editingValues, name: initWithName} : editingValues}
                    onSubmit={onB2CSubmit}
                    close={close}
                />
            )
        }
    ];

    return (
        <DashboardTabs tabs={TABS} readyContents={TABS.map(tab => tab.content)} />
    );
};

export default CustomerForm;
