import { DELETE, POST } from "../network";
import { sharedTemplateEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
  try {
    return await POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};


const deleteData = async (path, data) => {
    try {
      return await DELETE(path, data)
    } catch (e) {
      console.error(path, e);
    }
}

export const shareTemplate = async (data) => {
    await setData(sharedTemplateEndpoints.sharedTemplateWith, data)
}
  
export const copyTemplate = async (data) => {
    return await setData(sharedTemplateEndpoints.copyTemplate, data)
}
  
export const removeUser = async (data) => {
    await deleteData(sharedTemplateEndpoints.removeUser, data)
}