import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import cn from 'classnames';
import styles from './style.module.css';
import { useTranslation } from '../../../../../../../contexts/LocaleContext';
import Card from '../../../../../../common/parts/Card';
import { useNavigate } from 'react-router-dom';
import TaskTable from '../TaskTable';

const GlobalTask = ({ className, task, ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const accordionRef = useRef(null);

  const maxHeight = useMemo(() => {
    return isOpen ? accordionRef.current.scrollHeight : 0;
  }, [accordionRef?.current?.scrollHeight, isOpen]);

  const open = () =>
    navigate(
      `/dashboard/company-dashboard/tasks/view/${
        task.parent_task_id ? 'subtask/' : 'task/'
      }${task.task_id}`
    );
  const toggle = (event) => {
    event.stopPropagation();
    setIsOpen((value) => !value);
  };

  return (
    <div className={cn(styles.wrapper, className)} {...props}>
      <Card className={cn(styles.card)} onClick={open}>
        <div className={cn(styles.cardHeading)}>
          <button
            className={cn(styles.slideButton)}
            type="button"
            onClick={toggle}
          >
            <i
              className={cn(styles.slideButtonIcon, 'fas fa-chevron-down')}
              style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
            />
          </button>
          <h5 className={cn(styles.title)}>{task.title}</h5>
        </div>
        <div className={cn(styles.cardMain)}>
          <span className={cn(styles.details)}>
            {t('Number of subtasks:')}
            <b>{task.child_tasks?.length || 0}</b>
          </span>
          <span className={cn(styles.details)}>
            {t('Allocated people:')}
            <b>{task.assign_to_ids?.length || 1}</b>
          </span>
          <span className={cn(styles.details)}>
            {t('Date created:')}
            <b>{moment(task.created_at).format('DD.MM.YYYY')}</b>
          </span>
        </div>
      </Card>

      <div
        ref={accordionRef}
        className={cn(styles.accordion)}
        style={{ maxHeight }}
      >
        <div className={cn(styles.accordionContent)}>
          {task.child_tasks.length > 0 && (
            <TaskTable data={task.child_tasks.reverse()} />
          )}
        </div>
      </div>
    </div>
  );
};

export default GlobalTask;
