import React, { useState } from "react";
import Wrapper from "../../common/core/Wrapper";
import styles from "./style.module.scss";
import { Form } from 'informed';
import Input from "../Input";
import Button from "../../common/core/Button";
import InputPlaceholder from "../InputPlaceholder";
import useWaiting from "../../common/hooks/useWaiting";
import cn from 'classnames';
import { POST } from "../../../api/network";
import { useTranslation } from "../../../contexts/LocaleContext";
import { Col, Row } from "react-bootstrap";

function ForgotPasswordModal({ close }) {
  const { t } = useTranslation()


  async function resetPassword({ email }) {
    setText('');
    const res = await POST('/api/Account/resetpassword', { email });
    if (res && res.status === 200) {
      setText(t("password_reset,_check_your_email"));
    } else {
      setText(t("an_error_occurred"));
    }
  }


  const [waiting, resetPasswordWaiting] = useWaiting(resetPassword);
  const [text, setText] = useState('');

  return (
    <Wrapper className={styles.container}>
      <div className={styles.box}>
        <Form
          onSubmit={resetPasswordWaiting}
          className={styles.formHolder}

        >
          <Input
            field="email"
            type="email"
            placeholder={<InputPlaceholder text={t("email")} />}
            required
          />
          {<div className={cn(styles.fail, text && styles.failOpen)}>
            {text}
          </div>}
          <Row>

            <Col>
              <Button
                containerClassName={styles.resetButton}
                title={t("reset_password")}
                type="submit"
              />
            </Col>
            <Col>
              <Button
                containerClassName={styles.button}
                onClick={close}
                title={t("close")}
                type="button"
              />
            </Col>
          </Row>


        </Form>
      </div>
    </Wrapper>
  );
}

export default ForgotPasswordModal;
