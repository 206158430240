export const fastBossEndpoints = {
    wpJson: "https://fastboss.ai/wp-json/wp/v2/pages/701",
}

export const accountEndpoints = {
    signIn: "/api/Account/signin",
    resetPassword: "/api/Account/resetpassword",
    signUp: "/api/Account/signup",
    update: "/api/Account/update",
    getInfo: "/api/Account/getinfo",
    avatar: "/api/Account/avatar",
    uploadAvatar: "/api/Account/uploadavatar",
    suspend: "/api/Account/suspend",
    unsuspendByCode: "/api/Account/unsuspend-by-code",
    removeByCode: "/api/Account/remove-by-code",
    sendWithCodeToEmail: "/api/Account/send-with-code-to-email",
    confirm: "/api/Account/confirm",
    resendConfirm: "/api/Account/resendconfirm",
    seeTutorials: "/api/Account/seetutorials",
    cameFirstTime: "/api/Account/camefirsttime",
    changePassword: "/api/Account/changepassword",
}

export const accountSubscriptionsEndpoints = {
    createCharge: "/api/AccountSubscriptions/createcharge",
    getList: "/api/AccountSubscriptions/getlist",
    getPrices: "/api/AccountSubscriptions/getprices",
    createCheckoutSession: "/api/AccountSubscriptions/create-checkout-session",
}

export const assistantSettingsEndpoints = {
    create: "/api/AssistantSettings/create",
    update: "/api/AssistantSettings/update",
    avatar: "/api/AssistantSettings/avatar",
    uploadAvatar: "/api/AssistantSettings/uploadavatar",
    get: "/api/AssistantSettings/get",
    remove: "/api/AssistantSettings/remove",
}

export const assistantSupportEndpoints = {
    sendWithScreenshot: "/api/AssistantSupport/send-with-screenshot",
}

export const businessEndpoints = {
    create: "/api/Business/create",
    update: "/api/Business/update",
    getById: "/api/Business/get/",
    getList: "/api/Business/getlist",
    exportCsv: "/api/Business/exportcsv",
    importCsv: "/api/Business/importcsv",
    removeById: "/api/Business/remove/",
}

export const catalogEndpoints = {
    generateShareLink: "/api/Catalog/generate-share-link",
    getFileByToken: "/api/Catalog/get-file-by-token",
    getAttachCatalogProducts: "/api/Catalog/get-attach-catalog-products",
    getAttachCatalogServices: "/api/Catalog/get-attach-catalog-services",
    searchCatalogByProductService: "/api/Catalog/search-catalog-by-product-service/",
    attachToProduct: "/api/Catalog/attach-to-product",
    attachToService: "/api/Catalog/attach-to-service",
    deleteCatalogProduct: "/api/Catalog/delete-catalog-product",
    deleteCatalogService: "/api/Catalog/delete-catalog-service",
}

export const commandsEndpoints = {
    search: "/api/Commands/search",
}

export const companyEndpoints = {
    search: "/api/Company/search",
    create: "/api/Company/create",
    update: "/api/Company/update",
    getById: "/api/Company/get/",
    getList: "/api/Company/getlist",
    exportCsv: "/api/Company/exportcsv",
    importCsv: "/api/Company/importcsv",
    remove: "/api/Company/remove/",
}

export const companyAccountEndpoints = {
    createCompany: "/api/v2/CompanyAccount/create-company",
    updateCompany: "/api/v2/CompanyAccount/update-company",
    deleteCompany: "/api/v2/CompanyAccount/delete-company/",
    getInfo: "/api/v2/CompanyAccount/getinfo",
    deleteEmployeeFromCompany: "/api/v2/CompanyAccount/delete-employee-from-company",
    findUser: "/api/v2/CompanyAccount/find-user",
    userActivity: "/api/v2/CompanyAccount/user-activity",
    getInvitationsList: "/api/v2/CompanyAccount/get-invitations-list",
    createFlow: "/api/v2/CompanyAccount/create-flow",
    updateFlow: "/api/v2/CompanyAccount/update-flow",
    deleteFlow: "/api/v2/CompanyAccount/delete-flow",
    getFlows: "/api/v2/CompanyAccount/get-flows",
    createAuditLog: "/api/v2/CompanyAccount/create-auditlog",
    getListAuditLogs: "/api/v2/CompanyAccount/get-list-auditlogs",
    deleteAuditLogs: "/api/v2/CompanyAccount/delete-auditlogs",
    createTask: "/api/v2/CompanyAccount/create-task",
    updateTaskCompany: "/api/v2/CompanyAccount/update-task-company",
    deleteTaskCompany: "/api/v2/CompanyAccount/delete-task-company/",
    createTaskHistory: "/api/v2/CompanyAccount/create-task-history",
    getTasksList: "/api/v2/CompanyAccount/get-tasks-list",
    transferEmployee: "/api/v2/CompanyAccount/transfer-employee",
    acceptInviteTransfer: "/api/v2/CompanyAccount/accept-invite-transfer",
    deleteEmployee: "/api/v2/CompanyAccount/delete-employee/",
    getAllTemplates: "/api/v2/CompanyAccount/get-all-templates",
    getMyCompanyId: "/api/v2/CompanyAccount/get-my-company-id",
    getMyDepartment: "/api/v2/CompanyAccount/get-my-department",
    getMyEmployee: "/api/v2/CompanyAccount/get-my-employee",
    getMyRole: "/api/v2/CompanyAccount/get-my-role",
    inviteUserToCompany: "/api/v2/CompanyAccount/invite-user-to-company",
    confirmInviteEmployee: "/api/v2/CompanyAccount/confirm-invite-employee"
}

export const companySubscriptionEndpoints = {
    createCustomer: "/api/CompanySubscription/create-customer",
    createPaymentMethod: "/api/CompanySubscription/create-payment-method",
    getMyPaymentMethod: "/api/CompanySubscription/get-my-payment-method",
    createSubscription: "/api/CompanySubscription/create-subscription",
    retryInvoice: "/api/CompanySubscription/retry-invoice",
    retrieveSubscriptionInformation: "/api/CompanySubscription/retrieve-subscription-information",
    cancelSubscription: "/api/CompanySubscription/cancel-subscription",
    updateSubscription: "/api/CompanySubscription/update-subscription",
    retrieveCustomerPaymentMethod: "/api/CompanySubscription/retrieve-customer-payment-method",
    getAllMyInvoices: "/api/CompanySubscription/get-all-my-invoices",
    checkTrial: "/api/CompanySubscription/check-trial",
    getListPrices: "/api/CompanySubscription/get-list-prices",
}

export const competitorEndpoints = {
    search: "/api/Competitor/search",
    create: "/api/Competitor/create",
    update: "/api/Competitor/update",
    getById: "/api/Competitor/get/",
    getList: "/api/Competitor/getlist",
    exportCsv: "/api/Competitor/exportcsv",
    importCsv: "/api/Competitor/importcsv",
    remove: "/api/Competitor/remove/",
}

export const customerEndpoints = {
    personCreate: "/api/Customer/person/create",
    personUpdate: "/api/Customer/person/update",
    personGetById: "/api/Customer/person/get/",
    personGetList: "/api/Customer/person/getlist",
    personExportCsv: "/api/Customer/person/exportcsv",
    personImportCsv: "/api/Customer/person/importcsv",
    personRemove: "/api/Customer/person/remove/",
    personGet: "/api/Customer/person/get",
    personSearch: "/api/Customer/person/search",
    
    businessCreate: "/api/Customer/business/create",
    businessUpdate: "/api/Customer/business/update",
    businessGetById: "/api/Customer/business/get/",
    businessGetList: "/api/Customer/business/getlist",
    businessExportCsv: "/api/Customer/business/exportcsv",
    businessImportCsv: "/api/Customer/business/importcsv",
    businessRemove: "/api/Customer/business/remove/",
    businessGet: "/api/Customer/business/get",
    businessSearch: "/api/Customer/business/search",
}

export const customerActivityEndpoints = {
    create: "/api/CustomerActivity/create",
    update: "/api/CustomerActivity/update",
    getById: "/api/CustomerActivity/get/",
    getList: "/api/CustomerActivity/getlist",
    exportCsv: "/api/CustomerActivity/exportcsv",
    importCsv: "/api/CustomerActivity/importcsv",
    remove: "/api/CustomerActivity/remove/",
}

export const departmentEndpoints = {
    createDepartment: "/api/v2/Department/create-department",
    updateDepartment: "/api/v2/Department/update-department",
    deleteDepartment: "/api/v2/Department/delete-department/",
    getDepartmentList: "/api/v2/Department/get-department-list",
    getDepartmentInfo: "/api/v2/Department/get-department-info/",
    shareWithDocument: "/api/v2/Department/share-with-document",
    getAllSharedDocuments: "/api/v2/Department/get-all-shared-documents",
    createHierarchy: "/api/v2/Department/create-hierarchy",
    deleteHierarchy: "/api/v2/Department/delete-hierarchy/",
    updateHierarchy: "/api/v2/Department/update-hierarchy",
    assignEmployeeHierarchy: "/api/v2/Department/assign-employee-hierarchy",
    assignManagerToDepartment: "/api/v2/Department/assign-manager-to-department",
    getUsersList: "/api/v2/Department/get-users-list",
    getDepartmentTasks: "/api/v2/Department/get-department-tasks/",
    getAllDocuments: "/api/v2/Department/get-all-documents",
    denyAccess: "/api/v2/Department/deny-access",
    openAccess: "/api/v2/Department/open-access",
    reassign: "/api/v2/Department/reassign",
}

export const documentEndpoints = {
    create: "/api/Document/create",
    update: "/api/Document/update",
    getById: "/api/Document/get/",
    getList: "/api/Document/getlist",
    exportCsv: "/api/Document/exportcsv",
    importCsv: "/api/Document/importcsv",
    remove: "/api/Document/remove/",
    get: "/api/Document/get",
    search: "/api/Document/search",
    hitLimitDocuments: "/api/Document/hit-limit-documents",
    getGroupDocuments: "/api/Document/get-group-documents",
}

export const externalAuthEndpoints = {
    facebook: "/api/ExternalAuth/Facebook",
}

export const fileEndpoints = {
    uploadProductsCatalogues: "/api/File/uploadproductscatalogues",
    uploadServicesCatalogues: "/api/File/uploadservicescatalogues",
    remove: "/api/File/removefile/",
    getById: "/api/File/getfile/",
    getList: "/api/File/getfilelist",
    exportCsv: "/api/File/exportcsv",
}

export const localizationEndpoints = {
    get: "/api/Localization/get/",   
    getAllTranslations: "/api/Localization/get-all-translations",
    getAllLocales: "/api/Localization/get-all-locales",
    changeLocale: "/api/Localization/change-locale",
}

export const taskEndpoints = {
    search: "/api/v2/TaskFact/search",
    create: "/api/v2/TaskFact/create-task",
    update: "/api/v2/TaskFact/update-task",
    getById: "/api/v2/TaskFact/get-task/",
    getList: "/api/v2/TaskFact/get-tasks-list",
    exportCsv: "/api/v2/TaskFact/exportcsv",
    importCsv: "/api/v2/TaskFact/importcsv",
    remove: "/api/v2/TaskFact/delete-task/",
}

export const partnerEndpoints = {
    search: "/api/Partner/search",
    create: "/api/Partner/create",
    update: "/api/Partner/update",
    getById: "/api/Partner/get/",
    getList: "/api/Partner/getlist",
    exportCsv: "/api/Partner/exportcsv",
    importCsv: "/api/Partner/importcsv",
    remove: "/api/Partner/remove/",
}

export const productEndpoints = {
    search: "/api/Product/search",
    create: "/api/Product/create",
    update: "/api/Product/update",
    getById: "/api/Product/get/",
    getList: "/api/Product/getlist",
    exportCsv: "/api/Product/exportcsv",
    importCsv: "/api/Product/importcsv",
    remove: "/api/Product/remove/",
    get: "/api/Product/get",
}

export const resetPasswordEndpoints = {
    resetPassword: "/reset-password",
}

export const serverInfoEndpoints = {
    ping: "/api/ServerInfo/ping",
}

export const serviceEndpoints = {
    search: "/api/Service/search",
    create: "/api/Service/create",
    update: "/api/Service/update",
    getById: "/api/Service/get/",
    getList: "/api/Service/getlist",
    exportCsv: "/api/Service/exportcsv",
    importCsv: "/api/Service/importcsv",
    remove: "/api/Service/remove/",
    get: "/api/Service/get",
}

export const sharedTemplateEndpoints = {
    sharedTemplateWith: "/api/SharedTemplate/shared-template-with",
    findUser: "/api/SharedTemplate/find-user",
    sharedWithUsers: "/api/SharedTemplate/shared-with-users",
    templatesSharedWithMe: "/api/SharedTemplate/templates-shared-with-me",
    removeUser: "/api/SharedTemplate/remove-user",
    copyTemplate: "/api/SharedTemplate/copy-template",
    mySharedTemplates: "/api/SharedTemplate/my-shared-templates",
}

export const signatureEndpoints = {
    initiateSignatureReport: "/api/Signature/initiate-signature-report",
    attach: "/api/Signature/attach",
    uploadPicture: "/api/Signature/upload/picture/",
    get: "/api/Signature/get",
    generateShareLink: "/api/Signature/generate-share-link",
    getDocumentByToken: "/api/Signature/get-document-by-token",
    acceptDocumentRecipient: "/api/Signature/accept-document-recipient/",
    wrongDocumentRecipient: "/api/Signature/wrong-document-recipient",
    refuseSignature: "/api/Signature/refuse-signature",
    saveSignatureDocument: "/api/Signature/save-signature-document",
    generateSignatureReport: "/api/Signature/generate-signature-report",
    getSignatureReport: "/api/Signature/get-signature-report/",
    deleteSignatureReport: "/api/Signature/delete-signature-report/",
    checkCustomer: "/api/Signature/check-customer"
}

export const tagEndpoints = {
    create: "/api/Tag/create",
    update: "/api/Tag/update",
    getList: "/api/Tag/getlist",
    delete: "/api/Tag/delete/",
}

export const targetMarketEndpoints = {
    create: "/api/TargetMarket/create",
    update: "/api/TargetMarket/update",
    getById: "/api/TargetMarket/get/",
    getList: "/api/TargetMarket/getlist",
    exportCsv: "/api/TargetMarket/exportcsv",
    importCsv: "/api/TargetMarket/importcsv",
    remove: "/api/TargetMarket/remove/",
}

export const templateEndpoints = {
    create: "/api/Template/create",
    update: "/api/Template/update",
    getById: "/api/Template/get/",
    get: "/api/Template/get",
    getList: "/api/Template/getlist",
    getDefaultList: "/api/Template/getdefaultlist",
    exportCsv: "/api/Template/exportcsv",
    importCsv: "/api/Template/importcsv",
    variableFields: "/api/Template/variablefields",
    remove: "/api/Template/remove/"
}

export const accountInvitesEndpoints = {
    invitationList: "/api/AccountInvites/invitation-list",
    confirmInvite: "/api/AccountInvites/confirm-invite",
    rejectInvite: "/api/AccountInvites/reject-invite",
}

export const accountActiveDepartmentEndpoints = {
    getDepAndCompList: "/api/AccountActiveDepartment/get-dep-and-comp-list",
    chooseActivDepAndComp: "/api/AccountActiveDepartment/choose-activ-dep-and-comp/",
}

export const companyAccountSubscription = {
    createCustomer: "/api/CompanyAccountSubscription/create-customer",
    getInfoCustomer: "/api/CompanyAccountSubscription/getinfo-customer",
    updateCustomer: "/api/CompanyAccountSubscription/update-customer",
    createPaymentMethod: "/api/CompanyAccountSubscription/create-payment-method",
    deletePaymentMethod: "/api/CompanyAccountSubscription/delete-payment-method/",
    getMyPaymentMethod: "/api/CompanyAccountSubscription/get-my-payment-method",
    createSubscription: "/api/CompanyAccountSubscription/create-subscription",
    hasActiveSubscription: "/api/CompanyAccountSubscription/has-active-subscription",
    retryInvoice: "/api/CompanyAccountSubscription/retry-invoice",
    retrieveSubscriptionInformation: "/api/CompanyAccountSubscription/retrieve-subscription-information",
    cancelSubscription: "/api/CompanyAccountSubscription/cancel-subscription/",
    updateSubscription: "/api/CompanyAccountSubscription/update-subscription",
    retrieveCustomerPaymentMethod: "/api/CompanyAccountSubscription/retrieve-customer-payment-method/",
    getAllMyInvoices: "/api/CompanyAccountSubscription/get-all-my-invoices",
    getSubscriptions: "/api/CompanyAccountSubscription/get-subscriptions",
    getPrices: "/api/CompanyAccountSubscription/getprices"
}