import React from 'react';

const ChevronLeftIcon = ({ ...props }) => (
  <svg
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.25 10.5L0.75 6L5.25 1.5"
      stroke="#070F17"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ChevronLeftIcon;
