import React, { useEffect, useState, useContext } from 'react';
import styles from './search.module.css';
import { useTranslation } from '../../../../contexts/LocaleContext';
import SearchWrapper from './SearchWrapper';
import {
  getServiceByKeywords
} from '../../../../api/Service/dataGetters';
import { AssistantContext } from '../../../../contexts/AssistantContext';
import { useNavigate } from 'react-router-dom';

const getTableDataFromResponse = (
  data,
  openService
) => {
  if (!data?.length) return [];

  return data.map((service, index) => {
    return {
      id: service.id || index,
      classNames: {
        bar: styles.serviceBar,
      },
      columns: [
        {
          className: styles.serviceColumn,
          element: service.id,
        },
        {
          className: styles.serviceColumn,
          element: service.name,
        },
        {
          className: styles.serviceColumn,
          element: service.price,
        },
      ],
      onClick: openService(service.id),
    };
  });
};

const SearchServices = ({ keywords = [], onClose = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { close: closeAssistant } = useContext(AssistantContext);
  const [isLoading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [legend, setLegend] = useState({
    classNames: {
      bar: styles.serviceLegendBar,
    },
    columns: [
      {
        icon: 'fas fa-hashtag',
        title: t('code'),
      },
      {
        icon: 'fas fa-concierge-bell',
        title: t('name'),
      },
      {
        icon: 'fas fa-tag',
        title: t('price'),
      },
    ],
  });

  const openService = (id) => () => {
    closeAssistant();
    navigate(`/dashboard/services/${id}`);
  };

  useEffect(() => {
    setLoading(true);
    getServiceByKeywords(keywords).then((services) => {
      setTableData(
        getTableDataFromResponse(services, openService)
      );
      setLoading(false);
    });
  }, []);

  return (
    <SearchWrapper
      query={keywords.join(' ')}
      legend={legend}
      tableData={tableData}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default SearchServices;
