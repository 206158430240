import React from "react";
import cn from "classnames";
import { useTranslation } from "../../../../contexts/LocaleContext";
import { BounceLoader } from "react-spinners";
import TouchableOpacity from "../../../common/core/TouchableOpacity";
import TextareaAutosize from "react-textarea-autosize";
import styles from "./styles.module.css";

const InputArea = ({
  send = () => {},
  toggleRecord = () => {},
  isRecording = false,
  isDisabled = false,
  value = "",
  setValue = () => {},
}) => {
  const { t } = useTranslation();

  const sendMessage = () => {
    send(value);
    setValue("");
  };

  const listenKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className={styles.inputContainer}>
      <div className={styles.inputWrapper}>
        <div className={styles.textInputShape}>
          <TextareaAutosize
            placeholder={t("Type here or use voice input")}
            className={styles.input}
            value={value}
            autoFocus={true}
            onKeyPress={listenKey}
            onChange={(e) => setValue(e.target.value)}
            onSubmit={sendMessage}
            minRows={1}
            maxRows={4}
          />
        </div>
        <TouchableOpacity
          className={cn(styles.buttonMiddle, isDisabled ? styles.buttonDisabled : undefined)}
          onClick={isDisabled ? () => {} : toggleRecord}
          disabled={isDisabled}
        >
          <div
            style={isRecording ? {} : { display: "none" }}
            className={styles.recordIndicator}
          >
            <BounceLoader color={"#790079"} size={160} />
          </div>
          <i
            style={{ color: "white", zIndex: 10 }}
            className={isRecording ? "fas fa-stop" : "fas fa-microphone-alt"}
          />
        </TouchableOpacity>
        <TouchableOpacity
          className={cn(styles.buttonRight, isDisabled ? styles.buttonDisabled : undefined)}
          onClick={isDisabled ? () => {} : sendMessage}
          disabled={isDisabled}
        >
          <i style={{ color: "white" }} className={"fas fa-arrow-up"} />
        </TouchableOpacity>
      </div>
    </div>
  );
};

export default InputArea;
