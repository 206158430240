import React from "react";
import EditableTable from '../../../common/parts/EditableTable';
import "react-table/react-table.css";
import styles from "./style.module.css";
import {formatDate} from "../../../../utils/StringUtils";
import {useLoaded} from "../../../../contexts/DataContext";
import FetchLoading from "../../../common/parts/FetchLoading";
import {getPagedCustomersB2CData} from "../../../../api/Customer/dataGetters";
import {useTranslation} from "../../../../contexts/LocaleContext";
import { addressToStr } from "../../../../utils/StringUtils";

const columns = [{
  Header: 'CODE#',
  accessor: 'id',
  width: 65,
  index: 0,
},{
  Header: 'NAME',
  accessor: 'name',
  filter: true,
  width: 120,
  index: 1,
},{
  Header: 'ADDRESS',
  accessor: 'address',
  filter: false,
  width: 200,
  index: 2,
  format: addressToStr
},{
  Header: 'EMAIL',
  accessor: 'email',
  filter: true,
  width: 150,
  index: 3,
},{
  Header: 'PHONE',
  accessor: 'phone',
  filter: true,
  width: 100,
  index: 4,
},{
  Header: 'BANK DETAILS',
  accessor: 'bank_details',
  minWidth: 100,
  index: 5,
},{
  Header: 'profession',
  accessor: 'directionactivity',
  filter: true,
  minWidth: 150,
  index: 6,
},{
  Header: 'DATE OF BIRTH',
  accessor: 'dateofbirth',
  format: formatDate,
  filter: true,
  width: 100,
  index: 8,
},{
  Header: 'KEYWORDS',
  accessor: 'keywords',
  filter: true,
  width: 100,
  index: 10,
}];

function CustomersB2P ({createActivityModal, openEditModal}) {
  const {isLoaded, customersB2P} = useLoaded('customersB2P');
  const {t} = useTranslation()

  return isLoaded ? (
    <EditableTable
        data={customersB2P.data}
        getter={getPagedCustomersB2CData}
      deleteItem={customersB2P.remove}
      editItem={openEditModal}
      columns={columns.map((item)=>({...item, Header: t(item.Header.toLowerCase().replaceAll(' ','_')).toUpperCase()}))}
      headerClassName={styles.tableHeader}
      noWhatText={'customers'}
    />
  ) : (
    <FetchLoading/>
  );
}

export default CustomersB2P;
