import React, { useEffect } from 'react';
import NavigationBar from './NavigationBar';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { TAB_PATHS } from '../../../../contexts/TabContext';
import Structures from './screens/Structures';
import Flows from './screens/Flows';
import Tasks from './screens/Tasks';
import Company from './screens/Company';
import styles from './styles.module.css';
import CorporateContext, { useCorporateContext } from './CorporateContext';
import { RoundButton } from './common/Button';
import { useTranslation } from '../../../../contexts/LocaleContext';
import useNavigation from '../../../common/hooks/useNavigation';
import { SectionHeader } from './screens/Dashboard';
import ManageTask from './screens/Tasks/ManageTask';

const DASHBOARD_PATH = TAB_PATHS.COMPANY_DASHBOARD;

const PATHS = {
  DASHBOARD: DASHBOARD_PATH,
  STRUCTURES: DASHBOARD_PATH + '/structures',
  FLOWS: DASHBOARD_PATH + '/flows',
  TASKS: DASHBOARD_PATH + '/tasks',
  ADD_TASK: DASHBOARD_PATH + '/tasks/create',
};

export const items = [
  {
    title: 'Dashboard',
    to: PATHS.DASHBOARD,
    exact: true,
  },
  {
    title: 'Structures',
    to: PATHS.STRUCTURES,
  },
  {
    title: 'Tasks',
    to: PATHS.TASKS,
  },
];

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CompanyDashboard = ({}) => {
  const { fetchAll, ...data } = useCorporateContext();
  const { t } = useTranslation();
  const { PATHS, nav } = useNavigation();

  useEffect(() => {
    fetchAll();
  }, []);

  if (!data?.company?.isLoaded) return <div />;
  if (!data.company?.data?.name && data.company.isLoaded)
    return (
      <CorporateContext.Provider value={data}>
        <div className={styles.createCompanyContainer}>
          <SectionHeader
            text={t('Welcome!')}
            className={styles.welcomeHeader}
          />
          <Company forceCreate={true} />
        </div>
      </CorporateContext.Provider>
    );

  return (
    <CorporateContext.Provider value={data}>
      <div className={styles.corporateAccount}>
        <NavigationBar items={items} />
        <Outlet />
      </div>
    </CorporateContext.Provider>
  );
};

export default CompanyDashboard;
