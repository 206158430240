import React, {useEffect, useRef, useState} from 'react'
import styles from './style.module.css'
import Button from "../../../common/core/Button";
import Input from "../../../common/core/Input";
import {Form} from 'informed'
import {findUser, sharedWith} from "../../../../api/SharedTemplate/dataGetters";
import {removeUser, shareTemplate} from "../../../../api/SharedTemplate/dataSetters";
import {useTranslation} from "../../../../contexts/LocaleContext";

const UserItem = ({userName, id, onDelete}) => {
  const [deleting, setDeleting] = useState(false)
  return <div style={{transform: `scale(${deleting ? '0' : '1'})`}} className={styles.userItem}>
    <div >{userName}</div>
    <div className={styles.removeButton} onClick={() => {
      setDeleting(true)
      onDelete(id)
    }}><i className={'fas fa-times'}/></div>
  </div>
}


const ShareDialog = ({templateId = 0, onClose}) => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const {t} = useTranslation()


  const onDelete = async (userId) => {
    await removeUser({
      "accounts_id": [
        userId
      ],
      "template_id": templateId
    })
    await fetchUsers(templateId)
  }

  const fetchUsers = async (templateId) => {
    setLoading(true)
    const res = await sharedWith(templateId)
    if (res instanceof Array) setUsers(res)
    setLoading(false)
  }

  const onSubmit = async (state) => {
    setError(false)
    const searchResult = await findUser(state.email)
    if (users.filter((user)=>{return searchResult && user && user.id === searchResult?.id}).length > 0)
    {
      setError(t('already_listed'))
      return
    }
    if (searchResult?.id) {
      await shareTemplate({accounts_id: [searchResult.id], template_id: templateId})
      await fetchUsers(templateId)
    } else if (searchResult?.error) {setError(searchResult.error)}
    else
      setError(t('error'))
  }

  useEffect(() => {
    fetchUsers(templateId)
  }, [templateId])

  return <div className={styles.dialogBackground}>
    <div className={styles.dialogWrapper}>
      <div className={styles.dialogGrid}>
        <div className={styles.title}><div><h4>SHARE TEMPLATE</h4></div>
          {error && <div className={styles.error}>{error}</div>}</div>
        <div><Form className={styles.searchBar} onSubmit={onSubmit}>
          <div className={styles.input}><Input
            onChange={()=>setError(false)}
            placeholder={t('find_users_by_email')} field={'email'}/></div>
          <div className={styles.button}><Button height={35} type={'submit'}
                                                 title={<i className={'fas fa-plus'}/>}/></div>
        </Form></div>
        {users && users.length > 0 && users.filter((u)=>u!== null).length > 0 ? <div>{t('sharing_with')+':'}
          <div className={styles.users}>
            {users && users.filter((u)=>u!== null).map(user => <UserItem userName={user?.login || t('deleted')}
                        key={user?.id}
                        id={user?.id}
                        onDelete={onDelete}/>)}</div>
        </div> : <div className={styles.hint}>{loading ? 'Loading...' : t('this_template_is_not_shared')}</div> }
        <div className={styles.buttons}>
          <Button title={t('done')} onClick={onClose}/>
        </div>
      </div>
    </div>
  </div>
}

export default ShareDialog
