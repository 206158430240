import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Input, {GenderSelect} from "../../common/core/Input";
import styles from '../style.module.css';
import {Scope} from 'informed';
import moment from "moment";
import {useTranslation} from "../../../contexts/LocaleContext";

//<<<<<<< HEAD
export default () => {
  const {t} = useTranslation()
  return (
      <Row>
        <Col className={"col-12 m-0 pr-2 text-center"}>
          <Col className={styles.subheader}>
            {t("introduce_your_personal_data")}
          </Col>

          <Input field={'name'} placeholder={t('name')} required className={"mt-4"}/>
          <Input field={'surname'} placeholder={t('surname')} required/>
          <Input field={'dateofbirth'} parse={(value) => moment(value).format("YYYY-MM-DDTHH:mm:ss.SSSZ")}
                 placeholder={t('date_of_birth')} required type={"date"} max={'3000-01-01'}/>
        </Col>
      </Row>
  )
};

