import React, {useContext, useState, useRef, useEffect} from "react";
import 'react-credit-cards/es/styles-compiled.css';
import Wrapper from "../../core/Wrapper";
import Row from "react-bootstrap/Row";
import signUpStyles from "../../../SignUp/style.module.css";
import Image from "react-bootstrap/Image";
import logoImagePath from "../../../SignUp/images/logo_sm.png";
import {Container} from "react-bootstrap";
import Button from "../../core/Button";
import { confirmDeleteAccount, requestCodeToEmail } from "../../../../api/Account/dataSetters";
import NetworkContext from "../../../../contexts/NetworkContext";
import CodeInput from '../../core/CodeInput'
import styles from './style.module.scss'
import { useTranslation } from "../../../../contexts/LocaleContext";
import useCountDown from 'react-countdown-hook';
import cn from 'classnames'
import { Modal } from 'react-bootstrap'

const defaultStyleProps = {
  inputStyle: {
    margin: '5px',
    width: '40px',
    borderRadius: '3px',
    fontSize: '34px',
    fontWeight: '900',
    height: '56px',
    paddingLeft: '7px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    background:'#FAFAFA',
    borderRadius:"3px",
    userSelect:"none",
    caretColor:"transparent",
    appearance:"textfield",
    textAlign:"center",
    padding:"0"
  },
  inputStyleActive: {
    border:"2px solid #554590"
  }
}

const initialTime = 60 * 1000; 
const interval = 1000; 

const codeFieldsLength = 6
const RemoveAccountPage = () => {

  const {t} = useTranslation()
  const { onLogout } = useContext(NetworkContext);

  const [code, setCode] = useState('')
  const [codeInputStyle, setCodeInputStyle] = useState(defaultStyleProps)
  const [error, setError] = useState({msg:null})
  const [loading, setLoading] = useState(false)
  const [isRequestDisabled, setisRequestDisabled] = useState(false)

  const [popUpshow, setPopupShow] = useState(false);
  const popUpClose = () => setPopupShow(false);
  const popUpShow = () => setPopupShow(true);

  const [timeLeft, { start: startCountDown, reset: resetCountDown }] = useCountDown(initialTime, interval);

  useEffect(() => {
    if(timeLeft === 0) {
      setisRequestDisabled(false)
    }
  }, [timeLeft])

  useEffect(() => {
    const delay = setTimeout(() => {
      requestCodeToEmail()
    }, 2000);
    setisRequestDisabled(true)
    startCountDown()

    return () =>{ 
      clearTimeout(delay)
      resetCountDown()
    }
  }, [])
  

  const handleCodeChange = (data) => {
    setCode(data)
    if(error){
        setError({msg:null})
        setCodeInputStyle((styles) => ({...styles, inputStyle: {
          ...styles.inputStyle,
          border: '1px solid rgba(0, 0, 0, 0.1)',
        }}))
    }
  }

  const handleDeleteAccount = async () => {
    setLoading(true)
    const isRemoved = await confirmDeleteAccount(Number(code))
    if(isRemoved.status === 200){
      setLoading(false)
      setError({msg: null})
      setCodeInputStyle((styles) => ({...styles, inputStyle: {
          ...styles.inputStyle,
          border: '1px solid green',
      }}))
      return onLogout()
    }else{
      setLoading(false)
      setError({msg: isRemoved.data})
      setCodeInputStyle((styles) => ({...styles, inputStyle: {
        ...styles.inputStyle,
        border: '1px solid #f85149',
      }}))
    }
    popUpClose()
  }
  const handleResendConfirmation = async () => {
    startCountDown();
    setisRequestDisabled(true);
    await requestCodeToEmail()
  }

  const convertTime = (myDuration) => {
    const mm = Math.floor(myDuration/(1000*60))%60 
    const ss = Math.floor(myDuration/1000)%60;
    return (String(mm).length === 1 ? "0" + mm : mm )+ ":" + (String(ss).length === 1 ? "0" + ss : ss )
  }

  return (
    <Wrapper>
      <Row className={signUpStyles.hat}>
        <Image height={40} className={signUpStyles.logoImage} src={logoImagePath}/>
        <div className={signUpStyles.logout} onClick={onLogout}>{t('logout')}</div>
      </Row>
          <Container>
          <div className={styles.wrapper}>
                <div className={styles.title}>{t('delete_account')}</div>
                <div className={styles.description}>{t('we_send_email_with_confirmation_code')}</div>
                <Row className={styles.code}>
                    <span className={styles.codeTitle}>{t('enter_code')}:</span>
                    <CodeInput 
                      inputClassName={styles.codeInput}
                      onChange={handleCodeChange} 
                      type='number' 
                      fields={codeFieldsLength} 
                      {...codeInputStyle} 
                      />
                    {error.msg ? <div className={styles.errorFeedback}>{error.msg}</div>: ""}
                </Row>

                <Row className={styles.buttonWrapper}>
                  <Button
                    color='#f85149'
                    height={40}
                    width={299}
                    title={ t('delete_account')}
                    waiting={loading}
                    disabled={code.length !== +codeFieldsLength}
                    onClick={popUpShow}
                    transparent
                    noShadow
                    border
                  />
                </Row>
                <div className={styles.bottom}>
                    <span 
                      onClick={!isRequestDisabled && handleResendConfirmation} 
                      className={cn(styles.resendCode, isRequestDisabled && styles.resendCodeDisabled )}>{t('resend_code')} {isRequestDisabled  && <span>{convertTime(timeLeft)}</span>}</span>
                </div>
          </div>
          </Container>
          <DeleteAccountModal
            show={popUpshow} 
            handleClose={popUpClose}
            onConfirm={handleDeleteAccount}
            onCancel={popUpClose}
          />
    </Wrapper>
  );
};


export const DeleteAccountModal = ({show, handleClose, onConfirm, onCancel}) => {
  const {t} = useTranslation()
  return(
    <>
    <Modal show={show} 
      contentClassName={styles.modal}
      onHide={handleClose} 
      aria-labelledby="contained-modal-title-vcenter"
      style={{textAlign:"center", backgroundColor:"rgba(85, 69, 144, 0.17)"}}
    >
      <Modal.Header closeButton style={{border:"none"}}>
        <div className={styles.modalHeaderContent}>
          <div>
            <div className={styles.modalHeaderWrapper} >
            <div style={{width:"70px", height:"50px", overflow:"hidden"}}>
                <img style={{width:"70px"}} src="https://fastboss.ai/wp-content/uploads/2020/09/logo-FASTBOSS-ON-WEB.png" />
              </div>
              <Modal.Title className={styles.modalTitle}>
                {t("account_and_content_will_be")} <span className={styles.red}>{t("removed/erased/deleted.")}</span> {t("no_one will_have_access_to_this account")}
              </Modal.Title>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <p className={styles.modalParagraph}>{t("recomend_suspend_instead_delete")}</p>
        <p className={styles.modalParagraph}>{t("you_will_have_time_to_archive_and_download")}</p>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <div className={styles.modalFooterActions}>
          <div className={styles.modalCancelWrapper}>
            <Button
                  width={100}
                  height={40}
                  color="white"
                  textColor="black"
                  title={ t('cancel')}
                  onClick={onCancel}
                  noShadow
              />
            </div>
            <Button
                width={200}
                height={40}
                color="#f85149"
                title={ t('delete_account')}
                onClick={onConfirm}
                transparent
                noShadow
                border
            />
        </div>
      </Modal.Footer>
    </Modal>
  </>
  )
}

export default RemoveAccountPage