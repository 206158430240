import React, {useEffect, useState} from "react";
import EventCalendar from './EventCalendar'
import {fakeTask} from "../../../../../../api/Partner/dataGetters";

/*const MyWidget = () => {
    const getData = async () => {
        const _data = await fakeTask()
        return _data
    }

    let tasks = getData().items
    debugger
    return <EventCalendar data={tasks}/>
}*/



const MyWidget = () => {
    const [data,setData] = useState(null)

    useEffect(() =>{
        const req = async () => {
            try{
                const res =  await fakeTask()
                setData(res.items)
            }catch(err){console.log(err)}
        }

        req()
    },[])

    if(data)
        return <EventCalendar data={data}/>

    return null
}

export default MyWidget