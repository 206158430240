import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

export default class DemoApp extends React.Component {


    getTasks = () => {
        let tasks = this.props.data.map(task => {
            let newTask = {}
            newTask.title = task.task
            newTask.start = task.start_date.substring(0,19)
            newTask.end = task.due_date.substring(0,19)
            return newTask
        })
        debugger
        return tasks

    }

    tasks = this.getTasks()


    render() {
        return (
            <FullCalendar
                plugins={[ dayGridPlugin ]}
                initialView="dayGridWeek"
                events={this.tasks}
            />
        )
    }
}