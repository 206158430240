import React, {useContext, useEffect, useState, useMemo} from "react";
import {Form} from 'informed';
import 'react-credit-cards/es/styles-compiled.css';
import Wrapper from "../../core/Wrapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import signUpStyles from "../../../SignUp/style.module.css";
import Image from "react-bootstrap/Image";
import logoImagePath from "../../../SignUp/images/logo_sm.png";
import {Container} from "react-bootstrap";
import Button from "../../core/Button";
import Input from "../../core/Input";
import DataContext, { useLoadedFields } from "../../../../contexts/DataContext";
import FetchLoading from "../FetchLoading";
import useWaiting from "../../hooks/useWaiting";
import { createCheckoutSession } from "../../../../api/AccountSubscriptions/dataSetters";
import {useNavigate} from 'react-router-dom';
import {getIsSubscribed} from "../../../../contexts/utils/getIsSubscribed";
import styles from './style.module.scss';
import cn from 'classnames';
import TouchableOpacity from "../../core/TouchableOpacity";
import {useTranslation} from "../../../../contexts/LocaleContext";
import NetworkContext from "../../../../contexts/NetworkContext";
import { STRIPE_PUBLIC_KEY } from "../../../../config"
import Header from "../../../Dashboard/Header";
import { useAssistant } from "../../../../contexts/AssistantContext";
import { createSubscription } from "../../../../api/CompanyAccountSubscription/dataSetters";
import { formatDate } from "../../../../utils/StringUtils";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ListGroup from "react-bootstrap/ListGroup"
import ButtonBootstrap from "react-bootstrap/Button";
import Checkbox from "../../core/Checkbox";

import basicIconPath from "./icons/basic.svg"
import enterpriseIconPath from "./icons/enterprise.svg"

import { loadStripe } from "@stripe/stripe-js";
import { useTabContextValue } from "../../../../contexts/TabContext";
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const defaultCardState = {
  cvc: '',
  expiry: '',
  focused: '',
  name: '',
  number: '',
};

const plans = {
  1: 'month',
  2: 'year',
}

const cardDescription = [
  {
    text: "subs_documents",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_voice_digital",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_remote_document",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_remote_signature",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_signature_report",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_catalogues_remote",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_crm",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_template_builder",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_unlimited_doc",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_tasks_management",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_customers_management",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_advance_voice",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_assistant_bill",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_data_import",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_encryption_security",
    basicPlan: true,
    enterprisePlan: true
  },
  {
    text: "subs_create_digital",
    basicPlan: false,
    enterprisePlan: true
  },
  {
    text: "subs_create_flows",
    basicPlan: false,
    enterprisePlan: true
  },
  {
    text: "subs_voice_internal",
    basicPlan: false,
    enterprisePlan: true
  },
  {
    text: "subs_transfer_job",
    basicPlan: false,
    enterprisePlan: true
  },
  {
    text: "subs_available_support",
    basicPlan: false,
    enterprisePlan: true
  },
  {
    text: "subs_free_security",
    basicPlan: false,
    enterprisePlan: true
  },
]

const HEADER_HEIGHT = 60;
const LEFT_SIDE_WIDTH = 250;

const PaymentPage = () => {
  useTabContextValue();
  const {t} = useTranslation();
  const [quantity, setQuantity] = useState(0);

  const {isLoaded, pricesList, subscriptionList} = useLoadedFields(["pricesList", "subscriptionList"]);
  const assistant = useAssistant();

  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(1);

  const onSelectSubscriptionType = (type) => {
    setSelectedSubscriptionType(type);
  }

  useEffect(() => {
    try {
      setSelectedSubscriptionType(pricesList.data[0].type);
    } catch (e) {
      console.log(e);
    }
  }, [pricesList]);

  const currentSubscription = () => {
    const {quantity_of_users, status} = subscriptionList.data[0] || {};

    switch (status) {
      case 1: 
        return quantity_of_users >= 2 ? t("enterprise") : t("basic")
      case 2:
        return t("expired")
      case 5: 
        return t("trial")
      case 6:
        return t("free")
      default:
        return t("no subs")
    }
  }

  const detectPricing = selectedSubscriptionType === 1 ? pricesList?.data[0] : pricesList?.data[1];

  return (
    <Wrapper>
      <Header className={styles.userMenu} height={HEADER_HEIGHT} leftSideWidth={LEFT_SIDE_WIDTH} openAssistant={(query) => assistant.open(null, query)} />
      {isLoaded ? (
        <Container>
          <div
            style={{
              textAlign: "center",
              padding: "1rem 0 3rem 0",
              whiteSpace: "pre-wrap",
            }}
          >
            <CurrentSubscriptionPlan currentSubscription={currentSubscription} />
            <PlansIntroduction onSelectSubscriptionType={onSelectSubscriptionType} selectedSubscriptionType={selectedSubscriptionType}/>
            <SubscriptionListPlans price={detectPricing} selectedSubscriptionType={selectedSubscriptionType} setQuantity={setQuantity} quantity={quantity}/> 
          </div>
        </Container>
      ) : (
        <FetchLoading />
      )}
    </Wrapper>
  );
};

const PaymentForm = ({selectedSubscriptionType, quantity}) => {
  const {t} = useTranslation()
  const submit = async () => {
    await onPaymentSubmit({
      company_subscription_type: selectedSubscriptionType, 
      quantity_of_users: Number(quantity) || 0
    });
  };

  const [waiting, createPayment] = useWaiting(createSubscription)
  const [failText, setFailText] = useState(null);

  const onPaymentSubmit = async (data) => {
    const stripe = await stripePromise;
    const res = await createPayment(data) || {};
    if (res.body && res.body.success) {
      return await stripe.redirectToCheckout({
        sessionId: res.body.data.id,
      });
    } else if (res.body && !res.body.success) {
      setFailText(res.body.error);
    } else {
      setFailText(t("unknown_error,_please_try_again_later"));
    }
  };

  return (
    <Row className="d-flex justify-content-center" >
      <Col className="d-flex justify-content-center p-2">
        <Form
          initialValues={defaultCardState}
          onSubmit={submit}
          className={styles.cardForm}
        >
          <Button
            height={30}
            title={t("subs select plan")}
            waiting={waiting}
          />
        </Form>
      </Col>
      <Col xs={12} style={{textAlign: 'center'}}>
        {failText && (
          <div style={{color: '#aa2d0d', fontSize: '0.8rem'}}>{failText}</div>
        )}
      </Col>
    </Row>
  );
};

const CurrentSubscriptionPlan = ({currentSubscription = () => {}}) => {
  const { t } = useTranslation(); 

  return (
    <div className={styles.curSubsWrapper}>
      <h2 className={styles.curSubsTitle}>{t("subs your current")}: {currentSubscription()}</h2>
      <p className={styles.curSubsDescription}>{t("subs choose your plan")}</p>
    </div>
  )
}

const PlansIntroduction = ({onSelectSubscriptionType, selectedSubscriptionType}) => {
  const { t } = useTranslation()
  const { account } = useContext(DataContext)
  const navigate = useNavigate()

  const { name } = account.data

  const onGoBack = () => {
    navigate("/dashboard/settings");
  }; 

  return (
    <div className="position-relative p-4">
      <div className={styles.goBackButtonWrapper}>
        <Button
          height={30}
          title={<><i className={cn(styles.goBackButtonIcon, 'fas fa-chevron-left')}/> {t("go back")}</>}
          onClick={onGoBack}
          className={styles.goBackButton}
          color="white"
          textColor="black"
        />
      </div>
      <h2 className={cn(styles.curSubsTitle, "text-dark")}>{t("subs here are plans")}, {name}!</h2>
      <p className={cn(styles.curSubsDescription, "text-dark")}>{t("subs choose your plan")}</p>
      <ButtonGroup className="m-2">
        <Button
          containerClassName={styles.containerButton} 
          className={cn(styles.monthButton, selectedSubscriptionType === 1 ?  "" : styles.inactiveButton)} 
          title={t("month")}
          onClick={() => onSelectSubscriptionType(1)}
        />
        <Button 
          containerClassName={styles.containerButton} 
          className={cn(styles.yearButton, selectedSubscriptionType === 2 ? "" : styles.inactiveButton)} 
          title={t("year")}
          onClick={() => onSelectSubscriptionType(2)}
        />
      </ButtonGroup>
    </div>
  )
}

const SubscriptionListPlans = ({price, selectedSubscriptionType, quantity, setQuantity}) => {
  const { t } = useTranslation()

  useEffect(() => {
    setQuantity(0)
  }, [selectedSubscriptionType])

  const calculatedPrice = useMemo(() => {
    return price.amount_admin + (price.amount_user * quantity)
  }, [quantity])

  const showPrice = quantity > 0 ? calculatedPrice : `${price.amount_admin} + ${price.amount_user}`

  return (
    <div>
      <Row>
        <Col xs={3} className={styles.cardWrapperTransparent}>
          <div className={styles.cardHeader} />
          <div className={styles.cardMain}>
          <ListGroup variant="flush">
            {cardDescription?.map(({text}, id) => {
              return (
                <ListGroup.Item key={id} className={styles.mainDescriptionList}>
                  {t(text)}
                </ListGroup.Item>
              )
            })}
          </ListGroup>
          </div>
          <div className={styles.cardFooter} />
        </Col>
        <Col xs={4} className={styles.cardWrapper}>
          <div className={styles.cardHeaderContent}>
            <Image className="mt-5 mb-2" height={39} src={basicIconPath}/>
            <h3>{t("subs basic")}</h3>
            <p className={styles.cardDescription}>{t("subs all from free pack")}</p>
            <div className={styles.cardPriceContainer}>
              <div className={styles.priceSymbol}>{price.symbol}</div>
              <div className={styles.priceSum}>{price.amount_admin}</div>
              <div className={styles.pricePeriod}>/{t(plans[selectedSubscriptionType])}</div>
            </div>
          </div>
          <div className={styles.cardMain}>
          <ListGroup variant="flush">
            {cardDescription?.map(({basicPlan}, id) => {
              return (
                <ListGroup.Item key={id} className={styles.planDescriptionList}>
                  {basicPlan ? <Checkbox field={`checkbox-first-group-${id}`} className={styles.cardCheckbox} value={true}/> : "-"}
                </ListGroup.Item>
              )
            })}
          </ListGroup>
          </div>
          <div className={styles.cardFooterContent}>
            <PaymentForm subscription={price} selectedSubscriptionType={selectedSubscriptionType} quantity={quantity}/>
          </div>
        </Col>
        <Col xs={4} className={styles.cardWrapper}>
          <div className={styles.cardHeaderContent}>
            <Image className="mt-5 mb-2" height={39} src={enterpriseIconPath}/>
            <h3>{t("subs enterprize")}</h3>
            <p className={styles.cardDescription}>{t("subs unlimited documents")}</p>
            <div className={styles.cardPriceContainer}>
              <div className={styles.priceSymbol}>{price.symbol}</div>
              <div className={styles.priceSum}>{showPrice}</div>
              <div className={styles.pricePeriod}>/{t(plans[selectedSubscriptionType])} {t("user")}</div>
            </div>
            <Input 
              className={styles.cardInput}
              type="number"
              field="number"
              placeholder={t("subs number of users")}
              onChange={(e) => setQuantity(e.target.value)}
              min="1"
              max="1000"
            />
          </div>
          <div className={styles.cardMain}>
          <ListGroup variant="flush">
            {cardDescription?.map(({enterprisePlan}, id) => {
              return (
                <ListGroup.Item key={id} className={styles.planDescriptionList}>
                  {enterprisePlan ? <Checkbox field={`checkbox-second-group-${id}`} className={styles.cardCheckbox} value={true}/> : "-"}
                </ListGroup.Item>
              )
            })}
          </ListGroup>
          </div>
          <div className={styles.cardFooterContent}>
            <PaymentForm subscription={price} selectedSubscriptionType={selectedSubscriptionType} quantity={quantity}/>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PaymentPage;