import React, { useContext, useEffect, useState } from "react";
import s from './styles.module.css'
import CustomModal from "../Modal";
import { useTranslation } from "../../../../../../contexts/LocaleContext";
import Input, { Select } from "../Input";
import Controllers from "../Controllers";
import CardRow from "../CardRow";
import Button from "../Button";
import { REGEX } from "../useForm";
import { findCorporateUser } from "../../../../../../api/CompanyAccount/dataGetters.js"
import { inviteWorker, transferWorker } from "../../../../../../api/CompanyAccount/dataSetters";
import { reassignEmployee } from "../../../../../../api/Department/dataSetters";
import Avatar from "react-avatar";
import { idToColor } from "../../../../../../utils/StringUtils";
import { BasicText } from "../CardText";
import CorporateContext from "../../CorporateContext";
import cn from "classnames";
import useNavigation from "../../../../../common/hooks/useNavigation";

const Worker = ({ name = '', isManager = false, profilePicture, documents, email, id, load = () => { } }) => {
    const { t } = useTranslation()
    const [isManageModalOpen, setManageModalOpen] = useState(false)
    const [emailToTransferTo, setEmailToTransferTo] = useState(false)
    const [error, setError] = useState('')
    const { workers } = useContext(CorporateContext)

    useEffect(() => { setError('') }, [emailToTransferTo])

    function onClose() {
        setEmailToTransferTo(false)
        setManageModalOpen(false)
    }

    async function deleteUser() {
        await workers.remove(id)
        load()
        onClose()
    }

    function onTransferClick() {
        if (!emailToTransferTo)
            setEmailToTransferTo('')
        else
            transferUser()
    }
    async function transferUser() {
        const response = await transferWorker(id, emailToTransferTo)
        if (response.status === 404) {
            setError(t('User not found'))
        }
        else if (response.ok) {
            onClose()
        }
    }

    return <div className={s.workerCard} style={{ backgroundColor: '#fff' }} >
        <CustomModal onClose={onClose} isVisible={isManageModalOpen} title={t('Manage user')}>
            <CardRow>
                <Input title={t('Name')} value={name} disabled />
                <Input title={t('Email')} value={email} disabled />
            </CardRow>
            {emailToTransferTo !== false && <Input title={t('New email')} value={emailToTransferTo} onChange={setEmailToTransferTo} />}
            <Controllers error={error}>
                <Button title={t('Cancel')} transparent color={'black'} onClick={onClose} />
                <Button title={t('Transfer')} onClick={onTransferClick} disabled={emailToTransferTo !== false && !REGEX.EMAIL.test('' + emailToTransferTo)} />
                {emailToTransferTo === false && <Button title={t('Delete')} twoStep color={'red'} onClick={deleteUser} />}
            </Controllers>
        </CustomModal>
        <div className={s.workerHeader} >
            <div className={s.workerProfilePicture} >
                <Avatar name={name} size={44} color={idToColor(id)} src={profilePicture ? 'data:image/*;base64,' + profilePicture : null} />
                {isManager && <div title={'Manager'} className={s.managerDot} />}
            </div>
            <div className={s.workerInfo}>
                <div className={s.workerNameAndRole}><span className={s.workerName}>{name}</span>
                    <span className={s.workerRole} style={{ color: isManager ? '#F87C18' : 'black' }}>{isManager ? t('Manager') : t('Employee')}</span></div>
                <div className={s.workerEmail}>
                    {email}
                </div>
            </div>
            <div className={s.workerControllers} onClick={() => setManageModalOpen(true)}>
                <i className={'fas fa-user-edit'} />
            </div>
        </div>
    </div>
}

export const InviteWorkerModal = ({ isVisible = false, companyId = 0, departmentId = 0, employeeId = 0, role = 0, name = "", title = "", employeeEmail = "", onClose = () => { } }) => {
    const { t } = useTranslation()
    const {nav, PATHS} = useNavigation()
    const [email, setEmail] = useState('')
    const [type, setType] = useState(0)
    const [titlePosition, setTitlePosition] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        setError('')
    }, [email])

    const onInvite = async () => {
            const resp = await inviteWorker({ email: employeeEmail ? employeeEmail : email, companyId, departmentId, workerType: role === 1 ? 2 : type });
            if (!resp.ok) {
                setError(JSON.parse(resp?.data).error ? JSON.parse(resp?.data).error : t("An error occurred"));
            } else {
                onClose();
            }
    }

    const onEditPermission = async () => {
            const resp = await reassignEmployee({ employee_id: employeeId, employee_company_type: type, title: titlePosition });
            if (!resp?.ok) {
                setError(t("An error occurred"));
            } else {
                onClose();
                nav(PATHS.STRUCTURES, "departments", departmentId)
            }
    }

    const roles = [{ name: t("CEO"), value: 0 },  { name: t("Manager"), value: 1 }, { name: t("Employee"), value: 2 }] 

    if (role === 1) {
        roles.splice(0,2)
    } else if (role === 2) {
        roles.splice(0,3)
    }

    return (
        <CustomModal onClose={onClose} isVisible={isVisible} title={`${!title ? t('Invite user to') : title} ${name}`}>
            <CardRow>
                <Input title={t('Email')} value={employeeEmail ? employeeEmail : email} onChange={val => setEmail(val.trim())} disabled={employeeEmail ? true : false}/>
                <Select options={roles} title={t('Role')} onSelect={({ value }) => setType(value)} />
            </CardRow>
            <CardRow>
                    {employeeId ? <Input title={t('Position Title')} value={titlePosition} onChange={val => setTitlePosition(val.trim())}/> : null}
            </CardRow>
            <Controllers error={error}>
                <Button title={t('Cancel')} transparent onClick={onClose} />
                <Button title={employeeEmail ? t("Save") : t('Invite')} disabled={!REGEX.EMAIL.test(employeeEmail ? employeeEmail : email)} onClick={employeeEmail ? onEditPermission : onInvite} />
            </Controllers>
        </CustomModal>
    )
        
}


export const WorkerSmall = ({ name = '', profilePicture = '', id = 0, departmentId = 0, isManager = false }) => {
    const { nav, PATHS } = useNavigation()
    const { role } = useContext(CorporateContext);

    const navigateToEmployeePage = () => {
        if (role?.data === "Employee") return;

        nav(PATHS.STRUCTURES, 'employee', id, departmentId)
    }

    return (
        <div className={s.smallWrapper} onClick={() => navigateToEmployeePage()}>
            <div className={s.smallAvatar}>
                <Avatar size={32} borderRadius={8} name={name} color={idToColor(id)} src={profilePicture ? 'data:image/*;base64,' + profilePicture : null} />
                {isManager && <div title={'Manager'} className={cn(s.managerDot, s.smallDot)} />}
            </div>
            <div className={s.smallName}>{name}</div>
        </div>
    )
}

export default Worker
