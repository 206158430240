import React, {useRef, useState, useEffect, useContext} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard"
import styles from './style.module.css';
import TouchableOpacity from "../../common/core/TouchableOpacity";
import Col from "react-bootstrap/Col";
import {Text, Form} from 'informed';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import cn from 'classnames';
import {saveAs} from 'file-saver';
import html2pdf from 'html2pdf.js';
import TabContext, {EditorType} from "../../../contexts/TabContext";
import {isEmpty} from "../../../utils/StringUtils";
import Fields from "./Fields";
import {ClipLoader} from "react-spinners";
import DataContext from "../../../contexts/DataContext";
import {Editor as MCEditor} from '@tinymce/tinymce-react';
import './MCE.css'
import useDimensions from "../../common/hooks/useDimensions";
import {useTranslation} from "../../../contexts/LocaleContext";
import GuideContext from "../../Onboarding";
import Button from "../../common/core/Button";
import SignaturePad from "../Assistant/SignaturePad";
import useEscape from "../Assistant/useEscape";
import Collapse from "react-bootstrap/Collapse";
import { useRestriction } from "../../../contexts/Restriction/RestrictionContext"
import {TINY_API_KEY, SIGNATURE_BASE_URL} from "../../../config";
import SwitchToggle from "../../common/core/SwitchToggle";
import Input from "../../SignIn/Input";
import InputPlaceholder from "../../SignIn/InputPlaceholder";
import { getSignatureReport } from "../../../api/Signature/dataGetters";
import CKEditor from "../CKEditor";
import jsPDF from "jspdf";
import juice from "juice";
import { load as cheerio } from "cheerio";
import "jspdf-autotable";
import { toast } from "react-toastify";
import { Info } from "../../common/core/Toasts";

export const CLIENT_SIGNATURE_PLACEHOLDER = `<span class="client-signature fixed">_______________________</span>`
export const MY_SIGNATURE_PLACEHOLDER = `<span class="my-signature fixed">_______________________</span>`

const SignatureScreen = ({onClose=()=>{}, type=0, dispatchSignature=()=>{}, docId=null}) => {
    const containerRef = useEscape(styles.signContainer, onClose);

    return <div  ref={containerRef} className={styles.signContainer}>
        <SignaturePad type={type} dispatchSignature={dispatchSignature} docId={docId} close={onClose}/>
    </div>
}

function getContentWithLineStyles(editorContent, editorRef, documentDimensions) {
  const styles = [...document.styleSheets].reduce((accumulator, current) => {
    try {
        const rules = [...current['cssRules']]
          .filter((rule) => [':root', '.ck-content', '.style_documentEditor'].some((i) => rule.cssText.includes(i)))
          .map((rule) => rule.cssText);
        return `${accumulator} ${rules.join(' ')}`.trim();
    } catch (error) {
        return accumulator;
    }
  }, '');

  const editorRootElement = editorRef.current;
  const editorContainerElement = editorRef.current.lastChild;

  const inlineContent = juice.inlineContent(
    `<div class="${editorRootElement.getAttribute('class')}">
      <div class="${editorContainerElement.getAttribute('class')} temp-wrapper">
        <div class="ck-content">${editorContent}</div>
      </div>
    </div>`,
    styles,
    {
      preserveImportant: true,
      preserveFontFaces: true,
      resolveCSSVariables: true,
      applyAttributesTableElements: true,
    },
  );

  const $ = cheerio(inlineContent);
  const $wrapper = $('.temp-wrapper');
  const $ckContent = $('.temp-wrapper').children();
  if (documentDimensions) {
    $ckContent.css('width', `${documentDimensions.width}px`);
    $ckContent.children().toArray().map((current) => {
      const $element = $(current);
      const styleAttribute = current.attribs.style || "";
      const widthProperty = styleAttribute.split(';').find((property) => property.trim().startsWith('width:')).trim() || '';
      const widthPropertyValue = widthProperty.replace('width:', '').replace(';', '').trim();
      const widthPropertyIsPercentage = widthPropertyValue.includes('%');

      if (widthProperty && widthPropertyIsPercentage) {
        const percentage = Number(widthPropertyValue.replace('%', ''));
        $element.css({ width: `${documentDimensions.width / 100 * percentage}px` });
      } else if (widthProperty) {
        $element.css({ width: `${widthProperty}` });
      } else {
        $element.css({ width: `${documentDimensions.width}px` });
      }
    });

    $ckContent.find('*').toArray().map((current) => {
      const $element = $(current);
      $element.css({
        'font-family': 'Roboto',
        'word-break': 'break-word',
       });
    });
  }

  return $wrapper.html();
};

function getContentWithPagination(editorContent, documentDimensions) {
    const contentWithTempWrapper = `<div class="temp-wrapper">
      ${editorContent}
    </div>`;
    const $ = cheerio(contentWithTempWrapper);

    const $ckContent = $('.temp-wrapper').children();
    const elementsByPage = $ckContent.children().toArray().reduce((accumulator, current, index) => {
        const newPageBreak = current.attribs?.['data-pagination-page']
          ? 1
          : 0;
        const accumulatorPageLatest = accumulator.length ? accumulator.length - 1 : 0;
        const accumulatorPage = accumulatorPageLatest + newPageBreak;

        accumulator[accumulatorPage] = [
            ...(accumulator[accumulatorPage] || []),
            current,
        ];

        return accumulator;
    }, []);

    const htmlByPage = elementsByPage.reduce((accumulator, current, index) => {
        const $ckContentClone = $ckContent.clone().empty();
        current.map((element) => $ckContentClone.append(element))

        $ckContentClone.css('margin-top', `${documentDimensions.height * index}px`);

        return [...accumulator, $.html($ckContentClone)];
    }, []);

    return htmlByPage;
}

async function saveAsPdf(title, content, editorRef) {
    const pdf = new jsPDF('p', 'px', 'a4', true);

    const fonts = [
        {
            font: 'Roboto',
            weight: 'normal',
            style: 'normal',
            fileName: 'Roboto-Regular.ttf',
        },
        {
            font: 'Roboto',
            weight: 'normal',
            style: 'italic',
            fileName: 'Roboto-Italic.ttf',
        },
        {
            font: 'Roboto',
            weight: 'bold',
            style: 'normal',
            fileName: 'Roboto-Bold.ttf',
        },
        {
            font: 'Roboto',
            weight: 'bold',
            style: 'italic',
            fileName: 'Roboto-BoldItalic.ttf',
        },
    ];
    const fontsKeys = fonts.keys();
    const blobToBase64 = async (blob) => new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
    for (const fontIndex of fontsKeys) {
        const font = fonts[fontIndex];
        const fontResponse = await fetch(`/fonts/ckeditor/${font.fileName}`);
        const fontBlob = await fontResponse.blob();
        const fontBase64 = await blobToBase64(fontBlob);
        pdf
          .addFileToVFS(font.fileName, fontBase64.replace('data:font/ttf;base64,', '').replace('data:application/font-sfnt;base64,', ''))
          .addFont(font.fileName, font.font, font.style, font.weight);
    }

    pdf.setFont('Roboto');
    pdf.setFontSize(16);

    const marginVertical = 30;
    const marginHorizontal = 36;
    const margin = [marginVertical, marginHorizontal, marginVertical, marginHorizontal];

    const html2canvas = { scale: 0.56 };
    const documentScale = { x: 1.67, y: 1.72 };
    const documentWidth = pdf.internal.pageSize.width * documentScale.x - marginHorizontal * 2;
    const documentHeight = pdf.internal.pageSize.height * documentScale.y - marginVertical * 2;
    const documentDimensions = {
        width: documentWidth,
        height: documentHeight,
    };

    const contentFromEditor = editorRef
      .current.querySelector('.ck.ck-content')
      .ckeditorInstance.getData({ pagination: true });
    const contentWithStyles = getContentWithLineStyles(contentFromEditor, editorRef, documentDimensions);
    const contentWithPagination = getContentWithPagination(contentWithStyles, documentDimensions);
    const contentWithPaginationKeys = contentWithPagination.keys();

    for (const pageIndex of contentWithPaginationKeys) {
        const page = contentWithPagination[pageIndex];
        if (pageIndex !== 0) pdf.addPage();

        await new Promise((resolve) => pdf.html(page, {
            margin,
            html2canvas,
            callback: resolve,
        }));
    }

    pdf.save(`${title}.pdf`);
};

function saveAsDoc(title, content, editorRef) {
    const contentFromEditor = editorRef
      .current.querySelector('.ck.ck-content')
      .ckeditorInstance.getData({ pagination: true });
    const contentWithStyles = getContentWithLineStyles(contentFromEditor, editorRef);

    const contentWrapper = `<!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8" />
      </head>
      <body>
    ${contentWithStyles}
      </body>
    </html>`;

    const opt = {
      orientation: "portrait",
      margins: { top: 90, right: 70, left: 70, bottom: 90 },
    };

    const doc = window.htmlDocx.asBlob(contentWrapper, opt);
    saveAs(doc, `${title}.docx`);
}

function printDoc(content, editorRef) {
    const contentFromEditor = editorRef
      .current.querySelector('.ck.ck-content')
      .ckeditorInstance.getData({ pagination: true });
    const contentWithStyles = getContentWithLineStyles(contentFromEditor, editorRef);

    const contentWrapper = `<!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8" />
        <title>${document.title}</title>
      </head>
      <body>
        ${contentWithStyles}
      </body>
    </html>`;

    const myWindow = window.open('', 'print', '');

    myWindow.document.write(contentWrapper)

    myWindow.document.close(); 
    myWindow.focus();

    myWindow.onload = () => {
        myWindow.print();
        myWindow.close();
    };
}

function sendEmail(title, content) {
    /* const el = document.createElement('div');
     el.innerHTML = content;
     const formatted = Array
       .from(el.querySelectorAll('p'))
       .reduce((memo, el) => {
         return memo + el.innerText + '\n';
       }, '');
     window.open(`mailto:?subject=${title}&body=${encodeURIComponent(formatted)}`);*/
}

const Editor = () => {
    const _currentSignType = useRef(0)
    const currentSignType = _currentSignType.current
    const setCurrentSignType = (val) => _currentSignType.current = val

    const [formApi, setFormApi] = useState({});
  const {t, currentLocale} = useTranslation()
  const localeCode = currentLocale == 'fr' ? 'fr_FR' : currentLocale
  const localeURL = `/langs/${localeCode}.js`

  const resetFailsFunc = useRef(()=>{});
  const setResetFailsFunc = (f) => {
    resetFailsFunc.current = f;
  };

  const {
    closeEditor,
    saveEditor,
    editorType,
    editorTitle,
    editorKeywords,
    editorContent,
    editorIsPublic,
    isEditorOpen,
    documentToken,
    statusType,
    id: docOrTemplateId
  } = useContext(TabContext);

    const editorRef = useRef(null);
    const [content, setContent] = useState(editorContent);
    const { withRestrictions } = useRestriction();

    const appendContent = (content) => {
        const editor = editorRef
          .current.querySelector('.ck.ck-content')
          .ckeditorInstance;
          
        editor.model.change( writer => {
            const position = editor.model.document.selection.getFirstPosition();
            writer.insertText(content, position, 'after');
        });
    }

    const hasMySignature = content.includes(MY_SIGNATURE_PLACEHOLDER)
    const hasClientSignature = content.includes(CLIENT_SIGNATURE_PLACEHOLDER)

    const hasBothTypes = hasMySignature && hasClientSignature

    useEffect(()=>{
        if(!hasBothTypes){
            setCurrentSignType(!hasClientSignature ? 0 : 1)
        }
    },[hasBothTypes, content])
    const isDocSignable = () => {
        return hasMySignature || hasClientSignature
    }

    const {documents} = useContext(DataContext)
    const deleteDocument = () => {
        documents.remove(docOrTemplateId)
        closeEditor()
    }


    const isTemplate = editorType === EditorType.CREATE_TEMPLATE || editorType === EditorType.EDIT_TEMPLATE;
    const [editMode, setEditMode] = useState(isTemplate)
    useEffect(()=>{
        setEditMode(isTemplate)
    },[editorType])
  const {markDone} = useContext(GuideContext)

  const autoSaveOnFilledTitle = async () => {
    if (editorType !== EditorType.CREATE_TEMPLATE) return;
    const title = formApi.getValue('title');
    if (title) {
      markDone('template title')
      await saveEditor({title, content: '', keywords: '', is_public: editorIsPublic});
    }
  };

  useEffect(() => {
    setContent(editorContent);
  }, [editorContent]);
  useEffect(() => {
    if (isEmpty(formApi)) return;
    formApi.setValues({title: editorTitle, keywords: editorKeywords});
  }, [formApi, editorTitle, editorKeywords]);

  const onDragOver = (e) => {
    e.dataTransfer.dropEffect = 'copy';
    e.dataTransfer.effectAllowed = 'all';
  };


  const [waiting, setWaiting] = useState(false);
  const [isPublic, setIsPublic] = useState(editorIsPublic);
  const [reportReady, setReportReady] = useState(false);

  const handleReportReady = () => {
    if (statusType === 3 || statusType === 13 || statusType === 10 || statusType === 11 || statusType === 9 || statusType === 8) {
        return setReportReady(true);
    }
    return setReportReady(false);
  }

  useEffect(() => {
    setIsPublic(editorIsPublic);
    handleReportReady();
  }, [docOrTemplateId, isEditorOpen])

  const handleSwitchPublic = (e) => {
    setIsPublic(e.target.checked);
  }

  const save = async ({title, keywords}) => {
    setWaiting(true);
    await saveEditor({id:docOrTemplateId, title, keywords, content, is_public: isPublic});
    setWaiting(false);
    markDone('save template')
  };

  const generateSignatureReport = async () => {
    const data = await getSignatureReport(docOrTemplateId);
    if (data && data !== 'Signature report not found') {
        saveAs(data, `signature-report-${docOrTemplateId}.pdf`)
    } else {
        toast(<Info>{t('signature_report_not_ready_yet. _try_again_later.')}</Info>)
    }
  }

  const close = () => {
    setContent('');
    resetFailsFunc.current();
    formApi.reset();
    closeEditor();
  };

  const {templates} = useContext(DataContext);
  const {height} = useDimensions()

    const [isSignPadOpen, setSignPadOpen] = useState(false)
    const [awaitingSave, setAwaitingSave] = useState(false)

    const dispatchSignature = (type, signature) => {
        setContent(content=>content.replaceAll(type === 0 ? MY_SIGNATURE_PLACEHOLDER : CLIENT_SIGNATURE_PLACEHOLDER, signature))
        setAwaitingSave(true)
    }
    useEffect(()=>{
        if (awaitingSave) {
            const _save = async () => {
                await save({title: formApi.getValue('title'), keywords: formApi.getValue('keywords')})
            }
            _save().then(()=>setAwaitingSave(false))
        }
    },[awaitingSave])

  return (
    <>
        {isSignPadOpen && <SignatureScreen onClose={() => setSignPadOpen(false)}
                                           docId={docOrTemplateId}
                                           displayed={isSignPadOpen}
                                           dispatchSignature={dispatchSignature}
                                           type={currentSignType}
        />}
      <div className={cn(styles.main, 'editor-container')}>
        <div className={styles.mainContent}>
          <div className={styles.editorContainer}>
            <CustomForm
                autoSaveOnFilledTitle={autoSaveOnFilledTitle}
                getApi={setFormApi}
                onSubmit={save}
                close={close}
                setResetFailsFunc={setResetFailsFunc}
            />
            <div className={styles.editor}>
                { isTemplate ?
                    <Buttons
                        isTemplate={isTemplate}
                        save={formApi.submitForm}
                        saveAsDoc={() => {
                            saveAsDoc(formApi.getValue('title'), content, editorRef);
                        }}
                        saveAsPdf={() => {
                            saveAsPdf(formApi.getValue('title'), content, editorRef);
                        }}
                        sendEmail={() => {
                            sendEmail(formApi.getValue('title'), content);
                        }}
                        isDefaultTemplate={
                            (templates.getItemByIdLocal(docOrTemplateId) || {}).isDefault &&
                            editorType === EditorType.EDIT_TEMPLATE
                        }
                        handleSwitchPublic={handleSwitchPublic}
                        isPublic={isPublic}
                        cancel={close}
                        waiting={waiting}
                    /> : 
                    editMode && <div className={cn(styles.publicPrivateSwitch, "mb-3")}>
                                    <div>{isPublic ? t("public") : t("private")}</div>
                                    <SwitchToggle onChange={handleSwitchPublic} checked={isPublic}/>
                                </div>
                }
                <CKEditor key={localeCode+Number(editMode)} disabled={!editMode} data={content} setContent={setContent} ref={editorRef} />
            </div>
          </div>
        </div>
      </div>
        {isTemplate ? <Fields appendContent={appendContent} /> : <Tools
            key={docOrTemplateId}
            editMode={editMode}
            onEdit={() => setEditMode(true)}
            onSave={withRestrictions(() => {
                formApi.submitForm()
                if (formApi.getValue('title') && formApi.getValue('keywords')) {
                    setEditMode(false)
                }
            })}
            onSign={()=>setSignPadOpen(true)}
            onSaveDocx={()=>saveAsDoc(formApi.getValue('title'), content, editorRef)}
            onSavePdf={()=>saveAsPdf(formApi.getValue('title'), content, editorRef)}
            onSignatureReport={generateSignatureReport}
            onPrintDoc={() => printDoc(content, editorRef)}
            onDelete={withRestrictions(deleteDocument)}
            signable={isDocSignable()}
            setType={hasBothTypes ? setCurrentSignType : null}
            signatureReady={reportReady}
            documentToken={documentToken}
        />}
    </>
  );
};

const Tools = (
    {
        onEdit = () => {
        },
        onSave = () => {
        },
        editMode = false,
        onSign = ()=>{},
        onSaveDocx = ()=>{},
        onSavePdf = () =>{},
        onPrintDoc = () =>{},
        onDelete = () => {},
        onSignatureReport = () => {},
        signable = false,
        setType,
        signatureReady = false,
        documentToken = null
    }
) => {
    const [expanded, setExpanded] = useState(false)

    useEffect(()=> {
        if (setType === null)
            setExpanded(false)
    }, [setType])
    const {t} = useTranslation()
    const T = (string='') => t(string.toLowerCase().replaceAll(' ', '_'))
    const { withRestrictions } = useRestriction();

    const onLinkCopy = () => {
        toast(<Info>{t('signature_link_copied!')}</Info>)
    };

    return <div className={styles.toolbar}>
        <div className={styles.fieldsContent}>
            {!editMode ?
                <ToolbarButton label={T('Edit Document')} onClick={withRestrictions(onEdit)} icon={'fas fa-pen'} color={'#458153'}/> :
                <ToolbarButton label={T('Save changes')} onClick={withRestrictions(onSave)} icon={'fas fa-save'} color={'#458153'}/>}
            <ToolbarButton label={expanded ? T('Who is signing?') :T('Sign Document')} icon={expanded ? 'fas fa-question-circle' :'fas fa-signature'} color={'#2a035f'} onClick={setType == null ? onSign : ()=>setExpanded(p=>!p)} disabled={!signable}/>
            { setType !== null &&
            <Collapse in={expanded}>
                <div className={styles.typeSelectionWrapper}>
                    <ToolbarButton label={T('Me')} icon={'fas fa-user-tie'} color={'#006d75'}
                                   onClick={()=> {
                                       setType(0)
                                       onSign()
                                   }} white/>
                    <ToolbarButton label={T('Client')} icon={'fas fa-user-alt'} color={'#006d75'}
                                   onClick={()=> {
                                       setType(1)
                                       onSign()
                                   }} white/>
                </div>
            </Collapse>
            }
            <ToolbarButton label={T('Signature report')} icon={'fab fa-stumbleupon'}  color={'#2a5699'} onClick={onSignatureReport} disabled={!signatureReady}/>
            <CopyToClipboard text={SIGNATURE_BASE_URL + documentToken?.token} onCopy={onLinkCopy}>
                <ToolbarButton label={T('Copy remote link')} icon={'fas fa-copy'}  color={'#B11873'} disabled={!documentToken} />
            </CopyToClipboard>
            <ToolbarButton label={T('Save DOCX')} icon={'fas fa-file-word'}  color={'#2a5699'} onClick={onSaveDocx}/>
            <ToolbarButton label={T('Save PDF')} icon={'fas fa-file-pdf'} color={'#b30b00'} onClick={onSavePdf}/>
            <ToolbarButton label={T('Print')} icon={'fas fa-print'} color={'#b30b00'} onClick={onPrintDoc}/>
            <ToolbarButton label={T('Delete Document')} icon={'fas fa-trash-alt'} color={'red'} onClick={withRestrictions(onDelete)} twoStep/>
        </div>
    </div>
}

const ToolbarButton = ({
                           label = '',
                           onClick = () => {},
                           disabled = false,
                           color = "#000",
                           icon,
                           twoStep = false,
                           white=false
                       }) => {
    const {t} = useTranslation()
    const [confirmMode, setConfirmMode] = useState(false)
    const _onClick = () => {
        if (disabled) return ()=>{}
        if (!twoStep) return onClick
        return confirmMode ? onClick : ()=>setConfirmMode(true)
    }
    return <div className={styles.btnWrapper} onClick={_onClick()}
                onMouseOut={()=>setConfirmMode(false)}
                style={{opacity: disabled ? .5 : 1, backgroundColor: white ? '#fff' : '#ececec'}}>
        {icon && <i className={cn(icon, styles.btnIcon)} style={{color: color}}/> }<div className={styles.btnLabel} style={confirmMode ? {color: color} : {}}>{confirmMode ? t('click_again_to_confirm') : label}</div>
    </div>
}

const CustomForm = ({onSubmit, setResetFailsFunc, close, autoSaveOnFilledTitle, ...props}) => {
    const validate = value => !value || undefined;
    const [inputFails, setInputFails] = useState({});
    const {t} = useTranslation()

    const {editorTitle} = useContext(TabContext);
    const { withRestrictions } = useRestriction();

    const submit = (formState) => {
        setFails({});
        onSubmit(formState);
    };

    const setFails = (states) => {
        setInputFails({...states});
    };

    useEffect(() => {
        if (setResetFailsFunc) {
            const resetFailsFunc = () => setFails({});
            setResetFailsFunc(resetFailsFunc);
        }
    }, [setResetFailsFunc]);

    return (
        <Container className={styles.formContainer}>
            <Form
                onSubmitFailure={setFails}
                onSubmit={submit}
                autoComplete="off"
                initialValues={{title: editorTitle}}
                {...props}
            >
                <Row className={styles.inputContainer}>
                    <Col xs={7} className={inputFails.title && styles.inputErrorText}>
                        <Input 
                            placeholder={<InputPlaceholder text={t('enter_title')}/>}
                            field={'title'}
                            validate={validate}
                            onBlur={withRestrictions(autoSaveOnFilledTitle)}
                            errorStyle={inputFails.title && styles.inputErrorBorder}
                        />
                         <TouchableOpacity
                            className={styles.backButtonContainer}
                            onClick={close}
                        >
                            <i className="fas fa-chevron-left"/>
                        </TouchableOpacity>
                    </Col>
                    <Col xs={5} className={inputFails.keywords && styles.inputErrorText}>
                        <Input 
                            placeholder={<InputPlaceholder text={t('enter_comma-separated_keywords')}/>}
                            field={'keywords'}
                            validate={validate}
                            errorStyle={inputFails.keywords && styles.inputErrorBorder}
                        />
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

const Buttons = ({save, saveAsPdf, saveAsDoc, sendEmail, waiting, isDefaultTemplate, isTemplate, handleSwitchPublic, isPublic}) => {
    const {t} = useTranslation()
    const { withRestrictions } = useRestriction();
    return (
        <div className={cn(styles.buttonsContainer, isTemplate && styles.template)}>
            <TouchableOpacity
                className={cn(styles.save, isDefaultTemplate && styles.saveDisabled)}
                onClick={!isDefaultTemplate ? withRestrictions(save) : () => {
                }}
            >
                {waiting ?
                    <ClipLoader
                        size={20}
                        color={"#FFFFFF"}
                    />
                    :
                    <i className="fas fa-save"/>
                }
                <div>{waiting ? t('saving_template') : t('save_template')}</div>
            </TouchableOpacity>
            <div className={styles.publicPrivateSwitch}>
                <div>{isPublic ? t("public") : t("private")}</div>
                <SwitchToggle onChange={handleSwitchPublic} checked={isPublic}/>
            </div>
        </div>
    );
}

export default Editor;



