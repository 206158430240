const ContextShape = {
        pages: {
            'templates': {
                'use default': {
                    title: 'Try a default template',
                    hint: 'Navigate to default templates and click one',
                    done: false
                },
                'create template': {
                    title: 'Create your first template',
                    hint: `Use 'Create new' button for it`,
                    done: false,
                },
                'template title': {
                    title: 'Name your template',
                    hint: 'Use unique names for each template',
                    done: false,
                },
                'add keywords': {
                    title: 'Add keywords to your template',
                    hint: 'They help your Assistant to search templates',
                    done: false,
                },
                'add variable': {
                    title: 'Add variable fields',
                    hint: 'Just drag and drop them from the right panel. You can hover the variable read pop-up hints',
                    done: false,
                },
                'save template': {
                    title: 'Save your template',
                    hint: 'Click the save icon above the editor',
                    done: false,
                },
            },
            'assistant': {
                'use microphone': {
                    title: 'Try using microphone',
                    hint: 'Click the microphone button to speak to your Assistant. Don\'t forget to grant access for it in your browser',
                    done: false,
                },
                'create document': {
                    title: 'Create your first document',
                    hint: 'The Assistant will guide you through the template to create your document. It\'s pretty easy!',
                    done: false,
                }
            },
            'documents': {
                'preview document': {
                    title: 'Preview your document',
                    hint: 'Click one of your documents. You\'ll also be able to edit, share or print your document from there',
                    done: false
                },
                // TODO for TINY MCE
            },
            'products': {
                'add product': {
                    title: 'Add a new product',
                    hint: 'Store your products here and use them in any document',
                    done: false,
                },
                'fill product': {
                    title: 'Add the product info',
                    hint: 'The Assistant will use this data for document completion',
                    done: false,
                },
                'product keywords': {
                    title: 'Add some keywords',
                    hint: 'Grouping products step by step',
                    done: false,
                },
                'save product' : {
                    title: 'Save your product',
                    done: false,
                },
                'add catalogue': {
                    title: 'Try to import catalogues',
                    hint: 'Navigate to \'CATALOGUES\'. You can upload PDF catalogues to share them',
                    done: false,
                },
                'import csv': {
                    title: 'Import your products',
                    hint: 'You can import a CSV list of your products if you have one',
                    done: false,
                },
                'export csv': {
                    title: 'Export your products',
                    hint: 'You can also export your products into CSV',
                    done: false,
                },
            },
            'services': {
                'add service': {
                    title: 'Add a new service',
                    hint: 'Store your services here and use them in any document',
                    done: false,
                },
                'fill service': {
                    title: 'Add the service info',
                    hint: 'The Assistant will use this data for document completion',
                    done: false,
                },
                'service keywords': {
                    title: 'Add some keywords',
                    hint: 'They are also useful for grouping your services into categories (e.g. furniture, winter collection)',
                    done: false,
                },
                'save service' : {
                    title: 'Save your service',
                    done: false,
                },
                'add catalogue': {
                    title: 'Try to import catalogues',
                    hint: 'Navigate to \'CATALOGUES\'. You can upload PDF catalogues to share them',
                    done: false,
                },
                'import csv': {
                    title: 'Import your services',
                    hint: 'You can import a CSV list of your services if you have one',
                    done: false,
                },
                'export csv': {
                    title: 'Export your services',
                    hint: 'You can also export your services into CSV',
                    done: false,
                },
            },
            'profile': {
                'profile info': {
                    title: 'Set up your profile',
                    done: false,
                },
                'company info': {
                    title: 'Complete your company info',
                    done: false
                },
                'business info': {
                    title: 'Complete your business info',
                    done: false
                },
                'assistant setup': {
                    title: 'Set up your Assistant',
                    hint: 'Choose a name and an avatar',
                    done: false
                }
            },
            'customers': {
                'add customer': {
                    title: 'Add a new customer',
                    done: false
                },
                'customer activity': {
                    title: 'Add an activity to your customer',
                    hint: 'Thus you can plan interactions with your customers. You can also synchronize them with your mobile calendar using Fastboss mobile app',
                    done: false
                }
            },
            'partners': {
                'add partner': {
                    title: 'Add a new partner',
                    done: false
                }
            }
        },
        currentPage: 'templates',
        prevPage: 'templates',
    }

export default ContextShape
