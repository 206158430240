import React, {forwardRef, useEffect, useRef, useState} from "react";
import getRefRect from "../../../../utils/getRefRect";
import cn from "classnames";
import styles from "./style.module.scss";
import TouchableOpacity from "../TouchableOpacity";

const HIDE_TIME = 250;

export const ThreeDots = forwardRef(({className, ...props}, ref) => {
  return (
    <div
      ref={ref}
      className={cn(styles.dotsIcon, className)}
    >
      <i className={cn("fas fa-ellipsis-v")}/>
    </div>
  );
});

function DropDown({dropDown=[], rootRef, onTabClick=({action=()=>{}}) => action()}) {
  const [isShowing, setIsShowing] = useState(false);

  const dropDownRef = useRef(null);

  const hideTimer = useRef(0);
  function hide() {
    hideTimer.current = setTimeout(() => {
      setIsShowing(false);
    }, HIDE_TIME);
  }

  const onRootMouseEnter = () => {
    setIsShowing(true);
  };

  const onRootMouseOut = ({target, relatedTarget}) => {
    if (rootRef.current.contains(target) && !dropDownRef.current.contains(relatedTarget) && !rootRef.current.contains(relatedTarget)) {
      hide();
    }
  };

  const onDropDownMouseOut = ({target, relatedTarget}) => {
    if (dropDownRef.current.contains(target) && !dropDownRef.current.contains(relatedTarget) && !rootRef.current.contains(relatedTarget)) {
      hide();
    }
  };

  const onDropDownMouseEnter = () => {
    clearTimeout(hideTimer.current);
  };

  useEffect(() => {
    if (rootRef.current && dropDownRef.current) {
      rootRef.current.addEventListener('mouseenter', onRootMouseEnter);
      rootRef.current.addEventListener('mouseout', onRootMouseOut);
      dropDownRef.current.addEventListener('mouseout', onDropDownMouseOut);
      dropDownRef.current.addEventListener('mouseenter', onDropDownMouseEnter);
    }
  }, [rootRef, dropDownRef]);

  const getPosition = () => {
    if (rootRef.current && dropDownRef.current){
      const refRect = rootRef.current.getBoundingClientRect()
      return window.innerWidth - refRect.left < 230 ? "LEFT" : "RIGHT"
    }
  }
  const rootRect = getRefRect(rootRef);
  getPosition()
  return (
    <div
      ref={dropDownRef}
      className={cn(styles.dropdown, isShowing && styles.showing)}
      style={rootRect && { ...getPosition() === "LEFT" ? { left: rootRect.right - 110, top: rootRect.bottom } : { left: rootRect.right, top: rootRect.bottom }}}
    >
      {isShowing && dropDown.map((item) => {
        const {name} = item;
        return (
          <TouchableOpacity
            key={name}
            onClick={(e) => {
              e.stopPropagation();
              onTabClick(item);
              setIsShowing(false);
            }}
            className={styles.dropItem}
          >
            {name}
          </TouchableOpacity>
        );
      })}
    </div>
  );
}

export default DropDown;
