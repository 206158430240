import React, { useContext, useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DataContext from '../../../contexts/DataContext';
import EditableTable from '../../common/parts/EditableTable';
import moment from 'moment';
import Button from '../../common/core/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../contexts/LocaleContext';
import { DeleteAccountModal } from '../../common/parts/RemoveAccountForm';
import styles from './style.module.css';
import { Modal } from 'react-bootstrap';
import { suspendAccount } from '../../../api/Account/dataSetters';
import NetworkContext from '../../../contexts/NetworkContext';

const Billing = () => {
  const { pricesList, account, subscription, subscriptionList } =
    useContext(DataContext);
  const { onLogout } = useContext(NetworkContext);
  const { t } = useTranslation();
  const [acountDeleteModal, setAccountDeleteModal] = useState(false);
  const [accountSuspendModal, setAccountSuspendModal] = useState(false);

  const columns = [
    {
      Header: '#',
      accessor: 'id',
      width: 50,
    },
    {
      Header: t('start_date').toUpperCase(),
      accessor: 'start_date',
      format: (value) => moment(value).format('DD.MM.YYYY'),
    },
    {
      Header: t('end_date').toUpperCase(),
      accessor: 'end_date',
      format: (value) => moment(value).format('DD.MM.YYYY'),
    },
    {
      Header: t('amount').toUpperCase(),
      accessor: '',
      format: (value) => {
        const isAnnualSubscription = value.type === 2;
        const price = pricesList.data[isAnnualSubscription ? 1 : 0];
        return `${price.symbol}${price.amount_admin + (price.amount_user * value.quantity_of_users)}`;
      },
    },
    {
      Header: t('invoice').toUpperCase(),
      accessor: '',
      Cell: (value) => {
        if (!value.row._original.invoice_pdf) return <></>;
        return <a href={value.row._original.invoice_pdf} rel="noopener noreferrer" className={styles.billingInvoiceLink}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#070F17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 10L12 15L17 10" stroke="#070F17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 15V3" stroke="#070F17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>;
      },
    },
    {
      Header: t('description').toUpperCase(),
      accessor: '',
      width: 460,
      format: (value) => {
        const isAnnualSubscription = value.type === 2;
        const price = pricesList.data[isAnnualSubscription ? 1 : 0];
        return `${t(price.name)}, 1 ${t('Admin')} (${price.symbol}${price.amount_admin}${t('/seat')}), ${value.quantity_of_users} ${t('Users')} (${price.symbol}${price.amount_user * value.quantity_of_users}${t('/seat')})`;
      },
    },
  ];

  const onSuspendAccount = async () => {
    await suspendAccount();
    onLogout();
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!subscription.isLoaded) subscription.load();
    if (!subscriptionList.isLoaded) subscriptionList.load();
    if (!pricesList.isLoaded) pricesList.load();
  }, [subscription.isLoaded, subscriptionList.isLoaded, pricesList.isLoaded]);

  useEffect(() => {
    if (!subscription.isLoaded) subscription.load();
    if (!subscriptionList.isLoaded) subscriptionList.load();
    if (!pricesList.isLoaded) pricesList.load();
  }, [subscription.isLoaded, subscriptionList.isLoaded, pricesList.isLoaded]);

  return (
    <div id="tutorial-step-12">
      <div className={styles.billingAction} style={{ display: 'flex' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <div className={styles.tooltipBtn}>
              {t('renew_button_description')}
            </div>
          }
        >
          <Button
            title={t('renew_subscription')}
            onClick={() => {
              navigate('/payment');
            }}
            width={'15rem'}
            height={30}
            fontSize={15}
            containerStyle={{ marginLeft: '1rem', marginBottom: '0.5rem' }}
          />
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <div className={styles.tooltipBtn}>
              {t('suspend_button_description')}
            </div>
          }
        >
          <Button
            title={t('suspend_account')}
            disabledTitle={t('your_account_is_suspended')}
            disabledStyles={{
              opacity: '0.7',
            }}
            onClick={() => setAccountSuspendModal(true)}
            width={'15rem'}
            height={30}
            fontSize={15}
            containerStyle={{ marginLeft: '1rem', marginBottom: '0.5rem' }}
            disabled={account.data.is_suspended}
          />
        </OverlayTrigger>
      </div>

      <EditableTable
        defaultPageSize={4}
        noEdit={true}
        columns={columns}
        sortable={false}
        resizable={false}
        showToolbar={false}
        selectable={false}
        data={{ items: subscriptionList.data, page: 1, pages: 1 }}
        getter={() => ({ items: subscriptionList.data, page: 1, pages: 1 })}
        noWhatText='subscriptions'
      />

      <SuspendAccountPopup
        show={accountSuspendModal}
        handleClose={() => setAccountSuspendModal(false)}
        onConfirm={onSuspendAccount}
        onCancel={() => setAccountSuspendModal(false)}
      />
    </div>
  );
};

export default Billing;

const SuspendAccountPopup = ({ show, handleClose, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      contentClassName={styles.modal}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      style={{
        textAlign: 'center',
        backgroundColor: 'rgba(85, 69, 144, 0.17)',
      }}
    >
      <Modal.Header
        className={styles.header}
        closeButton
        style={{ border: 'none' }}
      >
        <div className={styles.modalHeaderContent}>
          <div>
            <div
              className={styles.modalHeaderWrapper}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{ width: '70px', height: '50px', overflow: 'hidden' }}
              >
                <img
                  style={{ width: '70px' }}
                  src="https://fastboss.ai/wp-content/uploads/2020/09/logo-FASTBOSS-ON-WEB.png"
                />
              </div>
              <Modal.Title className={styles.modalTitle}>
                {t('your_account_will_be_suspended_for_50_days')}
              </Modal.Title>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <p className={styles.modalParagraph}>
          {t('during_this_period_you_can_renew_subscription')}
        </p>
        <p className={styles.modalParagraph}>
          {t('you_will_still_be_able_after_suspend')}
        </p>

        <span className={styles.modalListTitle}>
          {t('you_still_will_be_able_to')}
        </span>
        <ul className={styles.modalBodyList}>
          {[
            t('view_documents_and_templates.'),
            t('download_documents_or_customer_database.'),
            t('view_all_platform_sections_and_users_from_your_company.'),
          ].map((title, index) => {
            return (
              <li className={styles.modalBodyItem}>
                <span>{index + 1}.</span>
                <span className={styles.modalBodyItemTitle}>{title}</span>
              </li>
            );
          })}
        </ul>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <p className={styles.modalFooterTitle}>
          {t('if_not_commence_renewal_subscription_account_will_be_removed')}
        </p>
        <Button
          width={'100%'}
          height={50}
          title={t('suspend_account')}
          onClick={onConfirm}
        />
      </Modal.Footer>
    </Modal>
  );
};
