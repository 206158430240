import React, { useContext, useState } from "react";
import EditableTable from "../../../common/parts/EditableTable";
import ElectByIndex from "../../../common/core/ElectByIndex";
import DashboardTabs from "../../../common/parts/DashboardTabs";
import MarketForm from "./MarketForm";
import "react-table/react-table.css";
import regularTableControllers from "../../../common/parts/regularTableControllers";
import AddEditTableModal from "../../../common/parts/AddEditTableModal";
import Enum from "../../../../utils/enum";
import { useLoaded } from "../../../../contexts/DataContext";
import FetchLoading from "../../../common/parts/FetchLoading";
import ImportCsvModal, { useCvsIO } from "../../../common/parts/ImportCsvModal";
import useWaiting from "../../../common/hooks/useWaiting";
import {getPagedBusinessData} from "../../../../api/Business/dataGetters";
import {useTranslation} from "../../../../contexts/LocaleContext";
import { useRestriction } from "../../../../contexts/Restriction/RestrictionContext";

const columns = [
  {
    Header: "CODE#",
    accessor: "id",
    width: 65,
    index: 0
  },
  {
    Header: "target_market_who_are_your_customers",
    accessor: "name",
    filter: true,
    index: 1
  },
  {
    Header: "KEYWORDS",
    accessor: "keywords",
    filter: true,
    width: 150,
    index: 2
  }
];

const [MARKET_MODAL] = Enum(1);

function Market() {
  const { isLoaded, markets } = useLoaded("markets");
  const [modalIndex, setModalIndex] = useState(null);
  const closeModal = () => setModalIndex(null);
  const { t } = useTranslation();
  const { withRestrictions } = useRestriction();

  const [editingValues, setEditingValues] = useState({});
  const openEditModal = product => {
    setEditingValues(product);
    setModalIndex(MARKET_MODAL);
  };

  const table = isLoaded ? (
    <EditableTable
      getter={getPagedBusinessData}
      data={markets.data}
      deleteItem={markets.remove}
      editItem={openEditModal}
      columns={columns.map((item)=>({...item, Header: t(item.Header.toLowerCase().replaceAll(' ','_')).toUpperCase()}))}
      noWhatText={"target_markets"}
    />
  ) : (
    <FetchLoading />
  );

  const { importCsvModal, importCSV, exportCSV, exportWaiting } = useCvsIO(
    markets,
    "Target Market,Keywords"
  );

  const TABS = [
    {
      name: t("target_markets").toUpperCase(),
      content: table,
      controllers: regularTableControllers({
        addNew: withRestrictions(() => setModalIndex(MARKET_MODAL)),
        importCSV: withRestrictions(importCSV),
        exportCSV,
        exportWaiting,
        t
      })
    }
  ];

  const MODALS = [
    <AddEditTableModal
      list={markets}
      CustomForm={MarketForm}
      close={closeModal}
      editingValues={editingValues}
      name={t("target_market")}
    />
  ];

  const content = TABS.map(item => item.content);

  const main = (
    <DashboardTabs tabs={TABS} readyContents={content} startTabIndex={0} />
  );

  return (
    <>
      <ElectByIndex index={modalIndex} defaultComponent={main}>
        {MODALS}
      </ElectByIndex>
      {importCsvModal}
    </>
  );
}

export default Market;
