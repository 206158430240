import { forwardRef, useRef } from 'react';
import { CKEditor as Editor } from '@ckeditor/ckeditor5-react';

import styles from './style.module.css';
import CKEditorCustom from 'ckeditor5-custom-build/build/ckeditor';

const editorConfiguration = {
  toolbar: {
    items: [
      'previousPage',
      'nextPage',
      'pageNavigation',
      'undo',
      'redo',
      '|',
      'heading',
      '|',
      'fontSize',
      'fontFamily',
      '|',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'alignment',
      '|',
      'insertTable',
      'numberedList',
      'bulletedList',
      '|',
      'outdent',
      'indent',
      '|',
      'todoList',
      'link',
      'blockQuote',
      'imageUpload',
      '|',
    ],
    shouldNotGroupWhenFull: true,
  },
  pagination: {
    pageWidth: '21cm',
    pageHeight: '32.7cm',
    pageMargins: {
      top: '12.7mm',
      bottom: '12.7mm',
      left: '12.7mm',
      right: '12.7mm',
    },
  },
};

const CKEditor = forwardRef(({ setContent = () => {}, ...rest }, ref) => {
  const toolbarRef = useRef(null);

  return (
    <div className={styles.documentEditor} ref={ref}>
      <div className={styles.documentEditor__toolbar} ref={toolbarRef} />
      <div className={styles.documentEditor__editableContainer}>
        <Editor
          config={editorConfiguration}
          onReady={(editor) => {
            window.editor = editor;

            if (toolbarRef.current)
              toolbarRef.current.appendChild(editor.ui.view.toolbar.element);
          }}
          onChange={(event, editor) => setContent(editor.getData())}
          editor={CKEditorCustom}
          {...rest}
        />
      </div>
    </div>
  );
});

export default CKEditor;
