import { POST } from "../network";
import { accountEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
    try {
      return await POST(path, data);
    } catch (e) {
      console.error(path, e);
    }
  };

const sendFile = async (path, file, fieldName) => {
    const formData = new FormData();
    formData.append(fieldName ? fieldName : "file", file);
    const res = await POST(path, formData, true);
};

export const setAccountData = async data => {
    if (data.password) delete data.password;
    await setData(accountEndpoints.update, data);
};
  
export const uploadAccountAvatar = async file => {
    await sendFile(accountEndpoints.uploadAvatar, file, "avatar");
};
  
export const requestCodeToEmail = async () => {
    return await setData(accountEndpoints.sendWithCodeToEmail, {});
};
  
export const confirmDeleteAccount = async (code) => {
    return await setData(accountEndpoints.removeByCode, { code });
};
  
export const suspendAccount = async () => {
    return await setData(accountEndpoints.suspend, { });
}

export const unsuspendByCode = async (code) => {
    return await setData(accountEndpoints.unsuspendByCode, { code });
}

export const changePasswordRequestHandler = async data => {
    return await setData(accountEndpoints.changePassword, data);
};
  