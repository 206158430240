import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import Select, { components } from 'react-select';
import Card from '../../../../../../common/parts/Card';
import { useTranslation } from '../../../../../../../contexts/LocaleContext';
import CardHeader from '../../../../../../common/parts/CardHeader';
import DataContext from '../../../../../../../contexts/DataContext';
import Member from '../../../../../../common/parts/Member';
import CorporateContext from '../../../CorporateContext';
import { Form } from 'informed';
import CheckboxWithLabel from '../../../../../../SignIn/CheckboxWithLabel';

const ManageTaskAssignments = ({
  className,
  disabled = false,
  author,
  members,
  setMembers,
}) => {
  const { t } = useTranslation();
  const { account } = useContext(DataContext);

  const onAssign = (assigned = []) => {
    const joined = [...members, ...assigned];
    const unique = joined.filter(
      (current, index, array) =>
        array.findIndex((i) => i.id === current.id) === index
    );
    setMembers(unique);
  };
  const onRemove = (member) => () => {
    setMembers(members.filter((current) => current.id !== member.id));
  };

  return (
    <Card className={cn(className)}>
      <CardHeader className={styles.cardHeadingMain}>
        {t('Assigned')}
      </CardHeader>
      <div className={cn(styles.cardSection)}>
        <CardHeader>{t('Author')}</CardHeader>
        <div className={cn(styles.cardContent)}>
          <Member member={author || account.data} />
        </div>
      </div>
      <div className={cn(styles.cardSection)}>
        <CardHeader>{t('Members')}</CardHeader>
        <div className={cn(styles.cardMembers)}>
          {members.map((current) => (
            <Member
              className={cn(styles.cardMemeber, {
                [styles.cardMemberRemovable]: !disabled,
              })}
              member={current}
              onClick={onRemove(current)}
            />
          ))}
          {!disabled && <AssignTaskMembers setMembers={onAssign} />}
        </div>
      </div>
    </Card>
  );
};

const AssignTaskMembers = ({ setMembers = () => {} }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { company, departments, role } = useContext(CorporateContext);
  const [formApi, setFormApi] = useState({});
  const getFormApiCallback = useCallback((api) => setFormApi(api), []);

  const onSubmit = (fields) => {
    const ids = Object.keys(fields).map((i) => Number(i.split('_')[1]));
    const members = departments.data.reduce(
      (accumulator, department) => [
        ...accumulator,
        ...department.employees_department.filter((employee) =>
          ids.includes(employee.id)
        ),
      ],
      []
    );
    formApi.setValues({});
    setMembers(members);
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 240,
      margin: 0,
      border: '1px solid #e7e7e8',
      outline: 'none',
      boxShadow: 'none',
      cursor: 'text',
      '&:hover': {
        borderColor: '#e7e7e8',
      },
    }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  };

  const options = useMemo(() => {
    return departments.data.reduce(
      (accumulator, department) => [
        ...accumulator,
        ...department.employees_department.map((employee) => ({
          value: {
            ...employee,
            department_name: department.name,
          },
          label: `${employee.user_name}, ${department.name}`,
        })),
      ],
      []
    );
  }, [departments.data]);

  const CustomOption = (option) => {
    return (
      <div className={cn(styles.assignCheckbox)}>
        <CheckboxWithLabel
          field={`assign_${option.value.id}`}
          value={option.value.id}
          label={
            <>
              {option.value.user_name}{' '}
              <span className={cn(styles.assignDepartment)}>
                {option.value.department_name}
              </span>
            </>
          }
        />
      </div>
    );
  };

  return (
    <Form
      getApi={getFormApiCallback}
      onSubmit={onSubmit}
      className={styles.form}
    >
      <Dropdown
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          formApi.submitForm();
        }}
        onBlur={() => formApi && formApi.submitForm()}
        target={
          <button
            type="button"
            className={cn(styles.assignButton)}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <div className={cn(styles.assignIconWrapper)}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 3.33325V12.6666"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.33301 8H12.6663"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <span className={cn(styles.assignText)}>{t('Assign users')}</span>
          </button>
        }
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          components={{
            Option: CustomOption,
            Control: CustomControl,
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={(newValue) => {
            setValue(newValue);
            setIsOpen(false);
            formApi.submitForm();
          }}
          options={options}
          placeholder={t('Search')}
          styles={selectStyles}
          tabSelectsValue={false}
          value={value}
          blurInputOnSelect={false}
        />
      </Dropdown>
    </Form>
  );
};

const CustomControl = (props) => {
  return (
    <div className={cn(styles.assignHeader)}>
      <div className={cn(styles.assignSearch)}>
        <components.Control {...props} />
        <button className={styles.assignSearchButton} type="submit">
          <svg
            className={styles.search}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z"
              stroke="#070F17"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.7498 15.75L12.4873 12.4875"
              stroke="#070F17"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? (
      <div className={cn(styles.assignDropdown)}>{children}</div>
    ) : null}
    {isOpen ? (
      <div
        style={{
          bottom: 0,
          left: 0,
          top: 0,
          right: 0,
          position: 'fixed',
          zIndex: 1,
        }}
        onClick={onClose}
      />
    ) : null}
  </div>
);

export default ManageTaskAssignments;
