import React, {useContext} from 'react';
import styles from './style.module.css';
import cn from 'classnames';
import Input from "../../../common/core/Input";
import Col from "react-bootstrap/Col";
import DashboardTabs from "../../../common/parts/DashboardTabs";
import Row from "react-bootstrap/Row";
import ButtonsPair from "../../../common/parts/ButtonsPair";
import {isEmpty} from "../../../../utils/StringUtils";
import AutoForm from "../../../common/core/AutoForm";
import useWaiting from "../../../common/hooks/useWaiting";
import DataContext from "../../../../contexts/DataContext";
import {useTranslation} from "../../../../contexts/LocaleContext";
import GuideContext from "../../../Onboarding";
import { useRestriction } from "../../../../contexts/Restriction/RestrictionContext"

const CustomInput = ({title, ...rest}) => (
  <>
    <Col className={cn(styles.title, 'col-12 pl-2 mb-3')}>{title.toUpperCase()}</Col>
    <Col className={'col-12'}>
      <Input heigth={40} {...rest}/>
    </Col>
  </>
);

const CustomForm = ({onSubmit, close, initialValues}) => {
  const [waiting, submit] = useWaiting(onSubmit);
  const {company} = useContext(DataContext);
  const {t} = useTranslation()
  const {markDone} = useContext(GuideContext);
  return (
    <AutoForm
      onSubmit={(p)=>{
        markDone('save service')
        submit(p)}
      }
      className={styles.form}
      initialValues={{producer: company.data.name, ...initialValues}}
    >
      <Row>
        <Col className={'col-4 pr-2'}>
          <CustomInput
            field={'name'}
            title={t('name_of_service')}
          />
        </Col>
        <Col className={'col-2 px-2'}>
          <CustomInput
            field={'price'}
            title={t('price')}
          />
        </Col>
        <Col className={'col-4 pl-2'}>
          <CustomInput
            field={'unitmeasure'}
            title={t('u.m._unit_measure')}
          />
        </Col>
      </Row>
      <Row className={'pt-2'}>
        <Col className={'col-10'}>
          <CustomInput
            field={'supply'}
            title={t('delivery')}
          />
        </Col>
      </Row>
      <Row>
        <Col className={'col-10 pt-2'}>
          <CustomInput
            field={'generaldescription'}
            title={t('general_description')}
          />
        </Col>
        <Col className={'col-10'}>
          <CustomInput
            field={'keywords'}
            title={t('keywords')}
            placeholder={t("enter_comma-separated_keywords")}
          />
        </Col>
      </Row>
      <Row>
        <ButtonsPair saveWaiting={waiting} onCancel={close}/>
      </Row>
    </AutoForm>
  );
};

const ModalService = ({
  editingValues={},
  close=()=>{},
  initWithName=null
}) => {
  const {services} = useContext(DataContext);
  const { withRestrictions } = useRestriction();
  const {t} = useTranslation()
  const createSubmit = (formState) => {
    withRestrictions(async (props) => await services.create(props))({...formState})
    close();
  };

  const editSubmit = (formState) => {
    withRestrictions(async (props) => await services.update(props))({...formState, id: editingValues.id})
    close();
  };

  const TABS = [{
    name: isEmpty(editingValues) ? t("add_new_service").toUpperCase() : `${t("edit_service")} #${editingValues.id}`.toUpperCase(),
    content: (
      <CustomForm
        onSubmit={isEmpty(editingValues) ? createSubmit : editSubmit}
        initialValues={initWithName ? {...editingValues, name: initWithName} : editingValues}
        close={close}
      />
    ),
  }];

  return (
    <DashboardTabs
      tabs={TABS}
      readyContents={TABS.map(tab => tab.content)}
    />
  );
};

export default ModalService;
