import { GET } from '../network';
import { filtersToQuery } from '../../utils/filtersToQuery';
import { partnerEndpoints } from '../requestEndpoints';

const getData = async (path, Type) => {
  try {
    const res = await GET(path);
    return res.body.data || JSON.parse(res.data);
  } catch (e) {
    if (Type) return new Type();
    return null;
  }
};

const getListData = async (path) => {
  const data = await getData(path);
  if (data) {
    return data.items;
  }
  return [];
};

const getPagedListData = async (path) => {
  const data = await getData(path);
  if (data) {
    return {
      items: data.items,
      page: data.currentPage || 1,
      pages: data.totalPages || 1,
    };
  }
  return { items: [], page: 1, pages: 1 };
};

const getFile = async (path, Type) => {
  try {
    const res = await GET(path);
    return res.data;
  } catch (e) {
    if (Type) return new Type();
    return null;
  }
};

const getByKeywords = async (path, keywords) => {
  const searchParams = new URLSearchParams();
  keywords.forEach((keyword) => {
    searchParams.append('keywords', keyword);
  });
  return (await getData(`${path}?${searchParams.toString()}`)) || [];
};

export const getPagedPartnersData = async (filters = {}) => {
  return getPagedListData(partnerEndpoints.getList + filtersToQuery(filters));
};

export const getPartnersData = async () => {
  return getListData(partnerEndpoints.getList);
};

export const getPartnersById = async (id) => {
  return getData(partnerEndpoints.getById + id);
};

export const getPartnersByKeywords = async (keywords) => {
  return getByKeywords(partnerEndpoints.search, keywords);
};

export const exportPartners = async () => {
  return getFile(partnerEndpoints.exportCsv, String);
};

export const fakeTask = async () => {
  return {
    page: 1,
    pages: 1,
    items: [
      {
        id: 0,
        task: 'Call  Hannah Darsey from General office',
        assigned_to: 'Sasha Camberly',
        status: 'done',
        start_date: '2021-09-05T12:07:00.511Z',
        due_date: '2021-09-08T12:08:00.511Z',
        duration: '1 hour',
        priority: 'Urgent',
        progress: '75',
      },
      {
        id: 1,
        task: 'Call  Hannah Darsey from General office',
        assigned_to: 'Sasha Camberly',
        status: 'done',
        start_date: '2021-09-04T12:06:00.511Z',
        due_date: '2021-09-07T12:08:00.511Z',
        duration: '1 hour',
        priority: 'Urgent',
        progress: '75',
      },
      {
        id: 2,
        task: 'Call  Hannah Darsey from General office',
        assigned_to: 'Sasha Camberly',
        status: 'done',
        start_date: '2021-09-09T12:06:00.511Z',
        due_date: '2021-09-15T12:08:00.511Z',
        duration: '1 hour',
        priority: 'Urgent',
        progress: '75',
      },
      {
        id: 3,
        task: 'Call  Hannah Darsey from General office',
        assigned_to: 'Sasha Camberly',
        status: 'done',
        start_date: '2021-09-05T12:06:00.511Z',
        due_date: '2021-09-08T12:08:00.511Z',
        duration: '1 hour',
        priority: 'Urgent',
        progress: '75',
      },
      {
        id: 4,
        task: 'Call  Hannah Darsey from General office',
        assigned_to: 'Sasha Camberly',
        status: 'done',
        start_date: '2021-09-29T12:06:00.511Z',
        due_date: '2021-09-29T12:08:00.511Z',
        duration: '1 hour',
        priority: 'Urgent',
        progress: '75',
      },
    ],
  };
};
