import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

const ReactCodeInput = (props) => {
    const {
      className,
      inputClassName,
      style,
      fields,
      inputStyle,
      inputStyleActive,
      placeholder,
      autoComplete,
      type,
      disabled,
      filterKeyCodes,
      forceUppercase,
      value
    } = props;


    const styles = {
      container: { display: 'inline-block', ...style },
      input: inputStyle,
    };


    useEffect(() => {
      if (forceUppercase) {
        value = value.toUpperCase();
      }
      
      const inputs = []
      for (let i = 0; i < +options.fields; i += 1) {
          if (i < 32) {
              const value = '';
              inputs.push(value);
          }
      }
      setOptions((prevState) => ({...prevState, input: inputs }))
    }, [])

    const [options, setOptions] = useState({
        value,
        fields,
        type,
        input: [],
        disabled,
        filterKeyCodes,
        defaultInputStyle: {
          fontFamily: 'monospace',
          MozAppearance: 'textfield',
          borderRadius: '6px',
          border: '1px solid',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
          margin: '4px',
          paddingLeft: '8px',
          paddingRight: 0,
          width: '36px',
          height: '42px',
          fontSize: '32px',
          boxSizing: 'border-box',
        },
  })

  const { input, defaultInputStyle } = options;
  const [activeInputId, setactiveInputId] = useState()
  const [textInput, setTextInput] = useState([])

  const handleFocus = (e, id) => {
    e.target.select();
    setactiveInputId(id)
  }

  const handleBlur = (e) => {
    setactiveInputId(null)
    handleTouch(e.target.value);
  }

  const handleTouch = (value) => {
    const { touch, untouch, name } = props;

    if (typeof touch === 'function' && typeof untouch === 'function') {
      if (value === '') {
        touch(name);
      } else {
        untouch(name);
      }
    }
  }
  useEffect(() => {
    const fullValue = options.input.join('');
    if (props.onChange) {
      props.onChange(fullValue);
    }
  }, [options.input, options.value])

  const handleChange = (e, dataId) => {
    const textInputCopy = [...textInput]
    const { filterChars, filterCharsIsWhitelist } = props;
    
    let value = String(e.target.value).length === 2 ? String(e.target.value).slice(1) : String(e.target.value)

    if (props.forceUppercase) {
      value = value.toUpperCase();
    }

    if (options.type === 'number') {
      value = value.replace(/[^\d]/g, '');
    }

    value = value.split('').filter(currChar => {
      if (filterCharsIsWhitelist) {
        return filterChars.includes(currChar);
      }
      return !filterChars.includes(currChar);
    }).join('');

    let fullValue = value;

    if (value !== '') {
      const input = options.input.slice();

      if (value.length > 1) {
        value.split('').map((chart, i) => {
          if (+dataId + i < props.fields) {
            input[+dataId + i] = chart;
          }
          return false;
        });
      } else {
        input[+dataId] = value;
      }

      input.map((s, i) => {
        if (textInputCopy[i]) {

          textInputCopy[i].value = s;
        }
        return false;
      });
      const newTarget = textInputCopy[dataId < input.length
        ? +dataId + (value.length > 2 ? value.length < input.length ? value.length : input.length - 1 : 1)
        : dataId];

      if (newTarget) {
        newTarget.focus();
        newTarget.select();
      }

      fullValue = input.join('');
      setOptions((prevState) => ({...prevState, value:  input.join(''), input }));

      if (props.onChange && fullValue) {
        props.onChange(fullValue);
      }
    }

    setTextInput(textInputCopy)
    handleTouch(fullValue);
  }

  const handleKeyDown = (e, dataId) => {
    const copyTextInput = textInput.slice()
    const target = +dataId,
      nextTarget = textInput[target + 1],
      prevTarget = textInput[target - 1];

    let input,value;

    switch (e.keyCode) {
      case BACKSPACE_KEY:
        e.preventDefault();
        copyTextInput[target].value = '';
        input = JSON.parse(JSON.stringify(options.input));
        options.input[target] = '';
        value = input.join('');

        setOptions((prevState) => ({...prevState, value:input}));
        if (copyTextInput[target].value === '') {
          if (prevTarget) {
            prevTarget.focus();
            prevTarget.select();
          }
        }
        break;

      case LEFT_ARROW_KEY:
        e.preventDefault();
        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        }
        break;

      case RIGHT_ARROW_KEY:
        e.preventDefault();
        if (nextTarget) {
          nextTarget.focus();
          nextTarget.select();
        }
        break;

      case UP_ARROW_KEY:
        e.preventDefault();
        break;

      case DOWN_ARROW_KEY:
        e.preventDefault();
        break;

      case E_KEY:
        if (e.target.type === 'number') {
          e.preventDefault();
          break;
        }
        break;

      default:
        break;
    }


    setTextInput(copyTextInput)
    handleTouch(value);

  }

    if (!className && Object.keys(inputStyle).length === 0) {
      Object.assign(inputStyle, {
        ...defaultInputStyle,
        color: 'black',
        backgroundColor: 'white',
        borderColor: 'lightgrey',
      });
    }

    if (disabled) {
      Object.assign(styles.input, {
        cursor: 'not-allowed',
        color: 'lightgrey',
        borderColor: 'lightgrey',
        backgroundColor: '#efeff1',
      });
    }


    return (
      <div className={classNames(className, 'react-code-input')} style={styles.container}>
        {input.map((value, i) => {
          const dataId = i;
          const withActive = {
            ...styles.input,
            ...inputStyleActive
          }
          

          return (
            <input
              className={inputClassName}
              ref={(ref) => {
                textInput[i] = ref;
              }}
              type={type}
              value={value}
              min={0}
              max={9}
              maxLength={input.length === i + 1 ? 1 : input.length}
              style={dataId === activeInputId ? withActive : styles.input}
              autoComplete={autoComplete}
              onFocus={(e) => handleFocus(e, dataId)}
              onBlur={(e) => handleBlur(e, dataId)}
              onInput={(e) => handleChange(e, dataId)}
              onKeyDown={(e) => handleKeyDown(e, dataId)}
              disabled={disabled}
              placeholder={placeholder}
            />
          );
        })}
      </div>
    );
}

ReactCodeInput.defaultProps = {
  autoComplete: 'off',
  autoFocus: true,
  disabled: false,
  forceUppercase: false,
  fields: 4,
  value: '',
  type: 'text',
  filterKeyCodes: [189, 190],
  filterChars: ['-', '.'],
  filterCharsIsWhitelist: false,
};


export default ReactCodeInput;

