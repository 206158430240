import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { Text, TextArea } from 'informed';
import styles from './style.module.css';
import Row from 'react-bootstrap/Row';

const Input = ({
  textarea = false,
  placeholder,
  inputPlaceholder,
  required,
  initialValue,
  errorStyle,
  ...otherProps
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);
  const Field = textarea ? TextArea : Text;

  return (
    <div>
      <div
        className={styles.placeholder}
        style={{ color: '#000', minHeight: 0 }}
      >
        <span className={styles.holderPlace}>
          {placeholder}
          <span className={styles.holderPlaceRequired}>
            {required ? '*' : ''}
          </span>
        </span>
      </div>
      <Row className={styles.inputContainer}>
        <div
          className={cn(styles.container, errorStyle, {
            [styles.containerFocus]: isFocused,
          })}
        >
          <Field
            onFocus={onFocus}
            onBlur={onBlur}
            className={styles.input}
            autoComplete={'off'}
            style={{ minHeight: 40, height: '100%' }}
            required={required}
            initialValue={initialValue}
            placeholder={inputPlaceholder}
            {...otherProps}
          />
        </div>
      </Row>
    </div>
  );
};

export default Input;
