import {GET} from "../network";
import { businessEndpoints } from "../requestEndpoints";
import { filtersToQuery } from "../../utils/filtersToQuery";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data); 
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

const getListData = async path => {
    const data = await getData(path);
    if (data) {
        return data.items;
    }
    return [];
};

const getPagedListData = async path => {
    const data = await getData(path);
    if (data) {
        return {items: data.items, page: data.currentPage || 1, pages: data.totalPages || 1};
    }
    return {items: [], page: 1, pages: 1};
}

export const getBusinessData = async () => {
    const data = await getListData(businessEndpoints.getList);
    return data ? data[0] : {};
};

export const getPagedBusinessData = async (filters = {}) => {
    return await getPagedListData(businessEndpoints.getList + filtersToQuery(filters));
};