import React from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import { Form, Text } from 'informed';
import { useTranslation } from '../../../../contexts/LocaleContext';

const Search = React.forwardRef(
  (
    {
      className,
      width = 148,
      height = 36,
      fontSize = 16,
      onSubmit = () => {},
      inputProps = {},
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <Form className={cn(className)} onSubmit={onSubmit} {...props}>
        <div className={styles.searchContainer}>
          <Text
            placeholder={t('search')}
            field={'field'}
            className={styles.searchInput}
            style={{ height, minWidth: width, fontSize }}
            itemRef={ref}
            {...inputProps}
          />
          <button
            className={styles.searchButton}
            type="submit"
            style={{ height, minWidth: height }}
          >
            <svg
              className={styles.search}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z"
                stroke="#070F17"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.7498 15.75L12.4873 12.4875"
                stroke="#070F17"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </Form>
    );
  }
);

export default Search;
