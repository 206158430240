import {GET} from "../network";
import { filtersToQuery } from "../../utils/filtersToQuery";
import { sharedTemplateEndpoints } from "../requestEndpoints";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data); 
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

const getPagedListData = async path => {
    const data = await getData(path);
    if (data) {
        return {items: data.items, page: data.currentPage || 1, pages: data.totalPages || 1};
    }
    return {items: [], page: 1, pages: 1};
}

export const findUser = async email => {
    return await getData(`${sharedTemplateEndpoints.findUser}?email=${email}`)
}

export const sharedWith = async templateId => {
    return await getData(`${sharedTemplateEndpoints.sharedWithUsers}?templateId=${templateId}`)
}

export const getSharedTemplates = async () => {
    return await getData(sharedTemplateEndpoints.templatesSharedWithMe)
}

export const getPageSharedTemplates = async (filters = {}) => {
    return await getPagedListData(sharedTemplateEndpoints.templatesSharedWithMe + filtersToQuery(filters))
}

export const getSentTemplates = async () => {
    return await getData(sharedTemplateEndpoints.mySharedTemplates)
}

export const getPageSentTemplates = async (filters = {}) => {
    return await getPagedListData(sharedTemplateEndpoints.mySharedTemplates + filtersToQuery(filters))
}