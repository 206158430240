import React from "react"
import cn from "classnames"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import styles from "./style.module.scss"
import awaitingIdPath from "./icons/awaitingId.svg"
import inReviewPath from "./icons/inReview.svg"
import inReviewSignedByOwnerPath from "./icons/inReviewSignedByOwner.svg"
import notSentPath from "./icons/notSent.svg"
import notSharedSignedByOwnerPath from "./icons/notSharedSignedByOwner.svg"
import ownerNotSignedCustomerRefusedPath from "./icons/ownerNotSignedCustomerRefused.svg"
import refusedToSignSignedByOwnerPath from "./icons/refusedToSignSignedByOwner.svg"
import sharedPath from "./icons/shared.svg"
import sharedSignedByOwnerPath from "./icons/sharedSignedByOwner.svg"
import signedPath from "./icons/signed.svg"
import signedByPartnerPath from "./icons/signedByPartner.svg"
import wrongRecipientPath from "./icons/wrongRecipient.svg"
import wrongRecipientSignedByOwnerPath from "./icons/wrongRecipientSignedByOwner.svg"
import awaitingUserSignedByOwnerPath from "./icons/awaitingUserSignedByOwner.svg"

import { useTranslation } from "../../../../contexts/LocaleContext"

export const documentStatusShape = {
  0 : "Not sent", 
  1 : "Shared",
  2 : "Awaiting id",
  3 : "wrong recipient",
  4 : "in review",
  5 : "not shared signed by owner",
  6 : "shared signed by owner",
  7 : "in review signed by owner",
  8 : "signed by partner",
  9 : "signed",
  10: "owner signed customer refused",
  11: "owner not signed customer refused",
  12: "awaiting user signed by owner",
  13: "wrong recipient signed by owner"
}

const DocumentStatus = ({statusType = 0, className, ...props}) => {
  const { t } = useTranslation(); 

  const documentStatusType = {
    0 : {
      description: t("Not sent"),
      icon: notSentPath
    }, 
    1 : {
      description: t("Shared"),
      icon: sharedPath
    }, 
    2 : {
      description: t("Awaiting id"),
      icon: awaitingIdPath
    }, 
    3 : {
      description: t("wrong recipient"),
      icon: wrongRecipientPath
    },
    4 : {
      description: t("in review"),
      icon: inReviewPath
    },
    5 : {
      description: t("not shared signed by owner"),
      icon: notSharedSignedByOwnerPath
    }, 
    6 : {
      description: t("shared signed by owner"),
      icon: sharedSignedByOwnerPath
    }, 
    7 : {
      description: t("in review signed by owner"),
      icon: inReviewSignedByOwnerPath  
    }, 
    8 : {
      description: t("signed by partner"),
      icon: signedByPartnerPath
    },
    9 : {
      description: t("signed"),
      icon: signedPath
    },
    10: {
      description: t("owner signed customer refused"),
      icon: refusedToSignSignedByOwnerPath
    },
    11: {
      description: t("owner not signed customer refused"),
      icon: ownerNotSignedCustomerRefusedPath
    },
    12: {
      description: t("awaiting user signed by owner"),
      icon: awaitingUserSignedByOwnerPath
    },
    13: {
      description: t("wrong recipient signed by owner"),
      icon: wrongRecipientSignedByOwnerPath
    }
  }

  const { description, icon } = documentStatusType[statusType]

  return (
      <OverlayTrigger placement="bottom" overlay={<Tooltip bsPrefix={styles.tooltip}>{description}</Tooltip>}>
        <img 
          src={icon}
          alt={description}
          className={cn(styles.documentStatusIcons, className)}
          {...props}
        />
      </OverlayTrigger>
  )
}

export default DocumentStatus;