import React, {useContext, useEffect, useState} from 'react'
import useVoiceIO from "../../VirtualAssistant/utils/useVoiceIO";
import avatarImagePath from "../../images/robot.jpg";
import Avatar from "../Avatar";
import {Dialog, RECORD_TIME} from "../index";
import Input from "../Input";
import styles from './style.module.css'
import DataContext from "../../../../contexts/DataContext";
import {useTranslation} from "../../../../contexts/LocaleContext";

export const searchTriggerWords = [
    "search", "look for", "find", "show",
]
const documentTriggerWords = [
    "create", // TODO SOLVE SCENARIO PICKING
]

const Initial = ({dispatchSearchQuery = ()=>{}, dispatchTemplateMode = ()=>{}}) => {
    const {t} = useTranslation()

    const {recorder, player} = useVoiceIO()
    const [message, setMessage] = useState('')
    const [input, setInput] = useState({value: ''})

    const data = useContext(DataContext);
    const {assistant} = data;

    useEffect(()=>{
        player.play(message)
    },[message])

    useEffect(()=>{
        setMessage(`${assistant.data.name ? assistant.data.name : t('your_assistant')} ${t('is_listening._how_can_i_help_you?')}`)
    },[])

    const onRecordEnd = (text) => {
        setInput({value: text})
    }

    const startRecord = () => {
        recorder.start(RECORD_TIME, onRecordEnd, 'INIT')
    }

    const stopRecord = () => {
        recorder.stop()
    }

    // TODO LISTEN
    // TODO SHOW SUGGESTIONS

    const checkIfSearchQuery = (query='')=> {
        const string = query.toLowerCase()
        return searchTriggerWords.some((word)=>string.includes(word))
    }
    const checkIfDocumentQuery = (query = '')=> {
        const string = query.toLowerCase()
        return documentTriggerWords.some((word)=> string.includes(word))
    }
    const analyzeQuery = (query='') => {
        const possiblySearchQuery = checkIfSearchQuery(query)
        //const possiblyDocumentQuery = checkIfDocumentQuery(query)
        if (possiblySearchQuery)
            dispatchSearchQuery(query)
        else if (!possiblySearchQuery)
            dispatchTemplateMode()
    }

    return <>
        <Avatar imagePath={avatarImagePath} />
        <Dialog listening={recorder.isRecording} question={message}/>
        <Suggestions/>
        <Input
            listening={recorder.isRecording}
            value={input}
            setInput={setInput}
            recordDisabled={!recorder.isMicrophoneAccessed}
            onMicrophoneClick={!recorder.isRecording ? startRecord : stopRecord}
            onSubmit={()=>analyzeQuery(input?.value || '')}
        />
    </>
}

const Suggestions = () => {
    const {t} = useTranslation()
    const suggestions = [
        t('i_want_to_find_something'),
        t('create_a_document_please'),
    ]

return <div className={styles.suggWrapper}>
    <div className={styles.suggHeader}>
        {t('suggestions')}
    </div>
    <div className={styles.suggList}>
        {suggestions.map(s=><Suggestion text={s}/>)}
    </div>
</div>
}

const Suggestion = ({text = ''}) => {
    return <div className={styles.suggItem}>{'\''+text+'\''}</div>
}

export default Initial
