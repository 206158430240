import React, { useContext } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from '../../../../../contexts/LocaleContext';
import CorporateContext from '../CorporateContext';

const NavigationBar = ({ items }) => {
  const { role } = useContext(CorporateContext); // todo remove

  return (
    <div className={styles.nav}>
      {items.map(({ title, subItems, to, exact }) => (
        <MenuItem
          to={to}
          exact={exact}
          text={title}
          subItems={subItems || false}
        />
      ))}
    </div>
  );
};
const MenuItem = ({ text = '', to = '', subItems, exact }) => {
  const { t } = useTranslation();
  return (
    <NavLink
      className={({ isActive }) =>
        cn(styles.link, { [styles.activeLink]: isActive })
      }
      to={to}
      end={exact || false}
    >
      <div className={styles.navMenuItem}>{t(text)?.toUpperCase() || ''}</div>
      {subItems && <Submenu items={subItems} to={to} />}
    </NavLink>
  );
};
const Submenu = ({ items, to = '' }) => {
  const getSection = (sectionName) => to + '?section=' + sectionName;

  return (
    <div className={styles.navSubmenu}>
      {items.map(({ title, section }) => (
        <SubmenuItem text={title} to={getSection(section)} />
      ))}
    </div>
  );
};
const SubmenuItem = ({ text = '', to = '' }) => {
  const { t } = useTranslation();
  return (
    <NavLink className={styles.link} to={to}>
      <div className={styles.navSubmenuItem}>{t(text)}</div>
    </NavLink>
  );
};

export default NavigationBar;
