import {GET} from "../network";
import { blobToBase64 } from "../../utils/blob2base64";
import {createAssistantData} from "./dataSetters";
import { assistantSettingsEndpoints } from "../requestEndpoints";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data); 
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

const getFile = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.data;
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

export const getAssistantData = async () => {
    const res = await GET(assistantSettingsEndpoints.get);

    let data = {};
    if (res) {
        if (!res.body.success) {
            await createAssistantData({});
            data = await getData(assistantSettingsEndpoints.get);
        }
        data = res.body.data;
    }

    const avatar = await getAssistantAvatar();
    const avatarUrl = avatar ? await blobToBase64(avatar) : "";

    return {...data, avatarUrl};
};

export const getAssistantAvatar = async () => {
    return await getFile(assistantSettingsEndpoints.avatar);
};