import React from 'react';

const DeleteIcon = ({ ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 4H3.33333H14"
      stroke="#83878B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33301 3.99992V2.66659C5.33301 2.31296 5.47348 1.97382 5.72353 1.72378C5.97358 1.47373 6.31272 1.33325 6.66634 1.33325H9.33301C9.68663 1.33325 10.0258 1.47373 10.2758 1.72378C10.5259 1.97382 10.6663 2.31296 10.6663 2.66659V3.99992M12.6663 3.99992V13.3333C12.6663 13.6869 12.5259 14.026 12.2758 14.2761C12.0258 14.5261 11.6866 14.6666 11.333 14.6666H4.66634C4.31272 14.6666 3.97358 14.5261 3.72353 14.2761C3.47348 14.026 3.33301 13.6869 3.33301 13.3333V3.99992H12.6663Z"
      stroke="#83878B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66699 7.33325V11.3333"
      stroke="#83878B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33301 7.33325V11.3333"
      stroke="#83878B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default DeleteIcon;
