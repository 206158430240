import cn from "classnames";
import globalStyles from "../global.module.css";
import React from "react";

const ElectById = ({activeElementId, containerClassName, children, ...rest}) => (
  children.map(({id, component}) => (
      <div key={id} className={cn(id !== activeElementId ? globalStyles.hidden : containerClassName)} {...rest}>
        {component}
      </div>
  ))
);

export default ElectById;
