import { DELETE, POST } from "../network";
import { fileEndpoints } from "../requestEndpoints";

const sendFile = async (path, file, fieldName) => {
    const formData = new FormData();
    formData.append(fieldName ? fieldName : "file", file);
    const res = await POST(path, formData, true);
};

export const createProductCatalogue = async file => {
    await sendFile(fileEndpoints.uploadProductsCatalogues, file);
};

export const createServiceCatalogue = async file => {
    await sendFile(fileEndpoints.uploadServicesCatalogues, file);
};

export const removeFile = async id => {
    await DELETE(`${fileEndpoints.remove}${id}`);
};
