import React, { useState, useEffect, useCallback, useContext } from "react";
import parse from "html-react-parser";
import cn from "classnames";
import Row from "react-bootstrap/Row";

import styles from "./style.module.css";

import Button from "../common/core/Button";
import FetchLoading from "../common/parts/FetchLoading";

import { getHowToPageWP } from "../../api/Account/dataGetters";
import { TutorialContext } from "../../contexts/TutorialContext";
import { AssistantContext } from "../../contexts/AssistantContext";
import { useTranslation } from "../../contexts/LocaleContext";

const HowTo = () => {
  const { t } = useTranslation()
  const { setOpenTutorial } = useContext(TutorialContext)
  const { open } = useContext(AssistantContext)

  const [loading, setLoading] = useState(false);
  const [howToData, setHowToData] = useState("");

  const fetchHowTo = useCallback(async () => {
    setLoading(true);
    const data = await getHowToPageWP();
    setHowToData(data);
    setLoading(false);
  }, []);

  const onSeeTutorial = () => {
    setOpenTutorial(true);
  }

  const onHowTo = () => {
    fetchHowTo()
  }

  const onSupport = () => open(null)

   if (loading) {
    return <FetchLoading />;
  }

  return (
      !loading && howToData ? (
        <div className={cn(styles.howTo)}>
          {parse(howToData.replace(/ *\[[^\]]*]/g, ""))}
        </div>
      ) : (
        <div className={styles.container}>
          <Row>
            <Button title={t("see_one_more_time_tutorial")} onClick={onSeeTutorial}/>
          </Row>
          <Row>
            <Button title={t("how_to")} onClick={onHowTo}/>
          </Row>
          <Row>
            <Button title={t("contact_support")} onClick={onSupport}/>
          </Row>
        </div>
      )
  );
};

export default HowTo;
