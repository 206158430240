import React, {useState, useLayoutEffect} from "react";
import styles from './style.module.css';
import cn from 'classnames';
import Wrapper from "../core/Wrapper";

export function useImagesLoaded() {
  const [loaded, setLoaded] = useState(false);

  useLayoutEffect(() => {
    const images = [].slice.call(document.querySelectorAll('img'));
    const loadPromises = images.map(img => {
      return new Promise((resolve) => {
        img.addEventListener('load', resolve);
        img.addEventListener('error', resolve);
      });
    });
    Promise.all(loadPromises).then(() => setLoaded(true));
  });

  return loaded;
}

export default (Component) => (
  (props) => {
    const imagesLoaded = useImagesLoaded();
    return (
      <Wrapper className={cn(!imagesLoaded && styles.hide)}>
        <Component imagesLoaded={imagesLoaded} {...props}/>
      </Wrapper>
    );
  }
);

