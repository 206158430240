import {templateFields} from "../../../contexts/utils/templateFieldsUtils";
import styles from "./style.module.css";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import TouchableOpacity from "../../common/core/TouchableOpacity";
import Collapse from "react-bootstrap/Collapse";
import cn from 'classnames'
import {useTranslation} from "../../../contexts/LocaleContext";
import GuideContext from "../../Onboarding";
import {getVariableFields} from "../VirtualAssistant/utils/variableFields";

function Fields({appendContent}) {
    const [opened, setOpened] = useState([])
    const [groupedFields, setGroupedFields] = useState({})
    const {t} = useTranslation()

    const fields = useMemo(
        ()=>Object
            .entries(getVariableFields(null))
            .map(([key, value])=>({fieldName: key, ...value}))
            .filter((item)=>item?.hidden !== true),
        [])

    useEffect(()=>{
        setTimeout(()=>setOpened(['General', 'My company']), 500)
    },[])

    useEffect(()=>{
        setGroupedFields(fields.reduce((list, element) => {
            return element.topic ?
                {
                    ...list,
                    [element.topic]: [...(list[element.topic] || []), element]
                }
                :
                {
                    ...list,
                    General: [...list.General, element]
                }
        }, {General: []}))
    },[])

    let fieldsComponents = []

    for (const topic in groupedFields) {
        fieldsComponents.push(
            <div>
                <div onClick={() => {
                    setOpened((prev) => {
                        return prev.includes(topic) ? prev.filter((el) => {
                            return el !== topic
                        }) : [...prev, topic]
                    })
                }}
                className={cn(styles.collapseHeader, opened.includes(topic) ? styles.opened : styles.closed)}
                >
                    {t(topic)}
                    <i className={'fas fa-chevron-down'} style={{transitionTimingFunction: 'ease', transitionDuration: '.3s', transform: opened.includes(topic) ? 'rotate(-180deg)' : 'rotate(0)'}}/>
                </div>
                <Collapse in={opened.includes(topic)}>
                    <div>{groupedFields[topic].map(item => {
                        return <Field text={item.fieldName}
                                      displayedText={t(item.fieldName.toLowerCase().replaceAll(' ', '_'))}
                                      hint={item.description && t(item.fieldName.toLowerCase().trim().replaceAll(' ', '_')+'_desc')}
                                      key={item.fieldName}
                                      appendContent={appendContent} />
                    })}</div>
                </Collapse>
            </div>
        )
    }

    return (
        <div className={styles.fields}>
            <div className={styles.fieldsContent}>
                <div className={styles.header}>
                    {t("add_variable_fields").toUpperCase()+':'}
                </div>
                {fieldsComponents}
            </div>
        </div>
    );
}

const Field = ({text, hint, displayedText, appendContent}) => {
  const field = useRef();
  const {markDone} = useContext(GuideContext)
  const key = '['+text.split(' ').join('_')+']';

  const handleClick = (event) => {
    if(event.detail === 2) {
      appendContent(key)
    }
  }

    const onDragStart = (e) => {
        window.getSelection().removeAllRanges();
        //const text = e.target.querySelector(`.${styles.text}`).textContent.toUpperCase();
        e.dataTransfer.setData('text/plain', key);
        markDone('add variable')
    };

  useEffect(() => {
    if (!field) return;
    const el = field.current;

    el.addEventListener('dragstart', onDragStart);

    return () => {
      el.removeEventListener('dragstart', onDragStart);
    }
  });

    return (
        <TouchableOpacity title={hint} draggable ref={field} className={styles.field} onClick={handleClick}>
            <div className={styles.text}>{displayedText}</div>
            {/*{hint && <div className={styles.hint}>{hint}</div>}*/}
        </TouchableOpacity>
    );
};

export default Fields;
