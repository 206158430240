import React from "react";
import { Checkbox } from "informed";
import styles from "./style.module.css";
import cn from "classnames";

// export default ({className, ...props}) => (
//     <Checkbox {...props}/>
// );
export default ({ className, onChangeHandler, value, ...props }) => {
  const controlProps = value !== undefined && {fieldState:{value}}
  return (
    <label className={cn(styles.container, className)}>
      <Checkbox 
        onChange={onChangeHandler} 
        {...controlProps}
        {...props} 
        />
      <span className={styles.checkmark} style={{ height: 20, width: 20 }}></span>
    </label>
  )
}
