import React, { useMemo } from 'react';
import Avatar from 'react-avatar';
import cn from 'classnames';
import styles from './style.module.css';
import { useTranslation } from '../../../../contexts/LocaleContext';
import { idToColor } from '../../../../utils/StringUtils';

const Member = ({ className, member, size = 32, ...props }) => {
  const { t } = useTranslation();

  const name =
    member.user_name ||
    member.fullname ||
    [member.name, member.surname].join(' ');

  const companyRole = useMemo(() => {
    switch (member.role) {
      case 0:
        return t('CEO');
      case 1:
        return t('Manager');
      case 2:
        return t('Employee');
      default:
        return t('CEO');
    }
  }, [t, member.role]);

  const profilePicture = useMemo(() => {
    return member.avatarUrl
      ? member.avatarUrl
      : member.avatar
      ? `data:image/*;base64,${member.avatar}`
      : null;
  }, [member.avatarUrl, member.avatar]);

  return (
    <div className={cn(styles.wrapper, className)} {...props}>
      <Avatar
        className={styles.avatar}
        size={size}
        name={name}
        color={idToColor(member.id)}
        fgColor={idToColor(member.id)}
        src={profilePicture}
        style={{ minWidth: size, minHeight: size }}
      />
      <div className={styles.details}>
        <div className={styles.name}>{name}</div>
        <div className={styles.position}>
          {companyRole}
          {member.department_name ? `, ${member.department_name}` : ''}
        </div>
      </div>
    </div>
  );
};

export default Member;
