import { GET } from '../network';
import { filtersToQuery } from '../../utils/filtersToQuery';
import { serviceEndpoints } from '../requestEndpoints';

const getData = async (path, Type) => {
  try {
    const res = await GET(path);
    return res.body.data || JSON.parse(res.data);
  } catch (e) {
    if (Type) return new Type();
    return null;
  }
};

const getListData = async (path) => {
  const data = await getData(path);
  if (data) {
    return data.items;
  }
  return [];
};

const getPagedListData = async (path) => {
  const data = await getData(path);
  if (data) {
    return {
      items: data.items,
      page: data.currentPage || 1,
      pages: data.totalPages || 1,
    };
  }
  return { items: [], page: 1, pages: 1 };
};
const getFile = async (path, Type) => {
  try {
    const res = await GET(path);
    return res.data;
  } catch (e) {
    if (Type) return new Type();
    return null;
  }
};

const getByKeywords = async (path, keywords) => {
  const searchParams = new URLSearchParams();
  keywords.forEach((keyword) => {
    searchParams.append('keywords', keyword);
  });
  return (await getData(`${path}?${searchParams.toString()}`)) || [];
};

export const getPagedServicesData = async (filters = {}) => {
  return getPagedListData(serviceEndpoints.getList + filtersToQuery(filters));
};

export const getServiceById = async (id) => {
  return getData(serviceEndpoints.getById + id);
};

export const getServiceByKeywords = async (keywords) => {
  return getByKeywords(serviceEndpoints.search, keywords);
};

export const searchService = async (keywords) => {
  return getByKeywords(serviceEndpoints.search, keywords);
};

export const getServicesData = async () => {
  return getListData(serviceEndpoints.getList);
};

export const exportServices = async () => {
  return getFile(serviceEndpoints.exportCsv, String);
};
