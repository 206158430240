import { GET } from "../network";
import { accountActiveDepartmentEndpoints } from "../requestEndpoints"

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        console.log("response data", res);
        return res.body.data || res.body;
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

export const getDepAndCompList = async () => {
    const data = await getData(accountActiveDepartmentEndpoints.getDepAndCompList);
    return data
};

export const chooseActivDepAndComp = async (departmentId) => {
    return await getData(accountActiveDepartmentEndpoints.chooseActivDepAndComp + departmentId);
};