import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import './preload.css';
import styles from './style.module.css';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import cn from 'classnames';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Wrapper from '../common/core/Wrapper';
import SignFooter from '../common/parts/SignFooter';
import logoImagePath from './images/logo.png';
import withOnImagesLoadedRender from '../common/HOCs/withOnImagesLoadedRender';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import ElectByIndex from '../common/core/ElectByIndex';
import { POST } from '../../api/network';
import NetworkContext from '../../contexts/NetworkContext';
import Enum from '../../utils/enum';
import { capitalize } from '../../utils/StringUtils';
import ForgotPasswordModal from './ForgotPasswordModal';
import { useTranslation } from '../../contexts/LocaleContext';
import LocaleSelectorFixed from '../common/core/LocaleSelector/LocaleSelectorFixed';
import { Navigate, Redirect } from 'react-router-dom';
import { useQuery } from '../Dashboard/contents/CompanyDashboard';
import DataContext from '../../contexts/DataContext';

const WIDTH = 350;

const [FORM_SIGN_IN, FORM_SIGN_UP] = Enum(2);

function SignIn() {
  const network = useContext(NetworkContext);
  const { loggedIn, isReady: isNetworkReady } = network;
  const queryEmail = useRef('');
  const queryCode = useRef('');

  const query = useQuery();

  useEffect(() => {
    queryEmail.current = query.get('email');
    queryCode.current = query.get('code');
  }, []);

  const [formIndex, setFormIndex] = useState(
    query.get('action') === 'signup' ? FORM_SIGN_UP : FORM_SIGN_IN
  );

  const [loginFailed, setLoginFailed] = useState(false);
  const [loginFailText, setLoginFailText] = useState(null);
  const [loginWaiting, setLoginWaiting] = useState(false);
  const [agreeWithTermsAndPolicy, setAgreeWithTermsAndPolicy] = useState(false);

  const [registerFailed, setRegisterFailed] = useState(false);
  const [registerFailText, setRegisterFailText] = useState(null);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [registerWaiting, setRegisterWaiting] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);

  const { t } = useTranslation();

  const { account, subscription, company } = useContext(DataContext);

  const getAccData = useCallback(async () => {
    await account._get();
    await subscription.load();
    await company.load();
  }, []);

  useEffect(() => {
    if (loggedIn) {
      getAccData();
    }
  }, [loggedIn, getAccData]);

  const redirectingVerification = () => {
    if (!subscription.data && !company?.data?.name) {
      return <Navigate to={'/dashboard/company-dashboard'} />;
    } else if (!subscription.data && company?.data?.name) {
      return <Navigate to={'/payment'} />;
    }

    return <Navigate to={'/dashboard/company-dashboard'} />;
  };

  if (loggedIn) return redirectingVerification();

  const agreeWithTermsAndPolicyHandler = () => {
    setAgreeWithTermsAndPolicy(!agreeWithTermsAndPolicy);
  };

  const loginUser = async (
    { login, password, remember },
    { redirect },
    fromRegister = false,
    phone
  ) => {
    setLoginWaiting(true);
    setLoginFailed(false);

    const reqBody = {
      login,
      email: '',
      password,
    };

    const isEmail = login.includes('@');
    if (isEmail) {
      reqBody.email = login;
      reqBody.login = '';
    }
    const res = await POST('/api/Account/signin', reqBody);

    if (res && res.status === 200) {
      const token = res.body;
      if (remember) {
        localStorage.setItem('token', token);
      } else {
        sessionStorage.setItem('token', token);
      }

      if (redirect) {
        network.onLogin();
      }
    } else if (res && res.status === 401) {
      setLoginFailed(true);
      setLoginFailText(t('incorrect_login_or_password'));
    } else {
      setLoginFailed(true);
      setLoginFailText(t('unknown_error,_please_try_again_later'));
    }

    setLoginWaiting(false);
  };

  const registerUser = async ({
    login,
    password,
    workemail = queryEmail.current ? queryEmail.current : '',
    phone,
    name = 'Name',
    surname = 'Surname',
  }) => {
    setRegisterWaiting(true);
    setRegisterFailed(false);

    const res = await POST('/api/Account/signup', {
      login,
      password,
      workemail,
      name,
      surname,
      confirm_code_invite_to_company: queryCode.current
        ? queryCode.current
        : '',
    });

    if (res && res.status === 200) {
      const body = res.body;

      if (body.success) {
        setRegisterFailed(false);
        setRegisterSuccess(true);
        network.addToRegisterTempData({ login, password, workemail });
        await loginUser({ login, password }, { redirect: true });
      } else {
        setRegisterFailed(true);
        setRegisterFailText(capitalize(body.error));
      }
    } else if (res && res.status === 400) {
      const responseErr = res.body.errors;
      let errors = [];
      for (const key in responseErr) {
        errors.push(responseErr[key][0]);
      }
      setRegisterFailed(true);
      setRegisterFailText(t(`${errors[0]}`));
    } else {
      setRegisterFailed(true);
      setRegisterFailText(t('unknown_error,_please_try_again_later'));
    }

    setRegisterWaiting(false);
  };

  const forms = [
    <SignInForm
      onSubmit={(state) => loginUser(state, { redirect: true })}
      failed={loginFailed}
      failText={loginFailText}
      waiting={loginWaiting}
      openForgotPasswordModal={openForgotPasswordModal}
    />,
    <SignUpForm
      onSubmit={(state) => registerUser(state)}
      failed={registerFailed}
      failText={registerFailText}
      success={registerSuccess}
      waiting={registerWaiting}
      agreeWithTermsAndPolicyHandler={agreeWithTermsAndPolicyHandler}
      agreeCheckboxValue={agreeWithTermsAndPolicy}
      queryEmail={queryEmail.current}
    />,
  ];

  const currentForm = (
    <ElectByIndex className={'w-100'} index={formIndex} children={forms} />
  );

  function openForgotPasswordModal() {
    setIsForgotPasswordModalOpen(true);
  }

  function closeForgotPasswordModal() {
    setIsForgotPasswordModalOpen(false);
  }

  return (
    <Wrapper className={cn(styles.wrapper)}>
      <Container className={styles.main} style={{ width: WIDTH }}>
        <Row>
          <Image className={styles.logoImage} src={logoImagePath} />
        </Row>

        <Row className={styles.tabs}>
          <Col
            className={cn(
              styles.tabSignIn,
              formIndex === FORM_SIGN_IN && styles.tabActive
            )}
            onClick={() => setFormIndex(FORM_SIGN_IN)}
          >
            {t('sign_in')}
          </Col>
          <Col
            className={cn(
              styles.tabSignUp,
              formIndex === FORM_SIGN_UP && styles.tabActive
            )}
            onClick={() => setFormIndex(FORM_SIGN_UP)}
          >
            {t('sign_up')}
          </Col>
        </Row>

        {currentForm}

        {isForgotPasswordModalOpen && (
          <ForgotPasswordModal close={closeForgotPasswordModal} />
        )}
      </Container>
      <LocaleSelectorFixed />
      <SignFooter className={styles.footer} />
    </Wrapper>
  );
}

export default withOnImagesLoadedRender(SignIn);
