import React, {useContext} from 'react';
import { Scope } from "informed"
import styles from './style.module.css';
import cn from 'classnames';
import Input from "../../../common/core/Input";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ButtonsPair from "../../../common/parts/ButtonsPair";
import useWaiting from "../../../common/hooks/useWaiting";
import AutoForm from "../../../common/core/AutoForm";
import {useTranslation} from "../../../../contexts/LocaleContext";
import GuideContext from "../../../Onboarding";

const CustomInput = ({title, ...rest}) => (
  <>
    <Col className={cn(styles.title, 'col-12 pl-2 mb-3')}>{title.toUpperCase()}</Col>
    <Col className={'col-12'}>
      <Input heigth={40} {...rest}/>
    </Col>
  </>
);

const PartnerForm = ({onSubmit, close, initialValues}) => {
  const [waiting, submit] = useWaiting(onSubmit);
  const {t} = useTranslation()
  const {markDone} = useContext(GuideContext)
  return (
    <AutoForm
      onSubmit={(state)=>{
        markDone('add partner')
        submit({
          ...initialValues,
          ...state,
          address: {
            ...initialValues.address,
            ...state.address
          }
        }) 
      }}
      className={styles.form}
      initialValues={initialValues}
    >
      <Row>
        <Col className={'col-10 pr-2'}>
          <CustomInput
            field={'name'}
            title={t('name_of_partner')}
          />
        </Col>
        <Scope scope="address">
          <Row>
            <Col className={"col-7 pr-2"}>
              <CustomInput
                field={"street"}
                title={t("street")}
              />
            </Col>
            <Col className={"col-3 pl-2"}>
              <CustomInput
                field={"building"}
                title={t("building")}
              />
            </Col>
          </Row>
          <Row>
            <Col className={"col-7 pr-2"}>
              <CustomInput
                field={"index"}
                title={t("index")}
              />
            </Col>
            <Col className={"col-3 pl-2"}>
              <CustomInput
                field={"city"}
                title={t("city")}
              />
            </Col>
          </Row>
          <Row>
            <Col className={"col-7 pr-2"}>
              <CustomInput
                field={"state"}
                title={t("state")}
              />
            </Col>
            <Col className={"col-3 pl-2"}>
              <CustomInput
                field={"country"}
                title={t("country")}
              />
            </Col>
          </Row>
        </Scope>
      </Row>
      <Row className={'pt-2'}>
        <Col className={'col-4 pr-2'}>
          <CustomInput
            field={'phone'}
            title={t('phone')}
          />
        </Col>
        <Col className={'col-6 pl-2'}>
          <CustomInput
            field={'email'}
            title={t('email')}
          />
        </Col>
      </Row>
      <Row>
        <Col className={'col-10 pt-2'}>
          <CustomInput
            field={'scope'}
            title={t('what_does_their_company_do')}
          />
        </Col>
        <Col className={'col-10 pt-2'}>
          <CustomInput
            field={'scopeneed'}
            title={t('what_does_their_company_need')}
          />
        </Col>
        <Col className={'col-10 pt-2'}>
          <CustomInput
            field={'customers'}
            title={t('who_are_their_customers')}
          />
        </Col>
        <Col className={'col-5 pt-2 pl-2'}>
          <CustomInput
            field={'contactperson'}
            title={t('contact_person')}
          />
        </Col>
        <Col className={'col-10 pt-2'}>
          <CustomInput
            field={'keywords'}
            title={t('keywords')}
            placeholder={t('enter_comma-separated_keywords')}
          />
        </Col>
      </Row>
      <Row>
        <ButtonsPair saveWaiting={waiting} onCancel={close} className={'pb-2'}/>
      </Row>
    </AutoForm>
  );
};

export default PartnerForm;
