import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TAB_PATHS } from '../../../contexts/TabContext';

const DASHBOARD_PATH = TAB_PATHS.COMPANY_DASHBOARD;

export const PATHS = {
  DASHBOARD: DASHBOARD_PATH,
  STRUCTURES: DASHBOARD_PATH + '/structures',
  FLOWS: DASHBOARD_PATH + '/flows',
  TASKS: DASHBOARD_PATH + '/tasks',
  ADD_TASK: DASHBOARD_PATH + '/tasks/create',
};

const useCorporateNavigation = () => {
  const navigate = useNavigate();
  const nav = (
    path = PATHS.DASHBOARD,
    section = '',
    item_id = '',
    department_id = '',
    action = ''
  ) => {
    const _section = section ? `section=${section}` : null;
    const _item_id = item_id ? `item_id=${item_id}` : null;
    const _department_id = department_id
      ? `department_id=${department_id}`
      : null;
    const _action = action ? `action=${action}` : null;
    const queryParams = [_section, _item_id, _department_id, _action]
      .filter((value) => !!value)
      .join('&');
    navigate(`${path}${queryParams.length ? '?' : ''}${queryParams}`);
  };
  return { nav, PATHS };
};

export default useCorporateNavigation;
