import React, { useContext, useEffect } from 'react'
import styles from "./styles.module.css"
import { Invites } from "../Dashboard"
import Screen from "../../common/Screen";
import Card, { Content } from "../../common/Card";
import Input from "../../common/Input";
import { useTranslation } from "../../../../../../contexts/LocaleContext";
import Controllers from "../../common/Controllers";
import Button from "../../common/Button";
import useForm from "../../common/useForm";
import { useQuery } from "../../index";
import CompanyDashboard from "../Dashboard";
import CorporateContext from "../../CorporateContext";
import { chooseActivDepAndComp } from '../../../../../../api/AccountActiveDepartment/dataGetters';

const AdminDashboard = ({ forceCreate = false }) => {
    const query = useQuery()
    const section = query.get('section')

    if (forceCreate) return (
        <>
            <Company />
            <Invites />
        </>
    )    

    const getContent = () => {
        switch (section) {
            case 'company':
                return (
                    <>
                        <Company />
                        <Invites />
                    </>
                )    
            default:
                return <CompanyDashboard />
        }
    }
    return getContent() || null
}

const Company = () => {
    const { t } = useTranslation()
    const { company } = useContext(CorporateContext)

    useEffect(() => {
        company.load()
    }, [])

    const handleCreate = async (data) => {
        await company.create(data)
        const token = await chooseActivDepAndComp(0);
        localStorage.setItem("token", token);
        window.location.reload();
    }

    return (
        <Screen className={styles.screenCard}>
            <Card title={company.data?.name ? t('Company Info') : t('Create a new company')} containerClassName={styles.companyContainer} isLoading={!company.isLoaded}>
                <CreateCompany onCreate={handleCreate} onUpdate={company.update} initState={company.data} />
            </Card>
        </Screen>
    )
}


const CreateCompany = ({
    onCreate = () => {
    }, onUpdate = () => {
    }, initState = null
}) => {
    const { t } = useTranslation()

    const {
        disabled,
        editMode,
        cancel,
        save,
        isSaveWaiting,
        edit,
        getInputProps
    } = useForm({
        initialState: initState,
        onCreate,
        onUpdate,
        requiredFieldNames: ['name', 'address']
    })

    return <>
        <Content>
            <Input color="#B11873" title={t('Company name')} {...getInputProps('name')} />
            <Input color="#B11873" title={t('Address')} {...getInputProps('address', 'street')} />
        </Content>
        {editMode && <Controllers>
            {initState !== null && <Button color="#B11873" title={t('Cancel')} onClick={cancel} />}
            <Button color="#B11873" title={'Save'} onClick={save} isLoading={isSaveWaiting}
                disabled={disabled} />
        </Controllers>}
        {!editMode && <Controllers>
            <Button color='#B11873' title={'Edit'} onClick={edit} />
        </Controllers>}
    </>
}


export default AdminDashboard
