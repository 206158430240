import { Form } from "informed";
import styles from "./style.module.css";
import Input from "./Input";
import loginImagePath from "./images/login.png";
import passwordImagePath from "./images/password.png";
import Button from "../common/core/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Checkbox from "../common/core/Checkbox";
import React from "react";
import InputPlaceholder from "./InputPlaceholder";
import cn from 'classnames';
import TouchableOpacity from "../common/core/TouchableOpacity";
import { useTranslation } from "../../contexts/LocaleContext";

function SignInForm({ onSubmit, failed, waiting, failText, openForgotPasswordModal }) {
  const { t } = useTranslation()
  return (
    <Form onSubmit={onSubmit} className={styles.form} style={{ marginTop: '-15px' }}>
      <Input
        field={"login"}
        placeholder={<InputPlaceholder text={t("login")} />}
        required
      />
      <Input
        field={"password"}
        type={"password"}
        placeholder={<InputPlaceholder text={t("password")} />}
        required
      />

      <Row className={'mb-1'} style={{ marginTop: '20px' }}>
        <Col className={styles.rememberContainer}>
          <Checkbox field={"remember"} />
          <span className={styles.rememberText}>{t("remember_me")}</span>
        </Col>
        <Col className={styles.forgotText}>
          <TouchableOpacity
            onClick={openForgotPasswordModal}
          >
            {t("forgot_password")}
          </TouchableOpacity>
        </Col>
      </Row>

      <div className={cn(styles.fail, failed && styles.failActive)}>
        {failed && failText}
      </div>

      <Row className={styles.buttonContainer} >
        <Button waiting={waiting} containerClassName={styles.button} width="100%" title={t("sign_in")} type={"submit"} />
      </Row>

    </Form>
  );
}

export default SignInForm;
