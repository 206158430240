import React, { useEffect, useState, useContext } from 'react';
import styles from './search.module.css';
import { useTranslation } from '../../../../contexts/LocaleContext';
import SearchWrapper from './SearchWrapper';
import {
  getPartnersByKeywords
} from '../../../../api/Partner/dataGetters';
import { AssistantContext } from '../../../../contexts/AssistantContext';
import { useNavigate } from 'react-router-dom';

const getTableDataFromResponse = (
  data,
  openPartner
) => {
  if (!data?.length) return [];

  return data.map((partner, index) => {
    return {
      id: partner.id || index,
      classNames: {
        bar: styles.partnerBar,
      },
      columns: [
        {
          className: styles.partnerColumn,
          element: partner.id,
        },
        {
          className: styles.partnerColumn,
          element: partner.name,
        },
        {
          className: styles.partnerColumn,
          element: partner.email,
        },
      ],
      onClick: openPartner(partner.id),
    };
  });
};

const SearchPartners = ({ keywords = [], onClose = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { close: closeAssistant } = useContext(AssistantContext);
  const [isLoading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [legend, setLegend] = useState({
    classNames: {
      bar: styles.partnerLegendBar,
    },
    columns: [
      {
        icon: 'fas fa-hashtag',
        title: t('code'),
      },
      {
        icon: 'fas fa-user',
        title: t('name'),
      },
      {
        icon: 'fas fa-envelope',
        title: t('email'),
      },
    ],
  });

  const openPartner = (id) => () => {
    closeAssistant();
    navigate(`/dashboard/partners/${id}`);
  };

  useEffect(() => {
    setLoading(true);
    getPartnersByKeywords(keywords).then((partners) => {
      setTableData(
        getTableDataFromResponse(partners, openPartner)
      );
      setLoading(false);
    });
  }, []);

  return (
    <SearchWrapper
      query={keywords.join(' ')}
      legend={legend}
      tableData={tableData}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default SearchPartners;
