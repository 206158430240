import {GET} from "../network";
import { filtersToQuery } from "../../utils/filtersToQuery";
import { competitorEndpoints } from "../requestEndpoints";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data); 
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

const getListData = async path => {
    const data = await getData(path);
    if (data) {
        return data.items;
    }
    return [];
};

const getPagedListData = async path => {
    const data = await getData(path, false, true);
    if (data) {
        return {items: data.items, page: data.currentPage || 1, pages: data.totalPages || 1};
    }
    return {items: [], page: 1, pages: 1};
}

const getFile = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.data;
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

export const getPagedCompetitorsData = async (filters = {}) => {
    return await getPagedListData(competitorEndpoints.getList + filtersToQuery(filters));
};

export const getCompetitorsData = async () => {
    return await getListData(competitorEndpoints.getList);
};

export const exportCompetitors = async () => {
    return await getFile(competitorEndpoints.exportCsv, String);
};