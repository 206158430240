import React, { useState, useRef, createContext, useContext } from 'react';
import { getDocumentHitLimit } from '../../api/Document/dataGetters';
import DataContext from '../DataContext';
import UpgradeModal from './modals/UpgradeModal';
import SuspendAccountModal from './modals/SuspendAccountModal';

const RestrictionContext = createContext();

export const RESTRICTIONS = {
  SUSPEND: 'suspend',
  TRIAL: 'trial',
};

const defaultRestrictions = [RESTRICTIONS.SUSPEND, RESTRICTIONS.TRIAL];

const RestrictionProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const { account, subscription } = useContext(DataContext);
  const SelectedModal = useRef();
  const closeModal = () => setModal(false);

  const withRestrictions = (
    cb = () => {},
    options = {
      onPopupOpen: () => {},
      restrictions: defaultRestrictions,
    }
  ) => {
    return async function (...props) {
      const { data: accountData } = account;
      if (
        options.restrictions.includes(RESTRICTIONS.TRIAL) &&
        accountData.is_suspended
      ) {
        SelectedModal.current = <SuspendAccountModal />;
        return setModal(true);
      }

      if (!subscription.data.is_trial) return await cb(...props);
      if (
        options.restrictions.includes(RESTRICTIONS.TRIAL) &&
        subscription.data.is_trial
      ) {
        const isHitLimit = await getDocumentHitLimit();
        const limitCount =
          typeof isHitLimit === 'object' ? +isHitLimit.data : +isHitLimit;
        if (0 < limitCount) {
          setModal(false);
          return await cb(...props);
        }
        SelectedModal.current = <UpgradeModal />;
        return setModal(true);
      }
    };
  };

  return (
    <RestrictionContext.Provider
      value={{ modal, withRestrictions, closeModal }}
    >
      {SelectedModal.current}
      {children}
    </RestrictionContext.Provider>
  );
};

const useRestriction = () => {
  const context = useContext(RestrictionContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a UserProvider');
  }
  return context;
};

export { RestrictionContext, RestrictionProvider, useRestriction };
