import {useReducer, createContext, useEffect, useState, useContext} from "react";
import Enum from "../utils/enum";
import {ListField, StateField, ListFieldTable} from "./utils/fields";

import { getAccountData } from '../api/Account/dataGetters'
import { setAccountData } from '../api/Account/dataSetters'

import {
   getSubscriptionData
} from '../api/AccountSubscriptions/dataGetters'

import { getAssistantData } from '../api/AssistantSettings/dataGetters'
import { updateAssistantData } from '../api/AssistantSettings/dataSetters'

import { getBusinessData } from '../api/Business/dataGetters'
import { setBusinessData } from '../api/Business/dataSetters'

import { getCompanyInfo } from "../api/CompanyAccount/dataGetters"
import { updateCorporateCompany } from "../api/CompanyAccount/dataSetters"

import {
    exportCompetitors,
    getCompetitorsData,
} from '../api/Competitor/dataGetters'
import {
    createCompetitor,
    importCompetitors,
    removeCompetitor,
    updateCompetitor
} from '../api/Competitor/dataSetters'

import {
    exportCustomersB2B,
    exportCustomersB2P,
    getCustomersB2BData,
    getCustomersB2PData,
    searchB2BCustomers,
    searchB2CCustomers,
    getCustomerB2BById,
    getCustomerB2PById
} from '../api/Customer/dataGetters'
import {
    createCustomerB2B,
    createCustomerB2P,
    importCustomersB2B,
    importCustomersB2P,
    removeCustomerB2B,
    removeCustomerB2P,
    updateCustomerB2B,
    updateCustomerB2P
} from '../api/Customer/dataSetters'

import { getActivitiesData } from '../api/CustomerActivity/dataGetters'
import {
    createActivity,
    removeActivity,
    updateActivity
} from '../api/CustomerActivity/dataSetters'

import {
    getDocumentById,
    getDocumentsData,
    getLatestDocument,
    getDocumentsLatestCreated
} from '../api/Document/dataGetters'
import {
    createDocument,
    removeDocument, 
    updateDocument
} from '../api/Document/dataSetters'

import {
    downloadProductCatalogue,
    downloadServiceCatalogue,
    getProductCataloguesData,
    getServiceCataloguesData
 } from '../api/File/dataGetters'
import {
    createProductCatalogue,
    createServiceCatalogue,
    removeFile
} from '../api/File/dataSetters'

import {
    exportPartners,
    getPartnersData,
    getPartnersById
 } from '../api/Partner/dataGetters'
import {
    createPartner,
    importPartners,
    removePartner,
    updatePartner
} from '../api/Partner/dataSetters'

import {
    exportProducts,
    getProductsData,
    searchProducts,
    getProductById
 } from '../api/Product/dataGetters'
import {
    createProduct,
    importProducts,
    removeProduct,
    updateProduct
} from '../api/Product/dataSetters'

import {
    exportServices,
    getServicesData,
    searchService,
    getServiceById
} from '../api/Service/dataGetters'
import {
    createService,
    importServices,
    removeService,
    updateService
} from '../api/Service/dataSetters'

import {
    exportMarkets,
    getMarketsData
 } from '../api/TargetMarket/dataGetters'
import {
    createTargetMarket,
    importMarkets,
    removeTargetMarket,
    updateTargetMarket
} from '../api/TargetMarket/dataSetters'

import {
    getTemplateById,
    getTemplatesByKeywords,
    getTemplatesData,
    getVariableFieldsData,
    getTemplateLatestCreated
 } from '../api/Template/dataGetters'
import {
    createTemplate,
    removeTemplate,
    updateTemplate
} from '../api/Template/dataSetters'

import { getDepAndCompList } from "../api/AccountActiveDepartment/dataGetters";

import { 
    hasActiveSubscription,
    getSubscriptions,
    getPrices
} from "../api/CompanyAccountSubscription/dataGetters";
import { createTask, importTasks, removeTask, updateTask } from "../api/Task/dataSetters";
import { exportTasks, getTaskById, getTasksData, getPagedTasksData } from "../api/Task/dataGetters";

const [
    ACCOUNT,
    BUSINESS,
    COMPANY,
    ASSISTANT,
    BILLING,
    SUBSCRIPTION_LIST,
    VARIABLE_FIELDS,
    TEMPLATES,
    DOCUMENTS,
    PRODUCTS,
    PRODUCT_CATALOGUES,
    SERVICES,
    SERVICE_CATALOGUES,
    CUSTOMERS_B2B,
    CUSTOMERS_B2P,
    ACTIVITIES,
    COMPETITORS,
    PARTNERS,
    MARKETS,
    TASKS,
    DEPARTMENTS_COMPANY,
    SUBSCRIPTION,
    PRICES_LIST
] = Enum(23);

const contextShape = {
    account: StateField.default,
    business: StateField.default,
    company: StateField.default,
    assistant: StateField.default,
    billing: ListField.default,
    departmentsCompany: ListField.default,
    subscriptionList: ListField.default,
    variableFields: ListField.default,
    templates: ListField.default,
    documents: ListFieldTable.default,
    products: ListFieldTable.default,
    productCatalogues: ListField.default,
    services: ListFieldTable.default,
    serviceCatalogues: ListField.default,
    customersB2B: ListFieldTable.default,
    customersB2P: ListFieldTable.default,
    activities: ListField.default,
    competitors: ListFieldTable.default,
    partners: ListFieldTable.default,
    markets: ListField.default,
    tasks: ListField.default,
    subscription: StateField.default,
    pricesList: ListField.default
};

function reducer(state, {
    type,
    account,
    business,
    company,
    assistant,
    billing,
    departmentsCompany,
    subscriptionList,
    variableFields,
    templates,
    documents,
    products,
    productCatalogues,
    services,
    serviceCatalogues,
    customersB2B,
    customersB2P,
    activities,
    competitors,
    partners,
    markets,
    tasks,
    subscription, 
    pricesList
}) {
    switch (type) {
        case ACCOUNT:
            return {...state, account};
        case BUSINESS:
            return {...state, business};
        case COMPANY:
            return {...state, company};
        case ASSISTANT:
            return {...state, assistant};
        case BILLING:
            return {...state, billing};
        case SUBSCRIPTION_LIST:
            return {...state, subscriptionList};
        case VARIABLE_FIELDS:
            return {...state, variableFields};
        case TEMPLATES:
            return {...state, templates};
        case DOCUMENTS:
            return {...state, documents};
        case PRODUCTS:
            return {...state, products};
        case PRODUCT_CATALOGUES:
            return {...state, productCatalogues};
        case SERVICES:
            return {...state, services};
        case SERVICE_CATALOGUES:
            return {...state, serviceCatalogues};
        case CUSTOMERS_B2B:
            return {...state, customersB2B};
        case CUSTOMERS_B2P:
            return {...state, customersB2P};
        case ACTIVITIES:
            return {...state, activities};
        case COMPETITORS:
            return {...state, competitors};
        case PARTNERS:
            return {...state, partners};
        case TASKS:
            return {...state, tasks};
        case MARKETS:
            return {...state, markets};
        case DEPARTMENTS_COMPANY:
            return {...state, departmentsCompany};
        case SUBSCRIPTION:
            return {...state, subscription};
        case PRICES_LIST:
            return {...state, pricesList};
        default:
            return state;
    }
}

const DataContext = createContext(contextShape);

export function useLoaded(fieldName) {
    const {[fieldName]: field} = useContext(DataContext);
    const [isLoaded, setLoaded] = useState(field.isLoaded);
    useEffect(() => {
        if (!field.isLoaded) {
            field.load().then(() => {
                setLoaded(true);
                console.log("FIELD LOADED:", fieldName, field);
            });
        }
    }, []);

    return {
        isLoaded,
        [fieldName]: field,
    };
}

export function useLoadedFields(fieldNames) {
    const data = useContext(DataContext);
    const fields = fieldNames.map(name => data[name]);

    const [isLoaded, setLoaded] = useState(fields.every(item => item.isLoaded));
    useEffect(() => {
        if (!fields.every(item => item.isLoaded)) {
            Promise
                .all(
                    fields.map(field => field.load())
                )
                .then(() => setLoaded(true));
        }
    }, []);

    return {
        isLoaded,
        ...data
    };
}

export function useApplicationData() {
    const account = new StateField({
        getter: getAccountData,
        setter: setAccountData,
        resolver: (account) => dispatch({type: ACCOUNT, account})
    });

    const business = new StateField({
        getter: getBusinessData,
        setter: setBusinessData,
        resolver: (business) => dispatch({type: BUSINESS, business})
    });

    const company = new StateField({
        getter: getCompanyInfo,
        setter: updateCorporateCompany,
        resolver: (company) => dispatch({type: COMPANY, company})
    });

    const assistant = new StateField({
        getter: getAssistantData,
        setter: updateAssistantData,
        resolver: (assistant) => dispatch({type: ASSISTANT, assistant})
    });

    // SUSPENDED API REQUESTS
    const billing = new ListField({
        listGetter: getSubscriptionData,
        resolver: (billing) => dispatch({type: BILLING, billing})
    });
    
    const subscription = new StateField({
        getter: hasActiveSubscription,
        resolver: (subscription) => dispatch({type: SUBSCRIPTION, subscription})
    })

    const departmentsCompany = new ListField({
        listGetter: getDepAndCompList,
        resolver: (departmentsCompany) => dispatch({type: DEPARTMENTS_COMPANY, departmentsCompany})
    });

    const subscriptionList = new ListField({
        listGetter: getSubscriptions,
        resolver: (subscriptionList) => dispatch({type: SUBSCRIPTION_LIST, subscriptionList})
    });

    const pricesList = new ListField({
        listGetter: getPrices,
        resolver: (pricesList) => dispatch({type: PRICES_LIST, pricesList})
    })

    const variableFields = new ListField({
        listGetter: getVariableFieldsData,
        resolver: (variableFields) => dispatch({type: VARIABLE_FIELDS, variableFields})
    });

    const templates = new ListFieldTable({
        listGetter: getTemplatesData,
        latestDocument: getTemplateLatestCreated,
        creator: createTemplate,
        updater: updateTemplate,
        deleter: removeTemplate,
        getterById: getTemplateById,
        getterByKeywords: getTemplatesByKeywords,
        resolver: (templates) => dispatch({type: TEMPLATES, templates})
    });

    const documents = new ListFieldTable({
        listGetterLatest: getDocumentsLatestCreated,
        listGetter: getDocumentsData,
        latestDocument: getLatestDocument,
        creator: createDocument,
        updater: updateDocument,
        deleter: removeDocument,
        getterById: getDocumentById,
        resolver: (documents) => dispatch({type: DOCUMENTS, documents})
    });

    const products = new ListFieldTable({
        name: 'products',
        listGetter: getProductsData,
        creator: createProduct,
        updater: updateProduct,
        deleter: removeProduct,
        getterById: getProductById,
        getterByKeywords: searchProducts,
        importerCSV: importProducts,
        exporterCSV: exportProducts,
        resolver: (products) => dispatch({type: PRODUCTS, products})
    });

    const productCatalogues = new ListField({
        listGetter: getProductCataloguesData,
        creator: createProductCatalogue,
        getterById: downloadProductCatalogue,
        deleter: removeFile,
        resolver: (productCatalogues) => dispatch({type: PRODUCT_CATALOGUES, productCatalogues})
    });

    const services = new ListFieldTable({
        name: 'services',
        listGetter: getServicesData,
        creator: createService,
        getterByKeywords: searchService,
        getterById: getServiceById,
        updater: updateService,
        deleter: removeService,
        importerCSV: importServices,
        exporterCSV: exportServices,
        resolver: (services) => dispatch({type: SERVICES, services})
    });

    const serviceCatalogues = new ListField({
        listGetter: getServiceCataloguesData,
        creator: createServiceCatalogue,
        getterById: downloadServiceCatalogue,
        deleter: removeFile,
        resolver: (serviceCatalogues) => dispatch({type: SERVICE_CATALOGUES, serviceCatalogues})
    });

    const customersB2B = new ListFieldTable({
        name: 'customersB2B',
        listGetter: getCustomersB2BData,
        getterById: getCustomerB2BById,
        creator: createCustomerB2B,
        updater: updateCustomerB2B,
        deleter: removeCustomerB2B,
        importerCSV: importCustomersB2B,
        exporterCSV: exportCustomersB2B,
        getterByKeywords: searchB2BCustomers,
        resolver: (customersB2B) => dispatch({type: CUSTOMERS_B2B, customersB2B})
    });

    const customersB2P = new ListFieldTable({
        name: 'customersB2P',
        listGetter: getCustomersB2PData,
        getterById: getCustomerB2PById,
        creator: createCustomerB2P,
        updater: updateCustomerB2P,
        deleter: removeCustomerB2P,
        importerCSV: importCustomersB2P,
        exporterCSV: exportCustomersB2P,
        getterByKeywords: searchB2CCustomers,
        resolver: (customersB2P) => dispatch({type: CUSTOMERS_B2P, customersB2P})
    });

    const activities = new ListField({
        listGetter: getActivitiesData,
        creator: createActivity,
        updater: updateActivity,
        deleter: removeActivity,
        resolver: (activities) => dispatch({type: ACTIVITIES, activities})
    });

    const competitors = new ListField({
        name: 'competitors',
        listGetter: getCompetitorsData,
        creator: createCompetitor,
        updater: updateCompetitor,
        deleter: removeCompetitor,
        importerCSV: importCompetitors,
        exporterCSV: exportCompetitors,
        resolver: (competitors) => dispatch({type: COMPETITORS, competitors})
    });

    const partners = new ListFieldTable({
        name: 'partners',
        listGetter: getPartnersData,
        getterById: getPartnersById,
        creator: createPartner,
        updater: updatePartner,
        deleter: removePartner,
        importerCSV: importPartners,
        exporterCSV: exportPartners,
        resolver: (partners) => dispatch({type: PARTNERS, partners})
    });

    const tasks = new ListField({
        name: 'tasks',
        getterById: getTaskById,
        listGetter: getPagedTasksData,
        creator: createTask,
        updater: updateTask,
        deleter: removeTask,
        importerCSV: importTasks,
        exporterCSV: exportTasks,
        resolver: (tasks) => dispatch({type: TASKS, tasks})
    });

    const markets = new ListField({
        name: 'markets',
        listGetter: getMarketsData,
        creator: createTargetMarket,
        updater: updateTargetMarket,
        deleter: removeTargetMarket,
        importerCSV: importMarkets,
        exporterCSV: exportMarkets,
        resolver: (markets) => dispatch({type: MARKETS, markets})
    });

    const initialState = {
        account,
        business,
        company,
        assistant,
        billing,
        departmentsCompany,
        subscriptionList,
        variableFields,
        templates,
        documents,
        products,
        productCatalogues,
        services,
        serviceCatalogues,
        customersB2B,
        customersB2P,
        activities,
        competitors,
        partners,
        markets,
        tasks,
        subscription,
        pricesList
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    return {data: state};
}

export default DataContext;
