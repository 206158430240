import React, { useContext, useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import cn from 'classnames';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import styles from './style.module.scss';
import TouchableOpacity from '../../common/core/TouchableOpacity';
import NetworkContext from '../../../contexts/NetworkContext';
import {
  AvatarPlaceholder,
  useUploadAvatarModal,
} from '../../common/parts/UploadAvatarModal';
import TabContext, { TAB_PATHS } from '../../../contexts/TabContext';
import DataContext from '../../../contexts/DataContext';
import { uploadAccountAvatar } from '../../../api/Account/dataSetters';
import { useTranslation } from '../../../contexts/LocaleContext';
import { LocaleSelectorSmall } from '../../common/core/LocaleSelector/LocaleSelectorFixed';
import { useNavigate, useMatch, Link, NavLink } from 'react-router-dom';
import Avatar from 'react-avatar';
import { idToColor } from '../../../utils/StringUtils';
import { useNetwork } from '../../../contexts/NetworkContext';
import { chooseActivDepAndComp } from '../../../api/AccountActiveDepartment/dataGetters';

const AVATAR_SIZE = 36;
const USER_HEIGHT = 80;
const LIST_ITEM_HEIGHT = 45;
const PADDING_LEFT = 25;
const ICON_SIZE = 18;
const SETTINGS_ICON_SIZE = 17;

function Menu({ items, width, style = {} }) {
  const { t } = useTranslation();
  const { tabPath, navigate, closeEditor } = useContext(TabContext);
  const routerNavigate = useNavigate();

  const onItemClick = (path) => {
    // closeEditor();
    // routerNavigate(path);
  };

  const [menusOpen, setMenusOpen] = useState(false);
  const toggleMenu = () => setMenusOpen((prev) => !prev);

  // useEffect(() => {
  //   return navigate.listen(() => setMenusOpen(false))
  // }, []);

  return (
    <div
      className={cn(styles.container, menusOpen && styles.containerOpen)}
      style={{ width, ...style }}
    >
      <Row
        style={{
          minHeight: LIST_ITEM_HEIGHT * items.length,
        }}
      >
        {items.map(({ name, path, Icon, id }, index) => (
          <MenuListItem
            id={id}
            key={path}
            title={name}
            path={path}
            onClick={() => onItemClick(path)}
            Icon={Icon}
          />
        ))}
      </Row>
      <div className={styles.burgerContainer}>
        <div onClick={toggleMenu} className={styles.bugerContent}></div>
        <span className={cn(styles.burger, menusOpen && styles.containerOpen)}>
          <span className={styles.burgetItem}></span>
        </span>
      </div>
      <Row className={styles.copyrightContainer}>
        <div className={styles.copyrightText}>
          {t('copyright_2020_c_all_rights_reserved')}
        </div>
      </Row>
    </div>
  );
}

const MenuListItem = ({ Icon, title, path, onClick, id }) => {
  const { closeEditor } = useContext(TabContext);
  return (
    <NavLink className={styles.navLink} to={path} onClick={closeEditor}>
      {({ isActive }) => (
        <TouchableOpacity
          className={cn(styles.listItemContainer, {
            [styles.active]: isActive,
          })}
          id={id}
          style={{
            paddingLeft: PADDING_LEFT,
            height: LIST_ITEM_HEIGHT,
          }}
        >
          <Icon size={ICON_SIZE} color={isActive ? '#B94189' : '#676767'} />
          <Col className={styles.titleContainer}>{title}</Col>
        </TouchableOpacity>
      )}
    </NavLink>
  );
};

export const User = () => {
  const { t } = useTranslation();
  const { onLogout } = useNetwork();
  const { account } = useContext(DataContext);
  const { company } = useContext(DataContext);
  const { departmentsCompany } = useContext(DataContext);
  const { name, surname, avatarUrl, id, activ_department } = account.data;

  const [currentDepartment, setCurrentDepartment] = useState({
    companyName: '',
    departmentName: 'Default',
    role: 0,
    departmentId: 0,
  });

  const onAvatarSave = async (file) => {
    await uploadAccountAvatar(file);
    await account._get();
  };

  const { avatarModal, openAvatarModal } = useUploadAvatarModal(onAvatarSave);

  const { navigate } = useContext(TabContext);

  const getCompanyRoleName = () => {
    switch (currentDepartment.role) {
      case 0:
        return 'CEO';
      case 1:
        return 'Manager';
      case 2:
        return 'Employee';
      default:
        return 'CEO';
    }
  };

  const onSettingsIconClick = () => {
    navigate(TAB_PATHS.SETTINGS);
  };

  const onSelectDepartment = async (item) => {
    setCurrentDepartment({
      companyName: item.company_name,
      departmentName: item.department_name,
      role: item.role,
      departmentId: item.department_id,
    });
    const token = await chooseActivDepAndComp(item.department_id);
    localStorage.setItem('token', token);
    window.location.reload();
  };

  const getDepartmentsList = async () => {
    const activeDepartment = departmentsCompany.data.find(
      (item) => item.department_id === activ_department
    );
    setCurrentDepartment({
      companyName: activeDepartment?.company_name,
      departmentName: activeDepartment?.department_name,
      role: activeDepartment?.role,
      departmentId: activeDepartment?.department_id,
    });
  };

  useEffect(() => {
    getDepartmentsList();
  }, [departmentsCompany.data]);

  return (
    <div className={styles.userContainer} style={{ height: 60 }}>
      <div className={styles.user}>
        <div className={styles.userInfoContainer}>
          <Row className={styles.userRow}>
            <div className={styles.userNameText}>
              <div>{`${name} ${surname}`}</div>
              <div className={styles.subtitle}>
                {company.data?.name || departmentsCompany.data.length !== 0 ? (
                  <>
                    <div>
                      {departmentsCompany.data.length === 0
                        ? company.data?.name
                        : currentDepartment.companyName}
                    </div>
                    |
                    <Dropdown id="tutorial-step-15">
                      <Dropdown.Toggle as={CustomToggle}>
                        {currentDepartment.departmentName}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {departmentsCompany.data.map((item, index) => {
                          return (
                            <React.Fragment key={item.department_id}>
                              <Dropdown.Item
                                onClick={() => onSelectDepartment(item)}
                              >
                                {item.department_name}
                              </Dropdown.Item>
                              {departmentsCompany.data.length - 1 !== index ? (
                                <Dropdown.Divider />
                              ) : null}
                            </React.Fragment>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                    |<Link to="settings">{t(getCompanyRoleName())}</Link>
                  </>
                ) : null}
              </div>
            </div>
            <TouchableOpacity
              className={styles.avatarContainer}
              style={{
                width: 36,
                minWidth: 36,
                height: 36,
              }}
            >
              <AvatarPlaceholder
                fontSize={20}
                className={styles.avatarPlaceholder}
                onClick={onSettingsIconClick}
              />
              <Avatar
                name={name + ' ' + surname}
                color={idToColor(id)}
                className={styles.avatarImage}
                size={AVATAR_SIZE}
                src={avatarUrl}
                onClick={onSettingsIconClick}
              />
            </TouchableOpacity>
          </Row>
        </div>

        <LocaleSelectorSmall />

        <div>
          <Dropdown>
            <TouchableOpacity>
              <Dropdown.Toggle
                className={styles.dropdownButton}
                bsPrefix="fas fa-cog"
              />
            </TouchableOpacity>

            <Dropdown.Menu>
              <Dropdown.Item onClick={onSettingsIconClick}>
                <i class="fas fa-user"></i> Profile
              </Dropdown.Item>
              <Dropdown.Item onClick={onLogout}>
                <i class="fas fa-sign-out-alt"></i> {t('logout')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {avatarModal}
      </div>
    </div>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

export default Menu;
