import React, { useContext, useEffect, useRef, useState } from "react";
import { decode as decodeHTMLEntities } from "html-entities";

import useRecorder from "../../Assistant/useRecorder";
import useGoogleSpeech from "../../Assistant/actions/useGoogleSpeech";

import DataContext from "../../../../contexts/DataContext";

const shape = {
  recorder: {
    start: (duration, onRecordEnd = () => {}, context) => {
      console.log("idle recstart");
    },
    stop: () => {
      console.log("idle recstop");
    },
    isMicrophoneAccessed: false,
    isRecording: false,
  },
  player: {
    play: async (text = "") => {
      console.log("idle playstart");
    },
    stop: () => {
      console.log("idle playerstop");
    },
    isPlay: false,
  },
};

const useVoiceIO = () => {
  const { getTranscript, getSpeech } = useGoogleSpeech();

  const data = useContext(DataContext);
  const { assistant } = data;

  const [recorder, setRecorder] = useState(shape.recorder);
  const [player, setPlayer] = useState(shape.player);
  const [initialized, setInitialized] = useState(false);

  const onRecordEndCallback = useRef(() => {});
  const _context = useRef(null);

  const onMicrophoneAccessDenied = () => {
    setRecorder((recorder) => ({
      ...recorder,
      isMicrophoneAccessed: false,
    }));
  };
  const onMicrophoneAccessGranted = () => {
    setRecorder((recorder) => ({
      ...recorder,
      isMicrophoneAccessed: true,
    }));
  };
  const _onRecordEnd = async (base64) => {
    setRecorder((recorder) => ({
      ...recorder,
      isRecording: false,
    }));
    const text = await speechToText(base64);
    try {
      onRecordEndCallback.current(text);
    } catch (e) {
      console.error(e);
    }
  };

  const [recStart, recForceStop] = useRecorder(
    _onRecordEnd,
    onMicrophoneAccessGranted,
    onMicrophoneAccessDenied
  );

  useEffect(() => {
    setPlayer({
      play: playSound,
      stop: stopSound,
    });
    setRecorder({
      start: startRecord,
      stop: stopRecord,
      isMicrophoneAccessed: false,
      isRecording: false,
    });
    setInitialized(true);
  }, []);

  const speechToText = async (base64 = "") => {
    return getTranscript(base64, _context.current);
  };

  const sound = useRef(null);

  const playSound = async (text = "") => {
    try {
      stopSound();
    } catch (e) {
      console.error(e);
    }

    const textDecoded = decodeHTMLEntities(text);
    const base64data = await getSpeech(textDecoded, assistant.data.gender);
    const newSound = new Audio("data:audio/MP3;base64," + base64data);
    sound.current = newSound;
    sound.current && sound.current.play();

    sound.current.onplay = () => {
      setPlayer((prev) => ({ ...prev, isPlay: true }));
    };
    sound.current.onended = () => {
      setPlayer((prev) => ({ ...prev, isPlay: false }));
    };
  };

  const stopSound = () => {
    sound.current && sound.current.pause();
  };

  useEffect(
    () => () => {
      stopSound();
    },
    []
  );

  const startRecord = (duration = 3, onRecordEnd = () => {}, context) => {
    try {
      stopSound();
    } catch (e) {
      console.error(e);
    }
    if (recorder.isRecording) return;
    if (context) _context.current = context;
    onRecordEndCallback.current = (text) => {
      onRecordEnd(text);
    };
    recStart(duration);
    setRecorder((recorder) => ({
      ...recorder,
      isRecording: true,
    }));
  };

  const stopRecord = () => {
    recForceStop();
  };

  return { recorder, player, initialized };
};

export default useVoiceIO;
