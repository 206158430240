import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import styles from './style.module.css';
import cn from 'classnames';
import './reactTableStyle.css';
import TouchableOpacity from '../../core/TouchableOpacity';
import selectTable from 'react-table/lib/hoc/selectTable';
import { useTranslation } from '../../../../contexts/LocaleContext';
import { useRestriction } from '../../../../contexts/Restriction/RestrictionContext';
import moment from 'moment';
import { addressToStr } from '../../../../utils/StringUtils';

const ROW_HEIGHT = 50;

// TODO limit letters count
const Cell = ({
  editable,
  className,
  saveData,
  bufferValue,
  colIndex,
  format,
  ...props
}) => {
  const cellEl = useRef(null);

  return (
    <div
      ref={cellEl}
      className={cn(styles.cell, editable && styles.editable, className)}
    >
      {format ? format(props.value) : props.value}
    </div>
  );
};

const TimeCell = ({ time }) => {
  let timeFormat = moment(time).format('YYYY-MM-DD');

  return <div className={styles.cell}>{timeFormat}</div>;
};

const ControlCell = ({ setEditingItem }) => (
  <div className={cn(styles.cell, styles.controlCell)}>
    <TouchableOpacity>
      <i
        onClick={setEditingItem}
        className={cn('fas fa-pen', styles.controlIcon)}
      />
    </TouchableOpacity>
  </div>
);

const ProgressCell = ({ start, end }) => {
  let fromStart = moment().diff(moment(start), 'seconds');
  let wholeInterval = moment(end).diff(moment(start), 'seconds');
  let timeLeft = wholeInterval - fromStart;
  let percents;

  if (fromStart < 0) {
    percents = 0;
  } else {
    percents = Math.min(Math.round((fromStart / wholeInterval) * 100), 100);
  }

  let initialStyle;

  if (percents >= 0 && percents < 20) {
    initialStyle = { width: `${percents}%`, backgroundColor: `#CD5C5C` };
  } else if (percents >= 20 && percents < 40) {
    initialStyle = { width: `${percents}%`, backgroundColor: `#FF7F50` };
  } else if (percents >= 40 && percents < 60) {
    initialStyle = { width: `${percents}%`, backgroundColor: `#FFD700` };
  } else if (percents >= 60 && percents < 80) {
    initialStyle = { width: `${percents}%`, backgroundColor: `#ADFF2F` };
  } else if (percents >= 80 && percents <= 100) {
    initialStyle = { width: `${percents}%`, backgroundColor: `#32CD32` };
  }

  let [style, setStyle] = useState(initialStyle);

  let [state, setState] = useState({
    initialTime: wholeInterval,
    timeLeft: timeLeft,
    percent: percents,
    fromStart: fromStart,
  });

  useEffect(() => {
    const progress = () => {
      let id = setInterval(progressStatus, 1000);
      let { percent, fromStart, timeLeft, initialTime } = state;

      function progressStatus() {
        if (percent >= 100) {
          clearInterval(id);
        } else {
          timeLeft--;
        }

        let percentage = 100 - (timeLeft / initialTime) * 100;
        let newPercent = Math.min(Math.round(percentage), 100);

        if (newPercent >= 0 && newPercent < 20) {
          setStyle({ width: `${newPercent}%`, backgroundColor: `#CD5C5C` });
        } else if (newPercent >= 20 && newPercent < 40) {
          setStyle({ width: `${newPercent}%`, backgroundColor: `#FF7F50` });
        } else if (newPercent >= 40 && newPercent < 60) {
          setStyle({ width: `${newPercent}%`, backgroundColor: `#FFD700` });
        } else if (newPercent >= 60 && newPercent < 80) {
          setStyle({ width: `${newPercent}%`, backgroundColor: `#ADFF2F` });
        } else if (newPercent >= 80 && newPercent <= 100) {
          setStyle({ width: `${newPercent}%`, backgroundColor: `#32CD32` });
        }
        setState(() => ({
          initialTime,
          timeLeft,
          percent: newPercent,
        }));
      }
    };
    progress();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.progressLineBG}>
        <div
          className={styles.progressLine}
          id={styles.progressLine}
          style={style}
        >
          {style.width}
        </div>
      </div>
    </div>
  );
};

const EditableTable = ({
  data = { items: [], page: 1, pages: 1 },
  getter,
  noWhatText = 'data',
  columns: inputColumns,
  deleteItem: deleteDataItem = async () => {},
  editItem: editDataItem = async () => {},
  headerClassName,
  noEdit = false,
  showToolbar = true,
  selectable = true,
  defaultPageSize,
  ...otherProps
}) => {
  const SelectTable = selectable ? selectTable(ReactTable) : ReactTable;

  const [selection, setSelection] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [tableData, setTableData] = useState(data);
  const [filters, setFilters] = useState([]);
  const [timer, setTimer] = useState(0);
  const [canApplyFilters, setCanApplyFilters] = useState(true);
  const { t } = useTranslation();
  const [desiredSorted, setDesiredSorted] = useState([
    { id: 'id', desc: true },
  ]);
  const { withRestrictions } = useRestriction();

  const tableRef = useRef(null);
  const getPageSize = () =>
    Math.floor((document.documentElement.clientHeight - 330) / ROW_HEIGHT);
  const [pageSize, setPageSize] = useState(getPageSize());
  const [desiredPage, setDesiredPage] = useState(0);
  const [isFilterShown, setFilterShown] = useState(false);
  const onResize = () => setPageSize(getPageSize());

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const deleteSelected = async () => {
    const promises = selection.map((item) => {
      const id = item.slice(7);
      return deleteDataItem(id);
    });
    return await Promise.allSettled(promises).then(() => fetchData());
  };

  const [isLoading, setLoading] = useState(true);

  const fetchData = async () => {
    const customersSortByString = (sortBy) => {
      switch (sortBy) {
        case 'bank_details':
          return 'BankDetails';
        case 'address':
          return 'FullAddressStreet';
        default:
          return (
            desiredSorted[0].id.charAt(0).toUpperCase() +
            desiredSorted[0].id.slice(1)
          );
      }
    };

    if (noWhatText === 'tasks') {
      setLoading(true);
      // todo pages, page, pagesize, filters, sortby, sorttype,
      const _data = await getter();
      let newData = _data.items.map((item, index) => {
        let newItem = { ...item };
        if (moment().diff(moment(newItem.start_date), 'seconds') < 0) {
          newItem.status = 'to do';
        } else if (
          moment().diff(moment(newItem.start_date), 'seconds') >= 0 &&
          moment().diff(moment(newItem.due_date), 'seconds') < 0
        ) {
          newItem.status = 'in progress';
        } else {
          newItem.status = 'done';
        }
        /*                newItem.start_date = moment(newItem.start_date).format('YYYY-MM-DD')
                                newItem.due_date = moment(newItem.due_date).format('YYYY-MM-DD')*/
        return newItem;
      });
      setTableData({ page: 1, pages: 1, items: newData });
      setLoading(false);
    } else {
      setLoading(true);
      // todo pages, page, pagesize, filters, sortby, sorttype,
      const _data = await getter({
        ...filters.reduce((filtersObj, filter) => {
          return { ...filtersObj, [filter.id]: filter.value };
        }, {}),
        pageSize: pageSize,
        Page: desiredPage + 1,
        SortBy: customersSortByString(desiredSorted[0].id),
        SortOrder: Number(desiredSorted[0].desc),
      });

      console.log('[payment-view] set table data:', noWhatText, _data, data);

      if (_data.pages < desiredPage + 1) setDesiredPage(0);
      else setTableData(_data);
      setLoading(false);
    }
  };

  useEffect(
    // Waits .5 sec after user stopped typing before fetching results
    () => {
      setCanApplyFilters(false);
      clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          setCanApplyFilters(true);
        }, 500)
      );
    },
    [filters]
  );

  useEffect(() => {
    if (!getter) {
      setLoading(false);
    } else if (canApplyFilters) fetchData();
  }, [canApplyFilters, pageSize, desiredPage, desiredSorted, data]);

  const columns = useMemo(() => {
    return inputColumns
      .map((item) => ({
        Cell:
          item.Header === '"PROGRESS"'
            ? ({ original }) => (
                <ProgressCell
                  start={original.start_date}
                  end={original.due_date}
                />
              )
            : item.Header === '"START_DATE"'
            ? ({ original }) => <TimeCell time={original.start_date} />
            : item.Header === '"DUE_DATE"'
            ? ({ original }) => <TimeCell time={original.due_date} />
            : ({ original, column, ...props }) => (
                <Cell colIndex={column.index} format={item.format} {...props} />
              ),
        headerClassName,
        ...item,
        filterable: item?.filter ? undefined : false,
      }))
      .concat(
        !noEdit
          ? {
              Cell: ({ original }) => (
                <ControlCell
                  setEditingItem={() => editDataItem(original)}
                  code={original.id}
                />
              ),
              headerClassName,
              width: 40,
              filterable: false,
              sortable: false,
            }
          : null
      )
      .filter((item) => item !== null);
  }, [inputColumns]);

  const toggleSelection = (key) => {
    let _selection = [...selection];
    const keyIndex = _selection.indexOf(key);
    if (keyIndex >= 0) {
      _selection = [
        ..._selection.slice(0, keyIndex),
        ..._selection.slice(keyIndex + 1),
      ];
    } else _selection.push(key);
    setSelection(_selection);
  };

  const toggleAll = () => {
    const keyField = 'id';
    let _selection = [];
    if (!tableRef.current) return;
    if (!selectAll) {
      const wrappedInstance = tableRef.current.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        _selection.push(`select-${item._original[keyField]}`);
      });
    }
    setSelection(_selection);
    setSelectAll((p) => !p);
  };

  const isSelected = (key) => {
    return selection.includes(`select-${key}`);
  };

  return (
    <div className={styles.container}>
      {showToolbar && (
        <div className={styles.toolBar}>
          <ToolBarItem
            label={!isFilterShown ? t('show_filters') : t('hide_filters')}
            iconClassName={'fas fa-filter'}
            callBack={() => setFilterShown((p) => !p)}
          />
          <ToolBarItem
            label={t('clear_filters')}
            iconClassName={'fas fa-times'}
            disabled={!filters.length}
            callBack={() => setFilters([])}
          />
          <ToolBarItem
            label={t('delete_selected')}
            iconClassName={'fas fa-trash'}
            disabled={!selection.length}
            twoStep
            callBack={withRestrictions(deleteSelected)}
          />
        </div>
      )}
      <SelectTable
        ref={tableRef}
        className={cn(styles.table)}
        pageText={t('page')}
        ofText={t('of').toLowerCase()}
        noDataText={t('no_data')}
        loading={isLoading}
        manual={!!getter}
        toggleAll={toggleAll}
        toggleSelection={toggleSelection}
        isSelected={isSelected}
        selectAll={selectAll}
        keyField={'id'}
        data={tableData?.items}
        columns={columns}
        showPageSizeOptions={false}
        sortable={true}
        sorted={desiredSorted}
        onSortedChange={(sorted) => setDesiredSorted(sorted)}
        page={desiredPage}
        onPageChange={(page) => setDesiredPage(page)}
        showFilters={isFilterShown}
        filterable={isFilterShown}
        NextComponent={Right}
        onFilteredChange={(v) => setFilters(v)}
        filtered={filters}
        PreviousComponent={Left}
        minRows={!defaultPageSize ? pageSize : defaultPageSize}
        pages={tableData?.pages}
        pageSize={!defaultPageSize ? pageSize : defaultPageSize}
        multiSort={false}
        selectType=""
        defaultSorted={[
          {
            id: 'id',
            desc: true,
          },
        ]}
        {...otherProps}
      />
    </div>
  );
};

const Left = ({ children, ...props }) => (
  <TouchableOpacity>
    <i className="fas fa-chevron-left" {...props} />
  </TouchableOpacity>
);

const Right = ({ children, ...props }) => (
  <TouchableOpacity>
    <i className="fas fa-chevron-right" {...props} />
  </TouchableOpacity>
);

const ToolBarItem = ({
  label = '',
  iconClassName = '',
  twoStep = false,
  callBack = () => {},
  disabled = false,
}) => {
  const isAsync = callBack?.constructor?.name === 'AsyncFunction';
  const [isLoading, setLoading] = useState(false);
  const [confirmMode, setConfirmMode] = useState(!twoStep);
  const { t } = useTranslation();

  const getOnClick = () => {
    if (disabled || isLoading) return () => {};
    if (!confirmMode && twoStep) return () => setConfirmMode(true);
    if (isAsync)
      return async () => {
        setLoading(true);
        await callBack();
        setLoading(false);
        setConfirmMode(false);
      };
    else
      return () => {
        callBack();
        setConfirmMode(false);
      };
  };
  const getLabel = () => {
    if (isLoading) return t('please_wait');
    if (twoStep && confirmMode) return t('click_again_to_confirm');
    return label;
  };

  return (
    <div
      className={styles.toolBarItem}
      style={disabled || isLoading ? { opacity: 0.5 } : {}}
      onClick={getOnClick()}
      onMouseOut={() => setConfirmMode(false)}
    >
      <i
        className={iconClassName}
        style={confirmMode && twoStep && !isLoading ? { color: 'red' } : {}}
      />
      <div style={confirmMode && twoStep && !isLoading ? { color: 'red' } : {}}>
        {getLabel()}
      </div>
    </div>
  );
};
export default EditableTable;
