import React, { useEffect, useState, useContext } from 'react';
import styles from './search.module.css';
import { useTranslation } from '../../../../contexts/LocaleContext';
import SearchWrapper from './SearchWrapper';
import {
  getProductsByKeywords
} from '../../../../api/Product/dataGetters';
import { AssistantContext } from '../../../../contexts/AssistantContext';
import { useNavigate } from 'react-router-dom';

const getTableDataFromResponse = (
  data,
  openProduct
) => {
  if (!data?.length) return [];

  return data.map((product, index) => {
    return {
      id: product.id || index,
      classNames: {
        bar: styles.productBar,
      },
      columns: [
        {
          className: styles.productColumn,
          element: product.id,
        },
        {
          className: styles.productColumn,
          element: product.name,
        },
        {
          className: styles.productColumn,
          element: product.price,
        },
      ],
      onClick: openProduct(product.id),
    };
  });
};

const SearchProducts = ({ keywords = [], onClose = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { close: closeAssistant } = useContext(AssistantContext);
  const [isLoading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [legend, setLegend] = useState({
    classNames: {
      bar: styles.productLegendBar,
    },
    columns: [
      {
        icon: 'fas fa-hashtag',
        title: t('code'),
      },
      {
        icon: 'fas fa-box-open',
        title: t('name'),
      },
      {
        icon: 'fas fa-tag',
        title: t('price'),
      },
    ],
  });

  const openProduct = (id) => () => {
    closeAssistant();
    navigate(`/dashboard/products/${id}`);
  };

  useEffect(() => {
    setLoading(true);
    getProductsByKeywords(keywords).then((products) => {
      setTableData(
        getTableDataFromResponse(products, openProduct)
      );
      setLoading(false);
    });
  }, []);

  return (
    <SearchWrapper
      query={keywords.join(' ')}
      legend={legend}
      tableData={tableData}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default SearchProducts;
