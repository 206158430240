import React, {useCallback, useContext, useState} from 'react';
import useEscape from "../../Dashboard/Assistant/useEscape";
import avatarModalStyles from "./UploadAvatarModal/style.module.css";
import {useDropzone} from "react-dropzone";
import Wrapper from "../core/Wrapper";
import cn from "classnames";
import Button from "../core/Button";
import useWaiting from "../hooks/useWaiting";
import {useTranslation} from "../../../contexts/LocaleContext";
import GuideContext from "../../Onboarding";


const UploadPdfModal = ({onClose, saveFile}) => {
  const {t} = useTranslation()
  const containerRef = useEscape(avatarModalStyles.container, onClose);
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState(null);
  const [waiting, saveWithWaiting] = useWaiting(saveFile);
  const {markDone} = useContext(GuideContext)
  
  const save = () => {
    markDone('add catalogue')
    saveWithWaiting(file);
  };
  
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const url = URL.createObjectURL(file);
    if (file) {
      setFile(file);
      setUrl(url);
    } else {
      // error
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'application/pdf',
  });

  return (
    <Wrapper
      className={avatarModalStyles.container}
      ref={containerRef}
    >
      <div className={avatarModalStyles.box}>
        <div className={avatarModalStyles.header}>
          {t("upload_pdf_catalogue").toUpperCase()}
        </div>
        {url && (
        <div>
          <iframe src={url} frameBorder='0' className={avatarModalStyles.pdfPreview}></iframe>
        </div>)}
        <div className={avatarModalStyles.main}>
          <div className={avatarModalStyles.rightSideContainer}>
            <div {...getRootProps({
              className: cn(
                avatarModalStyles.uploadBox,
                isDragAccept && avatarModalStyles.uploadBoxAccept,
                isDragReject && avatarModalStyles.uploadBoxReject,
              )})}>
              <input {...getInputProps({className: avatarModalStyles.input})} required/>
              <span>
                {
                  isDragReject ? t("you_can_upload_only_.pdf_files") :
                    t("upload_pdf_catalogue")
                }
              </span>
            </div>

            {file && file.name && <div className={avatarModalStyles.fileName}>
              {'• ' + file.name}
            </div>}

            <div className={avatarModalStyles.buttonsContainer}>
              <Button
                onClick={save}
                waiting={waiting}
                title={t('save')}
                type={'submit'}
                width={120}
                className={avatarModalStyles.leftButton}
              />
              <Button title={t('cancel')} type={'button'} color={'#C9CBCA'} className={avatarModalStyles.rightButton} width={120} onClick={onClose}/>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default UploadPdfModal;
