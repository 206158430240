import React, {createContext, useReducer, useContext, useState, useEffect, useRef} from 'react';
import Enum from "../utils/enum";
import AssistantContainer from "../components/Dashboard/Assistant/AssistantContainer";
import { useRestriction } from "../contexts/Restriction/RestrictionContext"

export const contextShape = {
  open: () => {},
  close: () => {},
  assistant: null,
};

export const AssistantContext = createContext(contextShape);

const [
  OPEN, CLOSE
] = Enum(2);

function reducer(state, {type, assistant}) {
  switch (type) {
    case OPEN:
      return {...state, assistant};
    case CLOSE:
      return {...state, assistant: null};
    default:
      return state;
  }
}

export const useAssistant = () => {
  const { withRestrictions } = useRestriction();
  const [isOpen, setOpen] = useState(false)
  const close = () => {
    setOpen(false)
    dispatch({type: CLOSE});
  };

  const open = (template, query=null) => {
    setOpen(true);
    dispatch({
      type: OPEN,
      assistant: (
        <AssistantContainer
          template={template}
          close={close}
          query={query}
        />
      ),
    });
  };

  const initState = {...contextShape, open: withRestrictions(open), close, isOpen};

  const [state, dispatch] = useReducer(reducer, initState);

  return state;
};
