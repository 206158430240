import React from 'react';
import cn from 'classnames';
import styles from './style.module.css';

const TaskTag = ({ background = '#BCE2EC', color = '#1E9DBD', label = '' }) => {
  return (
    <div className={cn(styles.wrapper)} style={{ background }}>
      <span className={cn(styles.label)} style={{ color }}>
        {label}
      </span>
    </div>
  );
};

export default TaskTag;
