import React, {useEffect, useState} from "react";
import {Form} from "informed";
import {isEmpty} from "../../../utils/StringUtils";

const AutoForm = ({onSubmit, initialValues, ...props}) => {
  const [formApi, setFormApi] = useState({});

  useEffect(() => {
    if (formApi && !isEmpty(formApi) && initialValues && !isEmpty(initialValues)) {
      formApi.setValues({...initialValues});
    }
  }, [formApi, initialValues]);

  const submit = async (...args) => {
    await onSubmit(...args);
    formApi.reset();
  };

  return (
    <Form
      onSubmit={submit}
      getApi={setFormApi}
      {...props}
    />
  );
};

export default AutoForm;
