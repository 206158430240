import { DELETE, PATCH, POST } from "../network";
import { companyAccountEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
  try {
    return await POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const patchData = async (path, data) => {
  try {
    return await PATCH(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const deleteData = async (path, data) => {
    try {
      return await DELETE(path, data)
    } catch (e) {
      console.error(path, e);
    }
}

export const corporateCreateCompany = async (company = { name: '', avatar: '', phone: '', email: '' }) => {
    return await setData(companyAccountEndpoints.createCompany, company)
}
  
export const createCorporateCompany = async (data) => {
    return await setData(companyAccountEndpoints.createCompany, data)
}
  
export const updateCorporateCompany = async (data) => {
    return await patchData(companyAccountEndpoints.updateCompany, data)
}
  
export const deleteCorporateCompany = async (id) => {
    return await deleteData(`${companyAccountEndpoints.deleteCompany}${id}`)
}

export const createFlow = async ({ fromId, toId }) => {
    return await setData(companyAccountEndpoints.createFlow, {
      flow_items: [{
        department_from_id: fromId,
        department_to_id: toId,
      }]
    })
}
  
export const updateFlow = async (data) => {
    return await setData(companyAccountEndpoints.updateFlow, data)
}
  
export const deleteFlow = async (id) => {
    return await deleteData(companyAccountEndpoints.deleteFlow, [id])
}
  
export const inviteWorker = async ({ email = '', companyId = 0, departmentId = 0, workerType = 0 }) => {
    return await setData(companyAccountEndpoints.inviteUserToCompany, {
      "accountEmail": email,
      "companyId": companyId,
      "departmentId": departmentId,
      "employeeType": workerType
    })
}
  
export const deleteWorker = async (id) => {
    return await deleteData(`${companyAccountEndpoints.deleteEmployee}${id}`)
}
  
export const transferWorker = async (id, email) => {
    return await setData(companyAccountEndpoints.transferEmployee, {
      "employee_id": id,
      "email_new_employee": email
    })
}