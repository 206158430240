import { GET } from "../network";
import { blobToBase64 } from "../../utils/blob2base64";
import { accountEndpoints, fastBossEndpoints } from "../requestEndpoints";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data);
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

const getFile = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.data;
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

export const getAccountData = async () => {
    const avatar = await getAccountAvatar();
    const avatarUrl = avatar ? await blobToBase64(avatar) : "";
    const data = await getData(accountEndpoints.getInfo, Object);
    return {...data, avatarUrl};
};

export const getAccountAvatar = async () => {
    return await getFile(accountEndpoints.avatar);
};

export const getHowToPageWP = () => {
    return fetch(fastBossEndpoints.wpJson).then(res =>
        res.json().then(({content: {rendered}}) => rendered)
    );
};