import { GET } from '../network';
import { filtersToQuery } from '../../utils/filtersToQuery';
import { documentEndpoints } from '../requestEndpoints';

const getData = async (path, Type) => {
  try {
    const res = await GET(path);
    return res.body.data || JSON.parse(res.data);
  } catch (e) {
    if (Type) return new Type();
    return null;
  }
};

const getListData = async (path) => {
  const data = await getData(path);
  if (data) {
    return data.items;
  }
  return [];
};

const getLatestItem = async (path) => {
  const data = await getData(path + `?PageSize=1&SortOrder=1`);
  if (data) {
    return data.items;
  }
  return [];
};

const getListDataLatestCreated = async (path) => {
  const data = await getData(path + `?PageSize=5&SortOrder=1`);
  if (data) {
    return data.items;
  }
  return [];
};

const getPagedListData = async (path) => {
  const data = await getData(path);
  if (data) {
    return {
      items: data.items,
      page: data.currentPage || 1,
      pages: data.totalPages || 1,
    };
  }
  return { items: [], page: 1, pages: 1 };
};

export const getDocumentsData = async () => {
  return getListData(documentEndpoints.getList);
};

export const searchDocuments = async (query) => {
  return getData(`${documentEndpoints.get}?keywords=${query}`);
};

export const getLatestDocument = async () => {
  return getLatestItem(documentEndpoints.getList);
};

export const getDocumentsLatestCreated = async () => {
  return getListDataLatestCreated(documentEndpoints.getList);
};

export const getPageDocumentsData = async (filters = {}) => {
  return getPagedListData(documentEndpoints.getList + filtersToQuery(filters));
};

export const getPageDocumentsDataSearch = async (filters = {}) => {
  return getPagedListData(documentEndpoints.search + filtersToQuery(filters));
};

export const getDocumentById = async (id) => {
  return getData(`${documentEndpoints.getById}${id}`);
};

export const getDocumentHitLimit = async (id) => {
  return getData(documentEndpoints.hitLimitDocuments);
};
