import cn from "classnames";
import globalStyles from "../global.module.css";
import React, {useState, useEffect} from "react";


const ElectByIndex = ({children, index, className='', defaultComponent=null}) => {
  const showingElected = index !== null && [...children.keys()].includes(index);

  /*const [keys, setKeys] = useState(generateKeys(children.length));
  useEffect(() => {
    setKeys(generateKeys(children.length));
  }, [children.length]);*/

  const electingContents = children.map((item, i) => (
    <div key={i} className={cn(i !== index ? globalStyles.hidden : className)}>
      {item}
    </div>
  ));
  const defaultContent = <div key={'default'} className={cn(showingElected ? globalStyles.hidden : className)}>{defaultComponent}</div>;
  return [electingContents, defaultContent];
};

export default ElectByIndex;
