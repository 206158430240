import React, { useState } from "react";
import { Scope } from "informed";
import styles from "./style.module.css";
import cn from "classnames";
import Input from "../../../common/core/Input";
import Col from "react-bootstrap/Col";
import DashboardTabs from "../../../common/parts/DashboardTabs";
import Row from "react-bootstrap/Row";
import ButtonsPair from "../../../common/parts/ButtonsPair";
import useWaiting from "../../../common/hooks/useWaiting";
import AutoForm from "../../../common/core/AutoForm";
import { isEmpty } from "../../../../utils/StringUtils";
import { useTranslation } from "../../../../contexts/LocaleContext";

const CustomInput = ({ title, ...rest }) => (
  <>
    <Col className={cn(styles.title, "col-12 pl-2 mb-3")}>
      {title.toUpperCase()}
    </Col>
    <Col className={"col-12"}>
      <Input heigth={40} {...rest} />
    </Col>
  </>
);

const CustomForm = ({ onSubmit, close, initialValues }) => {
  const { t } = useTranslation();
  const [waiting, submit] = useWaiting(onSubmit);
  return (
    <AutoForm
    onSubmit={(state) =>
      submit({
        ...initialValues,
        ...state,
      })
    }
      className={styles.form}
      initialValues={initialValues}
    >
      <Row>
        <Col className={"col-10 pt-2"}>
          <CustomInput field={"name"} title={t("name_of_customer")} required />
        </Col>
      </Row>
      <Scope scope="address">
        <Row>
          <Col className={"col-7 pr-2"}>
            <CustomInput field={"street"} title={t("street")} />
          </Col>
          <Col className={"col-3 pl-2"}>
            <CustomInput field={"building"} title={t("building")} />
          </Col>
        </Row>
        <Row>
          <Col className={"col-7 pr-2"}>
            <CustomInput field={"index"} title={t("index")} />
          </Col>
          <Col className={"col-3 pl-2"}>
            <CustomInput field={"city"} title={t("city")} />
          </Col>
        </Row>
        <Row>
          <Col className={"col-7 pr-2"}>
            <CustomInput field={"state"} title={t("state")} />
          </Col>
          <Col className={"col-3 pl-2"}>
            <CustomInput field={"country"} title={t("country")} />
          </Col>
        </Row>
      </Scope>
      <Row className={"pt-2"}>
        <Col className={"col-4 pr-2"}>
          <CustomInput field={"phone"} title={t("phone")} />
        </Col>
        <Col className={"col-6 pl-2"}>
          <CustomInput field={"email"} title={t("email")} />
        </Col>
      </Row>
      <Row className={"pt-2"}>
        <Col className={"col-10 pt-2"}>
          <CustomInput field={"bank_details"} title={t("bank_details")} />
        </Col>
      </Row>
      <Row>
        <Col className={"col-10 pt-2"}>
          <CustomInput
            field={"directionactivity"}
            title={t("what_does_their_company_do")}
          />
        </Col>
        <Col className={"col-10 pt-2"}>
          <CustomInput
            field={"needs"}
            title={t("what_does_their_company_need")}
          />
        </Col>
        <Col className={"col-10 pt-2"}>
          <CustomInput
            field={"responsibleforpurchasing"}
            title={t("person_who_decide")}
          />
        </Col>
        <Col className={"col-10 pt-2"}>
          <CustomInput
            field={"keywords"}
            title={t("keywords")}
            placeholder={t("enter_comma-separated_keywords")}
          />
        </Col>
      </Row>
      <Row>
        <ButtonsPair
          saveWaiting={waiting}
          onCancel={close}
          className={"pb-2"}
        />
      </Row>
    </AutoForm>
  );
};

const ModalB2B = ({
  onSubmit = () => {},
  close = () => {},
  editingValues = {},
  initWithName = null,
}) => {
  const { t } = useTranslation();
  const TABS = [
    {
      name: isEmpty(editingValues)
        ? t("add_new_").toUpperCase() + " B2B"
        : `${t("edit")} B2B  #${editingValues.id}`.toUpperCase(),
      content: (
        <CustomForm
          initialValues={
            initWithName
              ? { ...editingValues, name: initWithName }
              : editingValues
          }
          onSubmit={onSubmit}
          close={close}
        />
      ),
    },
  ];

  return (
    <DashboardTabs tabs={TABS} readyContents={TABS.map((tab) => tab.content)} />
  );
};

export default ModalB2B;
