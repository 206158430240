import { GOOGLE_CLOUD_API_KEY } from "../../../../config";
import { searchTriggerWords } from "../scenarios/Initial";
import { useContext } from "react";
import DataContext from "../../../../contexts/DataContext";
import { useTranslation } from "../../../../contexts/LocaleContext";
import { POST } from "../../../../api/network";
import { toast } from "react-toastify";
import { Info } from "../../../common/core/Toasts";

const headers = new Headers();
headers.append("Content-Type", "application/json");

const getLanguageCode = (_locale) => {
  switch (_locale) {
    case "en":
      return "en-US";
    case "ru":
      return "ru-RU";
    case "es":
      return "es-ES";
    case "it":
      return "it-IT";
    case "ro":
      return "ro-RO";
    case "fr":
      return "fr-FR";
    case "de":
      return "de-DE";
    default:
      return "en-US";
  }
};

const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

const getGoogleSpeechTranscript = async (base64data, phrases, locale) => {
  const formData = new FormData();
  formData.append("locale", getLanguageCode(locale));
  formData.append("audio", dataURIToBlob(base64data));

  const { body: { results = [] } } = await POST("/api/AssistantVoice/recognize", formData, true);
  const result = results.find(
    (current) => current && current.alternatives && current.alternatives[0].transcript
  );
  const text = (result && result.alternatives[0].transcript) || ""

  return text;
};

export const Gender = {
  0: "MALE",
  1: "FEMALE",
};

const getGoogleTextToSpeech = async (text, gender = 0, locale) => {
  const target = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${GOOGLE_CLOUD_API_KEY}`;

  const getVoiceName = (_locale, _gender) => {
    if (_gender == 0) {
      // MALE
      switch (_locale) {
        case "en":
          return "en-GB-Wavenet-B";
        case "ro":
          return "ro-RO-Wavenet-A"; // Male voice is not available for Romanian
        case "ru":
          return "ru-RU-Standard-B";
        case "es":
          return "es-ES-Wavenet-B";
        case "it":
          return "it-IT-Wavenet-D";
        case "fr":
          return "fr-FR-Wavenet-D";
        case "de":
          return "de-DE-Wavenet-B";
        default:
          return "en-GB-Standard-D";
      }
    } else {
      // FEMALE
      switch (_locale) {
        case "en":
          return "en-GB-Wavenet-F";
        case "ro":
          return "ro-RO-Wavenet-A"; // Male voice is not available for Romanian
        case "ru":
          return "ru-RU-Wavenet-C";
        case "es":
          return "es-ES-Standard-A";
        case "it":
          return "it-IT-Wavenet-B";
        case "fr":
          return "fr-FR-Wavenet-E";
        case "de":
          return "de-DE-Wavenet-C";
        default:
          return "en-GB-Wavenet-F";
      }
    }
  };

  const body = {
    input: {
      text: text,
    },
    voice: {
      languageCode: getLanguageCode(locale),
      name: getVoiceName(locale, gender),
      // 'ssmlGender': gender===0 ? 'MALE' : 'FEMALE'
    },
    audioConfig: {
      audioEncoding: "MP3",
    },
  };

  const params = {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  };

  const res = await fetch(target, params);
  const json = await res.json();

  return json && json.audioContent;
};

const useGoogleSpeech = () => {
  const data = useContext(DataContext);
  const { t, currentLocale } = useTranslation();
  const mapDataToPhrases = (fieldName) => {
    const array = data?.[fieldName]?.data;
    try {
      return array
        .map((entity) => {
          return [
            entity?.name || "",
            entity?.title || "",
            entity?.keywords || [],
          ];
        })
        .flat(Infinity);
    } catch (e) {
      console.error(e);
    }
  };
  const getPhrasesByContext = (_context = "") => {
    let phrases;

    switch (_context) {
      case "DATE":
        phrases = [
          "$MONTH $DAY $YEAR",
          "the $DAY of $MONTH $YEAR",
          "$MONTH $DAY",
          "the $DAY of $MONTH",
        ];
        break;
      case "NUMBER":
        phrases = ["$OOV_CLASS_DIGIT_SEQUENCE"];
        break;
      case "MONEY":
        phrases = ["$MONEY"];
        break;
      case "INIT":
        phrases = searchTriggerWords;
        break;
      case "PRODUCTS":
        phrases = mapDataToPhrases("products");
        break;
      case "SERVICES":
        phrases = mapDataToPhrases("services");
        break;
      case "CLIENTS":
        phrases = [
          mapDataToPhrases("customersB2P"),
          mapDataToPhrases("customersB2B"),
        ].flat();
        break;
      case "PARTNERS":
        phrases = mapDataToPhrases("products");
        break;
      case "TEMPLATES":
        phrases = mapDataToPhrases("templates");
        break;
      default:
        phrases = [""];
    }
    return phrases;
  };

  const getTranscript = async (base64data, context) => {
    const phrases = getPhrasesByContext(context);
    const text = await getGoogleSpeechTranscript(base64data, phrases, currentLocale);
    if (text) {
      return text;
    } else {
      toast(<Info>{t('We were unable to recognize your audio command. Please try again.')}</Info>);
    }
  };

  const getSpeech = async (text = "", gender = 0) => {
    const _text = text.replaceAll("_", " ");
    return getGoogleTextToSpeech(_text, gender, localStorage.getItem("USER_LANG"));
  };

  return { getTranscript, getSpeech };
};

export default useGoogleSpeech;
