import {GET} from "../network";
import { commandsEndpoints } from "../requestEndpoints";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data); 
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

export const search = async (query) => {
    return await getData(`${commandsEndpoints.search}?search=${query}`)
}