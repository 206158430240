import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from "react-router-dom";
import cn from 'classnames';
import styles from './style.module.css';
import { useTranslation } from "../../../../contexts/LocaleContext";

export default ({ className = '' }) => {
  const { t } = useTranslation()
  return (
    <div className={cn(className, styles.container)}>
      <Row>
        <Col className="text-right pr-2">
          <Link to={""}><span className={styles.link}>Solution</span></Link>
        </Col>
        <Col className="pl-2">
          <Link to={""}><span className={styles.link}>About us</span></Link>
        </Col>
      </Row>
      <Row><Col className={cn(styles.copyright, 'text-center')}>{t("copyright_2020_c_all_rights_reserved")}</Col></Row>
    </div>
  );
}
