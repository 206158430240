import { GET } from '../network';
import { companyAccountSubscription } from '../requestEndpoints';

const getData = async (path, Type) => {
  try {
    const res = await GET(path);
    console.log('response data', res);
    return res.body.data || res.body;
  } catch (e) {
    if (Type) return new Type();
    return null;
  }
};

const getListData = async (path) => {
  const data = await getData(path);
  if (data) {
    return data;
  }
  return [];
};

export const getInfoCustomer = async () => {
  return getData(companyAccountSubscription.getInfoCustomer);
};

export const getMyPaymentMethod = async () => {
  return getData(companyAccountSubscription.getMyPaymentMethod);
};

export const retrieveSubscriptionInformation = async () => {
  return getData(companyAccountSubscription.retrieveSubscriptionInformation);
};

export const retrieveCustomerPaymentMethod = async (id) => {
  return getData(companyAccountSubscription.retrieveCustomerPaymentMethod + id);
};

export const getAllMyInvoices = async () => {
  return getData(companyAccountSubscription.getAllMyInvoices);
};

export const getSubscriptions = async () => {
  return getListData(companyAccountSubscription.getSubscriptions);
};

export const getPrices = async () => {
  return getListData(companyAccountSubscription.getPrices);
};

export const hasActiveSubscription = async () => {
  return getData(companyAccountSubscription.hasActiveSubscription);
};
