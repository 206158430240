import { POST } from "../network"
import { accountInvitesEndpoints } from "../requestEndpoints"
import { filtersToQuery } from "../../utils/filtersToQuery";

const setData = async (path, data) => {
    try {
      return await POST(path, data);
    } catch (e) {
      console.error(path, e);
    }
};

export const confirmInvite = async (code) => {
    return await setData(accountInvitesEndpoints.confirmInvite + filtersToQuery({code}))
}

export const rejectInvite = async (code) => {
    return await setData(accountInvitesEndpoints.rejectInvite + filtersToQuery({code}))
}