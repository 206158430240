import React, { useEffect, useContext, useState, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import GuideChimp from 'guidechimp';
import 'guidechimp/dist/guidechimp.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxOpen,
  faConciergeBell,
  faUser,
  faQuestionCircle,
  faFile,
  faFileAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import Wrapper from '../common/core/Wrapper';
import Menu from './Menu';
import TutorialAssistant from './TutorialAssistant';
import useVoiceIO from './VirtualAssistant/utils/useVoiceIO';
import styles from './style.module.css';
import Header from './Header';
import Products from './contents/Products';
import Documents from './contents/Documents';
import Services from './contents/Services';
import Customers from './contents/Customers';
import TabContext, { TAB_PATHS } from '../../contexts/TabContext';
import { useTabContextValue } from '../../contexts/TabContext';
import Partners from './contents/Partners';
import Market from './contents/Market';
import Competitors from './contents/Competitors';
import { PulseLoader } from 'react-spinners';
import {
  AssistantContext,
  useAssistant,
} from '../../contexts/AssistantContext';
import { TutorialContext } from '../../contexts/TutorialContext';
import EditUserInfo from './UserSettings';
import DataContext, { useLoadedFields } from '../../contexts/DataContext';
import Templates from './contents/Templates';
import Editor from './Editor';
import ElectById from '../common/core/ElectById';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HowTo from '../HowTo';
import { useTranslation } from '../../contexts/LocaleContext';
import ChangePassword from '../ChangePassword';
import GuideContext, { GuideChecklist, useGuide } from '../Onboarding';
import CompanyDashboard from './contents/CompanyDashboard';
import Breadcrumbs from '../common/parts/Breadcrumbs';

const HEADER_HEIGHT = 60;
const LEFT_SIDE_WIDTH = 250;

function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { account, company, subscription } = useContext(DataContext);

  const accountWithNoCompanyFields = [
    'account',
    'departmentsCompany',
    'assistant',
    'subscription',
  ];

  const accountWithCompanyFields = [
    'account',
    'business',
    'company',
    'assistant',
    'billing',
    'templates',
    'departmentsCompany',
    'subscription',
    'pricesList',
  ];

  const fields = useMemo(() => {
    return account.isLoaded &&
      account.data?.activ_company &&
      account.data.activ_company !== 0
      ? accountWithCompanyFields
      : accountWithNoCompanyFields;
  }, [account.isLoaded]);
  const { isLoaded: isFieldsLoaded } = useLoadedFields(fields);

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (fields.includes('company')) {
        setIsLoaded(
          account.isLoaded &&
            isFieldsLoaded &&
            company.isLoaded &&
            !!company?.data?.name
        );
      } else {
        setIsLoaded(account.isLoaded && isFieldsLoaded);
      }
    }, 300);
    return () => clearTimeout(timeout);
  }, [account.isLoaded, fields, isFieldsLoaded, company]);

  useEffect(() => {
    if (!fields.includes('company')) {
      return;
    }

    if (isFieldsLoaded && subscription.isLoaded && !subscription.data) {
      navigate('/payment');
    }
  }, [fields, isFieldsLoaded, subscription]);

  const tabRoutes = [
    {
      name: t('company_dashboard'),
      id: 'tutorial-step-1',
      exact: false,
      path: TAB_PATHS.COMPANY_DASHBOARD,
      content: <CompanyDashboard />,
      Icon: CorpIcon,
    },
    {
      name: t('templates'),
      id: 'tutorial-step-2',
      exact: true,
      path: TAB_PATHS.TEMPLATES,
      paths: [`${TAB_PATHS.TEMPLATES}/:id`, TAB_PATHS.TEMPLATES],
      content: <Templates />,
      Icon: () => <FontAwesomeIcon icon={faFile} width={16} />,
    },
    {
      name: t('documents'),
      id: 'tutorial-step-3',
      path: TAB_PATHS.DOCUMENTS,
      paths: [`${TAB_PATHS.DOCUMENTS}/:id`, TAB_PATHS.DOCUMENTS],
      content: <Documents />,
      Icon: () => <FontAwesomeIcon icon={faFileAlt} width={16} />,
    },
    {
      name: t('products'),
      id: 'tutorial-step-4',
      path: TAB_PATHS.PRODUCTS,
      paths: [`${TAB_PATHS.PRODUCTS}/:id`, TAB_PATHS.PRODUCTS],
      content: <Products />,
      Icon: () => <FontAwesomeIcon icon={faBoxOpen} width={16} />,
    },
    {
      name: t('services'),
      id: 'tutorial-step-5',
      path: TAB_PATHS.SERVICES,
      paths: [`${TAB_PATHS.SERVICES}/:id`, TAB_PATHS.SERVICES],
      content: <Services />,
      Icon: () => <FontAwesomeIcon icon={faConciergeBell} width={16} />,
    },
    {
      name: t('customers'),
      id: 'tutorial-step-6',
      path: TAB_PATHS.CUSTOMERS,
      paths: [`${TAB_PATHS.CUSTOMERS}/:type/:id`, TAB_PATHS.CUSTOMERS],
      content: <Customers />,
      Icon: () => <FontAwesomeIcon icon={faUser} width={16} />,
    },
    {
      name: t('partners'),
      id: 'tutorial-step-7',
      path: TAB_PATHS.PARTNERS,
      paths: [`${TAB_PATHS.PARTNERS}/:id`, TAB_PATHS.PARTNERS],
      content: <Partners />,
      Icon: () => <FontAwesomeIcon icon={faUsers} width={16} />,
    },
    {
      name: t('How_to'),
      id: 'tutorial-step-8',
      path: TAB_PATHS.HOW_TO,
      content: <HowTo />,
      Icon: () => <FontAwesomeIcon icon={faQuestionCircle} width={16} />,
    },
  ];

  return isLoaded ? (
    <DashboardContent tabRoutes={tabRoutes}>
      <Outlet />
    </DashboardContent>
  ) : (
    <Loading />
  );
}

export const Loading = () => (
  <Wrapper
    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
  >
    <PulseLoader color={'#554590'} size={15} />
  </Wrapper>
);

const toastContainer = (
  <ToastContainer hideProgressBar={true} autoClose={8000} />
);

const DashboardContent = ({ tabRoutes, children }) => {
  const { t } = useTranslation();
  const { account } = useContext(DataContext);
  const { setProfileSettingsNavigation, setOpenTutorial, open } =
    useContext(TutorialContext);
  const [showTutorialAssistant, setShowTutorialAssistant] = useState(false);
  const tabContextValue = useTabContextValue();

  const { player, initialized } = useVoiceIO();

  const [scheduledText, setScheduledText] = useState(null);

  useEffect(() => {
    if (scheduledText && initialized) {
      player.play(scheduledText);
      setScheduledText(null);
    }
  }, [scheduledText, initialized]);

  useEffect(() => {
    if (account.data.hascamefirsttime && account.data.activ_company) {
      setOpenTutorial(true);
    }
  }, []);

  const onItemClick = (path) => {
    tabContextValue.navigate(path);
  };

  const profileSettingsTutorialNav = (index) => {
    onItemClick(TAB_PATHS.SETTINGS);
    setProfileSettingsNavigation(index);
  };

  const tour = [
    {
      element: '#tutorial-step-1',
      title: t('company_dashboard'),
      description: t('1_web_tutorial_dashboard'),
      onBeforeChange: () => onItemClick(TAB_PATHS.COMPANY_DASHBOARD),
      onAfterChange: () =>
        setScheduledText(t('1_web_tutorial_dashboard_speech')),
    },
    {
      element: '#tutorial-step-2',
      title: t('templates'),
      description: t('2_web_tutorial_templates'),
      onBeforeChange: () =>
        setScheduledText(t('2_web_tutorial_templates_speech')),
      onAfterChange: () => onItemClick(TAB_PATHS.TEMPLATES),
    },
    {
      element: '#tutorial-step-3',
      title: t('documents'),
      description: t('3_web_tutorial_documents'),
      onBeforeChange: () =>
        setScheduledText(t('3_web_tutorial_documents_speech')),
      onAfterChange: () => onItemClick(TAB_PATHS.DOCUMENTS),
    },
    {
      element: '#tutorial-step-4',
      title: t('products'),
      description: t('4_web_tutorial_products'),
      onBeforeChange: () =>
        setScheduledText(t('4_web_tutorial_products_speech')),
      onAfterChange: () => onItemClick(TAB_PATHS.PRODUCTS),
    },
    {
      element: '#tutorial-step-5',
      title: t('services'),
      description: t('5_web_tutorial_services'),
      onBeforeChange: () =>
        setScheduledText(t('5_web_tutorial_services_speech')),
      onAfterChange: () => onItemClick(TAB_PATHS.SERVICES),
    },
    {
      element: '#tutorial-step-6',
      title: t('customers'),
      description: t('6_web_tutorial_customers'),
      onBeforeChange: () =>
        setScheduledText(t('6_web_tutorial_customers_speech')),
      onAfterChange: () => onItemClick(TAB_PATHS.CUSTOMERS),
    },
    {
      element: '#tutorial-step-7',
      title: t('partners'),
      description: t('7_web_tutorial_partners'),
      onBeforeChange: () =>
        setScheduledText(t('7_web_tutorial_partners_speech')),
      onAfterChange: () => onItemClick(TAB_PATHS.PARTNERS),
    },
    {
      element: '#tutorial-step-8',
      title: t('How_to'),
      description: t('8_web_tutorial_how_to'),
      onBeforeChange: () => setScheduledText(t('8_web_tutorial_how_to_speech')),
      onAfterChange: () => onItemClick(TAB_PATHS.HOW_TO),
    },
    {
      element: '#tutorial-step-9',
      title: t('profile'),
      description: t('9_web_tutorial_personal_profile'),
      onBeforeChange: () => profileSettingsTutorialNav(0),
      onAfterChange: () =>
        setScheduledText(t('9_web_tutorial_personal_profile_speech')),
    },
    {
      element: '#tutorial-step-10',
      title: t('company'),
      description: t('10_web_tutorial_company_profile'),
      onBeforeChange: () => profileSettingsTutorialNav(1),
      onAfterChange: () =>
        setScheduledText(t('10_web_tutorial_company_profile_speech')),
    },
    {
      element: '#tutorial-step-11',
      title: t('assistant'),
      description: t('11_web_tutorial_assistant_profile'),
      onBeforeChange: () => profileSettingsTutorialNav(2),
      onAfterChange: () =>
        setScheduledText(t('11_web_tutorial_assistant_profile_speech')),
    },
    {
      element: '#tutorial-step-12',
      title: t('billing'),
      description: t('12_web_tutorial_billing'),
      onBeforeChange: () => profileSettingsTutorialNav(4),
      onAfterChange: () =>
        setScheduledText(t('12_web_tutorial_billing_speech')),
    },
    {
      element: '#tutorial-step-13',
      title: t('search'),
      description: t('13_web_tutorial_adv_search'),
      onBeforeChange: () => setProfileSettingsNavigation(null),
      onAfterChange: () =>
        setScheduledText(t('13_web_tutorial_adv_search_speech')),
    },
    {
      element: '#tutorial-step-14',
      title: t('fastboss_assistance'),
      description: t('14_web_tutorial_assistant'),
      onAfterChange: () =>
        setScheduledText(t('14_web_tutorial_assistant_speech')),
    },
    {
      element: '#tutorial-step-15',
      title: t('Departments'),
      description: t('15_web_tutorial_select_department'),
      onAfterChange: () =>
        setScheduledText(t('15_web_tutorial_select_department_speech')),
    },
    {
      element: '#tutorial-step-16',
      title: t('learn_to_use_fastboss'),
      description: t('16_web_tutorial_use_step_by_step'),
      position: 'top',
      onAfterChange: () =>
        setScheduledText(t('16_web_tutorial_use_step_by_step_speech')),
    },
  ];

  const tourOptions = {
    position: 'right',
    exitOverlay: false,
  };
  const guidechimp = GuideChimp(tour, tourOptions);

  guidechimp.on('onStart', () => {
    setShowTutorialAssistant(true);
  });

  guidechimp.on('onStop', () => {
    player.stop();
    setShowTutorialAssistant(false);
    setOpenTutorial(false);

    account.data.hascamefirsttime &&
      account.set({
        hascamefirsttime: false,
      });
  });

  useEffect(() => {
    open && guidechimp.start();
  }, [open]);

  const assistant = useAssistant();

  const { company } = useContext(DataContext);
  const isCompanyCreated = useMemo(
    () => company?.isLoaded && !!company?.data?.name,
    [company]
  );

  const elements = [
    {
      id: 'main',
      component: (
        <>
          <Breadcrumbs />
          {children}
        </>
      ),
    },
    {
      id: 'editor',
      component: (
        <div className={styles.editorWrapper}>
          <Editor />
        </div>
      ),
    },
  ];

  return (
    <TabContext.Provider value={tabContextValue}>
      <GuideChecklist />
      <TutorialAssistant show={showTutorialAssistant} />
      <AssistantContext.Provider value={assistant}>
        <Wrapper className={styles.wrapper}>
          <Header
            height={HEADER_HEIGHT}
            leftSideWidth={LEFT_SIDE_WIDTH}
            openAssistant={(query) => assistant.open(null, query)}
          />
          <div className={styles.main}>
            <Menu
              items={tabRoutes}
              width={LEFT_SIDE_WIDTH}
              style={{ display: isCompanyCreated ? 'flex' : 'none' }}
            />
            <ElectById
              activeElementId={tabContextValue.isEditorOpen ? 'editor' : 'main'}
              containerClassName={styles.mainAndEditor}
            >
              {elements}
            </ElectById>
          </div>
        </Wrapper>
        {assistant.assistant}
        {toastContainer}
      </AssistantContext.Provider>
    </TabContext.Provider>
  );
};

export default Dashboard;

const CorpIcon = ({ size, color }) => {
  return (
    <svg
      width={size}
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.44444 1H1V4.11111H6.44444V1Z"
        stroke={color}
        fill={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0001 1H9.55566V8.77778H15.0001V1Z"
        stroke={color}
        fill={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0001 11.8889H9.55566V15H15.0001V11.8889Z"
        stroke={color}
        fill={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.44444 7.22223H1V15H6.44444V7.22223Z"
        stroke={color}
        fill={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
