import React, { createContext, useState, useEffect } from "react"

const contextShape = {
    open: false,
    profile_settings_tab: null, 
    setProfileSettingsNavigation: () => {},
    setOpenTutorial: () => {}
}

export const TutorialContext = createContext(contextShape)

export const useTutorialContext = () => {
    const [state, setState] = useState(contextShape)

    const setProfileSettingsNavigation = (tabIndex) => {
        setState((prev) => ({...prev, profile_settings_tab: tabIndex}))
    }

    const setOpenTutorial = (boolean) => {
        setState((prev) => ({...prev, open: boolean}))
    }

    useEffect(() => {
        const setters = {
            setProfileSettingsNavigation,
            setOpenTutorial
        }
        const initialState = Object.assign(state, setters)
        setState(initialState)
    }, [])

    return state
}