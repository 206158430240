import React, {useState, useEffect, useContext} from 'react';
import styles from './style.module.css';
import cn from 'classnames';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Profile from "./contents/Profile";
import Wrapper from "../common/core/Wrapper";
import Button from "../common/core/Button";
import Image from "react-bootstrap/Image";
import {Form} from 'informed';
import logoImagePath from "./images/logo_sm.png";
import SignFooter from "../common/parts/SignFooter";
import Company from "./contents/Company";
import Business from "./contents/Business";
import withOnImagesLoadedRender from "../common/HOCs/withOnImagesLoadedRender";
import ElectByIndex from "../common/core/ElectByIndex";
import NetworkContext from "../../contexts/NetworkContext";
import {POST} from "../../api/network";
import {BusinessModel, CompanyModel} from "../../contexts/utils/DataModels";
import {useTranslation} from "../../contexts/LocaleContext";
import {Navigate} from "react-router-dom";

const WIDTH = 700;
const BAR_UNIT_WIDTH = 70;
const UNIT_ROUND = 30;


const FinalStepContent = () => {
  const [waiting, setWaiting] = useState(false);
  const networkContext = useContext(NetworkContext);

  const { loggedIn, isReady: isNetworkReady } = networkContext;

  const {t} = useTranslation()

    if (loggedIn) return <Navigate to={'/dashboard/company-dashboard'} />

  return (
    <div className={styles.final}>
      <div className={styles.message}>
          {t("check_your_email_to_confirm_registration")+' : '+networkContext.registerTempData.workemail}
      </div>
      <Button
        onClick={() => {
          //TODO
          if (waiting) return;
          setWaiting(true);
          setTimeout(() => networkContext.onLogin(), 100);
        }}
        waiting={waiting}
        containerClassName={styles.start}
        title={t('start_using_fastboss')}
      />
    </div>
  );
};

const SignUp = () => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [filledSteps, setFilledSteps] = useState([]);

  const registerTempData = useContext(NetworkContext).registerTempData;

  const [data, setData] = useState([]);
  const addData = (newData) => setData(data.concat(newData));

  const [isWaiting, setWaiting] = useState(false);

  const {t} = useTranslation()

    const STEPS = [
        {
            name: t('profile'),
            content: <Profile/>,
        },{
            name: t('company'),
            content: <Company/>,
        }/*,{
            name: t('business'),
            content: <Business/>,
        },*/
    ];

    const LAST_STEP_INDEX = STEPS.length-1;
    const FINAL_STEP_INDEX = LAST_STEP_INDEX+1;

  const register = async () => {
    //send data
    console.log(data);
    setWaiting(true);

    const [accountData, companyData, businessData] = data;

    // TODO OOOOOOOOOOOOO0000000000000000000000ooooooooo (later) (maybe never)
    const accountRes = Boolean(accountData) && await POST('/api/Account/update', accountData);
    const companyRes = await POST('/api/Company/create', companyData || CompanyModel.default);
    const businessRes = await POST('/api/Business/create', businessData || BusinessModel.default);

    const res = [accountRes, companyRes, businessRes];
    console.log(res);

    res.forEach(res => {
      if (res === null) {
        // alert('Unknown error');
      } else if (res && res.status !== 200) {
        // alert(res.status + ' ' + res.message);
        console.log(res.body)
      }
    });

    setWaiting(false);
  };

  useEffect(() => {
    if (activeStepIndex === FINAL_STEP_INDEX) register();
  }, [activeStepIndex]);

  const onStepSubmit = (formState) => {
    if (activeStepIndex === 0) {
      // TODO ask добавляем ЭобязательныеЭ поля
      formState = Object.assign(registerTempData, formState);
    } else if (activeStepIndex === 1) {
      // TODO ASK тоже такой себе фикс
      formState = Object.assign({phone: 'why', email: 'why@w.w'}, formState);
    }
    addData(formState);
    setFilledSteps([...filledSteps, true]);
    nextStep();
  };

  const onSkipStep = () => {
    addData(null);
    setFilledSteps([...filledSteps, false]);
    nextStep();
  };

  const nextStep = () => {
    setActiveStepIndex(activeStepIndex+1);
    window.scrollTo({top: 0});
  };

  const forms = STEPS.map(step => (
    <Form
      onSubmit={onStepSubmit}
    >
      {step.content}
      <Row className={styles.buttonContainer}>
        {true && <Button
          containerClassName={styles.skip}
          className={styles.button}
          color={'#C9CBCA'}
          onClick={onSkipStep}
          type={'button'}
          title={t("fill_in_later")}
        />}
        <Button
          containerClassName={styles.next}
          className={styles.button}
          type={'submit'}
          title={activeStepIndex === LAST_STEP_INDEX ? t("register") : t("next_step")}
          waiting={isWaiting}
        />
      </Row>
    </Form>
  ));

  const content = <ElectByIndex
    index={activeStepIndex}
    defaultComponent={<FinalStepContent/>}
    className={styles.form}
  >{forms}</ElectByIndex>;

  return (
    <Wrapper className={cn(styles.wrapper)}>
      <Row className={styles.hat}>
        <Image height={40} className={styles.logoImage} src={logoImagePath}/>
      </Row>
      <Container className={styles.main} style={{width: WIDTH}}>
        <Row className={styles.header}>
          {activeStepIndex !== FINAL_STEP_INDEX ?
            t("registration_form")
            : t("congratulations")}
        </Row>

        <ProgressBar stepIndex={activeStepIndex} filledSteps={filledSteps}/>

        {content}

        <SignFooter className={styles.footer}/>

      </Container>
    </Wrapper>
  );
};

const ProgressBar = ({stepIndex, filledSteps}) => {
    const {t} = useTranslation()
    const STEPS = [
        {
            name: t('profile'),
            content: <Profile/>,
        },{
            name: t('company'),
            content: <Company/>,
        }/*,{
            name: t('business'),
            content: <Business/>,
        },*/
    ];

    return(
        <Row className={styles.barContainer} style={{width: WIDTH + BAR_UNIT_WIDTH}}>
            <Row
                className={styles.barLine}
                style={{
                    top: UNIT_ROUND / 2,
                    left: BAR_UNIT_WIDTH / 2,
                    width: WIDTH
                }}
            >{}</Row>
            {STEPS.map((step, i) => (
                <Unit key={step.name} text={step.name} complete={i < stepIndex && filledSteps[i]}
                      current={i === stepIndex}/>
            ))}
        </Row>
    )
};

const Unit = ({text, complete, current}) => (
  <div className={styles.barUnitContainer} style={{width: BAR_UNIT_WIDTH}}>
    <div
      className={cn(styles.barUnitRound, current && styles.current)}
      style={{width: UNIT_ROUND, height: UNIT_ROUND}}
    >
      {complete && <Check/>}
    </div>
    <div className={styles.barUnitText}>{text}</div>
  </div>
);

const Check = () => (
  <div
    className={styles.check}
    style={{
      width: UNIT_ROUND / 2,
      height: UNIT_ROUND / 3,
      marginTop: - UNIT_ROUND / 5,
    }}
  />
);

export default withOnImagesLoadedRender(SignUp);
