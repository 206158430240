import React, {useContext, useEffect, useMemo, useState} from 'react'
import {PATHS, useQuery} from "../../index";
import CorporateContext from "../../CorporateContext";
import {getById, idToColor} from "../../../../../../utils/StringUtils";
import {SectionHeader} from "../Dashboard";
import {useTranslation} from "../../../../../../contexts/LocaleContext";
import styles from './styles.module.css'
import {Graph} from "react-d3-graph";
import useNavigation from "../../../../../common/hooks/useNavigation";
import SlideUpScreen from "../../common/SlideUpScreen";

const _node = `data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJ1c2VyLWNpcmNsZSIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLXVzZXItY2lyY2xlIGZhLXctMTYiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNDk2IDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMjQ4IDhDMTExIDggMCAxMTkgMCAyNTZzMTExIDI0OCAyNDggMjQ4IDI0OC0xMTEgMjQ4LTI0OFMzODUgOCAyNDggOHptMCA5NmM0OC42IDAgODggMzkuNCA4OCA4OHMtMzkuNCA4OC04OCA4OC04OC0zOS40LTg4LTg4IDM5LjQtODggODgtODh6bTAgMzQ0Yy01OC43IDAtMTExLjMtMjYuNi0xNDYuNS02OC4yIDE4LjgtMzUuNCA1NS42LTU5LjggOTguNS01OS44IDIuNCAwIDQuOC40IDcuMSAxLjEgMTMgNC4yIDI2LjYgNi45IDQwLjkgNi45IDE0LjMgMCAyOC0yLjcgNDAuOS02LjkgMi4zLS43IDQuNy0xLjEgNy4xLTEuMSA0Mi45IDAgNzkuNyAyNC40IDk4LjUgNTkuOEMzNTkuMyA0MjEuNCAzMDYuNyA0NDggMjQ4IDQ0OHoiPjwvcGF0aD48L3N2Zz4=`
const Flows = () => {
    // const query = useQuery()
    const {t} = useTranslation()
    const {flows, departments} = useContext(CorporateContext)

    const twoSideFlows = useMemo(() => {
        let backFlowIds=[]
        const mappedFlows = flows.data?.map((flow)=>{
            if (backFlowIds.includes(flow.flow_item_id))
                return null
            let foundBackFlowId = null
            const hasBackFlow = !!flows.data.find(({flow_item_id, department_to_id, department_from_id})=>{
                const _hasBackFlow = department_to_id === flow.department_from_id &&
                    department_from_id === flow.department_to_id // determines whether the flow item has the symmetrical flow
                if (_hasBackFlow) { // collects id of the reversed flow to filter them later
                    backFlowIds = [...backFlowIds, flow_item_id]
                    foundBackFlowId = flow_item_id
                }
                return _hasBackFlow
            })
            return {
                ids: [flow.flow_item_id, foundBackFlowId].filter(id=>!!id), // [flowA, flowB] if flowB is the reversed, otherwise [flowA]
                fromId: flow.department_from_id,
                toId: flow.department_to_id,
            }
        })

        return mappedFlows?.filter(item=>!!item)
    }, [flows])

    return (
        <SlideUpScreen className={styles.flowsWrapper} show={flows.isLoaded && departments.isLoaded}>
            <FlowChart/>
        </SlideUpScreen>
    )
}

const FlowItem = ({fromId, toId, ids=[]}) => {
    const {departments} = useContext(CorporateContext)
    const {nav, PATHS} = useNavigation()

    function navigateToDepartment (id) {
        return () => {
            nav(PATHS.STRUCTURES, 'departments', id)
        }
    }
    return <div className={styles.flowItem}>
        <div className={styles.flowTarget} onClick={navigateToDepartment(fromId)}>{getById(departments.data, fromId)?.name}</div>
        <div className={styles.flowType}>
            <i className={ids.length===2 ? 'fas fa-arrows-alt-h' :'fas fa-long-arrow-alt-right'}/>
        </div>
        <div  className={styles.flowTarget} onClick={navigateToDepartment(toId)}>
            {getById(departments.data, toId)?.name}
        </div>
    </div>
}

const DepartmentSmall = ({id}) => {
    return <div className={styles.departmentWrapper}>
        <div className={styles.departmentTitle}>

        </div>
    </div>
}

const FlowChart = () => {
    const {role, flows, departments} = useContext(CorporateContext)
    const {nav, PATHS} = useNavigation()

    const GRAPH_DIMS = {
        W: 400,
        H: 300,
    }

    const getData = () => {
        let nodes = (departments.data || [])?.map(({id, name}, index)=>({
            id,
            name,
            "strokeColor": idToColor(id),
            color: idToColor(id),
            x: Math.round((GRAPH_DIMS.W/2)+Math.cos(index/departments.data?.length*Math.PI*2)*(GRAPH_DIMS.W/3)),
            y: Math.round((GRAPH_DIMS.H/2)+Math.sin(index/departments.data?.length*Math.PI*2)*(GRAPH_DIMS.H/3))
        }))
        let links = (flows.data || []).map(({department_from_id, department_to_id})=>({
            source: department_from_id,
            target: department_to_id,
        }))
        let missingNodes = nodes.filter(({id})=>!links.find(({source, target})=>(source===id || target === id))).map(node=>{
            return {
                ...node,
                size: 160,
                color: idToColor(node?.id),
                strokeWidth: 16,
            }
        })
        let linkedNodes = nodes.filter(({id})=>links.find(({source, target})=>(source===id || target === id)))

        let missingLinks = missingNodes?.map(
            ({id}, index)=>({
                source: id,
                target: missingNodes[index+1]?.id || missingNodes[0]?.id,
                color: 'transparent',
                type: 'STRAIGHT',
                opacity: 0,
                "markerHeight": 0,
                "markerWidth": 0,
            })
        ) || []
        return {
            nodes: [...missingNodes, ...linkedNodes], links: [...links, ...missingLinks]
        }
    }

    const [data,setData] = useState({nodes:[{id: 0, name: ''}], links: []})


    const _data = {
        nodes: [
            {id: 'Harry'},
            {id: 'Sally'},
            {id: 'Alice'}
        ],
        links: [
            {source: 'Harry', target: 'Sally'},
            {source: 'Harry', target: 'Alice'},
        ]
    };

    useEffect(()=>{
        if (flows.isLoaded && departments.isLoaded)
            setTimeout(()=>setData(getData()), 50)
    },[flows.data, departments.data])


// the graph configuration, just override the ones you need
    const myConfig = {
        "automaticRearrangeAfterDropNode": true,
        "collapsible": false,
        "directed": true,
        "focusAnimationDuration": 0.75,
        "focusZoom": 1,
        "freezeAllDragEvents": false,
        "height": GRAPH_DIMS.H,
        "highlightDegree": 1,
        "highlightOpacity": 0.2,
        "linkHighlightBehavior": false,
        "maxZoom": 1,
        "minZoom": 1,
        "nodeHighlightBehavior": false,
        "panAndZoom": true,
        "staticGraph": false,
        "staticGraphWithDragAndDrop": false,
        "width": GRAPH_DIMS.W,
        "d3": {
            "alphaTarget": 1,
            "gravity": -200,
            "linkLength": 300,
            "linkStrength": 100,
            "disableLinkForce": false
        },
        "node": {
            "color": "#fff",
            "fontColor": "black",
            "fontSize": 12,
            "fontWeight": "bold",
            "highlightColor": "red",
            "highlightFontSize": 12,
            "highlightFontWeight": "bold",
            "highlightStrokeColor": "SAME",
            "highlightStrokeWidth": 1.5,
            "labelProperty": "name",
            "labelPosition": "right",
            "mouseCursor": "pointer",
            "opacity": 1,
            "renderLabel": true,
            "size": 600,
            "strokeColor": "#8A1D70",
            "strokeWidth": 3,
            "symbolType": "circle"
        },
        "link": {
            "color": "#5b5b5b",
            "fontColor": "red",
            "fontSize": 10,
            "fontWeight": "normal",
            "highlightColor": "blue",
            "highlightFontSize": 8,
            "highlightFontWeight": "bold",
            "mouseCursor": "pointer",
            "opacity": 1,
            "renderLabel": false,
            "semanticStrokeWidth": false,
            "strokeWidth": 1,
            "markerHeight": 6,
            "markerWidth": 6,
            "strokeDasharray": 2,
            "strokeLinecap": "round",
            "type": 'CURVE_SMOOTH'
        }
    }

    const config={

    }
    const onClickNode = function(nodeId) {
        if (role.data === "Employee") {
            return;
        }
        nav(PATHS.STRUCTURES, 'departments', nodeId)
       // window.alert(`Clicked node ${nodeId}`);
    };

   /* const onClickLink = function(source, target) {
        window.alert(`Clicked link between ${source} and ${target}`);
    };
*/

    return (
        <div className={styles.graphContainer}>
            <Graph
                id={"flow-chart"} // id is mandatory
                data={data}
                onClickNode={onClickNode}
                config={myConfig}
            />
        </div>
    )
}

export default Flows
