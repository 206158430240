import React, { useContext, useRef } from "react";
import { useEffect, useReducer } from "react";
import Enum from "../utils/enum";
import {useLocation, useNavigate, NavLink} from "react-router-dom";
import DataContext from "./DataContext";
import { toast } from "react-toastify";
import {Info, Link, TabLink} from "../components/common/core/Toasts";
import {useTranslation} from "./LocaleContext";

// TODO SPLIT TO SEPARATE CONTEXTS OR EVEN REMOVE ONE)

export const TAB_PATHS = {
  COMPANY_DASHBOARD: '/dashboard/company-dashboard',
  TEMPLATES: "/dashboard/templates",
  DOCUMENTS: "/dashboard/documents",
  PRODUCTS: "/dashboard/products",
  SERVICES: "/dashboard/services",
  TARGET_MARKET: "/dashboard/markets",
  CUSTOMERS: "/dashboard/customers",
  PARTNERS: "/dashboard/partners",
  COMPETITORS: "/dashboard/competitors",
  SETTINGS: "/dashboard/settings",
  HOW_TO: "/dashboard/how-to"
};

export const INIT_TAB_PATH = TAB_PATHS.COMPANY_DASHBOARD;

const contextShape = {
  tabPath: TAB_PATHS.TEMPLATES,
  setTabPath: () => {},
  navigate: () => {},

  isEditorOpen: false,
  openEditor: () => {},
  closeEditor: () => {},
  editorTitle: "",
  editorKeywords: "",
  editorContent: "",
  editorIsPublic: false,
  documentToken: null,
  statusType: null,
  editorType: null,
  saveEditor: () => {}
};

export const EditorType = {
  CREATE_TEMPLATE: 0,
  EDIT_TEMPLATE: 1,
  CREATE_DOCUMENT: 2,
  EDIT_DOCUMENT: 3
};

function isTemplate(editorType) {
  return (
    editorType === EditorType.CREATE_TEMPLATE ||
    editorType === EditorType.EDIT_TEMPLATE
  );
}
function isDocument(editorType) {
  return (
    editorType === EditorType.CREATE_DOCUMENT ||
    editorType === EditorType.EDIT_DOCUMENT
  );
}

const [SET_TAB_PATH, OPEN_EDITOR, CLOSE_EDITOR, SAVE_CREATED] = Enum(4);

function reducer(
  state,
  { type, tabPath, editorContent, editorTitle, editorType, editorKeywords, editorIsPublic, documentToken, id, statusType }
) {
  switch (type) {
    case SET_TAB_PATH:
      return { ...state, tabPath };
    case OPEN_EDITOR:
      return {
        ...state,
        isEditorOpen: true,
        editorTitle,
        editorKeywords,
        editorContent,
        editorType,
        editorIsPublic,
        documentToken,
        id,
        statusType
      };
    case CLOSE_EDITOR:
      return {
        ...state,
        isEditorOpen: false,
        editorTitle: "",
        editorContent: "",
        editorKeywords: "",
        editorIsPublic: false,
        documentToken: null,
        editorType: null,
        statusType: null
      };
    case SAVE_CREATED:
      return {
        ...state,
        editorType,
        id
      };
    default:
      return state;
  }
}

export function useTabContextValue() {
  const { templates, documents } = useContext(DataContext);
  const {t} = useTranslation()
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname) {
      const a = pathname.split("/");
      const tabPath = "/" + a[a.length - 1];
      state.setTabPath(tabPath);
    }
  }, [pathname]);

  const routeNavigate = useNavigate();

  const navigate = (path, closeEditor = true) => {
    if (closeEditor) dispatch({ type: CLOSE_EDITOR });
    routeNavigate(path);
  };

  // todo the same problem as in assistant
  const editorTypeRef = useRef(null);
  const idRef = useRef(null);
  const saveEditor = async data => {
    const editorType = editorTypeRef.current;
    const id = idRef.current;

    switch (editorType) {
      case EditorType.CREATE_TEMPLATE:
        const createdTemplate = await templates.create(data);
        dispatch({
          type: SAVE_CREATED,
          editorType: EditorType.EDIT_TEMPLATE,
          id: createdTemplate.id
        });
        break;
      case EditorType.EDIT_TEMPLATE:
        await templates.update({ id, ...data });
        break;
      case EditorType.CREATE_DOCUMENT:
        const createdDoc = await documents.create(data);
        dispatch({
          type: SAVE_CREATED,
          editorType: EditorType.EDIT_DOCUMENT,
          id: createdDoc.id
        });
        break;
      case EditorType.EDIT_DOCUMENT:
        await documents.update({ id, ...data });
        break;
      default:
        break;
    }

    toast(<Info> {`${isTemplate(editorType) ? t('template') : t('document')} "${
      data.title
    }" ${t('saved')}`}<TabLink to={isTemplate(editorType) ? "/templates" : "/documents"}>{t('see')}</TabLink>
    </Info>)
  };

  const initState = {
    ...contextShape,
    tabPath: TAB_PATHS.TEMPLATES,
    setTabPath: tabPath => {
      dispatch({ type: SET_TAB_PATH, tabPath });
    },
    openEditor: ({
      id,
      editorTitle = "",
      editorContent = "",
      editorType,
      editorKeywords = "",
      editorIsPublic = false,
      documentToken = null,
      statusType
    }) => {
      if (editorType === EditorType.CREATE_DOCUMENT) {
        dispatchCallback.current = () => {
          saveEditor({ title: editorTitle, content: editorContent });
        };
      }
      dispatch({
        type: OPEN_EDITOR,
        id,
        editorTitle,
        editorKeywords,
        editorContent,
        editorType,
        editorIsPublic,
        documentToken,
        statusType
      });
      if (isTemplate(editorType)) {
        navigate(TAB_PATHS.TEMPLATES, false);
      } else if (isDocument(editorType)) {
        navigate(TAB_PATHS.DOCUMENTS, false);
      }
    },
    closeEditor: () => {
      dispatch({ type: CLOSE_EDITOR });
    },
    saveEditor,
    navigate
  };

  const dispatchCallback = useRef(() => {});
  const [state, dispatch] = useReducer(reducer, initState);

  useEffect(() => {
    editorTypeRef.current = state.editorType;
    idRef.current = state.id;
    dispatchCallback.current();
    dispatchCallback.current = () => {};
    Object.entries(state).map(([key, value]) => contextShape[key] = value);
  }, [state]);

  return state;
}

const TabContext = React.createContext(contextShape);

export default TabContext;
