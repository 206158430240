import React, {useContext} from "react";
import EditableTable from '../../../common/parts/EditableTable';
import "react-table/react-table.css";
import {useLoaded} from "../../../../contexts/DataContext";
import FetchLoading from "../../../common/parts/FetchLoading";
import {getPagedProductsData} from "../../../../api/Product/dataGetters";
import {useTranslation} from "../../../../contexts/LocaleContext";

const columns = [{
  Header: 'CODE#',
  accessor: 'id',
  width: 65,
  index: 0,
},{
  Header: 'NAME',
  accessor: 'name',
  filter: true,
  width: 120,
  index: 1,
},{
  Header: 'PRICE',
  accessor: 'price',
  filter: true,
  width: 80,
  index: 2,
},{
  Header: 'U.M.',
  accessor: 'unitmeasure',
  filter: true,
  width: 80,
  index: 3,
},{
  Header: 'PRODUCER',
  accessor: 'producer',
  filter: true,
  width: 120,
  index: 4,
},{
  Header: 'GENERAL DESCRIPTION',
  accessor: 'generaldescription',
  filter: true,
  minWidth: 250,
  index: 5,
},{
  Header: 'DELIVERY',
  accessor: 'delivery',
  filter: true,
  width: 85,
  index: 6,
},{
  Header: 'KEYWORDS',
  accessor: 'keywords',
  filter: true,
  width: 100,
  index: 7,
},];

function ProductsTab ({openEditModal}) {
  const {isLoaded, products} = useLoaded('products');
  const {t} = useTranslation()
  return isLoaded ? (
    <EditableTable
      getter={getPagedProductsData}
      data={products.data}
      deleteItem={products.remove}
      editItem={openEditModal}
      columns={columns.map((item)=>({...item, Header: t(item.Header.toLowerCase().replaceAll(' ','_')).toUpperCase()}))}
      noWhatText={'products'}
    />
    ) : (
      <FetchLoading/>
    );
}


export default ProductsTab;
