import { POST, PUT, DELETE } from "../network";
import { companyAccountSubscription } from "../requestEndpoints";

const setData = async (path, data) => {
    try {
      return await POST(path, data);
    } catch (e) {
      console.error(path, e);
    }
};

const deleteData = async (path, data) => {
    try {
      return await DELETE(path, data)
    } catch (e) {
      console.error(path, e);
    }
};

const updateData = async (path, data) => {
    try {
      return await PUT(path, data);
    } catch (e) {
      console.error(path, e);
    }
};

export const createCustomer = async (data) => {
    await setData(companyAccountSubscription.createCustomer, data)
}

export const updateCustomer = async (data) => {
    await updateData(companyAccountSubscription.updateCustomer, data)
}

export const createPaymentMethod = async (data) => {
    await setData(companyAccountSubscription.createPaymentMethod, data)
}

export const deletePaymentMethod = async (paymentMethodId) => {
    await deleteData(companyAccountSubscription.deletePaymentMethod + paymentMethodId)
}

export const createSubscription = async (data) => {
    return await setData(companyAccountSubscription.createSubscription, data)
}

export const retryInvoice = async (data) => {
    await setData(companyAccountSubscription.retryInvoice, data)
}

export const cancelSubscription = async (subId) => {
    await setData(companyAccountSubscription.cancelSubscription + subId)
}

export const updateSubscription = async (data) => {
    await updateData(companyAccountSubscription.updateSubscription, data)
}

