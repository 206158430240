import React, {useContext} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Input from "../../common/core/Input"
import {TextArea, Scope} from "informed";
import {useTranslation} from "../../../contexts/LocaleContext";
import CorporateContext from "../contents/CompanyDashboard/CorporateContext";

// passing bool 'noHeader' to easy style it in UserSettings.
export default () => {
    const {t} = useTranslation()

    const data = useContext(CorporateContext)
    const toggle = () => {
        if (typeof(data.role.data) == typeof({a:1})) return false
        else if(data.role.data === 'CEO') return false
        else return true
    }

    return (
        <Row >
            <Row>
                <Col lg={'9'} sm={'6'} className={"m-0 pr-lg- text-center"}>
                    <Input field={'name'} placeholder={t('company_name')} required disabled={toggle()}/>
                    <Input field={'email'} type={'email'} placeholder={t('email')} required disabled={toggle()}/>
                    <Input field={'phone'} placeholder={t('phone')} required disabled={toggle()}/>


                    <Scope scope={'address'}>
                        <Row>
                            <Col lg={'8'} sm={'6'} className={"pr-lg-2"} >
                                <Input field={'street'} placeholder={t('street')} required disabled={toggle()}/>
                                <Input field={'city'} placeholder={t('city')} required disabled={toggle()}/>
                                <Input field={'country'} placeholder={t('country')} required disabled={toggle()}/>
                            </Col>
                            <Col lg={'4'} sm={'6'} className={"pl-lg-2"}>
                                <Input field={'building'} placeholder={t('building')} required disabled={toggle()}/>
                                <Input field={'index'} placeholder={t('index')} disabled={toggle()}/>
                                <Input field={'state'} placeholder={t('state')} disabled={toggle()}/>
                            </Col>
                        </Row>
                    </Scope>

                </Col>

            </Row>

            <Col className={'col-9'}>
                <Input required field={'companybankaccount'} placeholder={t("banking_details")} disabled={toggle()}/>
            </Col>


        </Row>
    )
};

