import moment from "moment";

export function capitalize(str) {
    return str[0].toUpperCase() + str.slice(1);
}

export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function isEmpty(obj) {
    return Object.entries(obj).length === 0;
}

export function exist(value) {
    return value !== undefined;
}

export function underscoresToSpaces(str='') {
    return str.split('_').join(' ');
}

export function spacesToUnderscores(str='') {
    return str.split(' ').join('_');
}

export function spacesToCommas(str='') {
    return str.split(' ').join(', ');
}

export function buildString(obj) {
    return Object.values(obj).join(' ');
}

export function formatDate(value) {
    return value ? moment(value).format("L") : moment().format("L");
}

export function addDaysToDate(date, days) {
    return moment(date).add(days, "day").format("D MMMM YYYY");
}

export function Option(value, label) {
    return {value, label};
}

export function sortByIdDesc(arr) {
    return [...arr].sort((a, b) => b.id - a.id);
}

export const getById = (arr=[], _id) => {
    const item = arr?.filter(({id})=>(id==_id))[0]
    return item
}

export const idToColor = (_id=0) =>{
    // const colors = ['#8A1D70', '#33ACC9', '#F87C18']
    // return colors[_id % colors.length]
    return '#E8BAD5';
}

export const addressToStr = (address) => {
    if (!address) return "Address";
    const fields = [
      address.building || "",
      address.street || "",
      address.city || "",
      address.state || "",
      address.index || "",
      address.country || "",
    ];
    return fields.filter((f) => !!f).join(", ");
};
