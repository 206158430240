import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Input from "../../common/core/Input";
import styles from '../style.module.css';
import {Scope} from "informed";
import {useTranslation} from "../../../contexts/LocaleContext";

// passing bool 'noHeader' to easy style it in UserSettings.
export default ({noHeader}) => {
  const {t} = useTranslation()
  return (
      <Row>
        {!noHeader &&
        <Row className={styles.subheader + ' mb-3'}>
          {t('your_company_description_and_contacts')}
        </Row>}
        <Row>
          <Col className={"col-12 m-0 pr-2 text-center"}>
            <Input field={'name'} placeholder={t('company_name')} required/>
            <Input field={'email'} type={'email'} placeholder={t('email')} required/>
            <Input field={'phone'} placeholder={t('phone')} required/>


            <Scope scope={'address'}>
              <Row>
                <Col className={"col-8 pr-2"}>
                  <Input field={'street'} placeholder={t('street')} required/>
                  <Input field={'city'} placeholder={t('city')} required/>
                  <Input field={'country'} placeholder={t('country')} required/>
                </Col>
                <Col className={"col-4 pl-2"}>
                  <Input field={'building'} placeholder={'№'} required/>
                  <Input field={'index'} placeholder={t('index')}/>
                  <Input field={'state'} placeholder={t('state')}/>
                </Col>
              </Row>
            </Scope>
          </Col>
        </Row>
      </Row>
  )
};

