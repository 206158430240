import React, { useEffect, useState, useContext } from 'react';
import styles from './search.module.css';
import { search } from '../../../../api/Commands/dataGetters';
import { useTranslation } from '../../../../contexts/LocaleContext';
import SearchWrapper from './SearchWrapper';
import { searchDocuments } from '../../../../api/Document/dataGetters';
import { formatDate } from '../../../../utils/StringUtils';
import { AssistantContext } from '../../../../contexts/AssistantContext';
import TabContext, { EditorType } from '../../../../contexts/TabContext';

const getTableDataFromResponse = (data, t, openDocument) => {
  const getStatusString = (statusNum = 0) => {
    switch (statusNum) {
      case 0:
        return t('opened');
      case 1:
        return t('not_opened');
      case 2:
        return t('awaiting_signature');
      case 3:
        return t('signed');
      default:
        return '';
    }
  };

  if (!data?.length) return [];
  return data.map((document, index) => {
    return {
      id: document.documentId || index,
      classNames: {
        bar: styles.documentBar,
      },
      columns: [
        {
          className: styles.documentName,
          element: document.title,
        },
        {
          className: styles.documentDate,
          element: formatDate(document.createdAt),
        },
        {
          className: styles.documentStatus,
          element: getStatusString(document.status),
        },
      ],
      relations: {
        products:
          document.relatedProducts &&
          document.relatedProducts.map((product) => {
            return {
              name: product.name,
              id: product.id,
              // todo decide which fields we need
            };
          }),
        services:
          document.relatedServices &&
          document.relatedServices.map((service) => {
            return {
              name: service.name,
              id: service.id,
              // todo decide which fields we need
            };
          }),
        clients:
          document.relatedClients &&
          document.relatedClients.map((client) => {
            return {
              name: client.name,
              id: client.id,
              // todo decide which fields we need
            };
          }),
        partners: [],
        templates: [],
      },
      onClick: openDocument(document),
    };
  });
};

const SearchDocuments = ({ query = '', onClose = () => {} }) => {
  const { t } = useTranslation();
  const { openEditor } = useContext(TabContext);
  const { close: closeAssistant } = useContext(AssistantContext);
  const [isLoading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [legend, setLegend] = useState({
    classNames: {
      bar: styles.documentLegendBar,
    },
    columns: [
      {
        icon: 'fas fa-file-alt',
        title: t('document_name'),
      },
      {
        icon: 'fas fa-calendar-day',
        title: t('date'),
      },
      {
        icon: 'fas fa-signature',
        title: t('status'),
      },
    ],
  });

  const openDocument = (document) => () => {
    closeAssistant();
    openEditor({
      id: document.id,
      editorTitle: document.title,
      editorContent: document.content,
      editorKeywords: document.keywords,
      statusType: document.document_status_type,
      editorType: EditorType.EDIT_TEMPLATE,
    });
  };

  useEffect(() => {
    setLoading(true);
    search(query).then((response) => {
      setTableData(getTableDataFromResponse(response, t, openDocument));
      setLoading(false);
    });
  }, []);

  return (
    <SearchWrapper
      query={query}
      legend={legend}
      tableData={tableData}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default SearchDocuments;
