import React from 'react';

const ChevronRightIcon = ({ ...props }) => (
  <svg
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.75 10.5L5.25 6L0.75 1.5"
      stroke="#070F17"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ChevronRightIcon;
