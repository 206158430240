import DashboardTabs from "../../../common/parts/DashboardTabs";
import React from "react";
import styles from "./style.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonsPair from "../../../common/parts/ButtonsPair";
import cn from "classnames";
import Input, {DateInput, SelectInput} from "../../../common/core/Input";
import moment from "moment";
import useWaiting from "../../../common/hooks/useWaiting";
import {ActivityTypes} from "./Activities";
import AutoForm from "../../../common/core/AutoForm";
import {isEmpty} from "../../../../utils/StringUtils";
import {useTranslation} from "../../../../contexts/LocaleContext";

const CustomInput = ({title, InputComponent, ...rest}) => (
  <Col className={'col-10 pt-2'}>
    <Col xs={12} className={cn(styles.title, 'col-12 pl-2 mb-3')}>{title.toUpperCase()}</Col>
    <Col xs={12}>
      <InputComponent heigth={40} {...rest}/>
    </Col>
  </Col>
);

const CustomForm = ({onSubmit, close, initialValues}) => {
  const [waiting, submit] = useWaiting(onSubmit);
  const defaultValues = {nextactiondate: moment().add(3, 'days')};
  const {t} = useTranslation()
  return (
    <AutoForm
      onSubmit={submit}
      className={styles.form}
      initialValues={isEmpty(initialValues) ? defaultValues : initialValues}
    >
      <Row>
        <CustomInput
          InputComponent={Input}
          title={t('text')}
          field={'text'}
          required
        />
        <CustomInput
          title={t('type')}
          field={'type'}
          placeholder={t('select_type')}
          InputComponent={SelectInput}
          options={Object.values(ActivityTypes).map(v=>({...v, label: t(v.label.toLowerCase())}))}
          required
        />
        <CustomInput
          InputComponent={DateInput}
          title={t("next_action_date")}
          field={'nextactiondate'}
          required
        />
      </Row>
      <Row>
        <ButtonsPair
          saveWaiting={waiting}
          onCancel={close}
          className={'pb-2'}
        />
      </Row>
    </AutoForm>
  );
};

const ModalActivity = ({
  onSubmit,
  close,
  editingValues,
}) => {
  const {t} = useTranslation()
  const TABS = [{
    name: isEmpty(editingValues) ? t("add_new_activity").toUpperCase() : `${t('edit_activity')} #${editingValues.id}`.toUpperCase(),
    content: (
      <CustomForm
        initialValues={editingValues}
        onSubmit={onSubmit}
        close={close}
      />
    ),
  }];

  return (
    <DashboardTabs
      tabs={TABS}
      readyContents={TABS.map(tab => tab.content)}
    />
  );
};

export default ModalActivity;
