import React, { useContext, useEffect, useMemo, useState } from 'react'
import cn from "classnames";
import moment from "moment";
import styles from './styles.module.css'
import { capitalize, formatDate, sortByIdDesc } from "../../../../../../utils/StringUtils";
import { useTranslation } from "../../../../../../contexts/LocaleContext";
import DataContext from "../../../../../../contexts/DataContext";
import TouchableOpacity from "../../../../../common/core/TouchableOpacity";
import TabContext, { EditorType, TAB_PATHS } from "../../../../../../contexts/TabContext";
import CorporateContext from "../../CorporateContext";
import { RoundButton } from "../../common/Button";
import SlideUpScreen from "../../common/SlideUpScreen";
import useNavigation from "../../../../../common/hooks/useNavigation";
import { Department } from "../Structures";
import { color } from 'd3-color';
import { Image } from 'react-bootstrap'
import edit from './images/edit-3.png'
import Button from '../../../../../common/core/Button';
import DocumentStatus from '../../../../../common/parts/DocumentStatus';
import { chooseActivDepAndComp } from '../../../../../../api/AccountActiveDepartment/dataGetters';
import FetchLoading from '../../../../../common/parts/FetchLoading';

const CompanyDashboard = () => {
    const { t } = useTranslation()
    const { departments } = useContext(CorporateContext)
    const { nav, PATHS } = useNavigation()
    const onManageDepartments = () => {
        nav(PATHS.STRUCTURES, 'departments')
    }

    return <SlideUpScreen show={departments.isLoaded}>
        <SectionHeader thin='true' text={<div className={styles.dateToday}>{capitalize(moment().format('dddd'))} {capitalize(moment().format('ll'))}</div>} />
        <div className={styles.mainRow}>
            <DocumentsOverview />
        </div>
        <div className={styles.mainRow}>
            <LatestDocuments />
        </div>
        <div className={styles.mainRow}>
            <Invites />
        </div>
    </SlideUpScreen>
}

export const SectionHeader = ({ text = '', controller = '', thin = false, className }) => {
    return <div style={{ marginLeft: 20 }} className={cn(styles.header, className)}>
        <div className={styles.today} style={thin ? { fontWeight: 900 } : {}}>{text}</div>
        <div className={styles.headerController}>
            {controller}
        </div>
    </div>
}

const DocumentsOverview = () => {
    const { t } = useTranslation()

    const { documents } = useContext(DataContext)

    useEffect(() => {
        documents.load()
    }, [])

    const getQuantityByStatus = (documents = [], statusCodes = []) => {
        return documents.reduce((acc, { document_status_type }) => (statusCodes.includes(document_status_type) ? acc + 1 : acc), 0)
    }

    const docsData = useMemo(() => {
        const todayDocs = sortByIdDesc(documents.data || []).filter(({ created_at }) => {
            const isToday = moment(created_at).isSame(moment(), 'day')
            return isToday
        })

        return [
            { quantity: getQuantityByStatus(todayDocs, [0]), type: t('Not sent'), color: '#b0b0b0' },
            { quantity: getQuantityByStatus(todayDocs, [1]), type: t('Unopened'), color: '#8A1D70' },
            { quantity: getQuantityByStatus(todayDocs, [2]), type: t('In review'), color: '#33ACC9' },
            { quantity: getQuantityByStatus(todayDocs, [3]), type: t('Signed'), color: '#F87C18' },
        ]
    }, [documents])


    return <div className={styles.overview}>
        <div className={styles.overviewHeader}>
            <div>{t('Today\'s documents')}</div>

        </div>
        <div className={styles.overviewContent}>
            {docsData.map(StatusCounter)}
        </div>
        <div className={styles.progressBar}>
            {docsData.map(({ color, quantity }) => <div style={{ backgroundColor: color, flex: quantity }} />)}
        </div>
    </div>
}

const StatusCounter = ({ quantity = 0, type = '', color = '' }) => {
    return <div className={styles.overviewItem}>
        <div className={styles.overviewIcon}>
            <i className={'fas fa-square'} style={{ color }} />
        </div>
        <div className={styles.overviewType} style={{ textDecoration: 'solid 2px ' + color }}>
            {type}:
        </div>
        <div className={styles.overviewQuatity}>{quantity}</div>
    </div>
}

const LatestDocuments = () => {
    const [latestDocuments, setLatestDocuments] = useState([]);
    const [isDocumentsLoaded, setIsDocumentsLoaded] = useState(false);
    const { documents } = useContext(DataContext)
    const { navigate } = useContext(TabContext)
    const { t } = useTranslation()

    useEffect(() => {
        setIsDocumentsLoaded(true);
        const fetchData = async () => {
            await documents.getListLatest().then((res) => setLatestDocuments(res));
            setIsDocumentsLoaded(false);
        }

        fetchData().catch(console.error)
    }, [])

    const onSeeAll = () => navigate(TAB_PATHS.DOCUMENTS)

    return <div className={styles.latestDocuments}>
        <div className={styles.latestDocumentsHeader}>
            {t('Latest documents')}
            <RoundButton iconCN={'fas fa-angle-up'} onClick={onSeeAll} />
        </div>
        <div className={styles.latestDocumentsContent}>
            {!isDocumentsLoaded ?
              latestDocuments.map(({ id, ...document }) =>
                <Document key={id} _document={{ ...document, id, title: document.title }} />
              )
              : <FetchLoading />
            }
        </div>
    </div>
}

const Document = ({ _document, ...props }) => {
    const { openEditor } = useContext(TabContext)
    const openDocument = () => {
        openEditor({
            id: _document.id,
            editorTitle: _document.title,
            editorContent: _document.content,
            editorKeywords: _document.keywords,
            editorIsPublic: _document.is_public,
            documentToken: _document.document_token,
            editorType: EditorType.EDIT_DOCUMENT,
            statusType: _document.document_status_type
        });
    }
    const { t } = useTranslation()

    const { title, document_status_type: statusCode } = _document

    return <div className={styles.document}>
        <div className={styles.documentStatus}>
            <DocumentStatus statusType={statusCode}/>
        </div>
        <div className={styles.documentTitle}>{_document.title.slice(0, 100)}</div>
        <div className={styles.documentDate}>{formatDate(_document.created_at)}</div>
        <TouchableOpacity className={styles.documentIcon}
            onClick={openDocument}
        >
            <Image src={edit} />
        </TouchableOpacity>
    </div>
}

const DepartmentsOverview = ({ departments = { data: [] } }) => {

    return <div className={styles.departmentList} id="tutorial-step-2">
        {departments.data.map(({ name, id, employees_department }) => <Department name={name}
            staff_size={employees_department?.length}
            id={id}
            employees={employees_department}
            key={id}
        />)}
    </div>
}

export const Invites = () => {
    const { t } = useTranslation()
    const { invites } = useContext(CorporateContext)

    useEffect(() => {
        invites.load()
    }, [])

    return (
        <div className={styles.inviteOverview}>
            <div className={styles.overviewHeader}>
                <div>{t('Invites')}</div>
            </div>
            {invites?.data?.length ?
                <div className={styles.mainInviteRow}>
                    {invites?.data?.map(InviteCard)}
                </div> 
                :
                <div className={styles.noInvite}>
                   <p>{t("no_invites_yet")}</p>
                </div> 
            }
        </div>
    )
}

const InviteCard = ({company_name, department_name, empoyee_type, expiring_at, confirmation_code, department_id}) => {
    const { t } = useTranslation()
    const { invites } = useContext(CorporateContext)
    const { departmentsCompany } = useContext(DataContext)
    const { company } = useContext(DataContext)

    const getCompanyRoleName = () => {
        switch (empoyee_type) {
          case 0:
            return "CEO"
          case 1:
            return "Manager"
          case 2: 
            return "Employee"
          default:
            return "CEO"
        }
    }

    const onConfirmIvite = async (department_id) => {
        await invites.create(confirmation_code)
        await departmentsCompany._getList()

        if (!company.data && departmentsCompany.data.length === 0) {
            const token = await chooseActivDepAndComp(department_id);
            localStorage.setItem("token", token);
            window.location.reload();
        }
    } 

    const onRejectInvite = async () => {
        await invites.remove(confirmation_code)
    }

    return (
        <div className={styles.inviteCard}>
            <div className={styles.inviteCardInfo}> 
                <div>{t("Company")}: <b>{company_name}</b></div>
                <div>{t("Department")}: <b>{department_name}</b></div>
                <div>{t("Role")}: <b>{getCompanyRoleName()}</b></div>
                <div className={styles.inviteCardFooter}>
                    <div className={styles.inviteCardButtons}>
                        <Button title="Accept" color="#00a2ed" onClick={() => onConfirmIvite(department_id)}/>
                        <Button title="Decline" color="#ff4040" onClick={onRejectInvite}/>
                    </div>
                    <div className={styles.inviteCardDate}>
                        <div>{t("Expiration date")}:</div>
                        <div><b>{formatDate(expiring_at)}</b></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyDashboard
