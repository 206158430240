import React from "react";

import Input from "../common/core/Input";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useTranslation} from "../../contexts/LocaleContext";
import styles from './style.module.css'

const ChangePassword = () => {
  const {t} = useTranslation()
  return (
    <Row className={styles.passwordChangeWrapper} >
      <Col style={{minWidth: "260px"}} className={'px-0'}  sm={4} >
        <Input
          field={"OldPassword"}
          placeholder={t("current_password")}
          type="password"
          required
        />
        <Input
          field={"NewPassword"}
          placeholder={t("new_password")}
          type="password"
          required
        />
        <Input
          field={"ConfirmPassword"}
          placeholder={t("repeat_new_password")}
          type="password"
          required
        />
      </Col>
    </Row>
  );
};

export default ChangePassword;
