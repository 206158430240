import React, { useMemo } from 'react';
import cn from 'classnames';
import styles from './style.module.css';

export const getTaskProgressPercentage = (task) => {
  if (task?.status === 2) return 100;
  if (!task?.child_tasks?.length) return 0;

  const subtaskPercentage = task.child_tasks.reduce(
    (accumulator, current) => accumulator + getTaskProgressPercentage(current),
    0
  );
  const totalSubtasks = task.child_tasks.length;

  return subtaskPercentage / totalSubtasks;
};

const TaskProgress = ({ task, width = 83 }) => {
  const percent = useMemo(() => getTaskProgressPercentage(task), [task]);

  return (
    <div className={cn(styles.wrapper)}>
      <div className={cn(styles.bar)} style={{ width }}>
        {percent > 0 && (
          <span className={cn(styles.progress)} style={{ width: percent }} />
        )}
      </div>

      <span className={cn(styles.percent)}>{percent.toFixed(0)} %</span>
    </div>
  );
};

export default TaskProgress;
