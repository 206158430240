import React, {useContext} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Input, {GenderSelect} from '../../../common/core/Input';
import styles from './style.module.css';
import Image from "react-bootstrap/Image";
import cn from 'classnames';
import TouchableOpacity from "../../../common/core/TouchableOpacity";
import Button from "../../../common/core/Button";
import {useUploadAvatarModal} from "../../../common/parts/UploadAvatarModal";
import {uploadAssistantAvatar} from "../../../../api/AssistantSettings/dataSetters";
import DataContext from "../../../../contexts/DataContext";
import {useTranslation} from "../../../../contexts/LocaleContext";

const AssistantSettings = () => {
    const {assistant} = useContext(DataContext);
    const {t, currentLocale} = useTranslation()
    const onSave = async (file) => {
        await uploadAssistantAvatar(file);
        await assistant._get();
    };
    const {avatarModal, openAvatarModal} = useUploadAvatarModal(onSave);
    return (
        <>
            {avatarModal}
            <Section
                left={t("choose_a_name_for_your_virtual_assistant")}
                right={
                    <Input
                        containerClassName={styles.sectionInputContainer}
                        field={'name'}
                        required
                    />
                }
            />
            <Section
                left={t("choose_a_voice_for_your_assistant")}
                right={
                    <GenderSelect available={currentLocale == 'ro' ? [
                        {value: "1", label: 'female'},
                    ] : [
                        {value: "0", label: 'male'},
                        {value: "1", label: 'female'},
                    ]}/>
                }
            />
            <Section
                left={t("upload_an_avatar_for_your_virtual_assistant")}
                right={
                    <Button
                        title={<div><i className="fas fa-camera mr-2"/>{t("upload_avatar")}</div>}
                        onClick={openAvatarModal}
                        height={35}
                        width={160}
                    />
                }
            />
        </>
    );
};

const Section = ({left, right}) => (
    <Row className={styles.sectionContainer}>
        <Col
            className={styles.leftContainer}
            xs lg={4} md={4} 
        >
            {left}
        </Col>
        <Col
            className={cn(styles.rightContainer)}
            xs md lg={8}
        >
            {right}
        </Col>
    </Row>
);

const AVATAR_HEIGHT = 150;

const Avatar = ({imageSrc, selected, onClick}) => (
    <Col
        xs={4}
        className={styles.avatarCol}
    >
        <TouchableOpacity
            style={{
                height: AVATAR_HEIGHT,
            }}
            className={cn(
                styles.avatarContainer,
                selected && styles.avatarContainerSelected
            )}
            onClick={onClick}
        >
            <Image
                fluid
                src={imageSrc}
                className={styles.avatarImage}
            />
        </TouchableOpacity>
    </Col>
);

export default AssistantSettings;
