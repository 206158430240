import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import cn from "classnames";
import { useParams } from "react-router-dom";

import styles from "./style.module.css";
import Board from "./Board";
import ShareDialog from "./ShareDialog";

import Wrapper from "../../../common/core/Wrapper";
import DashboardTabs from "../../../common/parts/DashboardTabs";
import Button from "../../../common/core/Button";
import { Info, TabLink } from "../../../common/core/Toasts";
import GuideContext, { GuideTrigger } from "../../../Onboarding";
import { DateInput } from "../../../common/core/Input";
import Input from "../../../common/core/Input";

import {
  getPageSentTemplates,
  getPageSharedTemplates,
} from "../../../../api/SharedTemplate/dataGetters";
import { useTranslation } from "../../../../contexts/LocaleContext";
import TabContext, { EditorType } from "../../../../contexts/TabContext";
import { useRestriction } from "../../../../contexts/Restriction/RestrictionContext";
import { getPageTemplatesDataPrivate, getPageTemplatesDataPublic } from "../../../../api/Template/dataGetters";
import DataContext, { useLoaded } from "../../../../contexts/DataContext";

const Templates = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { setPage } = useContext(GuideContext);
  const { isLoaded, templates } = useLoaded("templates");
  const { account, company } = useContext(DataContext);
  const [filters, setFilters] = useState({});
  const { withRestrictions } = useRestriction();

  useEffect(() => {
    checkAccount();
    setPage("templates");
  }, []);

  useEffect(() => {
    if (!isLoaded || !params.id) return;

    templates.getItemById(params.id).then((template) => {
      if (!template?.id) return;

      openEditor({
        id: template.id,
        editorTitle: template.title,
        editorContent: template.content,
        editorKeywords: template.keywords,
        editorIsPublic: template.is_public,
        editorType: EditorType.EDIT_TEMPLATE,
      });
    });
  }, [params, isLoaded]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [sortPublic, setSortPublic] = useState({
    by: "updatedAt",
    order: 1,
  });

  const [fetchedTemplatesPublic, setFetchedTemplatesPublic] = useState({
    items: null,
    loading: true,
    totalItems: 0,
  });

  const [
    { size: pageSizePublic, current: pageCurrentPublic, sizeOptions: pageSizeOptionsPublic },
    setPaginationPublic,
  ] = useState({
    size: 10,
    current: 1,
    sizeOptions: [5, 10, 15, 20, 50],
  });

  const fetchDataPublic = async (sortBy, withoutLoading) => {
    !withoutLoading &&
      setFetchedTemplatesPublic((prev) => ({ ...prev, loading: true }));
    const { items, pages } = await getPageTemplatesDataPublic({
      ...filters,
      pageSize: pageSizePublic,
      Page: pageCurrentPublic,
      SortBy: sortBy || sort.by,
      SortOrder: sort.order,
    });
    setFetchedTemplatesPublic((prev) => ({
      ...prev,
      items,
      loading: false,
      totalItems: pages * pageSizePublic,
    }));
  };

  const [sort, setSort] = useState({
    by: "updatedAt",
    order: 1,
  });

  const [fetchedTemplatesPrivate, setTemplatesPrivate] = useState({
    items: null,
    loading: true,
    totalItems: 0,
  });

  const [
    { size: pageSize, current: pageCurrent, sizeOptions: pageSizeOptions },
    setPaginationPrivate,
  ] = useState({
    size: 10,
    current: 1,
    sizeOptions: [5, 10, 15, 20, 50],
  });

  const fetchDataPrivate = async (sortBy, withoutLoading) => {
    !withoutLoading &&
      setTemplatesPrivate((prev) => ({ ...prev, loading: true }));
    const { items, pages } = await getPageTemplatesDataPrivate({
      ...filters,
      pageSize: pageSize,
      Page: pageCurrent,
      SortBy: sortBy || sort.by,
      SortOrder: sort.order,
    });
    setTemplatesPrivate((prev) => ({
      ...prev,
      items,
      loading: false,
      totalItems: pages * pageSize,
    }));
  };

  const openFilter = () => setIsFilterOpen(true);
  const closeFilter = () => setIsFilterOpen(false);

  const onReset = () => {
    if (filters && Object.keys(filters).length !== 0) {
      setFilters({});
    }
  };
  const onFilter = (filters, force) => {
    if (force) {
      return setFilters(filters);
    }
    if (filters && Object.keys(filters).length !== 0) {
      setFilters(filters);
    }
  };

  useEffect(() => {
    fetchDataPrivate();
  }, [filters, sort.data, sort.order, pageSize, pageCurrent]);

  useEffect(() => {
    fetchDataPublic();
  }, [filters, sortPublic.data, sortPublic.order, pageSizePublic, pageCurrentPublic]);

  const checkAccount = async () => {
    await account._get();
    if (
      !sessionStorage.getItem("SEEN_ACCOUNT_HINT") &&
      (account?.data?.name === "Name" || !account?.data?.address?.country)
    ) {
      toast(
        <Info>
          {t("don't_forget_to")}
          <TabLink to={"/settings"}>{t("set_up_your_account")}</TabLink>
        </Info>
      );
      sessionStorage.setItem("SEEN_ACCOUNT_HINT", "true");
    }

    await company._get();
    if (
      !sessionStorage.getItem("SEEN_COMPANY_HINT") &&
      (!company?.data?.email || !company?.data?.address?.country)
    ) {
      toast(
        <Info>
          {t("for_better_experience_please")}
          <TabLink to={"/settings/1"}>{t("provide_info")}</TabLink>
          {t("about_your_company")}
        </Info>
      );
      sessionStorage.setItem("SEEN_COMPANY_HINT", "true");
    }
  };

  useEffect(() => {
    checkAccount();
  }, []);


  let [sharedTemplates, setSharedTemplates] = useState({
    sent: {
      items: [],
      totalItems: 0,
      size: 5,
      current: 1,
      sizeOptions: [5, 10, 15, 20, 50],
      loading: true,
      sort: {
        by: "updatedAt",
        order: 1,
      },
      filters: {},
    },
    received: {
      items: [],
      totalItems: 0,
      size: 5,
      current: 1,
      sizeOptions: [5, 10, 15, 20, 50],
      loading: true,
      sort: {
        by: "updatedAt",
        order: 1,
      },
      filters: {},
    },
  });
  const [sharedMode, setSharedMode] = useState("Received");

  const fetchReceivedTemplates = async (sortBy, withoutLoading) => {
    !withoutLoading &&
      setSharedTemplates((prev) => ({
        ...prev,
        received: { ...prev.received, loading: true },
      }));

    const { items: receivedItems, pages: receivedTotalPages } =
      await getPageSharedTemplates({
        ...filters,
        pageSize: sharedTemplates.received.size,
        Page: sharedTemplates.received.current,
        SortBy: sortBy || sharedTemplates.received.sort.by,
        SortOrder: sharedTemplates.received.sort.order,
      });

    setSharedTemplates((prev) => ({
      ...prev,
      received: {
        ...prev.received,
        items: receivedItems.map((template) => ({
          ...template,
          isShared: true,
        })),
        totalItems: +receivedTotalPages * sharedTemplates.received.size,
        loading: false,
      },
    }));
  };

  const fetchSentTemplates = async (sortBy, withoutLoading) => {
    !withoutLoading &&
      setSharedTemplates((prev) => ({
        ...prev,
        sent: { ...prev.sent, loading: true },
      }));
    const { items: sentItems, pages: sentTotalPages } =
      await getPageSentTemplates({
        ...filters,
        pageSize: sharedTemplates.sent.size,
        Page: sharedTemplates.sent.current,
        SortBy: sortBy || sharedTemplates.sent.sort.by,
        SortOrder: sharedTemplates.sent.sort.order,
      });
    setSharedTemplates((prev) => ({
      ...prev,
      sent: {
        ...prev.sent,
        items: sentItems,
        totalItems: +sentTotalPages * sharedTemplates.sent.size,
        loading: false,
      },
    }));
  };

  useEffect(() => {
    fetchReceivedTemplates();
  }, [
    filters,
    sharedTemplates.received.size,
    sharedTemplates.received.current,
    sharedTemplates.received.sort.order,
    sharedTemplates.received.sort.sortBy,
  ]);

  useEffect(() => {
    fetchSentTemplates();
  }, [
    filters,
    sharedTemplates.sent.size,
    sharedTemplates.sent.current,
    sharedTemplates.sent.sort.order,
    sharedTemplates.sent.sort.sortBy,
  ]);

  function onChangePage(current, pageSize, tab) {
    if (tab === "Sent") {
      return setSharedTemplates((prev) => ({
        ...prev,
        sent: {
          ...prev.sent,
          current,
          size: pageSize,
        },
      }));
    }
    if (tab === "Received") {
      return setSharedTemplates((prev) => ({
        ...prev,
        received: {
          ...prev.received,
          current,
          size: pageSize,
        },
      }));
    }
    if (tab === "default") {
      setPaginationPublic((prev) => ({ ...prev, current, size: pageSize }));
    }
    setPaginationPrivate((prev) => ({ ...prev, current, size: pageSize }));
  }

  const onPerPageChange = (e, tab) => {
    const { value } = e.target;

    if (tab === "Sent") {
      return setSharedTemplates((prev) => ({
        ...prev,
        sent: {
          ...prev.sent,
          size: value,
          current: 1,
        },
      }));
    }
    if (tab === "Received") {
      return setSharedTemplates((prev) => ({
        ...prev,
        received: {
          ...prev.received,
          size: value,
          current: 1,
        },
      }));
    }
    if (tab === "default") {
      setPaginationPublic((prev) => ({ ...prev, size: value, current: 1 }));
    }
    setPaginationPrivate((prev) => ({ ...prev, size: value, current: 1 }));
  };

  const onSort = (sortBy, tab) => {
    if (tab === "Sent") {
      if (sharedTemplates.sent.sort.by === sortBy) {
        return setSharedTemplates((prev) => ({
          ...prev,
          sent: {
            ...prev.sent,
            sort: {
              ...prev.sent.sort,
              order: prev.sent.sort.order === 0 ? 1 : 0,
            },
          },
        }));
      }
      setSharedTemplates((prev) => ({
        ...prev,
        sent: {
          ...prev.sent,
          sort: {
            ...prev.sent.order,
            by: sortBy,
            order: 0,
          },
        },
      }));
      return fetchSentTemplates(sortBy);
    }
    if (tab === "Received") {
      if (sharedTemplates.received.sort.by === sortBy) {
        return setSharedTemplates((prev) => ({
          ...prev,
          received: {
            ...prev.received,
            sort: {
              ...prev.received.sort,
              order: prev.received.sort.order === 0 ? 1 : 0,
            },
          },
        }));
      }
      setSharedTemplates((prev) => ({
        ...prev,
        received: {
          ...prev.received,
          sort: {
            ...prev.received.sort,
            by: sortBy,
            order: 0,
          },
        },
      }));
      return fetchReceivedTemplates(sortBy);
    }
    if (tab === "default") {
      setSortPublic((prev) => ({ ...prev, by: sortBy, order: 0 }));
      fetchDataPublic(sortBy)
    }

    if (sort.by === sortBy) {
      return setSort((prev) => ({ ...prev, order: prev.order === 0 ? 1 : 0 }));
    }
    setSort((prev) => ({ ...prev, by: sortBy, order: 0 }));
    fetchDataPrivate(sortBy);
  };

  const onDeleteTemplate = async (id, tab) => {
    const promises = [templates.remove(id)];
    return await Promise.allSettled(promises).then(() => {
      let documentLength;
      if (tab === "Sent") {
        documentLength = sharedTemplates.sent.items.length;
        setSharedTemplates((prev) => ({
          ...prev,
          sent: {
            ...prev.sent,
            sort: {
              ...prev.sent.sort,
              ...(documentLength === 1 && {
                current: prev.sent.sort.current - 1,
              }),
            },
          },
        }));
      }
      if (tab === "Received") {
        documentLength = sharedTemplates.received.items.length;
        setSharedTemplates((prev) => ({
          ...prev,
          sent: {
            ...prev.received,
            sort: {
              ...prev.received.sort,
              ...(documentLength === 1 && {
                current: prev.received.sort.current - 1,
              }),
            },
          },
        }));
      }
      if (tab === "custom") {
        documentLength = fetchedTemplatesPrivate.items.length;
        setPaginationPrivate((prev) => ({
          ...prev,
          ...(documentLength === 1 && { current: prev.current - 1 }),
        }));
      }
      if (tab === "default") {
        documentLength = fetchedTemplatesPublic.items.length;
        setPaginationPublic((prev) => ({
          ...prev,
          ...(documentLength === 1 && { current: prev.current - 1 }),
        }));
      }
      fetchReceivedTemplates(null, false);
      fetchSentTemplates(null, false);
      fetchDataPrivate(null, false);
      fetchDataPublic(null, false)
    });
  };

  const [currentTemplate, setCurrentTemplate] = useState(false);

  const { openEditor, isEditorOpen } = useContext(TabContext);

  function createNewTemplate(isPublic = false) {
    openEditor({
      editorType: EditorType.CREATE_TEMPLATE,
      editorIsPublic: isPublic,
    });
  }

  useEffect(() => {
    fetchReceivedTemplates(null, true);
    fetchSentTemplates(null, true);
    fetchDataPrivate(null, true);
    fetchDataPublic(null, true)
  }, [isEditorOpen]);

  const CreateNewButton = ({isPublic}) => {
    return (
      <div className={styles.createNew}>
        <GuideTrigger item={"create template"}>
          <Button
            onClick={withRestrictions(() => createNewTemplate(isPublic))}
            thin
            className={styles.createNewButton}
            title={t("create_new")}
            height={28}
            width={150}
          />
        </GuideTrigger>
      </div>
    );
  };

  const OpenFilterButton = () => {
    return (
      <div className={styles.filterButton}>
        <Button
          thin
          transparent
          onClick={() => openFilter()}
          title={<i class="fas fa-filter" aria-hidden="true"></i>}
          height={28}
        />
      </div>
    );
  };

  const SwitchButtons = () => {
    const { t } = useTranslation();

    const getStyle = (active) => ({
      color: active ? "#aaa" : "transparent",
      textColor: active ? "#fff" : "#000",
    });

    return (
      <div className={styles.switchButtons}>
        <Button
          onClick={() => setSharedMode("Received")}
          thin
          className={styles.createNewButton}
          title={t("received")}
          height={28}
          width={100}
          {...getStyle(sharedMode === "Received")}
        />
        <Button
          onClick={() => setSharedMode("Sent")}
          thin
          className={styles.createNewButton}
          title={t("sent")}
          height={28}
          width={100}
          {...getStyle(sharedMode === "Sent")}
        />
      </div>
    );
  };

  const TABS = [
    {
      id: "custom",
      name: t("your_templates").toUpperCase(),
      component: (
        <Board
          share={(id) => setCurrentTemplate(id)}
          loaded={!fetchedTemplatesPrivate.loading}
          items={fetchedTemplatesPrivate.items}
          totalItems={fetchedTemplatesPrivate.totalItems}
          emptyHint={t("you_have_no_templates_yet._create_one")}
          onChangePage={(current, pageSize) =>
            onChangePage(current, pageSize, "custom")
          }
          onPerPageChange={(e) => onPerPageChange(e, "custom")}
          pageCurrent={pageCurrent}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          onSort={(sortBy) => onSort(sortBy, "custom")}
          sort={sort}
          onDeleteTemplate={(id) => onDeleteTemplate(id, "custom")}
          createButton={<CreateNewButton isPublic={false}/>}
        />
      ),
      controllers: [<OpenFilterButton />],
    },
    {
      id: "default",
      name: t("public_templates").toUpperCase(),
      component: (
        <Board
          share={(id) => setCurrentTemplate(id)}
          loaded={!fetchedTemplatesPublic.loading}
          items={fetchedTemplatesPublic.items}
          totalItems={fetchedTemplatesPublic.totalItems}
          emptyHint={t("you_have_no_templates_yet._create_one")}
          sort={sort}
          onChangePage={(current, pageSize) =>
            onChangePage(current, pageSize, "default")
          }
          onPerPageChange={(e) => onPerPageChange(e, "default")}
          pageCurrent={pageCurrentPublic}
          pageSize={pageSizePublic}
          pageSizeOptions={pageSizeOptionsPublic}
          onSort={(sortBy) => onSort(sortBy, "default")}
          onDeleteTemplate={(id) => onDeleteTemplate(id, "default")}
          createButton={<CreateNewButton isPublic={true}/>}
        />
      ),
      controllers: [<OpenFilterButton />],
    },
    {
      id: "shared",
      name: t("shared_templates").toUpperCase(),
      component: (
        <Board
          sharedMode={sharedMode}
          share={(id) => setCurrentTemplate(id)}
          loaded={
            sharedMode === "Sent"
              ? !sharedTemplates.sent.loading
              : !sharedTemplates.received.loading
          }
          items={
            sharedMode === "Sent"
              ? sharedTemplates.sent.items
              : sharedTemplates.received.items
          }
          totalItems={
            sharedMode === "Sent"
              ? sharedTemplates.sent.totalItems
              : sharedTemplates.received.totalItems
          }
          emptyHint={
            sharedMode === "Sent"
              ? t("you_have_not_shared_any_of_your_templates_yet")
              : t("you_have_not_received_any_template_yet")
          }
          withSort={true}
          withPagination={true}
          onChangePage={(current, pageSize) =>
            onChangePage(
              current,
              pageSize,
              sharedMode === "Sent" ? "Sent" : "Received"
            )
          }
          onPerPageChange={(e) =>
            onPerPageChange(e, sharedMode === "Sent" ? "Sent" : "Received")
          }
          pageCurrent={
            sharedMode === "Sent"
              ? sharedTemplates.sent.current
              : sharedTemplates.received.current
          }
          pageSize={
            sharedMode === "Sent"
              ? sharedTemplates.sent.size
              : sharedTemplates.received.size
          }
          pageSizeOptions={
            sharedMode === "Sent"
              ? sharedTemplates.sent.sizeOptions
              : sharedTemplates.received.sizeOptions
          }
          onSort={(sortBy) =>
            onSort(sortBy, sharedMode === "Sent" ? "Sent" : "Received")
          }
          sort={
            sharedMode === "Sent"
              ? sharedTemplates.sent.sort
              : sharedTemplates.received.sort
          }
          onDeleteTemplate={(id) =>
            onDeleteTemplate(id, sharedMode === "Sent" ? "Sent" : "Received")
          }
        />
      ),
      controllers: [<SwitchButtons />, <OpenFilterButton />],
    },
  ];

  const contents = TABS.map((item) => (
    <Wrapper key={item.id}>{item.component}</Wrapper>
  ));
  const main = (
    <DashboardTabs
      tabs={TABS}
      readyContents={contents}
      startTabIndex={0}
    />
  );

  return (
    <>
      {currentTemplate && (
        <ShareDialog
          templateId={currentTemplate}
          onClose={() => setCurrentTemplate(false)}
        />
      )}
      <div className={styles.container}>
        <div className={styles.tabs}>{main}</div>
        <Filters
          isOpen={isFilterOpen}
          open={openFilter}
          close={closeFilter}
          onSubmit={onFilter}
          reset={onReset}
        />
      </div>
    </>
  );
};

export default Templates;

const Filters = ({ isOpen, onSubmit, close, reset }) => {
  const { t } = useTranslation();
  const [filters, setfilters] = useState({});
  const [selectedStatus, setselectedStatus] = useState();

  const onChangeDate = (e, date) => {
    const { value } = e.target;
    setfilters((prev) => ({ ...prev, [date]: value }));
  };

  const onChangeSeach = (e) => {
    const { value } = e.target;
    setfilters((prev) => ({...prev, search: value}))
  }

  const resetFilter = () => {
    setfilters({});
    setselectedStatus("");
    reset();
  };
  const submit = () => {
    return onSubmit(filters, selectedStatus === "777" && true);
  };

  const handleKeyWords = (e) => {
    const { value } = e.target;
    setfilters((prev) => ({ ...prev, keywords: value }));
  };
  const validate = (value) => !value || undefined;

  const _handleSubmit = (e) => {
    if (e.key === "Enter") {
        submit()
    }
  }

  return (
    <div className={cn(styles.filter, isOpen && styles.filterOpen)}>
      <Row className={styles.filterHeader}>
        <Col className={styles.filterHeaderTitle}>
          <i className="fas fa-filter" aria-hidden="true"></i> {t("filter")}
        </Col>
        <div className={styles.filterClose} onClick={close}></div>
      </Row>
      <Col className={styles.filterBody}>
        <Col>
          <span>{t("filter_created_date")}</span>
          <Row className={"mt-2"}>
            <Col className="pr-lg-1" lg={6}>
              <DateInput
                onChange={(e) => {
                  onChangeDate(e, "StartCreatedAt");
                }}
                onKeyDown={_handleSubmit}
                field={"StartCreatedAt"}
                placeholder={t("start created at")}
                fieldState={{
                  maskedValue: filters.StartCreatedAt,
                }}
              />
            </Col>
            <Col className="pl-lg-1" lg={6}>
              <DateInput
                onChange={(e) => {
                  onChangeDate(e, "EndCreatedAt");
                }}
                onKeyDown={_handleSubmit}
                field={"EndCreatedAt"}
                placeholder={t("EndCreatedAt")}
                fieldState={{
                  maskedValue: filters.EndCreatedAt,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col className={"mt-4"}>
          <span>{t("filter_updated_date")}</span>
          <Row className={"mt-2"}>
            <Col className="pr-lg-1" lg={6}>
              <DateInput
                onChange={(e) => {
                  onChangeDate(e, "StartUpdatedAt");
                }}
                onKeyDown={_handleSubmit}
                field={"StartUpdatedAt"}
                placeholder={t("StartUpdatedAt")}
                fieldState={{
                  maskedValue: filters.StartUpdatedAt,
                }}
              />
            </Col>
            <Col className="pl-lg-1" lg={6}>
              <DateInput
                onChange={(e) => {
                  onChangeDate(e, "EndUpdatedAt");
                }}
                onKeyDown={_handleSubmit}
                field={"EndUpdatedAt"}
                placeholder={t("EndUpdatedAt")}
                customValue="2001-12-12"
                fieldState={{
                  maskedValue: filters.EndUpdatedAt,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Row className={'mt-4'}>
          <Col style={{display:"flex", flexDirection:"column"}}>
            <span>{t("filter_title")}</span>
            <Col style={{marginTop:"10px"}}>
              <Input
                onChange={onChangeSeach}
                onKeyDown={_handleSubmit}
                placeholder={t('Enter search query')}
                field={'search'}
                validate={validate}
                fieldState={{maskedValue: filters.search || ""}}
              />
            </Col>
          </Col>
        </Row>
        <Row className={"mt-4"}>
          <Col style={{ display: "flex", flexDirection: "column" }}>
            <span>{t("filter_keywords")}</span>
            <Col style={{ marginTop: "10px" }}>
              <Input
                onChange={handleKeyWords}
                onKeyDown={_handleSubmit}
                placeholder={t("enter_comma-separated_keywords")}
                field={"keywords"}
                validate={validate}
                fieldState={{ maskedValue: filters.keywords || "" }}
              />
            </Col>
          </Col>
        </Row>
      </Col>
      <div className={styles.filterAction}>
        <Button
          title={t("reset")}
          onClick={resetFilter}
          height={30}
          fontSize={15}
          transparent
          color={"red"}
        />
        <Button
          title={t("submit")}
          height={30}
          onClick={submit}
          fontSize={15}
          transparent
        />
      </div>
    </div>
  );
};
