import Button from '../../core/Button';
import React from "react";
import styles from './style.module.css';
import cn from 'classnames';
import {useTranslation} from "../../../../contexts/LocaleContext";

const ButtonsPair = ({
  saveWaiting=false,
  onSave=()=>{},
  onCancel=()=>{},
  className='',
  buttonClassName=''
}) => {
    const {t} = useTranslation()
    return (
        <div className={cn(styles.buttonsContainer, className)}>
            <Button
                title={t('cancel')}
                type={'button'}
                color={'#C9CBCA'}
                className={cn(styles.leftButton, buttonClassName)}
                onClick={onCancel}
            />
            <Button
                waiting={saveWaiting}
                title={t('save')}
                type={'submit'}
                className={cn(styles.rightButton, buttonClassName)}
                onClick={onSave}
            />
        </div>
    )
};

export default ButtonsPair;

