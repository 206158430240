import moment from "moment";
import { saveAs } from "file-saver";
import {deepClone} from '../../utils/StringUtils'

export class StateField {
  constructor({ getter = () => {}, setter = () => {}, resolver = () => {} }) {
    this.data = {};
    this.isLoaded = false;
    this.load = async () => {
      if (this.isLoaded) return;
      await this._get();
    };

    this._get = async () => {
      this.data = await getter();
      this.isLoaded = true;
      resolver({ ...this });
    };

    this.set = async data => {
      await setter(Object.assign(this.data, data));
      await this._get();
    };
  }

  static default = {
    data: {},
    isLoaded: false,
    load: async () => {},
    _get: async () => {},
    set: async () => {}
  };
}

export class ListField {
  constructor({
    name = "",
    listGetter = () => {},
    creator = () => {},
    updater = () => {},
    deleter = () => {},
    getterById = () => {},
    getterByKeywords = () => {},
    importerCSV = () => {},
    exporterCSV = () => {},
    resolver = () => {}
  }) {
    this.name = name;
    this.data = [];
    this.isLoaded = false;

    this.load = async () => {
      if (this.isLoaded) return;
      await this._getList();
    };

    this._getList = async (filters) => {
      this.data = await listGetter(filters);
      this.isLoaded = true;
      resolver({ ...this });
    };

    this.chooseItem = async (id) => {
      const token = await updater(id)
      await this._getList();
      return token
    }

    this.getItemById = async id => {
      return await getterById(id);
    };

    this.getItemByIdLocal = id => {
      return this.data.find(item => item.id === id);
    };

    this.getItemsByKeywords = async keywords => {
      return await getterByKeywords(keywords);
    };

    this.create = async data => {
      const response = await creator(data);
      await this._getList();
      if (Array.isArray(this.data)) {
        return [...this.data].sort((a, b) => b.id - a.id)[0];
      } else {
        return response.body.data;
      }
    };

    this.uploadFile = async file => {
      await creator(file);
    };

    this.update = async data => {
      const element = this.data.find(({ id }) => id === data.id);
      await updater(Object.assign(element, data));
      await this._getList();
    };

    this.remove = async id => {
      await deleter(id);
    };

    this.importCSV = async (file, separator) => {
      await importerCSV(file, separator);
      await this._getList();
    };

    this.exportCSV = async () => {
      const data = "data:text/csv;charset=utf-8," + (await exporterCSV());
      const file = await fetch(data).then(data => data.blob());
      saveAs(file, `${this.name}-${moment().format("DD-MM-YYYY")}.csv`);
    };
  }

  static default = {
    data: {},
    isLoaded: false,
    load: async () => {},
    _getList: async () => {},
    getById: async () => {},
    create: async () => {},
    update: async () => {},
    remove: async () => {}
  };
}

export class ListFieldTable {
  constructor({
    name = "",
    listGetter = () => {},
    latestDocument = () => {},
    listGetterLatest = () => {},
    creator = () => {},
    updater = () => {},
    deleter = () => {},
    getterById = () => {},
    getterByKeywords = () => {},
    importerCSV = () => {},
    exporterCSV = () => {},
    resolver = () => {}
  }) {
    this.name = name;
    this.data = [];
    this.isLoaded = false;

    this.load = async () => {
      if (this.isLoaded) return;
      await this._getList();
    };

    this._getList = async (filters) => {
      this.data = await listGetter(filters);
      this.isLoaded = true;
      resolver({ ...this });
    };
    
    this._getLatestCreatedData = async () => {
      this.data = await latestDocument();
      this.isLoaded = true;
      resolver({ ...this })
    }

    this.getListLatest = async () => {
      const data = await listGetterLatest();
      return data;
    }

    this.getItemById = async id => {
      return await getterById(id);
    };

    this.getItemByIdLocal = id => {
      return this.data.find(item => item.id === id);
    };

    this.getItemsByKeywords = async keywords => {
      return await getterByKeywords(keywords);
    };

    this.create = async data => {
      await creator(data);
      if (this.name) {
        await this._getList();
        return;
      }
      await this._getLatestCreatedData(); 
      return this.data[0];
    };

    this.uploadFile = async file => {
      await creator(file);
      await this._getList();
    };

    this.update = async data => {
      const newData = deepClone(data);
      const element = await this.getItemById(data.id);
      await updater(Object.assign(element, newData));
      await this._getList();
    };

    this.remove = async id => {
      await deleter(id);
    };

    this.importCSV = async (file, separator) => {
      await importerCSV(file, separator);
      await this._getList();
    };

    this.exportCSV = async () => {
      const data = "data:text/csv;charset=utf-8," + (await exporterCSV());
      const file = await fetch(data).then(data => data.blob());
      saveAs(file, `${this.name}-${moment().format("DD-MM-YYYY")}.csv`);
    };
  }

  static default = {
    data: {},
    isLoaded: false,
    load: async () => {},
    _getList: async () => {},
    getById: async () => {},
    create: async () => {},
    update: async () => {},
    remove: async () => {}
  };
}
