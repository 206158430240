import React, {useCallback, useState} from 'react';
import useEscape from "../../Dashboard/Assistant/useEscape";
import avatarModalStyles from "./UploadAvatarModal/style.module.css";
import {useDropzone} from "react-dropzone";
import Wrapper from "../core/Wrapper";
import cn from "classnames";
import Button from "../core/Button";
import useWaiting from "../hooks/useWaiting";
import Input from "../core/Input";
import {Form} from 'informed';
import {useTranslation} from "../../../contexts/LocaleContext";

const ImportCsvModal = ({onClose, saveFile, headerNames}) => {
  const containerRef = useEscape(avatarModalStyles.container, onClose);
  const [file, setFile] = useState(null);
  const [separator, setSeparatorState] = useState(',');
  const [waiting, saveWithWaiting] = useWaiting(saveFile);
  const {t} = useTranslation()

  const save = async () => {
    await saveWithWaiting(file, separator);
    onClose();
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setFile(file);
    } else {
      // error
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    multiple: false,
    accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
  });

  return (
    <Wrapper
      className={avatarModalStyles.container}
      ref={containerRef}
    >
      <div className={avatarModalStyles.box}>
        <div className={avatarModalStyles.header}>
          {t("import_.csv")}
        </div>
        {headerNames && (
          <div style={{marginBottom: '1rem'}}>
            <div style={{fontWeight: '700', fontSize: '1.2rem'}}>{t('use_header_names')}</div>
            <div>{headerNames.split(',').join(', ')}</div>
          </div>
        )}
        <div className="d-flex align-items-center mb-4">
          <div className="mr-2">{t("separator")}</div>
          <Form
            initialValues={{separator}}
            onChange={({values: {separator}}) => {
              setSeparatorState(separator);
            }}
          >
            <Input
              field="separator"
              containerStyle={{width: 100, marginBottom: 0}}
            />
          </Form>
        </div>
        <div className={avatarModalStyles.main}>
          <div className={avatarModalStyles.rightSideContainer}>
            <div {...getRootProps({
              className: cn(
                avatarModalStyles.uploadBox,
                isDragAccept && avatarModalStyles.uploadBoxAccept,
                isDragReject && avatarModalStyles.uploadBoxReject,
              )})}>
              <input {...getInputProps({className: avatarModalStyles.input})} required/>
              <span>
                {
                  isDragReject ? t("_you_can_upload_only_.csv_files") :
                      t("_drag_'n'_drop_your_.csv_file_here,_or_click_to_select")                }
              </span>
            </div>

            {file && file.name && <div className={avatarModalStyles.fileName}>
              {'• ' + file.name}
            </div>}

            <div className={avatarModalStyles.buttonsContainer}>
              <Button
                onClick={save}
                waiting={waiting}
                title={t('save')}
                type={'submit'}
                width={120}
                className={avatarModalStyles.leftButton}
              />
              <Button title={t('cancel')} type={'button'} color={'#C9CBCA'} className={avatarModalStyles.rightButton} width={120} onClick={onClose}/>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export function useCvsIO(items, headerNames) {
  const [isImportCsvModalOpen, setIsImportCsvModalOpen] = useState(false);
  const importCsvModal = isImportCsvModalOpen && (
    <ImportCsvModal
      saveFile={items.importCSV}
      onClose={() => setIsImportCsvModalOpen(false)}
      headerNames={headerNames}
    />
  );

  const [exportWaiting, exportCSV] = useWaiting(items.exportCSV);

  return {
    importCsvModal,
    importCSV: () => setIsImportCsvModalOpen(true),
    exportCSV,
    exportWaiting,
  };
}

export default ImportCsvModal;
