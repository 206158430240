import React from 'react'
import cn from "classnames";
import styles from './styles.module.css'

const Screen = ({className, ...props}) => {
    return <div className={cn(styles.screen, className)} {...props}>
        {props.children}
    </div>
}

export default Screen
