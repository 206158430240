import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext,
  Suspense,
} from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  createHashRouter,
  createRoutesFromElements,
  Outlet,
} from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Dashboard, { Loading } from './Dashboard';
import Suspended from './Suspended';
import NetworkContext, { useNetwork } from '../contexts/NetworkContext';
import PaymentPage from './common/parts/PaymentForm';
import DataContext, { useApplicationData } from '../contexts/DataContext';
import ConfirmEmail from './ConfirmEmail';

import {
  LocaleContext,
  useLocaleContext,
  useTranslation,
} from '../contexts/LocaleContext';

import GuideContext, { useGuide } from './Onboarding';
import { RestrictionProvider } from '../contexts/Restriction/RestrictionContext';
import {
  TutorialContext,
  useTutorialContext,
} from '../contexts/TutorialContext';
import Partners from './Dashboard/contents/Partners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HowTo from './HowTo';
import Customers from './Dashboard/contents/Customers';
import Documents from './Dashboard/contents/Documents';
import Services from './Dashboard/contents/Services';
import Products from './Dashboard/contents/Products';
import Templates from './Dashboard/contents/Templates';
import CompanyDashboard from './Dashboard/contents/CompanyDashboard';
import { TAB_PATHS } from '../contexts/TabContext';
import {
  faBoxOpen,
  faConciergeBell,
  faFile,
  faFileAlt,
  faQuestionCircle,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import EditUserInfo from './Dashboard/UserSettings';
import styles from './Dashboard/style.module.css';
import ManageTask from './Dashboard/contents/CompanyDashboard/screens/Tasks/ManageTask';
import Flows from './Dashboard/contents/CompanyDashboard/screens/Flows';
import Structures from './Dashboard/contents/CompanyDashboard/screens/Structures';
import TasksDashboard from './Dashboard/contents/CompanyDashboard/screens/Tasks';
import Company from './Dashboard/contents/CompanyDashboard/screens/Company';
import { YMInitializer } from 'react-yandex-metrika';

function App() {
  const network = useNetwork();
  const dictionary = useLocaleContext();
  const { loggedIn, isReady: isNetworkReady } = network;

  const tutorial = useTutorialContext();
  const { data } = useApplicationData();
  const { account } = data;
  const { company } = data;
  const { subscription } = data;
  const { t, initLocale, locales } = useTranslation();

  const [isLocaleSet, setLocaleSet] = useState(false);

  useEffect(() => {
    setLocale();
  }, []);

  const setLocale = async () => {
    setLocaleSet(false);
    await initLocale();
    setLocaleSet(true);
  };

  const guideContext = useGuide();

  const getAccData = useCallback(async () => {
    await account._get();
    await subscription.load();
    await company.load();
  }, []);

  useEffect(() => {
    if (loggedIn) {
      getAccData();
    }
  }, [loggedIn, getAccData]);

  useEffect(() => {
    network.onStartUp();
  }, []);

  return (
    <>
      <YMInitializer
        accounts={[86338175]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
        version="2"
      />
      <Suspense fallback={<div />}>
        <LocaleContext.Provider value={dictionary}>
          <GuideContext.Provider value={guideContext}>
            <NetworkContext.Provider value={network}>
              <DataContext.Provider value={data}>
                <TutorialContext.Provider value={tutorial}>
                  <RestrictionProvider>
                    {isNetworkReady ? <Outlet /> : <Loading />}
                  </RestrictionProvider>
                </TutorialContext.Provider>
              </DataContext.Provider>
            </NetworkContext.Provider>
          </GuideContext.Provider>
        </LocaleContext.Provider>
      </Suspense>
    </>
  );
}

export default App;

const CorpIcon = ({ size, color }) => {
  return (
    <svg
      width={size}
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.44444 1H1V4.11111H6.44444V1Z"
        stroke={color}
        fill={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0001 1H9.55566V8.77778H15.0001V1Z"
        stroke={color}
        fill={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0001 11.8889H9.55566V15H15.0001V11.8889Z"
        stroke={color}
        fill={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.44444 7.22223H1V15H6.44444V7.22223Z"
        stroke={color}
        fill={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
