import React, {useRef, useState} from 'react'
import styles from './styles.module.css'
import SignatureCanvas from "react-signature-canvas";
import Button from "../../../common/core/Button";
import {attachSignature, generateSignatureReport, getRemoteSigningToken, uploadSignature} from "../../../../api/Signature/dataSetters";
import resizeBase64 from "../../../../utils/base64resize";
import QRCode from 'qrcode.react'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {SIGNATURE_BASE_URL, SIGNATURE_RESOLUTION, SIGNATURE_SIZE} from "../../../../config";
import {useTranslation} from "../../../../contexts/LocaleContext";

const PREFIX = ''


const SignaturePad = ({type, docId, dispatchSignature = () => {}, close = () => {}}) => {
  const canvas = useRef(null)
  const [mode, setMode] = useState(type || 0)

  const {t} = useTranslation()
  const clear = () => {
    if (canvas?.current) {
      canvas.current.clear()
    }
  }
  //todo get - compress - attach - upload - insert
  const saveRemoteSignature = async () => {
      dispatchSignature(type, '{AWAITING_SIGNATURE}');
    }
    
  const saveLocalSignature = async () => {
      //getting img
      const base64String = await canvas.current.toDataURL('image/jpeg')
      // compressing
      const compressedImg = await resizeBase64(base64String, SIGNATURE_RESOLUTION.W, SIGNATURE_RESOLUTION.H)
      // attaching to doc
      dispatchSignature(type, `<img src="${PREFIX + compressedImg}" width="${SIGNATURE_SIZE.W}" height="${SIGNATURE_SIZE.H}"/>`)
      try {
        const resp = await attachSignature(type, docId)
        const id = resp?.body?.data?.id
        await upload(compressedImg, id)

        if (type === 1) {
          await generateSignatureReport({
            client_name: "", 
            client_address: "", 
            client_contact_person: "", 
            client_device_model: "",
            customer_type: 1,
            signature_id: id,
            used_token: "",
            document_id: docId,
            used_device: "",
          })
        }
        close();
      } catch (e) {console.warn('failed to attach signature');
        close();
    }
  }

  const upload = async (base64String, signatureId) => {
    const blob = await fetch(base64String).then(r => r.blob())
    const file = new File([blob], 'signature_' + signatureId + '.png')
    return await uploadSignature(signatureId, file)
  }

  const localSignature = (

    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>

      <SignatureCanvas
        ref={canvas}
        penColor={"#3c4a85"}
        canvasProps={{width: 600, height: 300, className: styles.canvas}}
        backgroundColor={"#fff"}
        velocityFilterWeight={0.5}
      />

    </div>
  )


  return <div className={styles.wrapper}>
    <div className={styles.centerLine}>
      <div className={styles.controller}>
        <div className={styles.header}>{type === 0 ? t('my_signature') : t('client_signature')}
          {type == 1 && <span onClick={() => setMode(p => (p + 1) % 2)} className={styles.link}>{mode == 0 ? t('sign_remotely') : t('sign_using_this_device')}</span>}
        </div>
        {mode == 0 ? localSignature : <ShareLink id={docId} saveRemoteSignature={saveRemoteSignature} />}
        <div className={styles.buttons}>
          {mode == 0 && <div className={styles.buttonWrapper}>
            <Button className={styles.button} title={t('clear')} color={'red'} onClick={clear} transparent border/>
          </div>}
          <div className={styles.buttonWrapper}>
          <Button className={styles.button} title={t('done')} onClick={() => {
            canvas?.current ? saveLocalSignature() : close();
          }}/>
        </div>
      </div>
      </div>
    </div>
  </div>
}

export default SignaturePad

const ShareLink = ({id, saveRemoteSignature = () => {}}) => {

  const {t} = useTranslation()
  const T = (string='') => t(string.toLowerCase().replaceAll(' ', '_'))

  const generateLink = async (id) => {
    const res = await getRemoteSigningToken(id)
    const token = res?.body?.data.slice(11)
    setLink(token)
    saveRemoteSignature();
  }

  const [link, setLink] = useState(null)
  const [copied, setCopied] = useState(false)
  return <div className={styles.columns}>

      <div className={styles.hint}>
        <p>{T('Share the following link to your customer or ask him to scan the QR code with his device to sign the document contactless')}</p>
        {copied && <p>{T("Don't forget to send the link to your client. It will be expired in 5 days")}</p>}
      </div>

    <div className={styles.codeArea}>{link !== null ?
      <QRCode  className={styles.code} value={SIGNATURE_BASE_URL + link} size={250}/> :
      <div onClick={() => generateLink(id)} className={styles.placeholder}>{t('get_qr')}</div>}

      {link !== null ?
        <CopyToClipboard text={SIGNATURE_BASE_URL + link} onCopy={() => setCopied(true)}>
          <span className={styles.link}>{copied ? t('link_copied') : t('copy_link')}</span>
        </CopyToClipboard>
        :  <span className={styles.link} onClick={() => generateLink(id)}>{link ? t('copy_link') : t('get_link')}</span>}

    </div>
  </div>
}
