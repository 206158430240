import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from '../../LocaleContext';
import Button from '../../../components/common/core/Button';
import styles from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import { phoneIcon, downloadIcon } from './icons';
import { RestrictionContext } from '../RestrictionContext';

export default ({ isOpen = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { modal, closeModal } = useContext(RestrictionContext);
  const onSuccess = () => {
    navigate('/payment');
    closeModal();
  };

  if (modal) {
    return (
      <Modal
        show={isOpen}
        contentClassName={styles.modal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        style={{
          textAlign: 'center',
          backgroundColor: 'rgba(85, 69, 144, 0.17)',
        }}
      >
        <Modal.Header style={{ border: 'none' }}>
          <div className={styles.modalHeaderContent}>
            <div>
              <div
                className={styles.modalHeaderWrapper}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{ width: '70px', height: '50px', overflow: 'hidden' }}
                >
                  <img
                    style={{ width: '70px' }}
                    src="https://fastboss.ai/wp-content/uploads/2020/09/logo-FASTBOSS-ON-WEB.png"
                  />
                </div>
                <Modal.Title className={styles.modalTitle}>
                  {t('your_account_is_suspended')}
                </Modal.Title>
              </div>
            </div>
            <span className={styles.modalSubHead}>
              {t('though_you_have_access_to_view_the_Fastboss_platform.')}
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <ul style={{ paddingRight: '0' }} className={styles.modalBodyList}>
            <li className={styles.modalBodyItem}>
              <img className={styles.modalBodyItemImg} src={downloadIcon} />
              <p className={styles.modalBodyItemTitle}>
                {t('you_can_download_documents_and_company_details.')}
              </p>
            </li>
            <li className={styles.modalBodyItem}>
              <img className={styles.modalBodyItemImg} src={phoneIcon} />
              <p className={styles.modalBodyItemTitle}>
                {t('can_access_fastboss_mobile_with_limited_functions')}
              </p>
            </li>
          </ul>

          <p className={styles.modalParagraph}>
            {t('if_you_want_subscription_choose_')}{' '}
            <span class={styles.bold}>{t('upgrade_plan')}</span>
          </p>
          <p className={styles.modalParagraph}>
            {t('if_you_want_corporate_account_choose_')}
            <span class={styles.bold}> {t('corporate_account')}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <div className={styles.modalFooterActions}>
            <a
              href="https://fastboss.ai/fastboss_home/prices/"
              style={{ textDecoration: 'none' }}
            >
              <Button height={50} title={t('corporate_account')} />
            </a>
            <Button height={50} title={t('upgrade_plan')} onClick={onSuccess} />
          </div>
          <span className={styles.footerTitle}>
            {t('support_team_will_estimate_price_for_your_company')}
          </span>
        </Modal.Footer>
      </Modal>
    );
  } else return null;
};
