import { POST, DELETE } from "../network";
import { documentEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
  try {
    return await POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const deleteData = async (path, data) => {
  try {
    return await DELETE(path, data)
  } catch (e) {
    console.error(path, e);
  }
}

export const createDocument = async data => {
    await setData(documentEndpoints.create, data);
};

export const updateDocument = async data => {
    await setData(documentEndpoints.update, data);
};
  
export const removeDocument = async id => {
    await deleteData(`${documentEndpoints.remove}${id}`);
};