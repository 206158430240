import React, { useState } from "react";
import cn from "classnames";
import styles from "./style.module.css";
import Row from "react-bootstrap/Row";
import ElectByIndex from "../../core/ElectByIndex";
import Col from "react-bootstrap/Col";
import TouchableOpacity from "../../core/TouchableOpacity";

const DashboardTabs = ({ tabs, startTabIndex = 0, readyContents, setTabIndex = () => {}, tabIndex = null }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(startTabIndex);

  const isTabIndex = tabIndex ? tabIndex : activeTabIndex
  // tabs
  const tabComponents = tabs.map((tab, index) => (
    <Tab
      key={index}
      name={tab.name}
      style={tab.style}
      active={index === isTabIndex}
      onClick={() => {
        if (tab.onClick) {
          tab.onClick();
        } else {
          setActiveTabIndex(index);
          setTabIndex(index);
          if (tab.onChange) tab.onChange(index);
        }
      }}
    />
  ));

  // right-side buttons
  const controllers = (
    <ElectByIndex className={styles.controllers} index={isTabIndex}>
      {tabs.map(
        (tab, tabId) =>
          tab.controllers &&
          tab.controllers.map((controller, controllerId) => (
            <Col key={tabId * 100 + controllerId} className={styles.controller}>
              {controller}
            </Col>
          ))
      )}
    </ElectByIndex>
  );

  // main contents
  const contents = (
    <ElectByIndex index={isTabIndex}>{readyContents}</ElectByIndex>
  );

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.tabs}>{tabComponents}</div>
        <div className={styles.controllersWrapper}>{controllers}</div>
      </div>
      {contents}
    </div>
  );
};

export const Tab = ({ name, active = true, onClick = () => {}, style }) => (
  <TouchableOpacity
    style={style}
    onClick={onClick}
    className={cn(styles.tab, active && styles.active)}
  >
    {name}
  </TouchableOpacity>
);

export default DashboardTabs;
