import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from '../../../../contexts/LocaleContext';
import editorStyles from './editorStyles';
import styles from './styles.module.css';
import Bar from '../Bar';

const LivePreview = ({
  onClose = () => {},
  getPreview = async () => '',
  isVisible = false,
  step = 0,
}) => {
  const { t } = useTranslation();
  const [content, setContent] = useState('');
  const webView = useRef(null);

  useEffect(() => {
    setContentAsync();
  }, [step, isVisible]);

  const setContentAsync = async () => {
    if (!isVisible) {
      setContent('');
      return;
    }
    const cont = await getPreview();
    setContent(cont);
  };

  let initContainerState = `
    <hmtl>
      <head>
        <style>${editorStyles}</style>
        <meta name="viewport" content="initial-scale=0.6">
      </head>
      <body style="display:flex; justify-content: center; user-select: none">
        <div id="preview-root" class="ql-container ql-editor">
            ${content}
        </div>
      </body>
    </hmtl>
  `;

  useEffect(() => {
    let field = document.getElementById('current-field');
    if (field)
      field.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'center',
      });
  });

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.box}>
          <Bar close={onClose} title={'Live preview'} />
          {/*<Bar goBack={onClose} title={t('Document preview')} vertical/>*/}
          <div className={styles.editorWrapper}>
            <div
              ref={webView}
              dangerouslySetInnerHTML={{ __html: initContainerState }}
              className={styles.webView}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivePreview;
