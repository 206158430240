import React, { useEffect, useState, useContext } from 'react';
import styles from './search.module.css';
import { useTranslation } from '../../../../contexts/LocaleContext';
import SearchWrapper from './SearchWrapper';
import { getTemplatesByKeywords } from '../../../../api/Template/dataGetters';
import { AssistantContext } from '../../../../contexts/AssistantContext';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../utils/StringUtils';
import TabContext, { EditorType } from '../../../../contexts/TabContext';

const getTableDataFromResponse = (data, openTemplate) => {
  if (!data?.length) return [];

  return data.map((template, index) => {
    return {
      id: template.id || index,
      classNames: {
        bar: styles.templateBar,
      },
      columns: [
        {
          className: styles.templateColumn,
          element: template.id,
        },
        {
          className: styles.templateColumn,
          element: template.title,
        },
        {
          className: styles.templateColumn,
          element: formatDate(template.createdAt),
        },
      ],
      onClick: openTemplate(template),
    };
  });
};

const SearchTemplates = ({ keywords = [], onClose = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openEditor } = useContext(TabContext);
  const { close: closeAssistant } = useContext(AssistantContext);
  const [isLoading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [legend, setLegend] = useState({
    classNames: {
      bar: styles.templateLegendBar,
    },
    columns: [
      {
        icon: 'fas fa-hashtag',
        title: t('code'),
      },
      {
        icon: 'fas fa-file',
        title: t('title'),
      },
      {
        icon: 'fas fa-calendar-day',
        title: t('date'),
      },
    ],
  });

  const openTemplate = (template) => () => {
    closeAssistant();
    openEditor({
      id: template.id,
      editorTitle: template.title,
      editorContent: template.content,
      editorKeywords: template.keywords,
      editorIsPublic: template.is_public,
      editorType: EditorType.EDIT_TEMPLATE,
    });
  };

  useEffect(() => {
    setLoading(true);
    getTemplatesByKeywords(keywords).then((templates) => {
      setTableData(getTableDataFromResponse(templates, openTemplate));
      setLoading(false);
    });
  }, []);

  return (
    <SearchWrapper
      query={keywords.join(' ')}
      legend={legend}
      tableData={tableData}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default SearchTemplates;
