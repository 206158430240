import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from "../../../../contexts/LocaleContext";
import DataContext from "../../../../contexts/DataContext";
import styles from './styles.module.css'
import TouchableOpacity from "../../../common/core/TouchableOpacity";
import logo from '../../images/logo-short.png'

const Bar = ({close=()=>{}, title='', withAvatar=false}) => {
    const [avatarRemote, setAvatarRemote] = useState('');

    const {assistant} = useContext(DataContext);
    const {avatarUrl} = assistant.data;

    const {t} = useTranslation()

    useEffect(() => {
        if (!avatarUrl) return;

        const pathname = avatarUrl.split('.').pop()
        const isImagePathname = pathname.includes('png', 'jpg', 'jpeg', 'gif', 'png', 'svg')
        if (isImagePathname) {
            setAvatarRemote(avatarUrl)
        }
    }, [avatarUrl])

    return <div className={styles.bar}>
         <div className={styles.avatar}>{withAvatar ? <img alt="ai-assistance avatar" src={avatarRemote || logo}/> : <div/>}</div>
        <div className={styles.heading}>{t(title)}</div>
        <TouchableOpacity onClick={close} className={styles.closeButton}><i  style={{fontSize: 20}} className={'fas fa-times'}/></TouchableOpacity>
    </div>
}

export default Bar
