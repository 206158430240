import React, {useContext, useEffect, useState} from "react";
import Pagination from "rc-pagination";
import { useParams } from "react-router-dom";
import styles from './style.module.css'
import documentsStyles from '../Documents/style.module.css'
import { Filters } from "../Products";
import Wrapper from "../../../common/core/Wrapper";
import DashboardTabs from "../../../common/parts/DashboardTabs";
import Button from "../../../common/core/Button";
import { SelectInput } from "../../../common/core/Input";
import ServicesTab from "./ServicesTab";
import CataloguesTab from "../../../common/parts/CataloguesTab";
import ModalService from "./ModalService";
import ElectByIndex from "../../../common/core/ElectByIndex";
import Enum from "../../../../utils/enum";
import UploadPdfModal from "../../../common/parts/UploadPdfModal";
import DataContext, { useLoaded } from "../../../../contexts/DataContext";
import { useCvsIO } from "../../../common/parts/ImportCsvModal";
import regularTableControllers from "../../../common/parts/regularTableControllers";
import {useTranslation} from "../../../../contexts/LocaleContext";
import GuideContext from "../../../Onboarding";
import { useRestriction } from "../../../../contexts/Restriction/RestrictionContext"
import { getServiceCataloguesData } from "../../../../api/File/dataGetters";

const [SERVICE_MODAL] = Enum(1);

function Services() {
  const params = useParams();
  const { withRestrictions } = useRestriction();
  const [modalIndex, setModalIndex] = useState(null);
  const { isLoaded: isServiceCataloguesLoaded, serviceCatalogues } = useLoaded(
    "serviceCatalogues"
  );
  const { services } = useContext(DataContext);
  const {t} = useTranslation()
  const {setPage, markDone} = useContext(GuideContext)
  useEffect(()=>{
    setPage('services')
  },[])

  const [fetchedCatalogs, setFetchedCatalogs] = useState({
    items: null,
    loading: false,
    totalItems: 1
  });

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState({
    by:"updatedAt",
    order:1
  });

  useEffect(() => {
    if (!services.isLoaded || !params.id) return;

    services.getItemById(params.id).then((service) => {
      if (service?.id) openEditModal(service);
    });
  }, [params, services.isLoaded]);

  const openFilter = () => setIsFilterOpen(true);
  const closeFilter = () => setIsFilterOpen(false);

  const onReset = () => {
    if (filters && Object.keys(filters).length !== 0) {
      setFilters({});
    }
  };

  const onFilter = (filters, force) => {
    if (force) {
      return setFilters(filters);
    }
    if (filters && Object.keys(filters).length !== 0) {
      setFilters(filters);
    }
  };

  const [{
    size: pageSize,
    current: pageCurrent,
    sizeOptions: pageSizeOptions,
  }, setPagination] = useState({
    size: 10,
    current: 1,
    sizeOptions: [5, 10, 15, 20, 50],
  });

  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const onUploadPdfClick = () => {
    markDone('add catalogue')
    setIsPdfModalOpen(true);
  };
  const saveFile = async (file) => {
    await serviceCatalogues.uploadFile(file);
    await fetchData();
    setPagination((prev) => ({...prev, current: 1}));
    setIsPdfModalOpen(false);
  };
  const removeFile = async (fileId) => {
    await serviceCatalogues.remove(fileId);
    await fetchData();
    setPagination((prev) => ({...prev, current: 1}));
  };
  const uploadPdfModal = isPdfModalOpen && (
    <UploadPdfModal
      onClose={() => setIsPdfModalOpen(false)}
      saveFile={saveFile}
    />
  );

  const openEditModal = service => {
    setEditingValues(service);
    setModalIndex(SERVICE_MODAL);
  };

  const [editingValues, setEditingValues] = useState({});

  const closeModal = () => {
    setEditingValues({})
    setModalIndex(null)
  };

  const modals = [
    <ModalService editingValues={editingValues} close={closeModal} />
  ];

  const { importCsvModal, importCSV, exportCSV, exportWaiting } = useCvsIO(
    services,
    "Name,Price,UnitMeasure,Producer,GeneralDescription,Delivery,Keywords"
  );

  const paginationLocale = {
    items_per_page: t('items_per_page'),
    jump_to: t('jump to'),
    jump_to_confirm: t('jump to confirm'),
    page: t('page'),

    prev_page: t('prev page'),
    next_page: t('next page'),
    prev_5: t('prev 5'),
    next_5: t('next 5'),
    prev_3: t('prev 3'),
    next_3: t('next 3'),
  };

  const fetchData = async (sortBy, withoutLoading) => {
    !withoutLoading && setFetchedCatalogs((prev) => ({...prev, loading: false}))
    const { items, pages } = await getServiceCataloguesData({
        ...filters,
        pageSize: pageSize,
        Page: pageCurrent,
        SortBy: sortBy || sort.by,
        SortOrder: sort.order,
    });
    setFetchedCatalogs( (prev) => ({...prev, items, totalItems: pages * pageSize, loading:true}));
  }

  useEffect(() => {
    fetchData();
  }, [sort.data, sort.order, pageSize, pageCurrent, filters])

  function onChange(current, pageSize) {
    setPagination((prev) => ({...prev, current, size: pageSize}))
  };

  const onPerPageChange = (e) => {
    const { value } = e.target;
    setPagination((prev) => ({...prev, size: value, current: 1}));
  };

  const TABS = [
    {
      name: t("services").toUpperCase(),
      content: <ServicesTab openEditModal={openEditModal} />,
      controllers: regularTableControllers({
        t,
        addNew: withRestrictions(() => {
          markDone('add service')
          setModalIndex(0)
        }),
        importCSV: (p) => withRestrictions((p)=>{
          markDone('import csv')
          importCSV(p)
        })(p),
        exportCSV: (p)=>{
          markDone('export csv')
          exportCSV(p)
        },
        exportWaiting
      })
    },
    {
      name: t("catalogues").toUpperCase(),
      content: (
        <div>  
          <CataloguesTab
            items={fetchedCatalogs.items}
            loaded={fetchedCatalogs.loading}
            cataloguesData={serviceCatalogues}
            removeFile={withRestrictions(removeFile)}
          />
          <div className={styles.pagination}>
            <Pagination
              className={documentsStyles.PaginationElement}
              current={pageCurrent}
              showSizeChanger
              pageSize={pageSize}
              defaultCurrent={3}
              total={fetchedCatalogs.totalItems}
              locale={paginationLocale}
              onChange={onChange}
              showLessItems={true}
            />
            <SelectInput
              field={'Per Page:'}
              placeholder={t('Per Page')}
              options={pageSizeOptions.map((i) => ({value:i, label:i}))}
              onChange={onPerPageChange}
              noDefaultMargin
              fieldState={{value: pageSize}}
            />   
          </div>
        </div>
      ),
      controllers: [
        <div style={{display:"flex", alignItems:"center", overflow: 'hidden'}}>
          <Button
            onClick={withRestrictions(onUploadPdfClick)}
            title={t("upload_pdf_catalogue")}
            height={27} 
            fontSize={"0.9rem"}
          />
          <div className={documentsStyles.filterButton }>
            <Button
              thin
              transparent
              onClick={() => openFilter()}
              title={<i class="fas fa-filter" aria-hidden="true"></i>}
              height={28}
              width={40}
            />
          </div>
      </div> 
      ]
    }
  ];

  const contents = TABS.map(item => <Wrapper>{item.content}</Wrapper>);

  const [tabIndex, setTabIndex] = useState(0);

  const main = (
    <DashboardTabs
      tabs={TABS}
      readyContents={contents}
      startTabIndex={0}
      setTabIndex={setTabIndex}
    />
  );

  return (
    <div className={tabIndex === 1 ? documentsStyles.container : ''}>
      <div className={documentsStyles.documentsWrapper}>
        <ElectByIndex index={modalIndex} defaultComponent={main}>
          {modals}
        </ElectByIndex>
      </div>
      {tabIndex === 1 &&
        (
          <Filters
            isOpen={isFilterOpen}
            open={openFilter}
            close={closeFilter}
            onSubmit={onFilter}
            reset={onReset}
          />
        )
      }
      {uploadPdfModal}
      {importCsvModal}
    </div>
    
  );
}

export default Services;
