import { POST } from "../network";
import { businessEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
  try {
    return await POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

export const setBusinessData = async data => {
    await setData(businessEndpoints.update, data);
};
  
export const createBusiness = async data => {
    await setData(businessEndpoints.create, data)
}