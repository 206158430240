import React, { useContext, useRef, useState, useEffect } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { Form } from 'informed';
import TabContext, { EditorType } from '../../../../contexts/TabContext';
import DataContext from '../../../../contexts/DataContext';
import styles from './style.module.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TouchableOpacity from '../../../common/core/TouchableOpacity';
import { AssistantContext } from '../../../../contexts/AssistantContext';
import { formatDate } from '../../../../utils/StringUtils';
import DropDown, { ThreeDots } from '../../../common/core/DropDown';
import { copyTemplate } from '../../../../api/SharedTemplate/dataSetters';
import { toast } from 'react-toastify';
import { Info, TabLink } from '../../../common/core/Toasts';
import { useTranslation } from '../../../../contexts/LocaleContext';
import GuideContext from '../../../Onboarding';
import { useRestriction } from '../../../../contexts/Restriction/RestrictionContext';
import cn from 'classnames';
import { PulseLoader } from 'react-spinners/dist';
import { SelectInput } from '../../../common/core/Input';
import { useMediaQuery } from 'react-responsive';
import documentStyles from '../Documents/style.module.css';
import Checkbox from '../../../common/core/Checkbox';
import Button from '../../../common/core/Button';
import Pagination from '../../../common/core/Pagination';

const ITEM_HEIGHT = {
  mobile: 90,
  desktop: 50,
};

const Board = ({
  emptyHint,
  items,
  totalItems,
  sharedMode,
  switchBoardText,
  switchBoardAction,
  loaded,
  share = () => {},
  onSort,
  sort,
  pageCurrent,
  pageSize,
  onShowSizeChange,
  onChangePage,
  pageSizeOptions,
  onPerPageChange,
  withSort = true,
  withPagination = true,
  onDeleteTemplate,
  createButton,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 980px)',
  });

  const paginationLocale = {
    items_per_page: t('items_per_page'),
    jump_to: t('jump to'),
    jump_to_confirm: t('jump to confirm'),
    page: t('page'),

    prev_page: t('prev page'),
    next_page: t('next page'),
    prev_5: t('prev 5'),
    next_5: t('next 5'),
    prev_3: t('prev 3'),
    next_3: t('next 3'),
  };

  const fixedSize =
    items && items.length > 0
      ? (isMobile ? ITEM_HEIGHT.mobile : ITEM_HEIGHT.desktop) * items.length +
        items.length * 7 +
        'px'
      : (isMobile ? ITEM_HEIGHT.mobile : ITEM_HEIGHT.desktop) * 7 + 'px';

  const [selectAll, setSelectAll] = useState(false);

  const selectAllHandler = () => {
    setSelectAll(!selectAll);
  };

  const deleteSelectedHandler = async (state) => {
    const selectedItems = [];

    if (selectAll) {
      const allItems = items.map((item) =>
        onDeleteTemplate(item.id).then(() => setSelectAll(false))
      );
      return;
    }

    for (const property in state) {
      if (state[property]) {
        const id = property.slice(7);
        selectedItems.push(Number(id));
      }
    }

    const promises = selectedItems.map((id) => {
      return onDeleteTemplate(id);
    });
  };

  return (
    <Form onSubmit={deleteSelectedHandler}>
      <Row className="pl-2">
        <Button
          thin
          type="submit"
          title={t('delete_selected')}
          color="#D92D15"
          height={28}
          width={150}
        />
        {createButton}
      </Row>
      <div className={styles.boardContainer}>
        <Col>
          <Row className={styles.board}>
            {withSort && (
              <TemplateHead
                sharedMode={sharedMode}
                selectAllHandler={selectAllHandler}
                selectAll={selectAll}
                onSort={onSort}
                activeSort={sort.by}
                order={sort.order}
              />
            )}
            {loaded ? (
              <div
                style={{
                  width: '100%',
                  height: fixedSize,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <>
                  {items?.length === 0 ? (
                    <div className={styles.createYourFirst}>
                      {emptyHint || t('you_have_no_templates_yet._create_one')}
                    </div>
                  ) : (
                    items?.map((item, index, array) => (
                      <BoardItem
                        sharedMode={sharedMode}
                        fieldName={`select-${item.id}`}
                        selectAll={selectAll}
                        isLast={index === array?.length - 1}
                        onDeleteItem={onDeleteTemplate}
                        info={item}
                        key={item.id}
                        share={share}
                      />
                    ))
                  )}
                </>
              </div>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: fixedSize,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 64px',
                }}
              >
                <PulseLoader color={'#bfbfbf'} />
              </div>
            )}
          </Row>
          {withPagination && (
            <div
              className={styles.Pagination}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '30px',
              }}
            >
              <Pagination
                className={documentStyles.PaginationElement}
                current={pageCurrent}
                // showSizeChanger
                pageSize={pageSize}
                onShowSizeChange={onShowSizeChange}
                // defaultCurrent={3}
                total={totalItems}
                locale={paginationLocale}
                onChange={onChangePage}
                // showLessItems={true}
              />
              {/* <div className={styles.perPage} >
                  <SelectInput
                    field={'Per Page:'}
                    placeholder={t('Per Page')}
                    options={pageSizeOptions.map((i) => ({value:i, label:i}))}
                    onChange={onPerPageChange}
                    noDefaultMargin
                    fieldState={{value: pageSize}}
                  />
                </div> */}
            </div>
          )}
        </Col>
      </div>
    </Form>
  );
};

const BOARD_ITEM_ICON_SIZE = 25;

const BoardItem = ({
  info: template,
  share,
  onDeleteItem,
  isLast,
  fieldName,
  selectAll,
  sharedMode,
}) => {
  const { t } = useTranslation();
  const assistant = useContext(AssistantContext);
  const { openEditor } = useContext(TabContext);
  const { templates } = useContext(DataContext);
  const { withRestrictions } = useRestriction();

  function editTemplate() {
    openEditor({
      id: template.id,
      editorTitle: template.title,
      editorContent: template.content,
      editorKeywords: template.keywords,
      editorIsPublic: template.is_public,
      editorType: EditorType.EDIT_TEMPLATE,
    });
  }

  function deleteTemplate() {
    onDeleteItem(template.id);
  }

  const saveToMyTemplates = async () => {
    const res = await copyTemplate({ templateId: template.id });
    const title = res.body?.data?.title;
    toast(<Info>{t('saved_to_my_templates_as') + ': ' + title}</Info>);
    await templates._getList();
  };

  function shareTemplate() {
    share(template.id);
  }

  const dotsRef = useRef(null);

  const regularDropDownOptions = [
    {
      name: t('edit'),
      action: editTemplate,
    },
    {
      name: t('share'),
      action: withRestrictions(shareTemplate),
    },
    {
      name: t('delete'),
      action: withRestrictions(deleteTemplate),
    },
  ];

  const sharedDropDownOptions = [
    {
      name: t('save_to_my_templates'),
      action: saveToMyTemplates,
    },
  ];

  const defaultDropDownOptions = [
    {
      name: t('open'),
      action: editTemplate,
    },
  ];

  const { markDone } = useContext(GuideContext);

  const dropDownItems = template.isDefault
    ? defaultDropDownOptions
    : template.isShared
    ? sharedDropDownOptions
    : regularDropDownOptions;

  const onTabClick = ({ action = () => {} }) => action();

  const [onCheckbox, setOnCheckbox] = useState(false);

  const checkBoxHandler = () => {
    setOnCheckbox(!onCheckbox);
  };

  const email =
    sharedMode === 'Received' ? template.senderEmail : template.recepientEmail;
  return (
    <ContextMenuTrigger id={template.id}>
      <Row>
        <Col
          xs={1}
          className={styles.documentContainer}
          style={isLast ? { borderColor: 'transparent' } : {}}
        >
          <Checkbox
            field={fieldName}
            className={styles.checkbox}
            onChange={checkBoxHandler}
            value={selectAll ? true : onCheckbox}
          />
        </Col>
        <Col
          xs={11}
          className={styles.documentContainer}
          style={isLast ? { borderColor: 'transparent' } : {}}
        >
          <TouchableOpacity
            className={cn(styles.listItem, styles.item)}
            onClick={() => {
              assistant.open(template);
              markDone('use default');
            }}
          >
            <span className={styles.documentTitle}>{template.title}</span>
            {sharedMode !== 'Received' && sharedMode !== 'Sent' && (
              <>
                <span className={styles.documentAuthor}>{template.author}</span>
                <span className={styles.documentDepartment}>
                  {template.dep_name}
                </span>
              </>
            )}
            {(sharedMode === 'Received' || sharedMode === 'Sent') && (
              <div className={styles.documentEmail}>{email}</div>
            )}
            <div className={styles.documentKeyWords}>
              {template.keywords.split(',').map((i, index, arr) => (
                <KeyWord title={i + (index === arr.length - 1 ? '' : ',')} />
              ))}
            </div>
            <div className={styles.documentDateCreated}>
              {formatDate(template.created_at)}
            </div>
            <div className={styles.documentDateUpdated}>
              {formatDate(template.updated_at)}
            </div>
            <div className={styles.documentOptions}>
              {<ThreeDots ref={dotsRef} />}
              <DropDown dropDown={dropDownItems} rootRef={dotsRef} />
            </div>
          </TouchableOpacity>
          <ContextMenu id={template.id} className={styles.contextMenu}>
            {dropDownItems.map((item) => {
              const { name } = item;
              return (
                <MenuItem
                  key={name}
                  onClick={(e) => {
                    e.stopPropagation();
                    onTabClick(item);
                  }}
                  className={styles.contextMenuItem}
                >
                  {name}
                </MenuItem>
              );
            })}
          </ContextMenu>
        </Col>
      </Row>
    </ContextMenuTrigger>
  );
};

const Tag = ({ title }) => {
  return <div className={styles.tag}>{title}</div>;
};

const KeyWord = ({ title }) => {
  return <span className={styles.keyword}>{title}</span>;
};

export const TemplateHead = ({
  onSort,
  activeSort,
  order,
  selectAllHandler,
  selectAll,
  sharedMode,
}) => {
  const sharedSortType =
    sharedMode === 'Received' ? 'senderEmail' : 'recepientEmail';

  return (
    <Row>
      <Col xs={1} className={cn(styles.documentContainer, styles.documentHead)}>
        <Checkbox
          onChangeHandler={selectAllHandler}
          field={'selectAll'}
          value={selectAll}
          className={styles.checkbox}
        />
      </Col>
      <Col
        xs={11}
        className={cn(styles.documentContainer, styles.documentHead)}
      >
        <div className={cn(styles.listItem, styles.item, styles.itemHead)}>
          <span className={cn(styles.documentTitle, styles.tableHead)}>
            <span style={{ cursor: 'pointer' }} onClick={() => onSort('title')}>
              Title
            </span>
            {activeSort === 'title' && (
              <i
                style={{
                  transform: order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
                className={cn('fas fa-long-arrow-alt-up', styles.headArrowIcon)}
              ></i>
            )}
          </span>
          {sharedMode !== 'Received' && sharedMode !== 'Sent' && (
            <>
              <span className={cn(styles.documentDepartment, styles.tableHead)}>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => onSort('dep_name')}
                >
                  Department
                </span>
                {activeSort === 'dep_name' && (
                  <i
                    style={{
                      transform:
                        order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                    className={cn(
                      'fas fa-long-arrow-alt-up',
                      styles.headArrowIcon
                    )}
                  ></i>
                )}
              </span>
              <span className={cn(styles.documentAuthor, styles.tableHead)}>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => onSort('author')}
                >
                  Author
                </span>
                {activeSort === 'author' && (
                  <i
                    style={{
                      transform:
                        order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                    className={cn(
                      'fas fa-long-arrow-alt-up',
                      styles.headArrowIcon
                    )}
                  ></i>
                )}
              </span>
            </>
          )}
          {(sharedMode === 'Received' || sharedMode === 'Sent') && (
            <div className={cn(styles.documentEmail, styles.tableHead)}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => onSort(sharedSortType)}
              >
                Email
              </span>
              {activeSort === sharedSortType && (
                <i
                  style={{
                    transform: order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                  className={cn(
                    'fas fa-long-arrow-alt-up',
                    styles.headArrowIcon
                  )}
                ></i>
              )}
            </div>
          )}
          <div
            className={cn(
              styles.documentTags,
              styles.documentTagsHeader,
              styles.tableHead
            )}
          >
            Tags
          </div>
          <div className={cn(styles.documentKeyWords, styles.tableHead)}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => onSort('keywords')}
            >
              Keywords
            </span>
            {activeSort === 'keywords' && (
              <i
                style={{
                  transform: order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
                className={cn('fas fa-long-arrow-alt-up', styles.headArrowIcon)}
              ></i>
            )}
          </div>
          <div className={cn(styles.documentDateCreated, styles.tableHead)}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => onSort('createdAt')}
            >
              Created Date
            </span>
            {activeSort === 'createdAt' && (
              <i
                style={{
                  transform: order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
                className={cn('fas fa-long-arrow-alt-up', styles.headArrowIcon)}
              ></i>
            )}
          </div>
          <div className={cn(styles.documentDateUpdated, styles.tableHead)}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => onSort('updatedAt')}
            >
              Updated Date
            </span>
            {activeSort === 'updatedAt' && (
              <i
                style={{
                  transform: order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
                className={cn('fas fa-long-arrow-alt-up', styles.headArrowIcon)}
              ></i>
            )}
          </div>
          <div className={cn(styles.documentOptions)}></div>
        </div>
      </Col>
    </Row>
  );
};

export default Board;
