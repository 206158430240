import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import Button from '../../core/Button';
import Card from '../Card';
import Search from '../Search';
import { useTranslation } from '../../../../contexts/LocaleContext';
import { useNavigate } from 'react-router-dom';
import { useRestriction } from '../../../../contexts/Restriction/RestrictionContext';

const ScreenHeader = ({
  className,
  onCreate = () => {},
  onSearch = () => {},
}) => {
  const { t } = useTranslation();
  const { withRestrictions } = useRestriction();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const onSearchChange = (event) => setSearchQuery(event.target.value || '');
  useEffect(() => {
    const throttleTimer = setTimeout(() => onSearch(searchQuery), 500);
    return () => clearTimeout(throttleTimer);
  }, [searchQuery, onSearch]);

  return (
    <Card className={cn(styles.wrapper, className)}>
      <div className={cn(styles.filters)}>
        <button className={cn(styles.filterButton)} type="button">
          <i className={cn(styles.filterButtonIcon, 'fas fa-filter')}></i>
        </button>
      </div>

      <div className={cn(styles.main)}>
        <Button
          className={cn(styles.addButton)}
          width={125}
          color="#5ED615"
          title={<>{t('Create New')}</>}
          onClick={onCreate}
        />
        <div className={cn(styles.line)} />
        <Search
          className={cn(styles.search)}
          width={168}
          height={32}
          fontSize={14}
          inputProps={{ onChange: onSearchChange }}
        />
      </div>
    </Card>
  );
};

export default ScreenHeader;
