import React, { useContext } from "react"
import Image from 'react-bootstrap/Image'

import styles from "./style.module.css"
import DataContext from "../../../contexts/DataContext"
import logo from "../images/logo-short.png"

const TutorialAssistant = ({show = false}) => {
    const { assistant } = useContext(DataContext)
    const { avatarUrl } = assistant.data

    return (
        <div className={show ? styles.overlay : styles.hideTutorialAvatar}>
            <Image src={avatarUrl ? avatarUrl : logo} fluid={false} rounded={false} roundedCircle={true} className={styles.avatar}/>
        </div>
    )
}

export default TutorialAssistant