import DashboardTabs from "../../../common/parts/DashboardTabs";
import React, {useContext, useEffect, useState} from "react";
import Button from "../../../common/core/Button";
import Enum from "../../../../utils/enum";
import ElectByIndex from "../../../common/core/ElectByIndex";
import EditableTable from "../../../common/parts/EditableTable";
import ModalActivity from "./ModalActivity";
import moment from "moment";
import {formatDate, isEmpty, Option} from "../../../../utils/StringUtils";
import cn from 'classnames';
import styles from './style.module.css';
import DataContext from "../../../../contexts/DataContext";
import {getPagedActivitiesGetter} from "../../../../api/CustomerActivity/dataGetters";
import {useTranslation} from "../../../../contexts/LocaleContext";
import GuideContext from "../../../Onboarding";
import { useRestriction } from "../../../../contexts/Restriction/RestrictionContext"

const [ACTIVITY_MODAL] = Enum(1);

export const ActivityTypes = {
  EMAIL: Option(0, 'Email'),
  CALL: Option(1, 'Call'),
  NOTE: Option(2, 'Note'),
  MEETING: Option(3, 'Meeting'),
  CONTRACT: Option(4, 'Contract'),
  INVOICE: Option(5, 'Invoice'),
  PRODUCT: Option(6, 'Product'),
  TRANSFER: Option(7, 'Transfer'),
  DELIVERY: Option(8, 'Delivery'),
};

export const getActivitiesByCustomerId = (activities, customerId) => {
  return activities.filter(
    ({customerid}) => customerid === customerId
  );
};

export const activityHighlightColor = 'rgba(255,221,138,0.51)';

export const shouldHighlightActivity = (activity) => {
  return moment(activity.nextactiondate).isSameOrBefore(moment(), 'day');
};

const Activities = ({customerId, onClose}) => {
  const [modalIndex, setModalIndex] = useState(null);
  const {t} = useTranslation()

  const {activities} = useContext(DataContext);
  useEffect(()=>{
    activities.load()
  }, [])
  const customerActivities = getActivitiesByCustomerId(activities.data, customerId);
  const { withRestrictions } = useRestriction();

  const [editingValues, setEditingValues] = useState({});

  const {markDone} = useContext(GuideContext)

  const openEditingModal = (activity) => {
    setEditingValues(activity);
    setModalIndex(ACTIVITY_MODAL);
  };

  const columns = [{
    Header: 'CODE#',
    accessor: 'id',
    width: 80,
    index: 0,
  },{
    Header: 'TEXT',
    filter: true,
    accessor: 'text',
    index: 1,
  },{
    Header: 'CREATED AT',
    // todo on back
    accessor: 'createdAt',
    format: value => value || moment().format('DD.MM.YYYY'),
    filter: true,
    width: 130,
    index: 2,
  },{
    Header: 'TYPE',
    accessor: 'type',
    format: (value) => {
      const option = Object.values(ActivityTypes).find(opt => opt.value === value);
      return option ? t(option.label.toLowerCase()) : '';
    },
    filter: true,
    width: 90,
    index: 3,
  },{
    Header: 'NEXT ACTION DATE',
    accessor: 'nextactiondate',
    format: formatDate,
    filter: true,
    width: 200,
    index: 4,
  }];

  const TABS = [{
    name: <i className={cn(styles.backToCustomers, "fas fa-caret-left")}/>,
    onClick: onClose,
  },{
    name: `CUSTOMER #${customerId} ACTIVITIES`,
    style: {marginLeft: '0.5rem'},
    content: (
      <EditableTable
        noWhatText={'activities'}
        data={getActivitiesByCustomerId(activities.data, customerId)}
        getter={getPagedActivitiesGetter(customerId)}
        columns={columns.map((item)=>({...item, Header: t(item.Header.toLowerCase().replaceAll(' ','_')).toUpperCase()}))}
        deleteItem={activities.remove}
        editItem={openEditingModal}
      />
    ),
    controllers: [
      <Button
        title={t('add_new_')}
        onClick={()=>setModalIndex(ACTIVITY_MODAL)}
        height={27}
        width={100}
        fontSize={'0.9rem'}
      />,
    ],
  }];

  const submitCreateActivity = async (formState) => {
    markDone('customer activity')
    await activities.create({
      customerid: customerId,
      ...formState
    });
    closeActivityModal();
  };

  const submitEditActivity = async (formState) => {
    await activities.update({
      ...formState,
      id: editingValues.id,
      customerid: customerId,
    });
    closeActivityModal();
  };

  const closeActivityModal = () => {
    setEditingValues({});
    setModalIndex(null);
  };

  const MODALS = [
    <ModalActivity
      onSubmit={isEmpty(editingValues) ? withRestrictions(submitCreateActivity) : withRestrictions(submitEditActivity)}
      close={closeActivityModal}
      editingValues={editingValues}
    />,
  ];

  const main = (
    <DashboardTabs
      tabs={TABS}
      readyContents={TABS.map(tab => tab.content)}
      startTabIndex={1}
    />
  );

  return (
    <ElectByIndex
      index={modalIndex}
      defaultComponent={main}
    >
      {MODALS}
    </ElectByIndex>
  );
};

export default Activities;
