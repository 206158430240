import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Info } from '../../common/core/Toasts';
import { useTranslation } from '../../../contexts/LocaleContext';
import Recorder from 'opus-recorder';
import encoderPath from 'opus-recorder/dist/encoderWorker.min.js';

const blobToDataURL = async (blob) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.readAsDataURL(blob);
});

const useRecorder = (
  onRecordEndCallback,
  onMicrophoneAccessGranted,
  onMicrophoneAccessDenied
) => {
  const recorder = useRef(null);
  const timer = useRef();
  const { t } = useTranslation();
  const verifyPermissionMessage = t(
    'Please verify the audio recording permissions.'
  );

  const onRecordEnd = async (bytes) => {
    const dataBlob = new Blob([bytes], { type: 'audio/ogg' });
    onRecordEndCallback(await blobToDataURL(dataBlob));
  };

  useEffect(() => {
    recorder.current = new Recorder({
      encoderPath,
      encoderSampleRate: 48000,
      originalSampleRateOverride: 16000,
      monitorGain: 0,
      recordingGain: 1,
      numberOfChannels: 1,
    });
    recorder.current.ondataavailable = onRecordEnd;

    return () => {
      recorder.current.close();
      clearTimeout(timer.current)
    }
  }, []);


  const start = (seconds) => {
    try {
      recorder.current.start();
      timer.current = setTimeout(stop, seconds * 1000);
    } catch (error) {
      toast(<Info>{verifyPermissionMessage}</Info>);
    }
  };

  const stop = () => {
    try {
      clearTimeout(timer.current);
      recorder.current.stop();
    } catch (error) {
      toast(<Info>{verifyPermissionMessage}</Info>);
    }
  };

  return [start, stop];
};

export default useRecorder;
