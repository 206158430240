import React, {useEffect, useState, useContext} from "react";
import Avatar from "react-avatar";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import styles from "./style.module.scss"

import { InviteWorkerModal } from "../../../common/Worker";
import CorporateContext from "../../../CorporateContext";

import DocumentStatus from "../../../../../../common/parts/DocumentStatus";
import Button from "../../../../../../common/core/Button";
import useWaiting from "../../../../../../common/hooks/useWaiting";
import FetchLoading from "../../../../../../common/parts/FetchLoading";

import { getUserActivity } from "../../../../../../../api/CompanyAccount/dataGetters";
import { idToColor, addressToStr, formatDate } from "../../../../../../../utils/StringUtils";
import { useTranslation } from "../../../../../../../contexts/LocaleContext";
import TabContext, {EditorType} from "../../../../../../../contexts/TabContext"

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const CardEmployee = ({employee, id, departmentId}) => {
    const { t } = useTranslation();
    const { role } = useContext(CorporateContext);
    const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);

    const getCompanyRoleName = () => {
        switch (employee.role) {
          case 0:
            return "CEO"
          case 1:
            return "Manager"
          case 2: 
            return "Employee"
          default:
            return "CEO"
        }
    }


    return (
        <div className={styles.employeeCard}>
            <div className={styles.employeeCardHeader}>
                <Avatar
                    className={styles.employeeAvatar}
                    size={100}
                    name={employee.fullname}
                    color={idToColor(id)}
                    src={employee.avatar ? "data:image/*;base64," + employee.avatar : null}
                    round
                />
                <div className={styles.employeeFullName}>
                    {employee.fullname}
                </div>
                <div className={styles.employeePositionCompany}>
                    {t(getCompanyRoleName())}, {employee.company_name}
                </div>
            </div>

            <div className={styles.divider}/>

            <div className={styles.employeeActivity}>
                <div className={styles.activityBox}>
                    <span className={styles.activityValue}>{employee.today_docs}</span>
                    <span className={styles.activityDescription}>{t("todays documents")}</span>
                </div>
                <div className={styles.activityBox}>
                    <span className={styles.activityValue}>{employee.size_documents}</span>
                    <span className={styles.activityDescription}>{t("overall documents")}</span>
                </div>
            </div>

            <div className={styles.divider}/>

            <div className={styles.employeeCardMain}>
                <h5 className={styles.detailsHeader}>{t("details")}</h5>
                <div>
                    <span className={styles.detailsDescription}>{t("email")}</span>
                    <p className={styles.detailsValue}>{employee.email || t("no email")}</p>
                </div>
                <div>
                    <span className={styles.detailsDescription}>{t("phone number")}</span>
                    <p className={styles.detailsValue}>{employee.telephone || t("no telephone number")}</p>
                </div>
                <div>
                    <span className={styles.detailsDescription}>{t("address")}</span>
                    <p className={styles.detailsValue}>{addressToStr(employee.fulladdress) || t("no address")}</p>
                </div>
            </div>

            {
                role?.data === "CEO" && 
                    <>
                        <div className={styles.divider}/>

                        <div className={styles.employeeCardFooter}>
                            <Button color="#1E9DBD" title={t("edit permission")} onClick={() => setIsPermissionModalOpen(true)}/>
                        </div>
                    </>
            }
            <InviteWorkerModal
                isVisible={isPermissionModalOpen}
                onClose={() => setIsPermissionModalOpen(false)}
                departmentId={departmentId}
                title={t("edit permission")}
                employeeEmail={employee.email}
                employeeId={employee.id}
                role={0}
            />
        </div>
    )
}

const EmployeeChart = ({employee}) => {
    const { t } = useTranslation()

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };
       
    const labels = [t("signed"), t("refused"), t("in review"), t("wrong recipient"), t("not shared")];
      
    const data = {
        labels,
        datasets: [
          {
            label: 'Documents',
            data: [employee?.signed_documents, employee?.refused_documents, employee?.in_progress, employee?.wrong_recipient, employee?.not_shared],
            backgroundColor: '#1e9dbd83', 
          },
        ],
      };

    return (
        <div className={styles.chartContainer}>
            <Bar options={options} data={data} />
        </div>
    )
}

const EmployeeDocuments = ({documents = []}) => {
    const { t } = useTranslation();
    const {openEditor} = useContext(TabContext);

    function editDocument(doc) {
        openEditor({
            id: doc.id,
            editorTitle: doc.title,
            editorContent: doc.content,
            editorKeywords: doc.keywords,
            editorIsPublic: doc.is_public,
            statusType: doc.status,
            editorType: EditorType.EDIT_DOCUMENT
        });
    }

    return (
        <div className={styles.cardWrapper}>
            <div className={styles.cardHeader}>{t("latest documents")}</div>
            <div className={styles.listContainer}>
                {
                    documents.length !== 0 ?
                        documents.map((item) => 
                            <div key={item.id} className={styles.listRow} onClick={() => editDocument(item)}>
                                <div className={styles.listTitle}>{item.title}</div>
                                <div className={styles.listStatus}>
                                    <DocumentStatus statusType={item.status}/>
                                </div>
                            </div>
                        ) : <div style={{textAlign: "center"}}>{t("This user has no documents")}</div>
                }
            </div>
        </div>
    )
}

const EmployeeTemplates = ({templates = []}) => {
    const { t } = useTranslation();
    const {openEditor} = useContext(TabContext);

    function editTemplate(temp) {
        openEditor({
            id: temp.id,
            editorTitle: temp.title,
            editorContent: temp.content,
            editorKeywords: temp.keywords,
            editorIsPublic: temp.is_public, 
            editorType: EditorType.EDIT_TEMPLATE,
          });
    }

    return (
        <div className={styles.cardWrapper}>
            <div className={styles.cardHeader}>{t("latest templates")}</div>
            <div className={styles.listContainer}>
                {templates.length !== 0 ? 
                    templates.map((item) => 
                        <div key={item.id} className={styles.listRow} onClick={() => editTemplate(item)}>
                            <div className={styles.listTitle}>{item.title}</div>
                            <div>{formatDate(item.updated_at)}</div>
                        </div>
                    ) : <div style={{textAlign: "center"}}>{t("This user has no templates")}</div>
                }
            </div>
        </div>
    )
}

const EmployeeView = ({id, departmentId}) => {    
    const [employee, setEmployee] = useState({})

    const [loading, getEmployee] = useWaiting(getUserActivity)

    const loadEmployee = async () => {
        const employee = await getEmployee({
            EmployeeId: id
        });
        setEmployee(employee);
    }

    useEffect(() => {
        loadEmployee();
    }, [id])

    if (loading) return <FetchLoading />

    return (
        <div className={styles.employeeContainer}>
            <CardEmployee employee={employee} id={id} departmentId={departmentId}/>
            <div className={styles.employeeStats}>
                <EmployeeChart employee={employee}/>
                <div className={styles.employeeDocTempContainer}>
                    <EmployeeDocuments documents={employee?.documents}/>
                    <EmployeeTemplates templates={employee?.templates}/>
                </div>
            </div>
        </div>
    )
}

export default EmployeeView;