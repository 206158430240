import React from 'react';
import cn from 'classnames';
import styles from './styles.module.css';
import RCPagination from 'rc-pagination';
import { ChevronLeftIcon, ChevronRightIcon } from '../../icons';

const Pagination = ({ className, ...props }) => {
  const locale = {
    items_per_page: '/ page',
    jump_to: 'Go to',
    jump_to_confirm: 'confirm',
    page: 'Page',
    prev_page: 'Previous Page',
    next_page: 'Next Page',
    prev_5: 'Previous 5 Pages',
    next_5: 'Next 5 Pages',
    prev_3: 'Previous 3 Pages',
    next_3: 'Next 3 Pages',
    page_size: 'Page Size',
  };

  return (
    <RCPagination
      className={cn(styles.pagination, className)}
      locale={locale}
      prevIcon={
        <div className={cn(styles.button)}>
          <ChevronLeftIcon />
        </div>
      }
      nextIcon={
        <div className={cn(styles.button)}>
          <ChevronRightIcon />
        </div>
      }
      jumpPrevIcon={<div className={cn(styles.jump)}>...</div>}
      jumpNextIcon={<div className={cn(styles.jump)}>...</div>}
      {...props}
    />
  );
};

export default Pagination;
