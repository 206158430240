import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import Card from '../../../../../../common/parts/Card';
import { useTranslation } from '../../../../../../../contexts/LocaleContext';
import { Form, RadioGroup } from 'informed';
import Input from '../../../../../../SignIn/Input';
import InputPlaceholder from '../../../../../../SignIn/InputPlaceholder';
import { DateInput } from '../../../../../../common/core/Input';
import moment from 'moment';
import CardHeader from '../../../../../../common/parts/CardHeader';
import Worker from '../../../common/Worker';
import DataContext from '../../../../../../../contexts/DataContext';
import Member from '../../../../../../common/parts/Member';
import Button from '../../../../../../common/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import InputSelect from '../../../../../../SignIn/Input/InputSelect';
import ManageSubtasks from '../ManageSubtasks';
import { useNavigate, useParams } from 'react-router-dom';
import ManageTaskAssignments from '../ManageTaskAssignment ';
import RadioboxWithLabel from '../../../../../../SignIn/RadioboxWithLabel';
import InputDate from '../../../../../../SignIn/Input/InputDate';
import CorporateContext, {
  useCorporateContext,
} from '../../../CorporateContext';
import Loader from '../../../../../../common/core/Loader';
import { updateTask } from '../../../../../../../api/Task/dataSetters';

const ManageTask = ({ className, editMode, subtask, children }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { tasks } = useContext(DataContext);
  const { workers, departments } = useCorporateContext(CorporateContext);
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [author, setAuthor] = useState(null);
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(editMode);
  const [rules, setRules] = useState([
    {
      value: '5',
      label: t('Approved'),
    },
    {
      value: '0',
      label: t('Approved by manager or admin'),
    },
    {
      value: '1',
      label: t('Document created'),
    },
    {
      value: '2',
      label: t('Signed document'),
    },
    {
      value: '3',
      label: t('Added an attachment'),
    },
    {
      value: '4',
      label: t('Sent information to email'),
    },
  ]);
  const [task, setTask] = useState(null);

  const onCreateSubmit = async (fields) => {
    const request = {
      type: 0,
      rules: 5,
      status: 0,
      start_date: moment().format('YYYY-MM-DDT00:00:00.000+00:00'),
      end_date: moment().format('YYYY-MM-DDT00:00:00.000+00:00'),
      assign_to_ids: members.map((member) => member.id),
      ...(subtask ? { parent_task_id: task.id } : {}),
      ...fields,
    };
    const { task_id: newTaskId } = await tasks.create(request);
    navigate(
      subtask
        ? `/dashboard/company-dashboard/tasks/view/subtask/${id}`
        : `/dashboard/company-dashboard/tasks/view/task/${newTaskId}`
    );
  };

  const onEditSubmit = async (fields) => {
    const request = {
      id,
      assign_to_ids: members.map((member) => member.id),
      ...fields,
    };
    await updateTask(request);
    navigate(
      subtask
        ? `/dashboard/company-dashboard/tasks/view/subtask/${id}`
        : `/dashboard/company-dashboard/tasks/view/task/${id}`
    );
  };

  useEffect(() => {
    if (!id || task?.id) return;
    setIsLoading(true);
    tasks.getItemById(id).then((task) => {
      setTask(task);
      setIsLoading(false);
    });
  }, [id, task]);

  useEffect(() => {
    if (departments.isLoaded) return;
    departments.load();
  }, [departments.isLoaded]);

  useEffect(() => {
    if (workers.isLoaded) return;
    workers.load();
  }, [workers.isLoaded]);

  useEffect(() => {
    if (!task || !workers.data || !departments.data || members.length) return;

    const owners =
      workers.data.filter((current) => current.department_id === 0) || [];
    const employees = departments.data.reduce(
      (accumulator, department) => [
        ...accumulator,
        ...department.employees_department.map((employee) => employee),
      ],
      owners
    );

    setAuthor(employees.find((employee) => employee.id === task.created_by_id));
    setMembers(
      employees.filter((employee) => task?.assign_to_ids?.includes(employee.id))
    );
  }, [task, workers.data, departments.data]);

  const initialValues = useMemo(() => {
    if (!editMode || !task?.id) {
      return {
        rules: '5',
        start_date: moment().toDate(),
        end_date: moment().add(1, 'day').toDate(),
      };
    }

    return {
      title: task.title,
      description: task.description,
      type: task.type.toString(),
      rules: task.rules.toString(),
      status: task.status.toString(),
      start_date: moment(task.start_date).toDate(),
      end_date: moment(task.end_date).toDate(),
    };
  }, [task, isLoading, editMode]);

  const title = useMemo(() => {
    return `${t(
      `${editMode ? 'Edit' : 'Create'} ${subtask ? 'Subtask' : 'Global Task'}`
    )}${task?.task_id ? ` #${task.task_id}` : ''}`;
  }, [t, task, subtask, editMode]);

  if (isLoading) {
    return (
      <div className={cn(styles.wrapper, className)}>
        <div className={cn(styles.loader)}>
          <Loader />
        </div>
      </div>
    );
  }

  console.log(
    `[debug][ManageTask.js] initialValues:`,
    !editMode || !task?.id,
    initialValues
  );

  return (
    <>
      <div className={cn(styles.wrapper, className)}>
        <h2 className={cn(styles.title)}>{title}</h2>

        <Form
          className={cn(styles.layout)}
          getApi={(api) => (formRef.current = api)}
          onSubmit={editMode ? onEditSubmit : onCreateSubmit}
        >
          <div className={cn(styles.column, styles.columnMain)}>
            <Card>
              <div className={styles.form} style={{ marginTop: '-15px' }}>
                <Input
                  field={'title'}
                  required
                  initialValue={initialValues.title}
                  placeholder={<InputPlaceholder text={t('Enter title')} />}
                  inputPlaceholder={t('Type')}
                />
                <InputDate
                  type={'date'}
                  field={'start_date'}
                  placeholder={<InputPlaceholder text={t('Start date')} />}
                  parse={(value) => {
                    return moment(value).format(
                      'YYYY-MM-DDTHH:00:00.000+00:00'
                    );
                  }}
                  format={(value) => moment(value).format('YYYY-MM-DD')}
                  initialValue={initialValues.start_date}
                  max={'3000-01-01'}
                  forceDate
                />
                <InputDate
                  type={'date'}
                  field={'end_date'}
                  placeholder={<InputPlaceholder text={t('End date')} />}
                  parse={(value) => {
                    return moment(value).format(
                      'YYYY-MM-DDTHH:00:00.000+00:00'
                    );
                  }}
                  format={(value) => moment(value).format('YYYY-MM-DD')}
                  initialValue={initialValues.end_date}
                  max={'3000-01-01'}
                  forceDate
                />
                <InputSelect
                  field={'type'}
                  formApi={formRef.current}
                  placeholder={<InputPlaceholder text={t('Type')} />}
                  initialValue={initialValues.type}
                  inputPlaceholder={t('Select')}
                  options={[
                    { id: '0', label: 'Standart' },
                    { id: '1', label: 'Urgent' },
                    { id: '2', label: 'Recurrent' },
                  ]}
                />
                <InputSelect
                  field={'status'}
                  formApi={formRef.current}
                  placeholder={<InputPlaceholder text={t('Status')} />}
                  initialValue={initialValues.status}
                  inputPlaceholder={t('Select')}
                  options={[
                    { id: '0', label: 'To do' },
                    { id: '1', label: 'In progress' },
                    { id: '2', label: 'Done' },
                  ]}
                />
                <Input
                  textarea
                  rows={5}
                  field={'description'}
                  initialValue={initialValues.description}
                  placeholder={<InputPlaceholder text={t('Description')} />}
                  inputPlaceholder={t('Type')}
                />
              </div>
            </Card>
          </div>
          <div className={cn(styles.column)}>
            <ManageTaskAssignments
              author={author}
              members={members}
              setMembers={setMembers}
            />

            <Card className={cn(className)}>
              <CardHeader className={styles.cardHeadingMain}>
                {t('Task completion rule')}
              </CardHeader>
              <div className={cn(styles.cardContent)}>
                <RadioGroup field="rules" initialValue={initialValues.rules}>
                  <div className={cn(styles.radioboxGroup)}>
                    {rules.map((rule, index) => (
                      <RadioboxWithLabel
                        key={rule.value}
                        value={rule.value}
                        label={
                          <span className={cn(styles.radioboxLabel)}>
                            {rule.label}
                          </span>
                        }
                      />
                    ))}
                  </div>
                </RadioGroup>
              </div>
            </Card>
          </div>
        </Form>
      </div>

      <div className={cn(styles.buttons)}>
        <Button
          className={styles.button}
          color="#B5B7BA"
          title={
            <>
              {t('Discard')} <FontAwesomeIcon icon={faClose} width={16} />
            </>
          }
          onClick={() => navigate('/dashboard/company-dashboard/tasks')}
        />
        <Button
          className={styles.button}
          color="#5ED615"
          title={
            <>
              {editMode ? t('Update') : t('Save')}{' '}
              <FontAwesomeIcon icon={faCheck} width={16} />
            </>
          }
          onClick={() => formRef.current.submitForm()}
        />
      </div>
    </>
  );
};

export const ManageSubtask = ({ editMode }) => (
  <ManageTask subtask editMode={editMode} />
);

export default ManageTask;

