import React, { useContext, useEffect, useState } from 'react'
import Avatar from 'react-avatar';
import cn from "classnames"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import FetchLoading from '../../../../../../common/parts/FetchLoading';
import Flows from '../../Flows';
import CorporateContext from "../../../CorporateContext";
import { getById, formatDate } from "../../../../../../../utils/StringUtils";
import styles from './styles.module.css'
import { SectionHeader } from "../../Dashboard";
import { useTranslation } from "../../../../../../../contexts/LocaleContext";
import CardRow from '../../../common/CardRow';
import { REGEX } from '../../../common/useForm';
import useForm from "../../../common/useForm";
import Input, { Select } from "../../../common/Input";
import Button, { RoundButton } from "../../../common/Button";
import ButtonCore from '../../../../../../common/core/Button';
import Card, { Content } from "../../../common/Card";
import TouchableOpacity from "../../../../../../common/core/TouchableOpacity";
import Modal from "../../../common/Modal";
import Controllers from "../../../common/Controllers";
import Worker, { InviteWorkerModal } from "../../../common/Worker";
import { getDepartmentInfo } from "../../../../../../../api/Department/dataGetters";
import SlideUpScreen from "../../../common/SlideUpScreen";
import useNavigation from "../../../../../../common/hooks/useNavigation";
import CustomModal from "../../../common/Modal";
import DataContext from '../../../../../../../contexts/DataContext';
import { chooseActivDepAndComp } from '../../../../../../../api/AccountActiveDepartment/dataGetters';
import { idToColor } from '../../../../../../../utils/StringUtils';
import { transferWorker } from '../../../../../../../api/CompanyAccount/dataSetters';
import { suspendEmployee, unsuspendEmployee } from '../../../../../../../api/Department/dataSetters';


ChartJS.register(ArcElement, Tooltip, Legend);

const AVATAR_SIZE = 40;

const DepartmentView = ({ id, inviteOnStart = false }) => {
    const { t } = useTranslation()
    const { company, account } = useContext(DataContext)
    const { activ_department } = account.data;
    const { departmentsCompany } = useContext(DataContext)
    const [isChangingScreen, setChangingScreen] = useState(false)
    const [inviteModalShow, setInviteModalShow] = useState(inviteOnStart)
    const [isDeleteModalShown, setDeleteModalShown] = useState(false)

    
    const closeDeleteModal = () => {
        setDeleteModalShown(false)
    }
    
    const activeDepartment = departmentsCompany.data.find((item) => item.department_id === activ_department)
    const deleteDepartment = async () => {
        closeDeleteModal()
        nav(PATHS.STRUCTURES, 'departments')
        await departments.remove(id)
        if (activ_department !== 0 && departmentsCompany.data.length > 0) {
            const token = await chooseActivDepAndComp(departmentsCompany.data[0].department_id);
            localStorage.setItem("token", token);
            window.location.reload();
        }
    }
    const [workers, setWorkers] = useState([])
    const [loadingWorkers, setLoadingWorkers] = useState(false);
    const { nav, PATHS } = useNavigation()

    const { departments, workers: { _workers } } = useContext(CorporateContext)

    const [department, setDepartment] = useState({})

    const load = () => {
        setLoadingWorkers(true)
        setChangingScreen(true)
        getDepartmentInfo(id).then(r => {
            setDepartment(r);
            setWorkers(r?.employees_department || [])
            setChangingScreen(false)
            setLoadingWorkers(false)
        })
    }
    useEffect(() => {
        load()
    }, [id, _workers])

    const { getInputProps, edit, editMode, save, disabled, isSaveWaiting } = useForm({
        initialState: department,
        onUpdate: departments.update,
        requiredFieldNames: ['name']
    })


    const navigateToDepartment = async (id) => {
        setChangingScreen(true)
        setTimeout(() => {
            nav(PATHS.STRUCTURES, 'departments', id)
            setChangingScreen(false)
        }, 300)
    }

    const openInviteModal = () => setInviteModalShow(true)

    const editButton = editMode ?
        <Button title={t("Save")} onClick={save} iconCN={'fas fa-check'} disabled={disabled} waiting={isSaveWaiting} /> :
        <Button title={t("Edit")} onClick={edit} iconCN={'fas fa-pen'} />

    const deleteButton = editMode ? null :  <Button
                                                color='#D92D15'
                                                title={t("Delete department")}
                                                iconCN={'fas fa-trash-alt'}
                                                fontColor={'firebrick'}
                                                onClick={() => setDeleteModalShown(true)}
                                            />

    if (!department?.name) return <FetchLoading />

    return (
        <SlideUpScreen className={styles.mainContainer} show={!isChangingScreen}>
            <div className={styles.departmentWrapper}>
                <div className={styles.departmentTopContainerWrapper}>
                    <DepartmentContainer department={department} editMode={editMode} getInputProps={getInputProps} staffSize={department.employees_department.length} controllers={[editButton, deleteButton]}/>
                    <FlowsContainer id={id} navigateToDepartment={navigateToDepartment}/>
                </div>
                <TableContainer departmentId={id} workers={workers} loadedTable={loadingWorkers} load={load} openInviteModal={openInviteModal}/>
            </div>
            <CustomModal isVisible={isDeleteModalShown} onClose={closeDeleteModal} title={t('Are you sure you want to delete this department?')}>
                <Controllers>
                    <Button onClick={closeDeleteModal} title={t('Cancel')} color={'black'} transparent />
                    <Button onClick={deleteDepartment} twoStep title={t('Delete')} color={'red'} />
                </Controllers>
            </CustomModal>
            <InviteWorkerModal isVisible={inviteModalShow && !isChangingScreen} onClose={() => setInviteModalShow(false)} companyId={company.data?.id} departmentId={id} role={activeDepartment.role}/>
        </SlideUpScreen>
    )
}

const FlowsCard = ({ type = 0, departmentId = 0, navigateToDepartment = () => { } }) => {
    const { flows, departments } = useContext(CorporateContext)
    const { t } = useTranslation()
    const [isAddModalVisible, setAddModalVisible] = useState(false)
    const [deletingItem, setDeletingItem] = useState(-1)

    const getDepartmentsWithinFlow = (differentType = type) => {

        const relatedFlows = flows.data?.filter((flow) => {
            return +flow[differentType === 0 ? "department_to_id" : "department_from_id"] === +departmentId
        })
        const relatedDepartments = relatedFlows?.map((flow) => {
            return {
                department: getById(departments.data, flow[differentType === 0 ? "department_from_id" : "department_to_id"]),
                onDelete: () => { setDeletingItem(flow.flow_item_id || flow.id) }
            }
        })
        return relatedDepartments || []
    }

    const deleteFlow = async () => {
        await flows.remove(deletingItem)
    }

    const _departments = getDepartmentsWithinFlow()

    return <Card title={type === 0 ?
        <div className={styles.flowsCardHeader}><i style={{ color: '#F87C18' }} className={'fas fa-angle-double-down'} />{t("Receives documents from")}</div>
        :
        <div className={styles.flowsCardHeader}><i style={{ color: '#33ACC9' }} className={'fas fa-angle-double-up'} />{t("Sends documents to")}</div>
    }>
        <Content>
            {_departments.map(({ department, onDelete }) => <div className={styles.department}>
                <div className={styles.departmentName} onClick={() => navigateToDepartment(department?.id)}>{department?.name}</div>
                <RoundButton iconCN={'fas fa-times'} className={styles.deleteButton} fontColor={'firebrick'} color={'transparent'} onClick={onDelete} /> {/*TODO IF ROLE === SEO*/}
            </div>)}
            {_departments?.length < (departments?.data?.length - 1) && <TouchableOpacity onClick={() => setAddModalVisible(true)}
                className={styles.addFlow}>{t('Crate flow')}</TouchableOpacity>}
            <AddFlowModal onClose={() => setAddModalVisible(false)}
                isVisible={isAddModalVisible} departmentId={departmentId}
                type={type}
                getDepartmentsWithinFlow={getDepartmentsWithinFlow}

            />
            <DeleteModal isVisible={deletingItem !== -1} onDelete={deleteFlow} onCancel={() => setDeletingItem(-1)} />
        </Content>
    </Card>
}

const DeleteModal = ({ isVisible = false, onDelete = () => { }, onCancel = () => { } }) => {
    const { t } = useTranslation()
    const [deleteWaiting, setWaiting] = useState(false)

    const _onDelete = async () => {
        setWaiting(true)
        await onDelete()
        setWaiting(false)
        onCancel()
    }
    return <Modal title={t('Are you sure you want to delete this flow?')} isVisible={isVisible} onClose={onCancel}>
        <Controllers>
            <Button title={t('Cancel')} onClick={onCancel} transparent color={'black'} />
            <Button title={t('Delete')} onClick={_onDelete} isLoading={deleteWaiting} color={'red'} />
        </Controllers>
    </Modal>
}

const AddFlowModal = ({ isVisible = false, onClose = () => { }, departmentId = 0, type = 0, getDepartmentsWithinFlow = () => { } }) => {

    const { t } = useTranslation()
    const { flows, departments } = useContext(CorporateContext)

    useEffect(()=> {
        departments.load()
    }, [])

    const alreadyLinkedFlows = getDepartmentsWithinFlow().map(({ department }) => department)
    const linkedBackFlows = getDepartmentsWithinFlow(Math.pow(type - 1, 2)).map(({ department }) => department)

    const options = departments.data?.filter(department => {
        return +department?.id !== +departmentId && !alreadyLinkedFlows.map(({ id }) => id).includes(department?.id)
    })



    const [state, setState] = useState({ targetId: options?.[0]?.id || 0, twoSide: false })

    useEffect(() => {
        setState({ targetId: options?.[0]?.id || 0, twoSide: false })
    }, [flows.data, departments.data])


    const onCreate = async () => {
        const fromThisToOther = { fromId: departmentId, toId: state.targetId }
        const fromOtherToThis = { fromId: state.targetId, toId: departmentId }

        await flows.create(type === 0 ? fromOtherToThis : fromThisToOther)

        if (state.twoSide === 1) {
            const hasThisBackFlow = linkedBackFlows.map(({ id }) => id).includes(state.targetId)
            if (!hasThisBackFlow) {
                await flows.create(type === 0 ? fromThisToOther : fromOtherToThis)
            }
        }

        onClose()
    }

    return <Modal isVisible={isVisible} title={t('Create new document flow')} onClose={onClose}>
        <div className={styles.modalRow}>
            <Select options={options} title={type === 0 ? t('From') : t('To')}
                onSelect={(value) => setState(prev => ({ ...prev, targetId: value.id }))} />
            <Select options={[{ value: 0, name: t('One side') }, { value: 1, name: t('Two side') }]}
                onSelect={(value) => setState(prev => ({ ...prev, twoSide: value.value }))}
                title={t('Flow type')}
            />
        </div>
        <Controllers>
            <Button title={'Add flow'} iconCN={false} onClick={onCreate} />
        </Controllers>
    </Modal>
}

const DepartmentContainer = ({department, staffSize, controllers, editMode, getInputProps = () => {}}) => {
    const { t } = useTranslation(); 
    const { role } = useContext(CorporateContext);

    const {name, refused_documents, signed_documents, in_progress, not_shared, wrong_recipient} = department;
    const data = {
        labels: [t("Signed"), t("Refused"), t("In review"), t("wrong recipient"), t("not shared")],
        datasets: [
          {
            label: 'Department statistics',
            data: [signed_documents, refused_documents, in_progress, wrong_recipient, not_shared],
            backgroundColor: [
                'rgba(75, 192, 192, 0.2)',
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 99, 132, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(54, 162, 235, 1)',
            ],
            
          },
        ],
        options: {
            maintainAspectRatio: false
        }
    };

    return (
        <div className={styles.departmentContainer}>
            <div className={styles.departmentHeader}>
                <div className={styles.departmentTitle}>
                    <div className={styles.departmentNameInfo}>
                        <h4>{t("Department")}:</h4>
                        <h4>{editMode ? <Input {...getInputProps('name')} /> :  name}</h4>
                    </div>
                    <div className={styles.departmentStaffSize}>{staffSize}</div>
                </div>
                {
                    role?.data === "CEO" &&
                        <div className={styles.departmentControllers}>
                            {controllers}
                        </div>
                }
            </div>
            <div className={styles.departmentMain}>
                <div className={styles.departmentChartContainer}>
                    <Doughnut data={data} />
                </div>
            </div>
        </div>
    )
}

const FlowsContainer = ({id, navigateToDepartment}) => {
    const { t } = useTranslation();
    const { role } = useContext(CorporateContext);

    return ( 
        <div className={styles.flowsContainer}>
            <div>
                <div>
                    <h4>{t("Flows")}:</h4>
                </div>
                {
                    role?.data === "CEO" &&
                        <div className={styles.flowsControls}>
                            <FlowsCard type={1} departmentId={id} navigateToDepartment={navigateToDepartment} />
                            <FlowsCard type={0} departmentId={id} navigateToDepartment={navigateToDepartment} />
                        </div>
                }
            </div>
            <Flows />
        </div>
    )
}

const TableContainer = ({workers, departmentId, loadedTable, load = () => {}, openInviteModal = () => {}}) => {
    const { t } = useTranslation();
    const { role } = useContext(CorporateContext);

    return (
        <div>
            {
                role?.data !== "Employee" &&
                    <div className={styles.tableControls}>
                        <ButtonCore
                            containerClassName={styles.inviteButtonContainer}
                            title={
                                <div className={styles.inviteButtonContent}>
                                    <div className={styles.inviteTitle}>
                                        {t("Invite user").toUpperCase()}
                                    </div>
                                    <i class="fas fa-plus"></i>
                                </div>
                            }
                            onClick={openInviteModal}
                        />
                    </div>
            }
            <div className={cn(styles.departmentTableAlignment, styles.departmentTableHeader)}>
                <div></div>
                <div>{t("name")}</div> 
                <div>{t("role")}</div>
                <div>{t("enrolled")}</div>
                <div>{t("documents")}</div>
                <div>{t("templates")}</div>
                <div>{t("signed")}</div>
                <div>{t("refused")}</div>
                <div>{t("in progress")}</div>
                <div></div>
            </div>
            <div className={styles.departmentTableContent}>
                {!loadedTable ? workers.map((item) => <TableColumn departmentId={departmentId} load={load} {...item}/>) : <FetchLoading />}
            </div>
        </div>
    )
}

const TableColumn = (props) => {
    const { t } = useTranslation();
    const { role: accountRole } = useContext(CorporateContext);
    const {nav, PATHS} = useNavigation();
    const {avatar, email, user_name, role, size_documents, size_templates, id, enrolled, in_progress, is_creater, refused_documents, signed_documents, is_suspended, departmentId, load} = props;

    const [isManageModalOpen, setManageModalOpen] = useState(false)
    const [emailToTransferTo, setEmailToTransferTo] = useState(false)

    const [error, setError] = useState('')
    const { workers } = useContext(CorporateContext)

    useEffect(() => { setError('') }, [emailToTransferTo])

    function onClose() {
        setEmailToTransferTo(false)
        setManageModalOpen(false)
    }

    async function deleteUser() {
        await workers.remove(id)
        load()
        onClose()
    }

    function onTransferClick() {
        if (!emailToTransferTo)
            setEmailToTransferTo('')
        else
            transferUser()
    }

    async function transferUser() {
        const response = await transferWorker(id, emailToTransferTo)
        if (response.status === 404) {
            setError(t('User not found'))
        }
        else if (response.ok) {
            onClose()
        }
    }

    const handleTransfer = (e) => {
        e.stopPropagation();
        setManageModalOpen(true)
        setEmailToTransferTo('')
    }

    const handleSuspend = async (e) => {
        e.stopPropagation();
        is_suspended ? await unsuspendEmployee(id) : await suspendEmployee(id)
        load()
    }

    const handleDelete = (e) => {
        e.stopPropagation();
        setManageModalOpen(true)
    }

    const navigateToEmployeePage = () => {
        nav(PATHS.STRUCTURES, 'employee', id, departmentId)
    }

    const getCompanyRoleName = () => {
        switch (role) {
          case 0:
            return "CEO"
          case 1:
            return "Manager"
          case 2: 
            return "Employee"
          default:
            return "CEO"
        }
    }

    const controllersVisibility = (isCreator) => {
        if (isCreator) return;

        return (
            <div className={cn(styles.departmentTableCell, styles.columnCellButtons)}>
                <Button title={t("transfer")} onClick={(e) => handleTransfer(e)} className={styles.controlButton}/>
                <Button title={is_suspended ? t("unsuspend") : t("suspend")} color="#F67716" onClick={(e) => handleSuspend(e)} className={styles.controlButton}/>
                <Button title={t("delete")} color="#D92D15" onClick={(e) => handleDelete(e)} className={styles.controlButton}/>
            </div>
        )
    }

    return (
        <div key={id}>
            <div className={cn(styles.departmentTableAlignment, styles.departmentTableColumn)} onClick={() => navigateToEmployeePage()}>
                <div className={cn(styles.departmentTableCell, styles.cellAvatar)}>
                    <Avatar
                        round
                        name={user_name}
                        color={idToColor(id)}
                        size={AVATAR_SIZE}
                        src={avatar ? 'data:image/*;base64,' + avatar : null}
                    />
                </div>
                <div className={styles.departmentTableCell}>
                    <div className={styles.departmentTableCell}>{user_name}</div>
                    <div className={styles.departmentTableCell}>{email}</div>
                </div>
                <div className={styles.departmentTableCell}>
                    {getCompanyRoleName()}
                </div>
                <div className={styles.departmentTableCell}>
                    {formatDate(enrolled)}
                </div>
                <div className={styles.departmentTableCell}>{size_documents}</div>
                <div className={styles.departmentTableCell}>{size_templates}</div>
                <div className={styles.departmentTableCell}>
                    {signed_documents}
                </div>
                <div className={styles.departmentTableCell}>
                    {refused_documents}
                </div>
                <div className={styles.departmentTableCell}>
                    {in_progress}
                </div>
                {accountRole === "Employee" ?
                    <div></div> : controllersVisibility(is_creater)
                }
            </div>
            <CustomModal onClose={onClose} isVisible={isManageModalOpen} title={t('Manage user')}>
                <CardRow>
                    <Input title={t('Name')} value={user_name} disabled />
                    <Input title={t('Email')} value={email} disabled />
                </CardRow>
                {emailToTransferTo !== false && <Input title={t('New email')} value={emailToTransferTo} onChange={setEmailToTransferTo} />}
                <Controllers error={error}>
                    <Button title={t('Cancel')} transparent color={'black'} onClick={onClose} />
                    {emailToTransferTo !== false && <Button title={t('Transfer')} onClick={onTransferClick} disabled={emailToTransferTo !== false && !REGEX.EMAIL.test('' + emailToTransferTo)} />}
                    {emailToTransferTo === false && <Button title={t('Delete')} color={'red'} onClick={deleteUser} />}
                </Controllers>
            </CustomModal>
        </div>
    )
}

export default DepartmentView
