import { useContext, useMemo } from "react";
import matchAll from "string.prototype.matchall";

import { formatDate } from "../../../../utils/StringUtils";
import DataContext from "../../../../contexts/DataContext";

export const CLIENT_SIGNATURE_PLACEHOLDER = `<span class="client-signature fixed">_______________________</span>`;
export const MY_SIGNATURE_PLACEHOLDER = `<span class="my-signature fixed">_______________________</span>`;

export const findFields = (templateContent = "", data = null) => {
  const regEx = /\[[A-Z|_]+\]/g;

  // collects found fields into [invalidFields] and validFields: [{fieldName: '', index: Number, ...propsFromData}]

  const validateFields = (foundItems = [{}]) => {
    const validFields = [];
    const invalidFields = [];
    const fieldList = getVariableFields(data);
    for (const field of foundItems) {
      if (fieldList.hasOwnProperty(field.fieldName))
        validFields.push({ ...field, ...fieldList[field.fieldName] });
      else invalidFields.push(field.fieldName);
    }
    return { validFields, invalidFields };
  };

  // Looks for '[VARIABLE_FIELDS]' and collects to [{fieldName: 'VARIABLE FIELDS', index: Number}]
  const foundFields = [...matchAll(templateContent, regEx)].map(
    ({ 0: substr, index }) => ({
      fieldName: substr.slice(1, -1).replace(/_/g, " "),
      index,
      fieldLength: substr.length,
    })
  );

  return validateFields(foundFields);
};

export const useFields = (templateContent = "") => {
  const data = useContext(DataContext);
  const { validFields, invalidFields } = useMemo(
    () => findFields(repairVariables(templateContent), data),
    [data, templateContent]
  );
  if (!templateContent) return { actions: [] };
  return { actions: validFields };
};

const variableFieldType = {
  displayedName: "", // If '' fallbacks to key. Displayed in variable picker
  topic: "", // For grouping under expandable lists
  description: true, // Text in tooltip, loads from locales
  memorable: true, // If true => asks once. Else for every match
  value: "", // For obvious variables such as MY NAME or TODAY DATE
  question: "", // User will be asked this way
  list: [], // Array of possible entities
  type: "", // todo consider if needed
  isMultipleSelection: true, // Possibility of picking several entities
  mappingFields: "", // Name of fields inserted into doc
  isNew: "", // Resets selection todo consider if needed
  hidden: true, // Hides from var picker
  specialType: "", // Processed during filling
  creatable: false, // Determines whether we can create an instance during the flow
  context: "", // Used for better speech recognition
};

export const addressToStr = (address) => {
  if (!address) return "Address";
  const fields = [
    address.building || "",
    address.street || "",
    address.city || "",
    address.state || "",
    address.index || "",
    address.country || "",
  ];
  return fields.filter((f) => !!f).join(", ");
};

const ProductSelect = (data, field, missingQuestion) => ({
  question: "Select products",
  topic: "Product",
  list: data?.products?.data || [],
  type: "products",
  isMultipleSelection: true,
  mappingFields: [field],
  memorable: false,
  isNew: field === "name",
  description: true,
  missingQuestion: missingQuestion || this.type,
  specialType: field === "price" ? "price" : false,
  context: "PRODUCTS",
});
const ServiceSelect = (data, field, missingQuestion) => ({
  question: "Select services",
  topic: "Service",
  list: data?.services?.data || [],
  type: "services",
  isMultipleSelection: true,
  mappingFields: [field],
  memorable: false,
  isNew: field === "name",
  description: true,
  missingQuestion: missingQuestion || this.type,
  specialType: field === "price" ? "price" : false,
  context: "SERVICES",
});
const CustomerSelect = (data, field, missingQuestion) => ({
  question: "Select customer",
  list: [
    ...(data?.customersB2B?.data || []),
    ...(data?.customersB2P?.data || []),
  ],
  topic: "Customer",
  type: "customersB2B,customersB2P",
  isMultipleSelection: true,
  mappingFields: [field],
  memorable: false,
  // isNew: field === 'name',
  description: true,
  missingQuestion: missingQuestion || this.type,
  context: "CLIENTS",
});
const PartnerSelect = (data, field, missingQuestion) => ({
  question: "Select partner",
  topic: "Partner",
  list: data?.partners?.data || [],
  type: "partners",
  isMultipleSelection: true,
  mappingFields: [field],
  memorable: false,
  isNew: field === "name",
  description: true,
  missingQuestion: missingQuestion || this.type,
  context: "PARTNERS",
});

export const getVariableFields = (data) => ({
  // Better with useMemo()
  "TODAY DATE": {
    description: true,
    value: formatDate(),
    memorable: true,
    context: "DATE",
  },
  "ISSUE NUMBER": {
    value: `${(data?.documents?.data?.length || 0) + 1} / ${formatDate()}`,
    memorable: true,
    description: true,
  },
  "MY EMAIL": {
    value: data?.account?.data?.workemail,
    question: "What is your email?", // if undefined
    memorable: true,
    topic: "My info",
  },
  "MY WORK PHONE": {
    value: data?.account?.data?.telephone,
    question: "What is your work phone number?", // if undefined
    memorable: true,
    context: "NUMBER",
    topic: "My info",
  },
  "MY COMPANY NAME": {
    value: data?.company?.data?.name,
    question: "What is the name of your company?", // if undefined
    topic: "My company",
    memorable: true,
  },
  "MY COMPANY ADDRESS": {
    value: addressToStr(data?.company?.data?.address),
    question: "What is the address of your company?",
    topic: "My company",
    memorable: true,
  },
  "MY COMPANY EMAIL": {
    value: data?.company?.data?.email,
    question: "What is your company's email?",
    topic: "My company",
    memorable: true,
  },
  "PRODUCT NAME": ProductSelect(data, "name", "Tell me the product's name"),
  "PRODUCT PRICE": ProductSelect(data, "price", "What is the product's price"),
  "PRODUCT DELIVERY": ProductSelect(
    data,
    "delivery",
    "How long is the product's delivery period"
  ),
  "PRODUCT PRODUCER": ProductSelect(
    data,
    "producer",
    "Who is the product's producer"
  ),
  "PRODUCT DESCRIPTION": ProductSelect(
    data,
    "generaldescription",
    "What is the product's description"
  ),
  "UNIT MEASURE": ProductSelect(
    data,
    "unitmeasure",
    "What unit measure do you use for this product?"
  ),
  "SERVICE NAME": ServiceSelect(data, "name", "Tell me the service's name"),
  "SERVICE PRICE": ServiceSelect(data, "price", "What is the service's price"),
  "SERVICE DELIVERY": ServiceSelect(
    data,
    "delivery",
    "How long is the service's delivery period"
  ),
  "SERVICE DESCRIPTION": ServiceSelect(
    data,
    "generaldescription",
    "What is the service's description"
  ),
  "MY BANK DETAILS": {
    value: data?.company?.data?.companybankaccount,
    description: true,
    topic: "My company",
    memorable: true,
  },
  "MY COMPANY PHONE": {
    value: data?.company?.data?.phone,
    topic: "My company",
    memorable: true,
  },
  "MY PERSONAL CODE": {
    value: data?.account?.data?.userbankaccount,
    question: "What is your personal banking details?",
    description: true,
    topic: "My info",
    memorable: true,
  },
  "MY ADDRESS": {
    value: `${data?.company?.data?.address?.city}, ${data?.company?.data?.address?.street} ${data?.company?.data?.address?.building}`,
    topic: "My info",
    memorable: true,
  },
  "MY NAME": {
    value:
      data?.account?.data?.name + " " + data?.account?.data?.surname ||
      "Name" + " " + data?.account?.data?.surname ||
      "Surname",
    topic: "My info",
    memorable: true,
  },
  TOTAL: {
    question: "What is the total price?",
    memorable: false,
    description: true,
    specialType: "total",
    context: "NUMBER",
    topic: "Calculation",
  },
  SUBTOTAL: {
    question: "?",
    memorable: false,
    description: true,
    specialType: "subtotal",
    context: "NUMBER",
    hidden: true,
  },
  "PREPAYMENT REQUIREMENTS": {
    question:
      "What is your advance payment? You may provide it in percents as well.",
    memorable: false,
    topic: "Calculation",
    description: true,
    specialType: "advance",
    context: "NUMBER",
  },
  "CUSTOMER NAME": CustomerSelect(data, "name", "What is your customer's name"),
  "CUSTOMER ADDRESS": CustomerSelect(
    data,
    "address",
    "What is your customer's address"
  ),
  "CUSTOMER EMAIL": CustomerSelect(
    data,
    "email",
    "What is your customer's email"
  ),
  "CUSTOMER CONTACT PERSON": CustomerSelect(
    data,
    "responsibleforpurchasing",
    "Who is the contact person of your customer"
  ),
  "CUSTOMER PHONE": CustomerSelect(
    data,
    "phone",
    "Please, tell me a phone number of your customer"
  ),
  "CUSTOMER ACTIVITY": CustomerSelect(
    data,
    "directionactivity",
    "What is your customer's direction of activity"
  ),
  "CUSTOMER BANK DETAILS": CustomerSelect(
    data,
    "bank_details",
    "Tell me your customer's bank details"
  ),

  "PARTNER NAME": PartnerSelect(data, "name", "What is your partner's name"),
  "PARTNER CONTACT PERSON": PartnerSelect(
    data,
    "contactperson",
    "Who is the contact person of your partner"
  ),
  "PARTNER ADDRESS": PartnerSelect(
    data,
    "address",
    "What is your partner's address"
  ),
  "PARTNER EMAIL": PartnerSelect(data, "email", "What is your partner's email"),
  "PARTNER PHONE": PartnerSelect(
    data,
    "phone",
    "Please, tell me a phone number of your partner"
  ),
  "PARTNER ACTIVITY": PartnerSelect(
    data,
    "scope",
    "What is your partner's direction of activity"
  ),
  DISCOUNT: {
    question: "What discount do you apply on this offer?",
    memorable: false,
    description: true,
    specialType: "discount",
    context: "NUMBER",
    topic: "Calculation",
  },
  QUANTITY: {
    question: "What’s the total quantity?",
    memorable: false,
    description: true,
    specialType: "quantity",
    context: "NUMBER",
    topic: "Calculation",
  },
  "CUSTOMER PRODUCTS": {
    question: "What products or services does your customer sell?",
    memorable: true,
    hidden: true,
  },
  "PAYMENT PERIOD OR CREDITS": {
    question: "What’s the payment period you offer for this customer?",
    memorable: true,
    topic: "Calculation",
  },
  "ANY NUMBER": {
    question: "Please provide for me this specific number",
    memorable: false,
    context: "NUMBER",
  },
  "ANY TEXT": {
    question: "Please provide for me this specific text",
    memorable: false,
  },
  "ANY DATE": {
    question: "Please provide for me this specific date",
    memorable: false,
    context: "DATE",
  },
  "PHOTO": {
    question: "You_must_add_a_photo",
    memorable: false,
    description: true,
    specialType: "photo",
  },
  "MY SIGNATURE": {
    value: MY_SIGNATURE_PLACEHOLDER,
    topic: "Signing",
    memorable: true,
  },
  "CUSTOMER SIGNATURE": {
    value: CLIENT_SIGNATURE_PLACEHOLDER,
    memorable: true,
    topic: "Signing",
  },
  PROVISION: {
    question: "Provision nr",
    memorable: false,
    description: true,
    specialType: "provision",
  },
  "READ FROM": {
    description: true,
    topic: "Helpers",
    specialType: "read key",
  },
  "READ TO": {
    description: true,
    topic: "Helpers",
    value: "", // Works as an empty variable to stop the speech
  },
});

// For var picker ui
export const VariableFields = Object.entries(getVariableFields(null)).map(
  ([name, props]) => ({
    fieldName: name,
    fieldNameForUI: props.displayedName,
    topic: props.topic,
    description: props.description,
  })
);

export const repairVariables = (str = "") => {
  let output = "";
  let tag = "";
  let isVar = false;
  let isTag = false;
  for (let i = 0; i < str.length; i++) {
    const s = str[i];
    if (s === "[") {
      isVar = true;
      output += s;
    } else if (s === "]") {
      isVar = false;
      output += s + tag;
      tag = "";
    } else if (isVar) {
      if (s === "<") {
        isTag = true;
        tag += s;
      } else if (s === ">") {
        isTag = false;
        tag += s;
      } else if (isTag) {
        tag += s;
      } else if (!isTag) {
        output += s;
      }
    } else output += s;
  }
  return output;
};
