import {exist} from "../../utils/StringUtils";

export class AccountModel {

  static default = {
    "name": "Name",
    "surname": "Surname",
  };

  constructor({
    login,
    name='Name',
    surname='Surname',
    password,
    dateofbirth='2000-01-01T00:00:00.000Z',
    workemail,
    alternativermail=undefined,
    gender=0,
    facebook=undefined,
  }) {
    this.login = String()
  }
}

export class BusinessModel {
  static default = {
    "id": 0,
    "businessmodel": "",
    "experience": "",
    "yearaverage": "",
    "deliverymodel": "",
    "deliverylocations": "",
    "businessmission": ""
  };

  constructor({id, businessmodel, experience, yearaverage, deliverymodel, deliverylocations, businessmission}) {
    this.id = exist(id) ? Number(id) : BusinessModel.default.id;
    this.businessmodel = exist(businessmodel) ? String(businessmodel) : BusinessModel.default.businessmodel;
    this.experience = exist(experience) ? String(experience) : BusinessModel.default.experience;
    this.yearaverage = exist(yearaverage) ? String(yearaverage) : BusinessModel.default.yearaverage;
    this.deliverymodel = exist(deliverymodel) ? String(deliverymodel) : BusinessModel.default.deliverymodel;
    this.deliverylocations = exist(deliverylocations) ? String(deliverylocations) : BusinessModel.default.deliverylocations;
    this.businessmission = exist(businessmission) ? String(businessmission) : BusinessModel.default.businessmission;
  }
}

export class CompanyModel {
  static default = {
    "id": 0,
    "name": "Company name",
    "dateofestablishment": "2000-01-01T00:00:00.000Z",
    // "address": {
    //   "id": 0,
    //   "street": "",
    //   "building": "",
    //   "index": "",
    //   "city": "",
    //   "state": "",
    //   "country": ""
    // },
    "registrationnumber": "",
    "licensenumber": "",
    "licencetype": "",
    "certification": "",
    "authorizations": "",
    "qualitycertificates": "",
    "brandscertificates": "",
    "description": ""
  };

  constructor({id, name, dateofestablishment}) {

  }
}

export const AddressModal = () => {

};
