import {useState} from "react";

const useWaiting = (func) => {
  const [waiting, setWaiting] = useState(false);

  const waitingFunc = async (...args) => {
    setWaiting(true);
    const result = await func(...args);
    setWaiting(false);
    return result;
  };

  return [waiting, waitingFunc];
};

export default useWaiting;
