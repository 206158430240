import { POST } from "../network";
import { targetMarketEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
  try {
    return await POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const sendFile = async (path, file, fieldName) => {
    const formData = new FormData();
    formData.append(fieldName ? fieldName : "file", file);
    const res = await POST(path, formData, true);
};

export const createTargetMarket = async data => {
    await setData(targetMarketEndpoints.create, data);
};

export const updateTargetMarket = async data => {
    await setData(targetMarketEndpoints.update, data);
};

export const removeTargetMarket = async id => {
    await setData(`${targetMarketEndpoints.remove}${id}`);
};

export const importMarkets = async file => {
    return await sendFile(targetMarketEndpoints.importCsv, file, "csvFile");
};