import React from "react"
import cn from "classnames"

import styles from "./styles.module.scss"

const SwitchToggle = ({onChange, checked, className, ...props}) => {

    return (
        <div>
            <label className={cn(styles.switch, className)}>
                <input
                    className={styles.input}
                    type="checkbox"
                    onChange={onChange}
                    checked={checked}
                    {...props}
                />
                <span className={styles.slider}></span>
            </label>
        </div>
    )
}

export default SwitchToggle