import { useEffect, useState, createContext } from "react";
import getToken, { removeToken } from "../utils/getToken";
import { getIsAuthorized } from "../api/ServerInfo/dataGetters";

const contextShape = {
  networkError: false,
  loggedIn: false,
  isReady: false,

  registerTempData: {},
  addToRegisterTempData: () => {},

  onNetworkError: () => {},
  onLogin: () => {},
  onLogout: () => {},
  onStartUp: () => {}
};

const NetworkContext = createContext(contextShape);

export default NetworkContext;

export const useNetwork = () => {
  const [value, setValue] = useState(contextShape);
  const setState = obj => setValue(value => Object.assign({ ...value }, obj));

  const onStartUp = async () => {
    const token = getToken();
    if (token) {
      await validateTokenAndLogin();
    } else {
      setState({ loggedIn: false, isReady: true });
    }
  };

  const onLogin = () => {
    setState({ loggedIn: true, isReady: true });
  };

  const validateTokenAndLogin = async () => {
    const isAuthorized = await getIsAuthorized();
    if (!isAuthorized) removeToken();
    setState({ loggedIn: isAuthorized, isReady: true });
  };

  const onLogout = () => {
    removeToken();
    window.location.reload();
  };

  const onNetworkError = () => setState({ networkError: true });

  const addToRegisterTempData = data => {
    const registerTempData = Object.assign(value.registerTempData, data);
    setState({ registerTempData });
  };

  useEffect(() => {
    const setters = {
      onLogin,
      onLogout,
      onNetworkError,
      onStartUp,
      addToRegisterTempData
    };
    const value = Object.assign(contextShape, setters);
    setValue(value);
  }, []);

  return value;
};
