import { POST } from "../network";
import { assistantSupportEndpoints } from "../requestEndpoints";

const sendFile = async (path, file, fieldName) => {
    const formData = new FormData();
    formData.append(fieldName ? fieldName : "file", file);
    const res = await POST(path, formData, true);
};

const setData = async (path, data) => {
    try {
      return await POST(path, data, true);
    } catch (e) {
      console.error(path, e);
    }
};

export const sendAssistantSupportIssue = async (query, file) => {
    const withQuery = query ? `?issue=${query}` : "";
    if (file) {
        return await sendFile(assistantSupportEndpoints.sendWithScreenshot + withQuery, file, "screenshot");
    }
    return await setData(assistantSupportEndpoints.sendWithScreenshot + withQuery);
}