import { DELETE, PATCH, POST } from "../network";
import { departmentEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
  try {
    return await POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const patchData = async (path, data) => {
  try {
    return await PATCH(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const deleteData = async (path, data) => {
  try {
    return await DELETE(path, data)
  } catch (e) {
    console.error(path, e);
  }
}

export const createDepartment = async (data) => {
    return await setData(departmentEndpoints.createDepartment, data)
}
  
export const updateDepartment = async (data) => {
    return await patchData(departmentEndpoints.updateDepartment, data)
}
  
export const deleteDepartment = async id => {
    return await deleteData(departmentEndpoints.deleteDepartment + id)
}

export const suspendEmployee = async id => {
  return await setData(departmentEndpoints.denyAccess, {employee_id: id})
}

export const unsuspendEmployee = async id => {
  return await setData(departmentEndpoints.openAccess, {employee_id: id})
}

export const reassignEmployee = async data => {
  return await setData(departmentEndpoints.reassign, data)
}