import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import { Form, Text } from 'informed';
import cn from 'classnames';

import styles from './style.module.css';
import logoImagePath from './images/logo.png';
import logoShortImagePath from './images/logo-short.png';
import lens from './images/icons/lens.png';
import { User } from './Menu';

import Button from '../common/core/Button';

import { useTranslation } from '../../contexts/LocaleContext';
import Logo from '../common/core/Logo';
import Search from '../common/parts/Search';

export default ({ height, leftSideWidth, openAssistant }) => {
  return (
    <Row className={styles.header} style={{ height }}>
      <Link
        to="/dashboard/company-dashboard"
        className={cn(styles.logoContainer, styles.logoContainerDesktop)}
        style={{ width: leftSideWidth }}
      >
        <Logo
          className={styles.logo}
          height={39}
          width="auto"
          src={logoImagePath}
        />
      </Link>

      <div
        className={cn(styles.logoContainer, styles.logoContainerMobile)}
        style={{ width: '68px' }}
      >
        <Image height={height} src={logoShortImagePath} />
      </div>

      <div className={styles.headerContentContainer}>
        <Search onSubmit={openAssistant} id="tutorial-step-13" />
        <Assistance openAssistant={openAssistant} />
      </div>
      <User />
    </Row>
  );
};

const Assistance = ({ openAssistant }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.assistanceContainer} id="tutorial-step-14">
      <Button
        height={36}
        title={
          <div className={styles.assistanceButton}>
            <div className={styles.assistanceTitle}>
              {t('fastboss_assistance')}
            </div>
            <div className={styles.mic}> </div>
          </div>
        }
        onClick={() => openAssistant(null)}
      />
    </div>
  );
};
