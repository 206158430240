import { POST } from "../network";
import { partnerEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
  try {
    return await POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const sendFile = async (path, file, fieldName) => {
    const formData = new FormData();
    formData.append(fieldName ? fieldName : "file", file);
    const res = await POST(path, formData, true);
};

export const createPartner = async data => {
    await setData(partnerEndpoints.create, data);
};

export const updatePartner = async data => {
    await setData(partnerEndpoints.update, data);
};

export const removePartner = async id => {
    await setData(`${partnerEndpoints.remove}${id}`);
};

 export const importPartners = async file => {
    return await sendFile(partnerEndpoints.importCsv, file, "csvFile");
};