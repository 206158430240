import {GET} from "../network";
import { departmentEndpoints } from "../requestEndpoints";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data); 
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

export const getDepartments = async () => {
    return await getData(departmentEndpoints.getDepartmentList)
}

export const getCompanyWorkers = async () => {
    return await getData(departmentEndpoints.getUsersList)
}

export const getDepartmentInfo = async (id) => {
    return await getData(`${departmentEndpoints.getDepartmentInfo}${id}`)
}