import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import './style.css';
import { useTranslation } from '../../../../../../../contexts/LocaleContext';
import gantt from 'dhtmlx-gantt';
import moment from 'moment';
import { getTaskProgressPercentage } from '../TaskProgress';
import { useNavigate } from 'react-router-dom';

const GanttGraph = ({ className, task, children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ganttRef = useRef(null);

  const [data, links] = useMemo(() => {
    const data = task.child_tasks
      .reduce(
        (accumulator, current) => [
          ...accumulator,
          { ...current },
          ...(current?.child_tasks || []),
        ],
        [{ ...task, parent_task_id: 0 }]
      )
      .map((current) => ({
        id: current.id,
        task_id: current.task_id,
        text: current.title,
        parent: current.parent_task_id,
        start_date: moment(current.start_date).format('DD-MM-YYYY'),
        duration:
          moment(current.end_date).diff(moment(current.start_date), 'days') ||
          1,
        status: `${current.status}`,
        progress: getTaskProgressPercentage(current) / 100,
        open: true,
      }));

    const links = data.reduce(
      (accumulator, current) => [
        ...accumulator,
        ...(current.parent
          ? [
              {
                id: current.id,
                source: current.parent,
                target: current.id,
                type: '0',
              },
            ]
          : []),
      ],
      []
    );

    return [data, links];
  }, [task?.id]);

  useEffect(() => {
    if (!ganttRef?.current) return;

    // gantt.config.date_format = '%Y-%m-%d';
    gantt.config.link_radius = 8;
    gantt.config.columns = [
      { name: 'text', label: 'Task name', width: '*', tree: true },
      { name: 'start_date', label: 'Start time', align: 'center' },
      { name: 'duration', label: 'Duration', align: 'center' },
    ];
    gantt.config.drag_move = false;
    gantt.config.drag_resize = false;
    gantt.config.drag_progress = false;
    // gantt.config.autoscroll = true;
    gantt.config.readonly = true;

    gantt.init(ganttRef.current);
    gantt.parse({ data, links });

    gantt.templates.task_class = (start, end, task) => {
      switch (task.status) {
        case '0':
          return 'status--todo';
        case '1':
          return 'status--inprogress';
        case '2':
          return 'status--done';
        default:
          return '';
      }
    };

    gantt.ext.zoom.init({
      levels: [
        {
          name: 'Days',
          scale_height: 60,
          min_column_width: 70,
          scales: [
            { unit: 'month', step: 1, format: '%F' },
            { unit: 'day', step: 1, format: '%d %M' },
          ],
        },
      ],
    });

    gantt.attachEvent('onTaskClick', (id) => {
      const current = data.find((i) => `${i.id}` === `${id}`);
      if (!current) return;
      navigate(`/dashboard/company-dashboard/tasks/`);
      setTimeout(() => {
        navigate(
          `/dashboard/company-dashboard/tasks/view/${
            current.parent_task_id ? 'subtask/' : 'task/'
          }${current.task_id}`
        );
      });
      return true;
    });

    return () => gantt.clearAll();
  }, [ganttRef, data, links, navigate]);

  return (
    <div className={cn(styles.wrapper)}>
      <div className={cn(styles.gantt)} ref={ganttRef} />
    </div>
  );
};

export default GanttGraph;
