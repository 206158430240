import React from 'react'
import styles from './styles.module.css'
import cn from "classnames";
import {ClipLoader} from "react-spinners";

const Card = ({title, className, containerClassName, isLoading=false, color='#b7b7b7', ...props})=> {
    return <div className={cn(styles.card, containerClassName)} style={{borderColor: color}} {...props}>
        {title && <div className={styles.title} style={{borderColor: color}}>
           <div style={{flex: 1}}>
               {title}
           </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {isLoading && <ClipLoader color={color} size={18}/>}
            </div>
        </div>}
        <>
            {isLoading && <div className={cn(styles.content, styles.placeHolder)}/>}
            <div  className={cn(styles.content, className)} style={{opacity: isLoading ? 0 : 1}}>{props.children}</div>
        </>
    </div>
}

export const Content = (props) => <div className={styles.contentBox}>{props.children}</div>

export default Card
