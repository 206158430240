import React from 'react';
import cn from 'classnames';
import styles from './style.module.css';

const CardHeader = ({ className, children }) => {
  return (
    <div className={cn(styles.card, className)}>
      <h5 className={cn(styles.heading)}>{children}</h5>
      <div className={cn(styles.line)} />
    </div>
  );
};

export default CardHeader;
