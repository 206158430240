import React, {useContext, useEffect, useState} from "react";
import Pagination from 'rc-pagination';
import cn from "classnames";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams } from "react-router-dom";
import styles from './style.module.css'
import documentsStyles from '../Documents/style.module.css'
import { SelectInput, DateInput } from '../../../common/core/Input'
import Input from "../../../common/core/Input";
import Wrapper from "../../../common/core/Wrapper";
import DashboardTabs from "../../../common/parts/DashboardTabs";
import Button from "../../../common/core/Button";
import ProductsTab from "./ProductsTab";
import ModalProduct from "./ModalProduct";
import ElectByIndex from "../../../common/core/ElectByIndex";
import Enum from "../../../../utils/enum";
import UploadPdfModal from "../../../common/parts/UploadPdfModal";
import CataloguesTab from "../../../common/parts/CataloguesTab";
import DataContext, { useLoaded } from "../../../../contexts/DataContext";
import regularTableControllers from "../../../common/parts/regularTableControllers";
import { useCvsIO } from "../../../common/parts/ImportCsvModal";
import {useTranslation} from "../../../../contexts/LocaleContext";
import GuideContext from "../../../Onboarding";
import { useRestriction } from "../../../../contexts/Restriction/RestrictionContext"
import { getProductCataloguesData } from "../../../../api/File/dataGetters";

const [PRODUCT_MODAL] = Enum(1);

function Products() {
  const params = useParams();
  const [modalIndex, setModalIndex] = useState(null);
  const {t} = useTranslation()
  const {setPage, markDone} = useContext(GuideContext)
  const { withRestrictions } = useRestriction();

  const { isLoaded: isProductCataloguesLoaded, productCatalogues } = useLoaded(
    "productCatalogues"
  );
  const { products } = useContext(DataContext);
  
  const [fetchedCatalogs, setFetchedCatalogs] = useState({
    items: null,
    loading: false,
    totalItems: 1
  });

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState({
    by:"updatedAt",
    order:1
  });

  useEffect(() => {
    if (!products.isLoaded || !params.id) return;

    products.getItemById(params.id).then((product) => {
      if (product?.id) openEditModal(product);
    });
  }, [params, products.isLoaded]);

  const openFilter = () => setIsFilterOpen(true);
  const closeFilter = () => setIsFilterOpen(false);

  const onReset = () => {
    if (filters && Object.keys(filters).length !== 0) {
      setFilters({});
    }
  };

  const onFilter = (filters, force) => {
    if (force) {
      return setFilters(filters);
    }
    if (filters && Object.keys(filters).length !== 0) {
      setFilters(filters);
    }
  };

  const [{
    size: pageSize,
    current: pageCurrent,
    sizeOptions: pageSizeOptions,
  }, setPagination] = useState({
    size: 10,
    current: 1,
    sizeOptions: [5, 10, 15, 20, 50],
  });

  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const onUploadPdfClick = () => {
    markDone('add catalogue' )
    setIsPdfModalOpen(true);
  };
  const saveFile = async (file) => {
    await productCatalogues.uploadFile(file);
    await fetchData();
    setPagination((prev) => ({...prev, current: 1}));
    setIsPdfModalOpen(false);
  };
  const removeFile = async (fileId) => {
    await productCatalogues.remove(fileId);
    await fetchData();
    setPagination((prev) => ({...prev, current: 1}));
  };
  const uploadPdfModal = isPdfModalOpen && (
    <UploadPdfModal
      onClose={() => setIsPdfModalOpen(false)}
      saveFile={withRestrictions(saveFile)}
    />
  );

  const openEditModal = product => {
    setEditingValues(product);
    setModalIndex(PRODUCT_MODAL);
  };

  useEffect(()=> {
    setPage('products')
  }, [])

  const [editingValues, setEditingValues] = useState({});

  const closeModal = () => {
    setEditingValues({})
    setModalIndex(null)
  };

  const modals = [
    <ModalProduct editingValues={editingValues} close={closeModal} />
  ];

  const { importCsvModal, importCSV, exportCSV, exportWaiting } = useCvsIO(
    products,
    "Name,Price,UnitMeasure,Producer,GeneralDescription,Delivery,Keywords"
  );

  const paginationLocale = {
    items_per_page: t('items_per_page'),
    jump_to: t('jump to'),
    jump_to_confirm: t('jump to confirm'),
    page: t('page'),

    prev_page: t('prev page'),
    next_page: t('next page'),
    prev_5: t('prev 5'),
    next_5: t('next 5'),
    prev_3: t('prev 3'),
    next_3: t('next 3'),
  };

  const fetchData = async (sortBy, withoutLoading) => {
    !withoutLoading && setFetchedCatalogs((prev) => ({...prev, loading: false}))
    const { items, pages } = await getProductCataloguesData({
        ...filters,
        pageSize: pageSize,
        Page: pageCurrent,
        SortBy: sortBy || sort.by,
        SortOrder: sort.order,
        FileType: 1,
    });
    setFetchedCatalogs( (prev) => ({...prev, items, totalItems: pages * pageSize, loading:true}));
  }

  useEffect(() => {
    fetchData();
  }, [sort.data, sort.order, pageSize, pageCurrent, filters])

  function onChange(current, pageSize) {
    setPagination((prev) => ({...prev, current, size: pageSize}))
  };

  const onPerPageChange = (e) => {
    const { value } = e.target;
    setPagination((prev) => ({...prev, size: value, current: 1}));
  };

  const TABS = [
    {
      name: t("products").toUpperCase(),
      content: <ProductsTab openEditModal={openEditModal} />,
      controllers: regularTableControllers({
        t,
        addNew: withRestrictions(() => {
          setModalIndex(0)
          markDone('add product')
        }),
        importCSV: withRestrictions((a)=>{
          markDone('import csv')
          importCSV(a)
        }),
        exportCSV: (a)=>{
          markDone('export csv')
          exportCSV(a)
        },
        exportWaiting
      })
    },
    {
      name: t("catalogues").toUpperCase(),
      content: (
        <>  
          <CataloguesTab
            items={fetchedCatalogs.items}
            loaded={fetchedCatalogs.loading}
            cataloguesData={productCatalogues}
            removeFile={withRestrictions(removeFile)}
          />
          <div className={styles.pagination}>
            <Pagination
              className={documentsStyles.PaginationElement}
              current={pageCurrent}
              showSizeChanger
              pageSize={pageSize}
              defaultCurrent={3}
              total={fetchedCatalogs.totalItems}
              locale={paginationLocale}
              onChange={onChange}
              showLessItems={true}
            />
            <SelectInput
              field={'Per Page:'}
              placeholder={t('Per Page')}
              options={pageSizeOptions.map((i) => ({value:i, label:i}))}
              onChange={onPerPageChange}
              noDefaultMargin
              fieldState={{value: pageSize}}
            />   
          </div>
        </>
      ),
      controllers: [
        <div style={{display:"flex", alignItems:"center", overflow: 'hidden'}}>
          <Button
          onClick={withRestrictions(onUploadPdfClick)}
          title={t("upload_pdf_catalogue")}
          height={27} 
          fontSize={"0.9rem"}
          />
         <div className={documentsStyles.filterButton }>
            <Button
              thin
              transparent
              onClick={() => openFilter()}
              title={<i class="fas fa-filter" aria-hidden="true"></i>}
              height={28}
              width={40}
            />
          </div>
        </div> 
      ]
    }
  ];

  const contents = TABS.map(item => <Wrapper>{item.content}</Wrapper>);

  const [tabIndex, setTabIndex] = useState(0);

  const main = (
      <DashboardTabs tabs={TABS} readyContents={contents} startTabIndex={0} setTabIndex={setTabIndex}/>
  );

  return (
    <div className={tabIndex === 1 ? documentsStyles.container : ''}>
      <div className={documentsStyles.documentsWrapper}>
        <ElectByIndex index={modalIndex} defaultComponent={main}>
          {modals}
        </ElectByIndex>
      </div>
      {tabIndex === 1 &&
        (
          <Filters
            isOpen={isFilterOpen}
            open={openFilter}
            close={closeFilter}
            onSubmit={onFilter}
            reset={onReset}
          />
        )
      }
      {uploadPdfModal}
      {importCsvModal}
    </div>
  );
}

export default Products;

export const Filters = ({ isOpen, onSubmit, close, reset }) => {
  const { t } = useTranslation();
  const [filters, setFilter] = useState({});
  const [selectedStatus, setSelectedStatus] = useState();

  const onChangeDate = (e, date) => {
    const { value } = e.target;
    setFilter((prev) => ({ ...prev, [date]: value }));
  };

  const onChangeName = (e) => {
    const { value } = e.target;
    setFilter((prev) => ({...prev, name: value}))
  }

  const resetFilter = () => {
    setFilter({});
    setSelectedStatus("");
    reset();
  };

  const submit = () => {
    return onSubmit(filters, selectedStatus === "777" && true);
  };

  const validate = (value) => !value || undefined;

  return (
    <div className={cn(documentsStyles.filter, isOpen && documentsStyles.filterOpen)}>
      <Row className={documentsStyles.filterHeader}>
        <Col className={documentsStyles.filterHeaderTitle}>
          <i className="fas fa-filter" aria-hidden="true"></i> {t("filter")}
        </Col>
        <div className={documentsStyles.filterClose} onClick={close}></div>
      </Row>
      <Col className={documentsStyles.filterBody}>
        <Col>
          <span>Created Date</span>
          <Row className={"mt-2"}>
            <Col className="pr-lg-1" lg={6}>
              <DateInput
                onChange={(e) => {
                  onChangeDate(e, "StartCreatedAt");
                }}
                field={"StartCreatedAt"}
                placeholder={t("start created at")}
                fieldState={{
                  maskedValue: filters.StartCreatedAt,
                }}
              />
            </Col>
            <Col className="pl-lg-1" lg={6}>
              <DateInput
                onChange={(e) => {
                  onChangeDate(e, "EndCreatedAt");
                }}
                field={"EndCreatedAt"}
                placeholder={t("EndCreatedAt")}
                fieldState={{
                  maskedValue: filters.EndCreatedAt,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Row className={'mt-4'}>
          <Col style={{display:"flex", flexDirection:"column"}}>
            <span>Name</span>
            <Col style={{marginTop:"10px"}}>
              <Input
                onChange={onChangeName}
                placeholder={t('name')}
                field={'name'}
                validate={validate}
                fieldState={{maskedValue: filters.name || ""}}
              />
            </Col>
          </Col>
        </Row>
      </Col>
      <div className={documentsStyles.filterAction}>
        <Button
          title={t("reset")}
          onClick={resetFilter}
          height={30}
          fontSize={15}
          transparent
          color={"red"}
        />
        <Button
          title={t("submit")}
          height={30}
          onClick={submit}
          fontSize={15}
          transparent
        />
      </div>
    </div>
  );
};
