import React from 'react';
import cn from 'classnames';
import styles from './style.module.css';

const Card = ({ className, children, ...props }) => {
  return (
    <div className={cn(styles.card, className)} {...props}>
      {children}
    </div>
  );
};

export default Card;
