import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Wrapper from '../../common/core/Wrapper';
import Assistant from '../VirtualAssistant';
import styles from './assistant.module.css';

const AssistantContainer = ({
  template: initTemplate = null,
  close,
  query = null,
}) => {
  const location = useLocation();
  const [locationPrevious, setLocationPrevious] = useState(null);

  useEffect(() => {
    setLocationPrevious(location.pathname);
    if (!locationPrevious) return;
    if (location.pathname !== locationPrevious) close();
  }, [location]);

  const content = () => {
    return (
      <Assistant
        template={initTemplate || null}
        onClose={close}
        initialQuery={query || null}
      />
    );
  };

  return (
    <Wrapper className={styles.container}>
      <div className={styles.box}>{content()}</div>
    </Wrapper>
  );
};

export default AssistantContainer;
