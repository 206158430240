import { POST } from "../network";
import { productEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
  try {
    return await POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const sendFile = async (path, file, fieldName) => {
    const formData = new FormData();
    formData.append(fieldName ? fieldName : "file", file);
    const res = await POST(path, formData, true);
  };

const sendCsvFile = async (path, file, separator) => {
    path += `?Delimeter=${separator}`;
    const res = sendFile(path, file, "csvFile");
};

export const createProduct = async data => {
    await setData(productEndpoints.create, data);
};

export const updateProduct = async data => {
    await setData(productEndpoints.update, data);
};

export const removeProduct = async id => {
    await setData(`${productEndpoints.remove}${id}`);
};

export const importProducts = async (file, separator) => {
    return await sendCsvFile(productEndpoints.importCsv, file, separator);
};