export function blobToBase64(blob) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function() {
      const base64data = reader.result;
      if (base64data === 'data:') resolve('');
      resolve(base64data);
    }
  });
}
