import React, { useContext } from 'react';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import TabContext from '../../../../contexts/TabContext';

export const Info = (props) => (
  <span className={styles.wrapper}>{props.children}</span>
);

export const Link = (props) => {
  const navigate = useNavigate();
  return (
    <span onClick={() => navigate(props.to)} className={styles.link}>
      {props.children}
    </span>
  );
};

export const TabLink = (props) => {
  const { navigate } = useContext(TabContext);

  return (
    <span onClick={() => navigate(props.to)} className={styles.link}>
      {props.children}
    </span>
  );
};
