import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import Card from '../../../../../../common/parts/Card';
import { useTranslation } from '../../../../../../../contexts/LocaleContext';
import { Form, RadioGroup } from 'informed';
import Input from '../../../../../../SignIn/Input';
import InputPlaceholder from '../../../../../../SignIn/InputPlaceholder';
import { DateInput } from '../../../../../../common/core/Input';
import moment from 'moment';
import CardHeader from '../../../../../../common/parts/CardHeader';
import Worker from '../../../common/Worker';
import DataContext from '../../../../../../../contexts/DataContext';
import Member from '../../../../../../common/parts/Member';
import Button from '../../../../../../common/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import InputSelect from '../../../../../../SignIn/Input/InputSelect';
import ManageSubtasks from '../ManageSubtasks';
import { useNavigate, useParams } from 'react-router-dom';
import ManageTaskAssignments from '../ManageTaskAssignment ';
import RadioboxWithLabel from '../../../../../../SignIn/RadioboxWithLabel';
import CorporateContext, {
  useCorporateContext,
} from '../../../CorporateContext';
import TaskProgress from '../TaskProgress';
import TaskTable from '../TaskTable';
import TaskTag from '../TaskTag';
import GanttGraph from '../GanttGraph';
import DeleteTaskModal from '../DeleteTaskModal';
import { DeleteIcon, EditIcon } from '../../../../../../common/icons';
import Loader from '../../../../../../common/core/Loader';

const ViewTask = ({ className, children }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { account, tasks } = useContext(DataContext);
  const { workers, departments } = useCorporateContext(CorporateContext);
  const deleteTaskModal = useRef(null);
  const navigate = useNavigate();
  const [task, setTask] = useState(null);
  const [author, setAuthor] = useState({});
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    tasks.getItemById(id).then((task) => {
      setTask(task);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (departments.isLoaded) return;
    departments.load();
  }, [departments.isLoaded]);

  useEffect(() => {
    if (workers.isLoaded) return;
    workers.load();
  }, [workers.isLoaded]);

  useEffect(() => {
    if (!task || !workers.data || !departments.data || members.length) return;

    const owners =
      workers.data.filter((current) => current.department_id === 0) || [];
    const employees = departments.data.reduce(
      (accumulator, department) => [
        ...accumulator,
        ...department.employees_department.map((employee) => employee),
      ],
      owners
    );

    setAuthor(employees.find((employee) => employee.id === task.created_by_id));
    setMembers(
      employees.filter((employee) => task?.assign_to_ids?.includes(employee.id))
    );
  }, [task, workers.data, departments.data]);

  const type = useMemo(() => {
    if (!task?.id) return;

    const types = [
      {
        label: t('Standart'),
        background: '#BCE2EC',
        color: '#1E9DBD',
      },
      {
        label: t('Urgent'),
        background: '#F4C0B9',
        color: '#D92D15',
      },
      {
        label: t('Recurrent'),
        background: '#D8BDD4',
        color: '#7B206F',
      },
    ];

    return types[task.type] || types[0];
  }, [task]);

  const status = useMemo(() => {
    if (!task?.id) return;

    const statuses = [
      {
        label: t('To do'),
        background: '#FDD7BA',
        color: '#F67716',
      },
      {
        label: t('In progress'),
        background: '#E8BAD5',
        color: '#B11873',
      },
      {
        label: t('Done'),
        background: '#E7E7E8',
        color: '#83878B',
      },
    ];

    return statuses[task.status] || statuses[0];
  }, [task]);

  const rule = useMemo(() => {
    if (!task?.id) return;

    const rules = [
      t('Approved by manager or admin'),
      t('Document created'),
      t('Signed document'),
      t('Added an attachment'),
      t('Sent information to email'),
      t('Approved'),
    ];

    return rules[task.rules] || rules[0];
  }, [task]);

  if (isLoading) {
    return (
      <div className={cn(styles.wrapper, className)}>
        <div className={cn(styles.loader)}>
          <Loader />
        </div>
      </div>
    );
  }

  const onDelete = () => deleteTaskModal.current.open();
  const onDeleted = () => navigate('/dashboard/company-dashboard/tasks');
  const onEdit = () =>
    navigate(
      `/dashboard/company-dashboard/tasks/edit/${
        task.parent_task_id ? 'subtask/' : 'task/'
      }${task.task_id}`
    );

  return (
    <>
      <DeleteTaskModal
        ref={deleteTaskModal}
        task={task}
        onDeleted={onDeleted}
      />

      <div className={cn(styles.wrapper, className)}>
        <div className={cn(styles.titleWrapper)}>
          <h2 className={cn(styles.title)}>
            <span className={cn(styles.titleId)}>{task.task_id} •</span>
            {task.title}
          </h2>
          <button
            className={cn(styles.titleButton)}
            type="button"
            onClick={onEdit}
          >
            <EditIcon width="24" height="24" />
          </button>
          <button
            className={cn(styles.titleButton)}
            type="button"
            onClick={onDelete}
          >
            <DeleteIcon width="24" height="24" />
          </button>
        </div>

        <div className={cn(styles.layout)}>
          <div className={cn(styles.column, styles.columnMain)}>
            <Card>
              <CardHeader className={styles.cardHeadingMain}>
                {t('Overview')}
              </CardHeader>
              <div className={cn(styles.cardContent)}>
                <div className={cn(styles.cardContentColumn)}>
                  <div className={cn(styles.property)}>
                    <span className={cn(styles.propertyLabel)}>
                      {t('ID Task:')}
                    </span>
                    <span className={cn(styles.propertyValue)}>
                      {task.task_id}
                    </span>
                  </div>
                  <div className={cn(styles.property)}>
                    <span className={cn(styles.propertyLabel)}>
                      {t('Name:')}
                    </span>
                    <span className={cn(styles.propertyValue)}>
                      {task.title}
                    </span>
                  </div>
                  <div className={cn(styles.property)}>
                    <span className={cn(styles.propertyLabel)}>
                      {t('Progress:')}
                    </span>
                    <span className={cn(styles.propertyValue)}>
                      <TaskProgress task={task} />
                    </span>
                  </div>
                  <div className={cn(styles.property)}>
                    <span className={cn(styles.propertyLabel)}>
                      {t('Type:')}
                    </span>
                    <span className={cn(styles.propertyValue)}>
                      <TaskTag {...type} />
                    </span>
                  </div>
                  <div className={cn(styles.property)}>
                    <span className={cn(styles.propertyLabel)}>
                      {t('Status:')}
                    </span>
                    <span className={cn(styles.propertyValue)}>
                      <TaskTag {...status} />
                    </span>
                  </div>
                </div>
                <div className={cn(styles.cardContentColumn)}>
                  <div className={cn(styles.property)}>
                    <span className={cn(styles.propertyLabel)}>
                      {t('Date created:')}
                    </span>
                    <span className={cn(styles.propertyValue)}>
                      {moment(task.start_date).format('DD.MM.YYYY')}
                    </span>
                  </div>
                  <div className={cn(styles.property)}>
                    <span className={cn(styles.propertyLabel)}>
                      {t('Due date:')}
                    </span>
                    <span className={cn(styles.propertyValue)}>
                      {moment(task.end_date).format('DD.MM.YYYY')}
                    </span>
                  </div>
                  <div className={cn(styles.property)}>
                    <span className={cn(styles.propertyLabel)}>
                      {t('Completion rule:')}
                    </span>
                    <span className={cn(styles.propertyValue)}>{rule}</span>
                  </div>
                </div>
              </div>
              <CardHeader>{t('Description')}</CardHeader>
              {task.description ? (
                <p className={cn(styles.description)}>{task.description}</p>
              ) : (
                <p className={cn(styles.description, styles.descriptionEmpty)}>
                  {t('No description provided.')}
                </p>
              )}
            </Card>
          </div>
          <div className={cn(styles.column)}>
            <ManageTaskAssignments
              disabled
              author={author}
              members={members}
              setMembers={setMembers}
            />
          </div>
        </div>
        <div className={cn(styles.layout)}>
          <div className={cn(styles.row)}>
            <Card>
              <CardHeader className={styles.cardHeadingMain}>
                {t('Subtasks')}
              </CardHeader>

              <TaskTable
                className={styles.table}
                data={task.child_tasks.reverse()}
              />

              <div className={styles.cardButtons}>
                <Button
                  className={styles.button}
                  color="#B11873"
                  title={
                    <>
                      {t('Add subtask')}{' '}
                      <FontAwesomeIcon icon={faPlus} width={16} />
                    </>
                  }
                  onClick={() =>
                    navigate(
                      `/dashboard/company-dashboard/tasks/view/${
                        task.parent_task_id ? 'subtask/' : 'task/'
                      }${task.task_id}/create`
                    )
                  }
                />
              </div>
            </Card>
          </div>
          <div className={cn(styles.row)}>
            <Card>
              <CardHeader className={styles.cardHeadingMain}>
                {t('Gantt')}
              </CardHeader>

              <GanttGraph task={task} />
            </Card>
          </div>
          <div className={cn(styles.row)}>
            <Card>
              <CardHeader className={styles.cardHeadingMain}>
                {t('Linked tasks')}
              </CardHeader>

              <TaskTable className={styles.table} data={[]} />

              <div className={styles.cardButtons}>
                <Button
                  className={styles.button}
                  color="#B11873"
                  title={
                    <>
                      {t('Connect task')}{' '}
                      <FontAwesomeIcon icon={faPlus} width={16} />
                    </>
                  }
                  // onClick={() =>
                  //   navigate('/dashboard/company-dashboard/tasks/:id/add')
                  // }
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export const ViewSubtask = () => <ViewTask subtask />;

export default ViewTask;

