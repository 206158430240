import Button from "../core/Button";
import React from "react";
import {useTranslation} from "../../../contexts/LocaleContext";

const regularTableControllers = ({
                                     addNew = () => {
                                     },
                                     importCSV = () => {
                                     },
                                     exportCSV = () => {
                                     },
                                     exportWaiting = false,
                                     t = (v) => v,
                                     fromWhere = ''
                                 }
) => {

    return [
        <Button
            title={t('add_new')}
            onClick={addNew}
            height={27}
            fontSize={'0.9rem'}
        />,
        fromWhere==='tasks'?<></>:(<Button
            title={t("import_.csv")}
            onClick={importCSV}
            height={27}
            fontSize={'0.9rem'}
        />),
        fromWhere==='tasks'?<></>:(<Button
            title={t("export_.csv")}
            onClick={exportCSV}
            height={27}
            fontSize={'0.9rem'}
            waiting={exportWaiting}
        />),
    ];
};

export default regularTableControllers;
