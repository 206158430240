import React from 'react';
import cn from 'classnames';
import styles from './styles.module.css';
import { PulseLoader } from 'react-spinners';

const Loader = ({ className, ...props }) => {
  return (
    <div className={cn(styles.loader, className)}>
      <PulseLoader color="#B11873" {...props} />
    </div>
  );
};

export default Loader;
