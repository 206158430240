import { GET } from "../network";
import { filtersToQuery } from "../../utils/filtersToQuery";
import { fileEndpoints } from "../requestEndpoints";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data); 
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

const getPagedListData = async path => {
    const data = await getData(path);
    if (data) {
        return {items: data.items, page: data.currentPage || 1, pages: data.totalPages || 1};
    }
    return {items: [], page: 1, pages: 1};
}

const getFile = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.data;
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

export const getProductCataloguesData = async (filters = {}) => {
    const data = await getPagedListData(fileEndpoints.getList + filtersToQuery(filters));
    return data; 
};

export const downloadProductCatalogue = async id => {
    return await getFile(`${fileEndpoints.getById}${id}`);
};

export const getServiceCataloguesData = async (filters = {}) => {
    const data = await getPagedListData(fileEndpoints.getList + filtersToQuery(filters));
    return data;
};

export const downloadServiceCatalogue = async id => {
    return await getFile(`${fileEndpoints.getById}${id}`);
};
