import React, { useEffect, useState, useContext } from 'react';
import styles from './search.module.css';
import Collapse from 'react-bootstrap/Collapse';
import cn from 'classnames';
import { search } from '../../../../api/Commands/dataGetters';
import { useTranslation } from '../../../../contexts/LocaleContext';
import TabContext from '../../../../contexts/TabContext';
import { PulseLoader } from 'react-spinners';
import { formatDate } from '../../../../utils/StringUtils';
import Button from '../../../common/core/Button';
import { AssistantContext } from '../../../../contexts/AssistantContext';

const colors = {
  product: '#24788d',
  service: '#7c1a65',
  client: '#c66313',
  partner: '#8A1D70',
  template: '#8A1D70',
};

const Loading = () => {
  return (
    <div className={styles.loadingWrapper}>
      <PulseLoader color={'#554590'} size={15} />
    </div>
  );
};

const RelatedEntity = ({ type = 'product', name }) => {
  return (
    <div className={styles.entityWrapper}>
      <span className={styles.entityName} style={{ color: colors[type] }}>
        {name}
      </span>
    </div>
  );
};

const Legend = ({ legend }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.legendWrapper}>
      <div className={legend.classNames.bar}>
        {legend.columns.map(({ icon, title }, index) => (
          <div className={cn(styles.legendItem)} key={title}>
            <i className={icon} />
            <span>{title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const TableItem = ({
  tableItem = {
    id: 0,
    classNames: {},
    columns: [],
    relations: {},
    onClick: () => {},
  },
  isExpanded = false,
  expand = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={styles.documentWrapper}
      style={isExpanded ? { borderColor: '#909090' } : {}}
    >
      <div className={tableItem.classNames.bar} onClick={expand}>
        {tableItem.columns.map(({ className, element }) => (
          <div className={className}>
            <span>{element}</span>
          </div>
        ))}

        <Button
          className={
            isExpanded ? styles.documentButton : styles.documentButtonExpanded
          }
          height="100%"
          title={t('open')}
          onClick={tableItem.onClick}
        />
      </div>
      {tableItem.relations && (
        <Collapse in={isExpanded}>
          <div className={styles.documentEntities}>
            <EntitiesList
              type={'client'}
              entities={tableItem.relations.clients}
              title={t('client') + ':'}
            />
            <EntitiesList
              type={'product'}
              entities={tableItem.relations.products}
              title={t('products') + ':'}
            />
            <EntitiesList
              type={'service'}
              entities={tableItem.relations.services}
              title={t('services') + ':'}
            />
          </div>
        </Collapse>
      )}
    </div>
  );
};

const EntitiesList = ({ title = '', type = 'products', entities = [] }) => {
  if (!entities?.length) return null;
  return (
    <div className={styles.entitiesSection}>
      <div
        className={styles.sectionName}
        style={{ color: colors[type], borderTop: `solid 2px ${colors[type]}` }}
      >
        {title}
      </div>
      <div className={styles.listWrapper}>
        <div className={styles.entitiesList}>
          {entities.map((entity) => (
            <RelatedEntity type={type} name={entity?.name || 'name'} />
          ))}
        </div>
      </div>
    </div>
  );
};

const SearchWrapper = ({
  query= "",
  legend = {},
  tableData = {},
  isLoading = true,
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const [expandedId, setExpandedId] = useState(-1);

  useEffect(() => {
    setTimeout(() => setExpandedId(0), 500);
  }, []);

  if (isLoading) {
    return (
      <div className={styles.backdrop}>
        <div className={styles.container}>
          <div className={styles.searchWrapper}>
            <div className={styles.searchTitle}>{`${t(
              'search_results_for'
            )}: '${query}'`}</div>
            <Loading />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.backdrop}>
      <div className={styles.container}>
        <div className={styles.searchWrapper}>
          <div className={styles.searchTitle}>
            {`${t('search_results_for')}: '${query}'`}
            <div onClick={onClose} className={styles.closeButton}>
              <i className={'fas fa-times'} />
            </div>
          </div>
          {tableData?.length && tableData.length > 0 ? (
            <Legend legend={legend} />
          ) : (
            t('no_results')
          )}
          <div className={styles.docList}>
            {tableData.map((current, index) => (
              <TableItem
                tableItem={current}
                isExpanded={expandedId === index}
                expand={() => setExpandedId(expandedId === index ? -1 : index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchWrapper;
