import React from 'react'
import s from './styles.module.css'
import {Modal} from "react-bootstrap";

const CustomModal = ({isVisible = false, onClose=()=>{}, title='', ...props}) => {

    return <Modal show={isVisible}
                  onBackdropClick={onClose}
                  onEscapeKeyDown={onClose}
                  onHide={onClose}>
        <div className={s.modalWrapper}>
            <div className={s.modalHeader}>
                <div className={s.modalTitle}>
                    {title}
                </div>
                <div className={s.modalCloseButton} onClick={onClose}>
                    <i className={'fas fa-times'}/>
                </div>
            </div>
            <div className={s.modalContent}>
                {props.children}
            </div>
        </div>
    </Modal>
}

export default CustomModal
