import React, { useEffect, useState, useContext } from 'react';
import styles from './search.module.css';
import { search } from '../../../../api/Commands/dataGetters';
import { useTranslation } from '../../../../contexts/LocaleContext';
import SearchWrapper from './SearchWrapper';
import {
  getCustomersB2BByKeywords,
  getCustomersB2PByKeywords,
  searchCustomers,
} from '../../../../api/Customer/dataGetters';
import { formatDate } from '../../../../utils/StringUtils';
import { AssistantContext } from '../../../../contexts/AssistantContext';
import TabContext from '../../../../contexts/TabContext';
import { useNavigate } from 'react-router-dom';

const getTableDataFromResponse = (
  customersB2C = [],
  customersB2B = [],
  openCustomer
) => {
  const isB2CLargerArray = customersB2C.length >= customersB2B.length;
  const largerArray = isB2CLargerArray ? customersB2C : customersB2B;
  const smallerArray = isB2CLargerArray ? customersB2B : customersB2C;
  const data = largerArray.reduce((accumulator, current, index) => {
    const odd = [{ ...current, type: isB2CLargerArray ? 'B2C' : 'B2B' }];
    const even = smallerArray[index]
      ? [{ ...smallerArray[index], type: isB2CLargerArray ? 'B2B' : 'B2C' }]
      : [];

    return [...accumulator, ...odd, ...even];
  }, []);

  return data.map((customer, index) => {
    return {
      id: customer.id || index,
      type: customer.type,
      classNames: {
        bar: styles.customerBar,
      },
      columns: [
        {
          className: styles.customerName,
          element: customer.id,
        },
        {
          className: styles.customerName,
          element: customer.name,
        },
        {
          className: styles.customerDate,
          element: customer.email,
        },
        {
          className: styles.customerStatus,
          element: customer.type,
        },
      ],
      onClick: openCustomer(customer.id, customer.type),
    };
  });
};

const SearchCustomers = ({ keywords = [], onClose = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { close: closeAssistant } = useContext(AssistantContext);
  const [isLoading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [legend, setLegend] = useState({
    classNames: {
      bar: styles.customerLegendBar,
    },
    columns: [
      {
        icon: 'fas fa-hashtag',
        title: t('code'),
      },
      {
        icon: 'fas fa-user',
        title: t('name'),
      },
      {
        icon: 'fas fa-envelope',
        title: t('email'),
      },
      {
        icon: 'fas fa-signature',
        title: t('type'),
      },
    ],
  });

  const openCustomer = (id, type) => () => {
    closeAssistant();
    navigate(`/dashboard/customers/${type}/${id}`);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getCustomersB2PByKeywords(keywords),
      getCustomersB2BByKeywords(keywords),
    ]).then(([customersB2C, customersB2B]) => {
      setTableData(
        getTableDataFromResponse(customersB2C, customersB2B, openCustomer)
      );
      setLoading(false);
    });
  }, []);

  return (
    <SearchWrapper
      query={keywords.join(' ')}
      legend={legend}
      tableData={tableData}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default SearchCustomers;
