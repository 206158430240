import React, { useContext } from "react";
import {isEmpty} from "../../../utils/StringUtils";
import DashboardTabs from "./DashboardTabs";
import {useTranslation} from "../../../contexts/LocaleContext";
import { useRestriction } from "../../../contexts/Restriction/RestrictionContext"

const AddEditTableModal = ({
                             editingValues,
                             close,
                             list,
                             CustomForm,
                             name
}) => {

  const {t} = useTranslation();
  const { withRestrictions } = useRestriction();
  const createSubmit = (formState) => {
    withRestrictions( async (props) =>  await list.create(props))({...formState})
    close();
  };

  const editSubmit = async (formState) => {
    withRestrictions( async (props) =>  await list.update(props))({...formState, id: editingValues.id})
    close();
  };

  const tabs = [{
    name: isEmpty(editingValues) ? `${t('add_new_')}: ${name}`.toUpperCase() : `${t('edit')}: ${name} #${editingValues.id}`.toUpperCase(),
    content: (
      <CustomForm
        onSubmit={isEmpty(editingValues) ? createSubmit : editSubmit}
        initialValues={editingValues}
        close={close}
      />
    ),
  }];

  return (
    <DashboardTabs
      tabs={tabs}
      readyContents={tabs.map(tab => tab.content)}
    />
  );
};

export default AddEditTableModal;
