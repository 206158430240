import React, {useContext} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from './style.module.css'
import Input from "../../common/core/Input"
import {TextArea, Scope} from "informed";
import {useTranslation} from "../../../contexts/LocaleContext";
import CorporateContext from "../contents/CompanyDashboard/CorporateContext";
import {AvatarPlaceholder,useUploadAvatarModal} from '../../common/parts/UploadAvatarModal'
import DataContext from '../../../contexts/DataContext';
import { uploadAccountAvatar } from '../../../api/Account/dataSetters';
import TouchableOpacity from '../../common/core/TouchableOpacity';
import Avatar from 'react-avatar';
import { idToColor } from '../../../utils/StringUtils';

export default () => {
    const {t} = useTranslation()
    const data = useContext(CorporateContext)
    const { account } = useContext(DataContext);
    const { name, surname, avatarUrl, id } = account.data;
    
    const toggle = () => {
        if (typeof (data.role.data) == typeof ({a: 1})) return false
        else if (data.role.data === 'CEO') return false
        else return true
    }
    
    const onAvatarSave = async file => {
        await uploadAccountAvatar(file);
        await account._get();
    };
    
    const { avatarModal, openAvatarModal } = useUploadAvatarModal(onAvatarSave);

    return (
        <Row>
            <Col xs={8}>
                <Row>
                    <Col lg={'9'} sm={'6'} className={"m-0 pr-lg- text-center"}>

                        <Input field={'name'} placeholder={t('name')} required={!toggle()} disabled={toggle()}/>    
                        <Input field={'surname'} placeholder={t('surname')} required={!toggle()} disabled={toggle()}/>
                        <Input field={'workemail'} type={'email'} placeholder={t('email')} required={!toggle()}
                            disabled={toggle()}/>
                        <Input field={'telephone'} placeholder={t('phone')} required={!toggle()} disabled={toggle()}/>
                        <Scope scope={'address'}>
                                <Row>
                                    <Col lg={'8'} sm={'6'} className={"pr-lg-2"}>
                                        <Input field={'street'} placeholder={t('street')} required={!toggle()}
                                            disabled={toggle()}/>
                                        <Input field={'city'} placeholder={t('city')} required={!toggle()} disabled={toggle()}/>
                                        <Input field={'country'} placeholder={t('country')} required={!toggle()}
                                            disabled={toggle()}/>
                                    </Col>
                                    <Col lg={'4'} sm={'6'} className={"pl-lg-2"}>
                                        <Input field={'building'} placeholder={t('building')} required={!toggle()}
                                            disabled={toggle()}/>
                                        <Input field={'index'} placeholder={t('index')} disabled={toggle()}/>
                                        <Input field={'state'} placeholder={t('state')} disabled={toggle()}/>
                                    </Col>
                                </Row>
                        </Scope>

                    </Col>
                </Row>
                <Col className={'col-9'}>
                    <Input required={!toggle()} field={'userbankaccount'} placeholder={t("banking_details")}
                        disabled={toggle()}/>
                </Col>
            </Col>
            <Col xs={4}>
                <TouchableOpacity
                className={styles.avatarContainer}
                
                >
                    <AvatarPlaceholder
                        fontSize={20}
                        className={styles.avatarPlaceholder}
                        onClick={openAvatarModal}
                    />
                    <Avatar name={name + ' ' + surname}
                        color={idToColor(id)}
                        className={styles.avatarImage}
                        size={150}
                        src={avatarUrl}
                        onClick={openAvatarModal}
                    />
                </TouchableOpacity>
                {avatarModal}
            </Col>
        </Row>
    )
};

