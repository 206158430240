import React, {useContext} from 'react';
import DashboardTabs from "../../../common/parts/DashboardTabs";
import {isEmpty} from "../../../../utils/StringUtils";
import DataContext from "../../../../contexts/DataContext";
import {useTranslation} from "../../../../contexts/LocaleContext";
import { useRestriction } from "../../../../contexts/Restriction/RestrictionContext"
import PartnerForm from "./PartnerForm";


const ModalPartner = ({
                          editingValues={},
                          close=()=>{},
                          initWithName=null
                      }) => {
    const {t} = useTranslation()

    const {partners} = useContext(DataContext);
    const { withRestrictions } = useRestriction();

    const createSubmit = (formState) => {
        withRestrictions(async (props) =>  await partners.create(props))({...formState});
        close();
    };

    const editSubmit = (formState) => {
        withRestrictions(async (props) =>  await partners.update(props))({...formState, id: editingValues.id});
        close();
    };

    const TABS = [{
        name: isEmpty(editingValues) ? t('add_new_partner').toUpperCase() : `${t("edit_partner").toUpperCase()} #${editingValues.id}`,
        content: (
            <PartnerForm
                initialValues={initWithName ? {...editingValues, name: initWithName} : editingValues}
                onSubmit={isEmpty(editingValues) ? createSubmit : editSubmit}
                close={close}
            />
        ),
    }];

    return (
        <DashboardTabs
            tabs={TABS}
            readyContents={TABS.map(tab => tab.content)}
        />
    );
};

export default ModalPartner;
