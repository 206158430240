import React, {useContext, useState} from 'react'
import {useTranslation} from "../../../../contexts/LocaleContext";
import styles from './styles.module.css'
import ModalProduct from "../../contents/Products/ModalProduct";
import ModalService from "../../contents/Services/ModalService";
import CustomerForm from "../../Assistant/CustomerForm";
import DataContext from "../../../../contexts/DataContext";
import TouchableOpacity from "../../../common/core/TouchableOpacity";
import ModalPartner from "../../contents/Partners/ModalPartner";

const getModalContent = (type, close, onB2BSubmit, onB2CSubmit) => {
    switch (type) {
        case 'products':
            return <ModalProduct close={close}/>
        case 'services':
            return <ModalService close={close}/>
        case 'customersB2B':
        case 'customersB2P':
            return <CustomerForm
                onB2BSubmit={(formState)=>{onB2BSubmit(formState, close)}}
                onB2CSubmit={(formState)=>{onB2CSubmit(formState, close)}}
                close={close}/>
        case 'partners':
            return <ModalPartner close={close} onSubmit={close}/>
        default:
            return null
    }
}

const getModal = (type, close = () => {}, _styles, t=v=>v, onB2BSubmit, onB2CSubmit) => {
    let barTitle = ''
    switch (type) {
        case 'products':
            barTitle = 'New product';
            break;
        case 'services':
            barTitle = 'New service';
            break;
        case 'customersB2B':
            barTitle = 'New B2B client';
            break;
        case 'customersB2P':
            barTitle = 'New B2C client';
            break;
        case 'partners':
            barTitle = 'New partner'
            break;
        default:
            return null
    }
    return <div>
        <div className={styles.modalContainer}>
            <div className={styles.modalBox}>
                {/* <Bar title={t(barTitle)} goBack={close} vertical accentColorName={'orange'}/>*/}
                <div style={{flex: 1}}>{getModalContent(type, close, onB2BSubmit, onB2CSubmit)}</div>
            </div>
        </div>
    </div>
}

const Options = ({options = []}) => {

    const {customersB2B, customersB2P, documents} = useContext(DataContext)

    const {t} = useTranslation()

    const onB2BSubmit = async (formState, close=()=>{}) => {
        await customersB2B.create(formState);
        close()
    }

    const onB2CSubmit = async (formState, close=()=>{}) => {
        await customersB2P.create(formState);
        close()
    }


    const [currentModal, setCurrentModal] = useState('none')

    if (!options.length) return null

    return <div className={styles.optionsContainer}>
        {getModal(currentModal, () => setCurrentModal('none'), styles, t, onB2BSubmit, onB2CSubmit)}
        <div className={styles.optionsShape}>
            {options.map((option, id) => <Option {...option} key={id} isLast={id === options.length - 1}
                                                 openModal={setCurrentModal}/>)}
        </div>
    </div>
}

const Option = ({
                    label = '',
                    onPress = () => {
                    },
                    createsNew = false,
                    isLast = false,
                    openModal = () => {
                    },
                    ...props
                }) => {

    return <TouchableOpacity onClick={createsNew ? () => openModal(props.type) : onPress}
                             className={styles.option}
                             style={{borderBottomWidth: isLast ? 0 : 2}}>
        {createsNew && <i className={'fas fa-plus'} style={{marginRight: 4, fontSize: 11, color: '#441c7c'}}/>}
        <div
            className={styles.optionText}
            style={{
            color: createsNew ? '#441c7c' : 'black',
            fontWeight: createsNew ? 'bold' : 'normal'
        }}>{label}</div>
    </TouchableOpacity>
}

export default Options
