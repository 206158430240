import {GET} from "../network";
import { filtersToQuery } from "../../utils/filtersToQuery";
import { customerEndpoints } from "../requestEndpoints";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data); 
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

const getListData = async path => {
    const data = await getData(path);
    if (data) {
        return data.items;
    }
    return [];
};

const getPagedListData = async path => {
    const data = await getData(path);
    if (data) {
        return {items: data.items, page: data.currentPage || 1, pages: data.totalPages || 1};
    }
    return {items: [], page: 1, pages: 1};
}
const getFile = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.data;
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

const getByKeywords = async (path, keywords) => {
    const searchParams = new URLSearchParams();
    keywords.forEach(keyword => {
        searchParams.append("keywords", keyword);
    });
    return (await getData(`${path}?${searchParams.toString()}`)) || [];
};

export const getCustomersB2BData = async () => {
    return await getListData(customerEndpoints.businessGetList);
};

export const getCustomerB2BById = async id => {
    return await getData(`${customerEndpoints.businessGetById}${id}`);
};

export const getCustomersB2PData = async () => {
    return await getListData(customerEndpoints.personGetList);
};

export const getCustomerB2PById = async id => {
    return await getData(`${customerEndpoints.personGetById}${id}`);
};

export const getCustomersB2PByKeywords = async keywords => {
    return await getByKeywords(customerEndpoints.personSearch, keywords);
};

export const searchB2BCustomers = async keywords => {
    return await getByKeywords(customerEndpoints.businessSearch, keywords);
};

export const searchB2CCustomers = async keywords => {
    return await getByKeywords(customerEndpoints.personSearch, keywords);
};

export const getCustomersB2BByKeywords = async keywords => {
    return await getByKeywords(customerEndpoints.businessSearch, keywords);
};

export const exportCustomersB2P = async () => {
    return await getFile(customerEndpoints.personExportCsv, String);
};

export const exportCustomersB2B = async () => {
    return await getFile(customerEndpoints.businessExportCsv, String);
};

export const getPagedCustomersB2BData = async (filters = {}) => {
    return await getPagedListData(customerEndpoints.businessGetList + filtersToQuery(filters));
};

export const getPagedCustomersB2CData = async (filters = {}) => {
    return await getPagedListData(customerEndpoints.personGetList + filtersToQuery(filters));
};