import React from 'react'
import styles from './styles.module.css'

const Controllers = ({error=false, ...props}) => <div className={styles.controllers}>
    <div className={styles.error}>
        {error || ''}
    </div>
    <div className={styles.grid}>
        {props.children}
    </div>
</div>

export default Controllers
