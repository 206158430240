import React, { useState, useContext, useEffect, useMemo } from "react";
import styles from "./style.module.css";
import { Form } from "informed";
import ButtonsPair from "../../common/parts/ButtonsPair";
import Profile from "./Profile";
import Business from "../../SignUp/contents/Business";
import DashboardTabs from "../../common/parts/DashboardTabs";
import Enum from "../../../utils/enum";
import AssistantSettings from "./VirtualAssistant";
import { deepClone } from "../../../utils/StringUtils";
import useWaiting from "../../common/hooks/useWaiting";
import { useNavigate, useParams } from "react-router-dom";
import DataContext from "../../../contexts/DataContext";
import Billing from "./Billing";
import ChangePassword from "../../ChangePassword";
import { toast } from "react-toastify";
import { changePasswordRequestHandler } from "../../../api/Account/dataSetters";
import { createBusiness } from "../../../api/Business/dataSetters";
import { createCorporateCompany } from "../../../api/CompanyAccount/dataSetters";
import { TutorialContext } from "../../../contexts/TutorialContext";


import {Info} from "../../common/core/Toasts";
import {useTranslation} from "../../../contexts/LocaleContext";
import GuideContext from "../../Onboarding";
import Company from "./Company";

const [ACCOUNT, COMPANY, ASSISTANT, CHANGEPASSWORD, BUSINESS] = Enum(5);


export default function EditUserInfo({ showing }) {
  const {t} = useTranslation()
  const { account, company, business, assistant } = useContext(DataContext);
  const { setPage, markDone } = useContext(GuideContext);
  const { profile_settings_tab } = useContext(TutorialContext)

  const { activ_department } = account.data;

  useEffect(()=>{setPage('profile')},[])

  const { tab } = useParams()
  const INIT_TAB = tab || 0;

  const navigate = useNavigate();
  const close = () => navigate.goBack();

  const [formApis, setFormApis] = useState(Array(4));
  const setFormApi = (index, state) => {
    setFormApis(({ [index]: oldState, ...arr }) => ({
      [index]: state,
      ...arr
    }));
  };

  const setFormData = (index, data = {}) => {
    if (formApis[index]) {
      formApis[index].reset();
      if (data && data.gender !== undefined) data.gender = String(data.gender);
      formApis[index].setValues({ ...data });
    }
  };

  const setAccountFormData = () => setFormData(ACCOUNT, account.data);
  const setCompanyFormData = () => setFormData(COMPANY, company.data);
  const setBusinessFormData = () => setFormData(BUSINESS, business.data);
  const setAssistantFormData = () => setFormData(ASSISTANT, assistant.data);

  useEffect(setAccountFormData, [formApis, account]);
  useEffect(setCompanyFormData, [formApis, company]);
  useEffect(setBusinessFormData, [formApis, business]);
  useEffect(setAssistantFormData, [formApis, assistant]);

  useEffect(() => {
    setAccountFormData();
    setCompanyFormData();
    setBusinessFormData();
    setAssistantFormData();
  }, [showing]);

  const formTabs = [
    {
      index: 0,
      name: t('profile').toUpperCase(),
      content: <Profile />,
      id: "tutorial-step-9"
    },
    {
      index: 1,
      name: t('company').toUpperCase(),
      content: <Company noHeader />,
      id: "tutorial-step-10"
    },
    {
      index: 2,
      name: t('assistant').toUpperCase(),
      content: <AssistantSettings />,
      id: "tutorial-step-11"
    },
    { 
      index: 3,
      name: t('change_password').toUpperCase(),
      content: <ChangePassword /> }
  ];

  const otherTabs = [
    {
      index: 4,
      name: t('billing').toUpperCase(),
      content: <Billing />
    }
  ];

  const isCEO = useMemo(() => {
    return company?.data?.role === "CEO";
  }, [company.isLoaded, company.data]);

  const accessTabs = () => {
      if (activ_department !== 0) {
        formTabs.splice(1,1);
        return formTabs
      }
      return !isCEO ? formTabs : formTabs.concat(otherTabs)
  };

  useEffect(() => {
    accessTabs();
  }, [])

  const changePasswordHandler = async formState => {
    if (formState.NewPassword === formState.ConfirmPassword) {
      await changePasswordRequestHandler(formState);
      toast(<Info>{t("you_changed_password_successfully!")}</Info>);
    } else {
      toast(<Info>{t("passwords_don't_match,_please_check_it")}</Info>);
    }
  };

  const onFormSubmit = async (tabIndex, formState = {}) => {
    
    const data = deepClone(formState);
    if (data && data.gender !== undefined) data.gender = Number(data.gender);
    switch (tabIndex) {
      case ACCOUNT:
        markDone('profile info')
        await account.set(data);
        break;
      case COMPANY:
        if (!company?.data?.id) {
          markDone('company info')
          await createCorporateCompany(data)
          await company._get(data)
        }
        else {
          markDone('company info')
          await company.set(data);
        }
        break;
      case BUSINESS: if (!business?.data?.id) {
        markDone('business info')
        await createBusiness(data)
        await business._get(data)
        }
        else {
        markDone('business info')
        await business.set(data);
      }
        break;
      case ASSISTANT:
        markDone('assistant setup')
        await assistant.set(data);
        break;
      case CHANGEPASSWORD:
        await changePasswordHandler(formState);
        break;
      default:
        break;
    }
    // close();
  };

  //todo
  const [waiting, submit] = useWaiting(onFormSubmit);
  // useEffect(() => {alert(waiting)}, [waiting]);

  const forms = formTabs.map((tab) => {
    return (
      <div key={tab.name} className={styles.formContainer} >
        <Form
          className={styles.form}
          onSubmit={formState => submit(tab.index, formState)}
          getApi={api => setFormApi(tab.index, api)}
          id={tab.id}
        >
          {tab.content}
          <ButtonsPair onCancel={close} saveWaiting={waiting} />
        </Form>
      </div>
    );
  });

  const filtratedForms = forms.filter((item, index) => index !== 1);

  const readyContents = useMemo(() => {
    const readyContentsOther = otherTabs.map(tab => tab.content);
    const readyContentsForms = !isCEO
      ? forms
      : forms.concat(readyContentsOther)
    return activ_department !== 0 ? filtratedForms : readyContentsForms
  }, [activ_department, filtratedForms, otherTabs, isCEO])
  
  return (
    <div>
      <DashboardTabs
        tabs={accessTabs()}
        startTabIndex={INIT_TAB}
        readyContents={readyContents}
        tabIndex={profile_settings_tab}
      />
    </div>
  );
}
