import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import { Form } from 'informed';
import TouchableOpacity from '../../../common/core/TouchableOpacity';
import Image from 'react-bootstrap/Image';
import clipboardBlueImagePath from '../../images/icons/templates/clipboard_blue.png';
import templatesStyles from '../Templates/style.module.css';
import styles from './style.module.css';
import Row from 'react-bootstrap/Row';
import cn from 'classnames';
import Button from '../../../common/core/Button';
import TabContext, { EditorType } from '../../../../contexts/TabContext';
import { formatDate, sortByIdDesc } from '../../../../utils/StringUtils';
import DataContext, { useLoaded } from '../../../../contexts/DataContext';
import FetchLoading from '../../../common/parts/FetchLoading';
import { AssistantContext } from '../../../../contexts/AssistantContext';
import Col from 'react-bootstrap/Col';
import DropDown, { ThreeDots } from '../../../common/core/DropDown';
import { useTranslation } from '../../../../contexts/LocaleContext';
import GuideContext from '../../../Onboarding';
import { removeDocument } from '../../../../api/Document/dataSetters';
import { useRestriction } from '../../../../contexts/Restriction/RestrictionContext';
import { getPageDocumentsData } from '../../../../api/Document/dataGetters';
import { DateInput } from '../../../common/core/Input';
import { SelectInput } from '../../../common/core/Input';
import { PulseLoader } from 'react-spinners/dist';
import Input from '../../../common/core/Input';
import Checkbox from '../../../common/core/Checkbox';
import DocumentStatus, {
  documentStatusShape,
} from '../../../common/parts/DocumentStatus';
import { useMediaQuery } from 'react-responsive';
import Pagination from '../../../common/core/Pagination';

const ITEM_HEIGHT = {
  mobile: 90,
  desktop: 50,
};

const DocumentList = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { isLoaded, documents } = useLoaded('documents');
  const { openEditor } = useContext(TabContext);
  const {
    assistant,
    open: openAssistant,
    isOpen: assistantOpen,
  } = useContext(AssistantContext);

  const paginationLocale = {
    items_per_page: t('items_per_page'),
    jump_to: t('jump to'),
    jump_to_confirm: t('jump to confirm'),
    page: t('page'),
    prev_page: t('prev page'),
    next_page: t('next page'),
    prev_5: t('prev 5'),
    next_5: t('next 5'),
    prev_3: t('prev 3'),
    next_3: t('next 3'),
  };

  const { withRestrictions } = useRestriction();
  const [filters, setFilters] = useState({});
  const [isFilterOpen, setisFilterOpen] = useState(false);
  const [sort, setSort] = useState({
    by: 'updatedAt',
    order: 1,
  });

  const [prevAssistantOpen, setPrevAssistantOpen] = useState(false);

  useEffect(() => {
    if (!isLoaded || !params.id) return;

    documents.getItemById(params.id).then((document) => {
      if (!document?.id) return;

      openEditor({
        id: document.id,
        editorTitle: document.title,
        editorContent: document.content,
        editorKeywords: document.keywords,
        editorIsPublic: document.is_public,
        documentToken: document.document_token,
        statusType: document.document_status_type,
        editorType: EditorType.EDIT_DOCUMENT,
      });
    });
  }, [params, isLoaded]);

  useEffect(() => {
    if (assistantOpen === false && prevAssistantOpen === true) {
      setPrevAssistantOpen(true);
      fetchData();
    } else {
      setPrevAssistantOpen(true);
    }
  }, [assistantOpen, assistant]);

  const isMobile = useMediaQuery({
    query: '(max-width: 830px)',
  });

  const [
    { size: pageSize, current: pageCurrent, sizeOptions: pageSizeOptions },
    setpagination,
  ] = useState({
    size: 10,
    current: 1,
    sizeOptions: [5, 10, 15, 20, 50],
  });

  const [fetchedDocuments, setFetchedDocuments] = useState({
    items: null,
    loading: true,
    totalItems: 1,
  });

  function onChange(current, pageSize) {
    setpagination((prev) => ({ ...prev, current, size: pageSize }));
  }

  const fetchData = async (sortBy, withoutLoading) => {
    !withoutLoading &&
      setFetchedDocuments((prev) => ({ ...prev, loading: true }));
    const { items, pages } = await getPageDocumentsData({
      ...filters,
      pageSize: pageSize,
      Page: pageCurrent,
      SortBy: sortBy || sort.by,
      SortOrder: sort.order,
    });
    setFetchedDocuments((prev) => ({
      ...prev,
      items,
      totalItems: pages * pageSize,
      loading: false,
    }));
  };

  useEffect(() => {
    fetchData();
  }, [sort.data, sort.order, pageSize, pageCurrent, filters]);

  const onFilter = (filters, force) => {
    if (force) {
      setpagination((prev) => ({ ...prev, current: 0 }));
      return setFilters(filters);
    }
    if (filters && Object.keys(filters).length !== 0) {
      setFilters(filters);
    }
    setpagination((prev) => ({ ...prev, current: 0 }));
  };

  const onResetFilters = () => {
    if (filters && Object.keys(filters).length !== 0) {
      setFilters({});
    }
  };

  const openFilter = () => setisFilterOpen(true);
  const closeFilter = () => setisFilterOpen(false);

  const onPerPageChange = (e) => {
    const { value } = e.target;
    setpagination((prev) => ({ ...prev, size: value, current: 1 }));
  };

  const onSort = (sortBy) => {
    if (sort.by === sortBy) {
      return setSort((prev) => ({ ...prev, order: prev.order === 0 ? 1 : 0 }));
    }
    setSort((prev) => ({ ...prev, by: sortBy, order: 0 }));
    fetchData(sortBy);
  };

  const onDeleteSelected = async (id) => {
    const promises = [documents.remove(id)];
    return await Promise.allSettled(promises).then(() => fetchData());
  };

  const fixedSize =
    fetchedDocuments.items && fetchedDocuments.items.length > 0
      ? (isMobile ? ITEM_HEIGHT.mobile : ITEM_HEIGHT.desktop) *
          fetchedDocuments.items.length +
        fetchedDocuments.items.length * 7 +
        'px'
      : (isMobile ? ITEM_HEIGHT.mobile : ITEM_HEIGHT.desktop) * 7 + 'px';

  const [selectAll, setSelectAll] = useState(false);

  const selectAllHandler = () => {
    setSelectAll(!selectAll);
  };

  const deleteSelectedHandler = async (state) => {
    const selectedItems = [];

    if (selectAll) {
      const allItems = fetchedDocuments.items.map((item) =>
        onDeleteSelected(item.id).then(() => setSelectAll(false))
      );
      return;
    }

    for (const property in state) {
      if (state[property]) {
        const id = property.slice(7);
        selectedItems.push(Number(id));
      }
    }

    const promises = selectedItems.map((id) => {
      return onDeleteSelected(id);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.documentsWrapper}>
        <Row className={styles.header}>
          <div className={styles.name}>{t('documents').toUpperCase()}</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={styles.filterButton}>
              <Button
                thin
                transparent
                onClick={() => openFilter()}
                title={<i class="fas fa-filter" aria-hidden="true"></i>}
                height={28}
              />
            </div>
          </div>
        </Row>
        <Form onSubmit={deleteSelectedHandler}>
          <Row className="pl-2">
            <Button
              thin
              type="submit"
              title={t('delete_selected')}
              color="#D92D15"
              height={28}
              width={150}
            />
            <Button
              thin
              onClick={withRestrictions(() => openAssistant(true))}
              title={t('create_new')}
              height={28}
              width={150}
            />
          </Row>
          <Row style={{ marginTop: '20px' }} className={templatesStyles.board}>
            <DocumentHead
              onSort={onSort}
              selectAllHandler={selectAllHandler}
              selectAll={selectAll}
              activeSort={sort.by}
              order={sort.order}
            />
            <div
              style={{
                width: '100%',
                height: fixedSize,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {!fetchedDocuments.loading &&
              fetchedDocuments.items?.length > 0 ? (
                <>
                  {fetchedDocuments.items.length > 0 ? (
                    fetchedDocuments.items
                      .filter((item) => !item?.title?.includes('TEMP '))
                      .map((item, index, array) => (
                        <DocumentItem
                          isLast={index === array?.length - 1}
                          fieldName={`select-${item.id}`}
                          selectAll={selectAll}
                          fetchData={fetchData}
                          onDeleteSelected={onDeleteSelected}
                          document={item}
                        />
                      ))
                  ) : (
                    <div className={styles.noDocs}>
                      {t('you_have_no_documents_yet')}
                    </div>
                  )}
                </>
              ) : !fetchedDocuments.loading &&
                fetchedDocuments.items?.length === 0 ? (
                <div
                  style={{
                    width: '100%',
                    height: fixedSize,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={styles.noDocs}
                >
                  {t('you_have_no_documents_yet')}
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: fixedSize,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 64px',
                  }}
                >
                  <PulseLoader color={'#bfbfbf'} />
                </div>
              )}
            </div>

            {fetchedDocuments.items && fetchedDocuments.items.length !== 0 && (
              <div className={styles.Pagination}>
                <Pagination
                  className={styles.PaginationElement}
                  current={pageCurrent}
                  // showSizeChanger
                  pageSize={pageSize}
                  // defaultCurrent={3}
                  total={fetchedDocuments.totalItems}
                  locale={paginationLocale}
                  onChange={onChange}
                  showLessItems={true}
                />
                {/* <div className={styles.perPage}>
              <SelectInput
                field={'Per Page:'}
                placeholder={t('Per Page')}
                options={pageSizeOptions.map((i) => ({value:i, label:i}))}
                onChange={onPerPageChange}
                noDefaultMargin
                fieldState={{value: pageSize}}
            />
            </div> */}
              </div>
            )}
          </Row>
        </Form>
      </div>
      <Filters
        isOpen={isFilterOpen}
        open={openFilter}
        close={closeFilter}
        onSubmit={onFilter}
        items={[...documents.data]}
        reset={onResetFilters}
      />
    </div>
  );
};

export default DocumentList;

export const DocumentHead = ({
  onSort,
  activeSort,
  order,
  selectAll,
  selectAllHandler,
}) => {
  return (
    <Row>
      <Col
        xs={1}
        className={cn(
          templatesStyles.documentContainer,
          templatesStyles.documentHead
        )}
      >
        <Checkbox
          onChangeHandler={selectAllHandler}
          field={'selectAll'}
          value={selectAll}
          className={templatesStyles.checkbox}
        />
      </Col>
      <Col
        xs={11}
        className={cn(
          templatesStyles.documentContainer,
          templatesStyles.documentHead
        )}
      >
        <div className={cn(styles.listItem, styles.item, styles.itemHead)}>
          <span className={cn(styles.documentTitle, styles.tableHead)}>
            <span style={{ cursor: 'pointer' }} onClick={() => onSort('title')}>
              Title
            </span>
            {activeSort === 'title' && (
              <i
                style={{
                  transform: order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
                className={cn('fas fa-long-arrow-alt-up', styles.headArrowIcon)}
              ></i>
            )}
          </span>
          <span className={cn(styles.documentDepartment, styles.tableHead)}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => onSort('dep_name')}
            >
              Department
            </span>
            {activeSort === 'dep_name' && (
              <i
                style={{
                  transform: order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
                className={cn('fas fa-long-arrow-alt-up', styles.headArrowIcon)}
              ></i>
            )}
          </span>
          <span className={cn(styles.documentAuthor, styles.tableHead)}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => onSort('author')}
            >
              Author
            </span>
            {activeSort === 'author' && (
              <i
                style={{
                  transform: order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
                className={cn('fas fa-long-arrow-alt-up', styles.headArrowIcon)}
              ></i>
            )}
          </span>
          <div className={cn(styles.documentDateCreated, styles.tableHead)}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => onSort('createdAt')}
            >
              Created Date
            </span>
            {activeSort === 'createdAt' && (
              <i
                style={{
                  transform: order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
                className={cn('fas fa-long-arrow-alt-up', styles.headArrowIcon)}
              ></i>
            )}
          </div>
          <div className={cn(styles.documentDateUpdated, styles.tableHead)}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => onSort('updatedAt')}
            >
              Updated Date
            </span>
            {activeSort === 'updatedAt' && (
              <i
                style={{
                  transform: order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
                className={cn('fas fa-long-arrow-alt-up', styles.headArrowIcon)}
              ></i>
            )}
          </div>
          <div className={cn(styles.documentStatus, styles.tableHead)}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => onSort('status')}
            >
              Status
            </span>
            {activeSort === 'status' && (
              <i
                style={{
                  transform: order === 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
                className={cn('fas fa-long-arrow-alt-up', styles.headArrowIcon)}
              ></i>
            )}
          </div>
          <div className={cn(styles.documentOptions)}></div>
        </div>
      </Col>
    </Row>
  );
};

export const DocumentItem = ({
  isLast = false,
  document,
  col = false,
  className = '',
  mini,
  onDeleteSelected,
  fetchData,
  fieldName,
  selectAll,
}) => {
  const { documents } = useContext(DataContext);
  const { openEditor, isEditorOpen } = useContext(TabContext);
  const { t } = useTranslation();
  const { markDone } = useContext(GuideContext);
  const { withRestrictions } = useRestriction();

  const [prevEditorOpen, setPrevEditorOpen] = useState(false);
  useEffect(() => {
    if (isEditorOpen === false && prevEditorOpen === true) {
      setPrevEditorOpen(true);
      fetchData();
    } else {
      setPrevEditorOpen(true);
    }
  }, [isEditorOpen]);

  function editDocument() {
    markDone('preview document');
    openEditor({
      id: document.id,
      editorTitle: document.title,
      editorContent: document.content,
      editorKeywords: document.keywords,
      editorIsPublic: document.is_public,
      documentToken: document.document_token,
      editorType: EditorType.EDIT_DOCUMENT,
      statusType: document.document_status_type,
    });
  }

  function deleteDocument() {
    onDeleteSelected(document.id);
  }

  const dotsRef = useRef(null);

  const dropDown = [
    {
      name: t('edit'),
      action: editDocument,
    },
    {
      name: t('delete'),
      action: withRestrictions(deleteDocument),
    },
  ];

  const onTabClick = ({ action = () => {} }) => action();

  const [onCheckbox, setOnCheckbox] = useState(false);

  const checkBoxHandler = () => {
    setOnCheckbox(!onCheckbox);
  };

  return (
    <ContextMenuTrigger id={document.id}>
      <Row>
        <Col
          xs={1}
          className={templatesStyles.documentContainer}
          style={isLast ? { borderColor: 'transparent' } : {}}
        >
          <Checkbox
            field={fieldName}
            className={templatesStyles.checkbox}
            onChangeHandler={checkBoxHandler}
            value={selectAll ? true : onCheckbox}
          />
        </Col>
        <Col
          xs={11}
          className={cn(templatesStyles.documentContainer, className)}
          style={isLast ? { borderColor: 'transparent' } : {}}
        >
          <TouchableOpacity
            className={cn(styles.listItem, styles.item, mini && styles.mini)}
            onClick={editDocument}
          >
            <span className={styles.documentTitle}>{document.title}</span>
            <span className={styles.documentDepartment}>
              {document.dep_name}
            </span>
            <span className={styles.documentAuthor}>{document.author}</span>
            <div className={styles.documentDateCreated}>
              {formatDate(document.created_at)}
            </div>
            <div className={styles.documentDateUpdated}>
              {formatDate(document.updated_at)}
            </div>
            <div className={styles.documentStatus}>
              <DocumentStatus statusType={document.document_status_type} />
            </div>
            <div className={styles.documentOptions}>
              <ThreeDots ref={dotsRef} />
              <DropDown dropDown={dropDown} rootRef={dotsRef} />
            </div>
          </TouchableOpacity>
          <ContextMenu id={document.id} className={templatesStyles.contextMenu}>
            {dropDown.map((item) => {
              const { name } = item;
              return (
                <MenuItem
                  key={name}
                  onClick={(e) => {
                    e.stopPropagation();
                    onTabClick(item);
                  }}
                  className={templatesStyles.contextMenuItem}
                >
                  {name}
                </MenuItem>
              );
            })}
          </ContextMenu>
        </Col>
      </Row>
    </ContextMenuTrigger>
  );
};

const Tag = ({ title }) => {
  return <div className={styles.tag}>{title}</div>;
};

const ALL_STATUS_ID = 777;
const Filters = ({ isOpen, onSubmit, close, reset }) => {
  const { t } = useTranslation();
  const [filters, setfilters] = useState({});
  const [selectedStatus, setselectedStatus] = useState();

  const onChangeDate = (e, date) => {
    const { value } = e.target;
    setfilters((prev) => ({ ...prev, [date]: value }));
  };

  const onChangeSeach = (e) => {
    const { value } = e.target;
    setfilters((prev) => ({ ...prev, search: value }));
  };

  const resetFilter = () => {
    setfilters({});
    setselectedStatus('');
    reset();
  };
  const submit = () => {
    return onSubmit(filters, selectedStatus === '777' && true);
  };

  const onStatusChange = (e) => {
    const { value } = e.target;
    setselectedStatus(value);
    if (+value === ALL_STATUS_ID) {
      const copyState = Object.entries(filters).filter((i) => {
        if (i[0] !== 'status') {
          return i;
        }
      });
      return setfilters(Object.fromEntries(copyState));
    }
    setfilters((prev) => ({ ...prev, status: value }));
  };
  const handleKeyWords = (e) => {
    const { value } = e.target;
    setfilters((prev) => ({ ...prev, keywords: value }));
  };
  const validate = (value) => !value || undefined;

  const _handleSubmit = (e) => {
    if (e.key === 'Enter') {
      submit();
    }
  };

  return (
    <div className={cn(styles.filter, isOpen && styles.filterOpen)}>
      <Row className={styles.filterHeader}>
        <Col className={styles.filterHeaderTitle}>
          <i className="fas fa-filter" aria-hidden="true"></i> {t('filter')}
        </Col>
        <div className={styles.filterClose} onClick={close}></div>
      </Row>
      <Col className={styles.filterBody}>
        <Col>
          <span>{t('filter_created_date')}</span>
          <Row className={'mt-2'}>
            <Col className="pr-lg-1" lg={6}>
              <DateInput
                onChange={(e) => {
                  onChangeDate(e, 'StartCreatedAt');
                }}
                onKeyDown={_handleSubmit}
                field={'StartCreatedAt'}
                placeholder={t('start_create_date')}
                fieldState={{
                  maskedValue: filters.StartCreatedAt,
                }}
              />
            </Col>
            <Col className="pl-lg-1" lg={6}>
              <DateInput
                onChange={(e) => {
                  onChangeDate(e, 'EndCreatedAt');
                }}
                onKeyDown={_handleSubmit}
                field={'EndCreatedAt'}
                placeholder={t('end_create_date')}
                fieldState={{
                  maskedValue: filters.EndCreatedAt,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col className={'mt-4'}>
          <span>{t('filter_updated_date')}</span>
          <Row className={'mt-2'}>
            <Col className="pr-lg-1" lg={6}>
              <DateInput
                onChange={(e) => {
                  onChangeDate(e, 'StartUpdatedAt');
                }}
                onKeyDown={_handleSubmit}
                field={'StartUpdatedAt'}
                placeholder={t('start_update_date')}
                fieldState={{
                  maskedValue: filters.StartUpdatedAt,
                }}
              />
            </Col>
            <Col className="pl-lg-1" lg={6}>
              <DateInput
                onChange={(e) => {
                  onChangeDate(e, 'EndUpdatedAt');
                }}
                onKeyDown={_handleSubmit}
                field={'EndUpdatedAt'}
                placeholder={t('end_update_date')}
                customValue="2001-12-12"
                fieldState={{
                  maskedValue: filters.EndUpdatedAt,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Row className={'mt-4'}>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{t('filter_title')}</span>
            <Col style={{ marginTop: '10px' }}>
              <Input
                onChange={onChangeSeach}
                onKeyDown={_handleSubmit}
                placeholder={t('Enter search query')}
                field={'search'}
                validate={validate}
                fieldState={{ maskedValue: filters.search || '' }}
              />
            </Col>
          </Col>
        </Row>
        <Row className={'mt-4'}>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{t('filter_keywords')}</span>
            <Col style={{ marginTop: '10px' }}>
              <Input
                onChange={handleKeyWords}
                onKeyDown={_handleSubmit}
                placeholder={t('enter_comma-separated_keywords')}
                field={'keywords'}
                validate={validate}
                fieldState={{ maskedValue: filters.keywords || '' }}
              />
            </Col>
          </Col>
        </Row>
        <Col className={'mt-4'}>
          <span>{t('filter_status')}</span>
          <Row className={'mt-2'} style={{ width: '100%' }}>
            <Col>
              <SelectInput
                style={{ width: '100%', height: '30px' }}
                field={'Status'}
                placeholder={t('Status')}
                options={[...Object.entries(documentStatusShape)].map(
                  ([key, value]) => ({ value: key, label: t(value) })
                )}
                onChange={onStatusChange}
                onKeyDown={_handleSubmit}
                noDefaultMargin
                fieldState={{ value: selectedStatus }}
              />
            </Col>
          </Row>
        </Col>
      </Col>
      <div className={styles.filterAction}>
        <Button
          title={t('reset')}
          onClick={resetFilter}
          height={30}
          color={'red'}
          fontSize={15}
          transparent
        />
        <Button
          title={t('submit')}
          height={30}
          onClick={submit}
          fontSize={15}
          transparent
        />
      </div>
    </div>
  );
};
