import { createHashRouter } from 'react-router-dom';
import App from '../components/App';
import SignIn from '../components/SignIn';
import SignUp from '../components/SignUp';
import PrivateRoute from '../components/PrivateRoute';
import PaymentPage from '../components/common/parts/PaymentForm';
import ConfirmEmail from '../components/ConfirmEmail';
import Dashboard from '../components/Dashboard';
import Suspended from '../components/Suspended';
import CompanyDashboardWrapper from '../components/Dashboard/contents/CompanyDashboard/screens';
import Company from '../components/Dashboard/contents/CompanyDashboard/screens/Company';
import Structures from '../components/Dashboard/contents/CompanyDashboard/screens/Structures';
import TasksDashboard from '../components/Dashboard/contents/CompanyDashboard/screens/Tasks';
import Flows from '../components/Dashboard/contents/CompanyDashboard/screens/Flows';
import ManageTask, {
  ManageSubtask,
} from '../components/Dashboard/contents/CompanyDashboard/screens/Tasks/ManageTask';
import EditUserInfo from '../components/Dashboard/UserSettings';
import Documents from '../components/Dashboard/contents/Documents';
import Templates from '../components/Dashboard/contents/Templates';
import Products from '../components/Dashboard/contents/Products';
import Services from '../components/Dashboard/contents/Services';
import Customers from '../components/Dashboard/contents/Customers';
import Partners from '../components/Dashboard/contents/Partners';
import HowTo from '../components/HowTo';
import CompanyDashboard from '../components/Dashboard/contents/CompanyDashboard';
import ViewTask from '../components/Dashboard/contents/CompanyDashboard/screens/Tasks/ViewTask';

const router = createHashRouter([
  {
    path: '*',
    element: <App />,
    children: [
      {
        path: '',
        element: <PrivateRoute />,
      },
      {
        path: 'login',
        element: <SignIn />,
      },
      {
        path: 'register',
        element: <SignUp />,
      },
      {
        path: 'confirm-email',
        element: (
          <PrivateRoute>
            <ConfirmEmail />
          </PrivateRoute>
        ),
      },
      {
        path: 'payment',
        element: (
          <PrivateRoute>
            <PaymentPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'suspended',
        element: (
          <PrivateRoute>
            <Suspended />
          </PrivateRoute>
        ),
      },
      {
        path: 'dashboard',
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => ({
            path: '/dashboard/company-dashboard',
            label: 'Dashboard',
          }),
        },
        children: [
          {
            path: 'settings',
            element: <EditUserInfo />,
            handle: {
              crumb: () => ({
                path: '/dashboard/settings',
                label: 'Settings',
              }),
            },
          },
          {
            path: 'documents',
            element: <Documents />,
            handle: {
              crumb: () => ({
                path: '/dashboard/documents',
                label: 'Documents',
              }),
            },
          },
          {
            path: 'templates',
            element: <Templates />,
            handle: {
              crumb: () => ({
                path: '/dashboard/templates',
                label: 'Templates',
              }),
            },
          },
          {
            path: 'products',
            element: <Products />,
            handle: {
              crumb: () => ({
                path: '/dashboard/products',
                label: 'Products',
              }),
            },
          },
          {
            path: 'services',
            element: <Services />,
            handle: {
              crumb: () => ({
                path: '/dashboard/services',
                label: 'Services',
              }),
            },
          },
          {
            path: 'customers',
            element: <Customers />,
            handle: {
              crumb: () => ({
                path: '/dashboard/customers',
                label: 'Customers',
              }),
            },
          },
          {
            path: 'partners',
            element: <Partners />,
            handle: {
              crumb: () => ({
                path: '/dashboard/partners',
                label: 'Partners',
              }),
            },
          },
          {
            path: 'how-to',
            element: <HowTo />,
            handle: {
              crumb: () => ({
                path: '/dashboard/how-to',
                label: 'How to',
              }),
            },
          },
          {
            path: 'company-dashboard',
            element: <CompanyDashboard />,
            children: [
              {
                path: '',
                element: <Company />,
              },
              {
                path: 'structures',
                element: <Structures />,
                handle: {
                  crumb: () => ({
                    path: '/dashboard/company-dashboard/structures',
                    label: 'Structures',
                  }),
                },
              },
              {
                path: 'flows',
                element: <Flows />,
                handle: {
                  crumb: () => ({
                    path: '/dashboard/company-dashboard/flows',
                    label: 'Flows',
                  }),
                },
              },
              {
                path: 'tasks',
                handle: {
                  crumb: () => ({
                    path: '/dashboard/company-dashboard/tasks',
                    label: 'Tasks',
                  }),
                },
                children: [
                  {
                    path: '',
                    element: <TasksDashboard />,
                  },
                  {
                    path: 'create',
                    handle: {
                      crumb: () => ({
                        path: '/dashboard/company-dashboard/tasks/create',
                        label: 'Create Task',
                      }),
                    },
                    children: [
                      {
                        path: '',
                        element: <ManageTask />,
                      },
                    ],
                  },
                  {
                    path: 'view',
                    children: [
                      {
                        path: 'task/:id',
                        handle: {
                          crumb: (props) => ({
                            path: `/dashboard/company-dashboard/tasks/view/task/${props.id}`,
                            label: `View Task${
                              props.id ? ` #${props.id}` : ''
                            }`,
                          }),
                        },
                        children: [
                          {
                            path: '',
                            element: <ViewTask />,
                          },
                          {
                            path: 'create',
                            element: <ManageSubtask />,
                            handle: {
                              crumb: (props) => ({
                                path: `/dashboard/company-dashboard/tasks/view/task/${props.id}/create`,
                                label: `Create Subtask`,
                              }),
                            },
                          },
                        ],
                      },
                      {
                        path: 'subtask/:id',
                        handle: {
                          crumb: (props) => ({
                            path: `/dashboard/company-dashboard/tasks/view/subtask/${props.id}`,
                            label: `View Subtask${
                              props.id ? ` #${props.id}` : ''
                            }`,
                          }),
                        },
                        children: [
                          {
                            path: '',
                            element: <ViewTask />,
                          },
                          {
                            path: 'create',
                            element: <ManageSubtask />,
                            handle: {
                              crumb: (props) => ({
                                path: `/dashboard/company-dashboard/tasks/view/subtask/${props.id}/create`,
                                label: `Create Subtask`,
                              }),
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'edit',
                    children: [
                      {
                        path: 'task/:id',
                        element: <ManageTask editMode />,
                        handle: {
                          crumb: (props) => ({
                            path: `/dashboard/company-dashboard/tasks/edit/task/${props.id}`,
                            label: `Edit Task${
                              props.id ? ` #${props.id}` : ''
                            }`,
                          }),
                        },
                      },
                      {
                        path: 'subtask/:id',
                        element: <ManageSubtask editMode />,
                        handle: {
                          crumb: (props) => ({
                            path: `/dashboard/company-dashboard/tasks/edit/subtask/${props.id}`,
                            label: `Edit Subtask${
                              props.id ? ` #${props.id}` : ''
                            }`,
                          }),
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
