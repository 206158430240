import React from 'react';
import { Radio } from 'informed';
import cn from 'classnames';
import styles from './style.module.css';

const RadioboxWithLabel = ({
  className,
  textarea = false,
  required,
  label,
  ...rest
}) => {
  return (
    <label className={cn(styles.wrapper, className)}>
      <Radio className={styles.checkbox} required={required} {...rest} />
      <span className={cn(styles.checkmark)}></span>
      <span className={cn(styles.label)}>{label}</span>
    </label>
  );
};

export default RadioboxWithLabel;
