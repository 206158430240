import { POST } from "../network";
import { customerEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
  try {
    return await POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const sendFile = async (path, file, fieldName) => {
    const formData = new FormData();
    formData.append(fieldName ? fieldName : "file", file);
    const res = await POST(path, formData, true);
};
  
const sendCsvFile = async (path, file, separator) => {
    path += `?Delimeter=${separator}`;
    const res = sendFile(path, file, "csvFile");
};

export const createCustomerB2P = async data => {
    await setData(customerEndpoints.personCreate, data);
};

export const updateCustomerB2P = async data => {
    await setData(customerEndpoints.personUpdate, data);
};

export const removeCustomerB2P = async id => {
    await setData(`${customerEndpoints.personRemove}${id}`);
};
  
export const createCustomerB2B = async data => {

    await setData(customerEndpoints.businessCreate, data);
};

export const updateCustomerB2B = async data => {
    await setData(customerEndpoints.businessUpdate, data);
};

export const removeCustomerB2B = async id => {
    await setData(`${customerEndpoints.businessRemove}${id}`);
};
  
export const importCustomersB2P = async file => {
    return await sendFile(customerEndpoints.personImportCsv, file, "csvFile");
};

export const importCustomersB2B = async (file, separator) => {
    return await sendCsvFile(customerEndpoints.businessImportCsv, file, separator);
};