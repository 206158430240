import { GET } from '../network';
import { filtersToQuery } from '../../utils/filtersToQuery';
import { templateEndpoints } from '../requestEndpoints';

const getData = async (path, Type) => {
  try {
    const res = await GET(path);
    return res.body.data || JSON.parse(res.data);
  } catch (e) {
    if (Type) return new Type();
    return null;
  }
};

const getListData = async (path) => {
  const data = await getData(path);
  if (data) {
    return data.items;
  }
  return [];
};

const getLatestItem = async (path) => {
  const data = await getData(path + `?PageSize=1&SortOrder=1`);
  if (data) {
    return data.items;
  }
  return [];
};

const getPagedListData = async (path) => {
  const data = await getData(path);
  if (data) {
    return {
      items: data.items,
      page: data.currentPage || 1,
      pages: data.totalPages || 1,
    };
  }
  return { items: [], page: 1, pages: 1 };
};

const getByKeywords = async (path, keywords) => {
  const searchParams = new URLSearchParams();
  keywords.forEach((keyword) => {
    searchParams.append('keywords', keyword);
  });
  return (await getData(`${path}?${searchParams.toString()}`)) || [];
};

export const getVariableFieldsData = async () => {
  return getListData(templateEndpoints.variableFields);
};

export const getTemplatesData = async () => {
  const publicTemplates = await getListData(
    `${templateEndpoints.getList}?IsPublic=${true}`
  );
  const userTemplates = await getListData(templateEndpoints.getList);
  return (publicTemplates || []).concat(userTemplates);
};

export const getTemplateLatestCreated = async () => {
  return getLatestItem(templateEndpoints.getList);
};

export const getPageTemplatesDataPrivate = async (filters = {}) => {
  const userTemplates = await getPagedListData(
    templateEndpoints.getList + filtersToQuery(filters)
  );
  return userTemplates;
};

export const getPageTemplatesDataPublic = async (filters = {}) => {
  const publicTemplates = await getPagedListData(
    templateEndpoints.getList + filtersToQuery({ ...filters, isPublic: true })
  );
  return publicTemplates;
};

export const getTemplateById = async (id) => {
  return getData(`${templateEndpoints.getById}${id}`);
};

export const getTemplatesByKeywords = async (keywords) => {
  return getByKeywords(templateEndpoints.get, keywords);
};
