import React from 'react';
import Select from 'react-select';

import styles from './styles.module.css';

import { useTranslation } from '../../../../contexts/LocaleContext';

const LocaleSelectorFixed = () => {
  const { locales, setLocale, currentLocale } = useTranslation();
  const options =
    (locales?.length ? locales : []).map(([value, label]) => ({
      value,
      label,
    })) || [];
  const defaultOption = (locales?.length ? locales : [])
    .filter(([value, _]) => value == currentLocale)
    ?.map(([val, lab]) => ({ value: val, label: lab }))?.[0];

  const onLocaleChange = (p) => {
    setLocale(p.value);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid #ececec',
      color: state.isSelected ? 'white' : '#B11873',
      backgroundColor: !state.isSelected ? 'white' : '#B11873',
      padding: 8,
    }),
    control: () => ({
      backgroundColor: 'white',
      borderRadius: 8,
      border: 'solid 2px #B11873',
      width: 140,
      display: 'flex',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const fontWeight = '700';
      return { ...provided, opacity, transition, fontWeight };
    },
  };

  return (
    <div className={styles.selectWrapper}>
      <Select
        styles={customStyles}
        onChange={onLocaleChange}
        defaultValue={defaultOption}
        isClearable={false}
        isSearchable={false}
        options={options}
      />
    </div>
  );
};

export const LocaleSelectorSmall = ({}) => {
  const { locales, setLocale, currentLocale, t } = useTranslation();

  const options =
    (locales?.length ? locales : []).map(([value, label]) => {
      const slicedLabel = label.split('').slice(0, 2).join('').toUpperCase();
      if (slicedLabel === 'GE') {
        return { value, label: 'DE' };
      }

      return { value, label: slicedLabel };
    }) || [];

  const defaultOption =
    (locales?.length ? locales : [])
      .filter(([value, _]) => value == currentLocale)
      ?.map(([val, lab]) => {
        const slicedLab = lab.split('').slice(0, 2).join('').toUpperCase();

        return { value: val, label: slicedLab };
      })?.[0] || 'EN';

  const onLocaleChange = (p) => {
    setLocale(p.value);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      width: 'calc(100% - 8px)',
      margin: '0 4px',
      borderRadius: 4,
      borderBottom: '1px solid #ececec',
      color: state.isSelected ? 'white' : '#B11873',
      backgroundColor: !state.isSelected ? 'white' : '#B11873',
      cursor: 'pointer',
      padding: 8,
    }),
    control: () => ({
      display: 'flex',
      width: 84,
      height: 36,
      fontSize: '15px',
      backgroundColor: 'white',
      cursor: 'pointer',
      // borderRadius: 8,
      // border: "solid 2px #B11873",
      border: 'solid 1px #E7E7E8',
      borderRadius: 4,
      boxShadow: 'none',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const fontWeight = '700';
      return { ...provided, opacity, transition, fontWeight };
    },
  };

  return (
    <Select
      styles={customStyles}
      menuPlacement={'auto'}
      onChange={onLocaleChange}
      defaultValue={defaultOption}
      isClearable={false}
      isSearchable={false}
      options={options}
    />
  );
};

export default LocaleSelectorFixed;
