import React, {useState} from "react";
import EditableTable from "../../../common/parts/EditableTable";
import ElectByIndex from "../../../common/core/ElectByIndex";
import DashboardTabs from "../../../common/parts/DashboardTabs";
import CompetitorForm from "./CompetitorForm";
import styles from "../Customers/style.module.css";
import regularTableControllers from "../../../common/parts/regularTableControllers";
import Enum from "../../../../utils/enum";
import AddEditTableModal from "../../../common/parts/AddEditTableModal";
import "react-table/react-table.css";
import { useLoaded } from "../../../../contexts/DataContext";
import FetchLoading from "../../../common/parts/FetchLoading";
import { useCvsIO } from "../../../common/parts/ImportCsvModal";
import {getPagedCompetitorsData} from "../../../../api/Competitor/dataGetters";
import {useTranslation} from "../../../../contexts/LocaleContext";
import { useRestriction } from "../../../../contexts/Restriction/RestrictionContext"
const columns = [
  {
    Header: "CODE#",
    accessor: "id",
    width: 65,
    index: 0
  },
  {
    Header: "NAME",
    accessor: "name",
    filter: true,
    width: 100,
    index: 1
  },
  {
    Header: "ADDRESS",
    accessor: "address",
    filter: true,
    width: 120,
    index: 2
  },
  {
    Header: "EMAIL",
    accessor: "email",
    filter: true,
    width: 150,
    index: 3
  },
  {
    Header: "PHONE",
    accessor: "phone",
    filter: true,
    width: 100,
    index: 4
  },
  {
    Header: "WHAT DOES THEIR COMPANY DO",
    accessor: "scope",
    filter: true,
    minWidth: 140,
    index: 5
  },
  {
    Header: "WHO ARE THEIR CUSTOMERS",
    accessor: "customers",
    filter: true,
    minWidth: 130,
    index: 7
  },
  {
    Header: "BUSINESS FACEBOOK PAGE LINK",
    accessor: "pagelink",
    filter: true,
    minWidth: 160,
    index: 8
  },
  {
    Header: "KEYWORDS",
    accessor: "keywords",
    filter: true,
    width: 100,
    index: 9
  }
];

const [COMPETITOR_MODAL] = Enum(1);

function Competitors() {
  const { isLoaded, competitors } = useLoaded("competitors");
  const {t} = useTranslation()

  const [modalIndex, setModalIndex] = useState(null);
  const closeModal = () => setModalIndex(null);
  const { withRestrictions } =useRestriction();

  const [editingValues, setEditingValues] = useState({});
  const openEditModal = product => {
    setEditingValues(product);
    setModalIndex(COMPETITOR_MODAL);
  };

  const table = isLoaded ? (
    <EditableTable
      getter={getPagedCompetitorsData}
      data={competitors.data}
      deleteItem={withRestrictions(competitors.remove)}
      editItem={openEditModal}
      columns={columns.map((item)=>({...item, Header: t(item.Header.toLowerCase().replaceAll(' ','_')).toUpperCase()}))}
      headerClassName={styles.tableHeader}
      noWhatText={"competitors"}
    />
  ) : (
    <FetchLoading />
  );

  const { importCsvModal, importCSV, exportCSV, exportWaiting } = useCvsIO(
    competitors,
    "Name,Address,Email,Phone,What Does Their Company?,Who Are Their Customers,Business Facebook Page Link,Keywords"
  );

  const TABS = [
    {
      name: "COMPETITORS",
      content: table,
      controllers: regularTableControllers({
        addNew: withRestrictions(() => setModalIndex(COMPETITOR_MODAL)),
        importCSV: withRestrictions(importCSV),
        exportCSV,
        exportWaiting,
        t
      })
    }
  ];

  const MODALS = [
    <AddEditTableModal
      editingValues={editingValues}
      CustomForm={CompetitorForm}
      close={closeModal}
      list={competitors}
      name={t('competitor')}
    />
  ];

  const content = TABS.map(item => item.content);

  const main = (
    <DashboardTabs tabs={TABS} readyContents={content} startTabIndex={0} />
  );

  return (
    <>
      <ElectByIndex index={modalIndex} defaultComponent={main}>
        {MODALS}
      </ElectByIndex>
      {importCsvModal}
    </>
  );
}

export default Competitors;
