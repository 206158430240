import { POST } from "../network";
import { templateEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
  try {
    return await POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

export const createTemplate = async data => {
    function makeKeywords(arr) {
      return arr
        .map(item => item.toLowerCase())
        .map(item => item.trim())
        .filter(item => item !== "");
    }
    const keywords = makeKeywords(data.keywords.split(","));
    const titleKeywords = makeKeywords(data.title.split(" "));
    data.keywords = keywords.concat(titleKeywords).join(", ");
    await setData(templateEndpoints.create, data);
};

export const updateTemplate = async data => {
    await setData(templateEndpoints.update, data);
};

export const removeTemplate = async id => {
    await setData(`${templateEndpoints.remove}${id}`);
};