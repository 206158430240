import React, {useContext} from "react";
import EditableTable from '../../../common/parts/EditableTable';
import "react-table/react-table.css";
import {useLoaded} from "../../../../contexts/DataContext";
import FetchLoading from "../../../common/parts/FetchLoading";
import {getPagedServicesData} from "../../../../api/Service/dataGetters";
import {useTranslation} from "../../../../contexts/LocaleContext";

const columns = [{
  Header: 'CODE#',
  accessor: 'id',
  width: 65,
},{
  Header: 'NAME',
  accessor: 'name',
  filter: true,
  width: 120,
},{
  Header: 'PRICE',
  accessor: 'price',
  filter: true,
  width: 80,
},{
  Header: 'U.M.',
  accessor: 'unitmeasure',
  filter: true,
  width: 80,
},{
  Header: 'GENERAL DESCRIPTION',
  accessor: 'generaldescription',
  filter: true,
  minWidth: 250,
},{
  Header: 'DELIVERY',
  accessor: 'supply',
  filter: true,
  width: 85,
},{
  Header: 'KEYWORDS',
  accessor: 'keywords',
  filter: true,
  width: 100,
}];

function ServicesTab({openEditModal}) {
  const {isLoaded, services} = useLoaded('services');
  const {t} = useTranslation()
  return isLoaded ? (
    <EditableTable
      getter={getPagedServicesData}
      data={services.data}
      deleteItem={services.remove}
      editItem={openEditModal}
      columns={columns.map((item)=>({...item, Header: t(item.Header.toLowerCase().replaceAll(' ','_')).toUpperCase()}))}
      noWhatText={'services'}
    />
    ) : (
      <FetchLoading/>
    );
}


export default ServicesTab;
