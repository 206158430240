import { PUT, DELETE, POST } from '../network';
import { taskEndpoints } from '../requestEndpoints';

const setData = async (path, data) => {
  try {
    return POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const updateData = async (path, data) => {
  try {
    return PUT(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const deleteData = async (path, data) => {
  try {
    return DELETE(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const sendFile = async (path, file, fieldName) => {
  const formData = new FormData();
  formData.append(fieldName ? fieldName : 'file', file);
  const res = await POST(path, formData, true);
};

export const createTask = async (data) => {
  return setData(taskEndpoints.create, data);
};

export const updateTask = async (data) => {
  return updateData(taskEndpoints.update, data);
};

export const removeTask = async (id) => {
  return deleteData(`${taskEndpoints.remove}${id}`);
};

export const importTasks = async (file) => {
  return sendFile(taskEndpoints.importCsv, file, 'csvFile');
};
