import {GET} from "../network";
import { companyAccountEndpoints } from "../requestEndpoints";
import { filtersToQuery } from "../../utils/filtersToQuery";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data); 
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

export const getCompanyInfo = async () => {
    const company = await getData(companyAccountEndpoints.getInfo);
    const role = await getData(companyAccountEndpoints.getMyRole);
  
    if (company) {
      Object.assign(company, { role });
      return company;
    }
  
    return null;
}

export const findCorporateUser = async (email) => {
    return await getData(`${companyAccountEndpoints.findUser}?email=` + email)
}

export const getFlows = async () => {
    return await getData(companyAccountEndpoints.getFlows)
}

export const getMyRole = async () => {
    return await getData(companyAccountEndpoints.getMyRole)
}

export const getUserActivity = async (userData) => {
    return await getData(companyAccountEndpoints.userActivity + filtersToQuery(userData))
}