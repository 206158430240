import React, { useContext, useEffect, useState } from 'react';
import { useAssistantScenarios } from './utils/useAssistantDialog';
import DataContext from '../../../contexts/DataContext';
import styles from './styles.module.css';
import Dialog from './Dialog';
import Options from './Options';
import InputArea from './InputArea';
import Bar from './Bar';
import LivePreview from './LivePreview';
import SearchDocuments from './Search/SearchDocuments';
import SearchCustomers from './Search/SearchCustomers';
import SearchPartners from './Search/SearchPartners';
import SearchServices from './Search/SearchServices';
import SearchTemplates from './Search/SearchTemplates';
import SearchProducts from './Search/SearchProducts';

const Assistant = ({
  scenario,
  template,
  initialQuery,
  onClose = () => {},
}) => {
  const [value, setValue] = useState('');
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [isRecognizingAudio, setIsRecognizingAudio] = useState(false);

  const {
    templates,
    customersB2P,
    customersB2B,
    partners,
    products,
    services,
  } = useContext(DataContext);
  useEffect(() => {
    !templates.isLoaded && templates.load();
    !customersB2P.isLoaded && customersB2P.load();
    !customersB2B.isLoaded && customersB2B.load();
    !partners.isLoaded && partners.load();
    !products.isLoaded && products.load();
    !services.isLoaded && services.load();
  }, []);

  const [isSearchOpened, setSearchOpened] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchKeywords, setSearchKeywords] = useState([]);

  const {
    messages,
    options,
    isThinking,
    send,
    recorder,
    getPreview,
    step,
    isReady,
    context,
    isResultsButtonShown = false,
    isSearch = false,
    supportMessageSent = false,
  } = useAssistantScenarios({
    initialScenario: scenario || 'template',
    initialTemplate: template || null,
    initialSearchQuery: initialQuery || '',
    searchOpener: (query, type = 'documents', keywords = []) => {
      if (keywords) setSearchKeywords(keywords);
      if (query) setSearchQuery(query);
      setSearchOpened(type);
    },
  });

  const isPreviewAvailable = step >= 0;

  const onRecordEnd = (text) => {
    if (text) {
      if (options.length > 1 || isSearch) {
        send(text);
        setValue('');
      } else setValue(text);
    }
    setIsRecognizingAudio(false);
  };

  const toggleRecord = () => {
    if (recorder.isRecording) {
      recorder.stop();
      setIsRecognizingAudio(true);
    } else {
      recorder.start(30, onRecordEnd, context);
      // 30 seconds is long enough time to finish the longest possible provision
    }
  };

  return (
    <div className={styles.wrapper}>
      {isSearchOpened && isSearchOpened === 'documents' && (
        <SearchDocuments
          query={searchQuery}
          onClose={() => setSearchOpened(false)}
        />
      )}
      {isSearchOpened && isSearchOpened === 'templates' && (
        <SearchTemplates
          keywords={searchKeywords}
          onClose={() => setSearchOpened(false)}
        />
      )}
      {isSearchOpened && isSearchOpened === 'customers' && (
        <SearchCustomers
          keywords={searchKeywords}
          onClose={() => setSearchOpened(false)}
        />
      )}
      {isSearchOpened && isSearchOpened === 'partners' && (
        <SearchPartners
          keywords={searchKeywords}
          onClose={() => setSearchOpened(false)}
        />
      )}
      {isSearchOpened && isSearchOpened === 'services' && (
        <SearchServices
          keywords={searchKeywords}
          onClose={() => setSearchOpened(false)}
        />
      )}
      {isSearchOpened && isSearchOpened === 'products' && (
        <SearchProducts
          keywords={searchKeywords}
          onClose={() => setSearchOpened(false)}
        />
      )}
      {isPreviewVisible && (
        <LivePreview
          getPreview={getPreview}
          step={step}
          onClose={() => setPreviewVisible(false)}
          isVisible={isPreviewVisible}
        />
      )}
      <Bar close={onClose} title={'Virtual Assistant'} withAvatar={true} />
      <Dialog
        messages={messages}
        isThinking={isThinking}
        isPreviewAvailable={isPreviewAvailable}
        isRecognizingAudio={isRecognizingAudio}
        isResultsAvailable={isResultsButtonShown}
        isReady={isReady}
        supportMessageSent={supportMessageSent}
        close={onClose}
        onPreview={() => setPreviewVisible(true)}
      />
      <Options options={options} />
      <InputArea
        send={send}
        isDisabled={isThinking || isRecognizingAudio}
        isRecording={recorder.isRecording}
        toggleRecord={toggleRecord}
        setValue={setValue}
        value={value}
      />
    </div>
  );
};

export default Assistant;
