import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '../../index';
import Enum from '../../../../../../utils/enum';
import DataContext, { useLoaded } from '../../../../../../contexts/DataContext';
import { useTranslation } from '../../../../../../contexts/LocaleContext';
import { useRestriction } from '../../../../../../contexts/Restriction/RestrictionContext';
import GuideContext from '../../../../../Onboarding';
import EditableTable from '../../../../../common/parts/EditableTable';
import {
  fakeTask,
  getPagedPartnersData,
} from '../../../../../../api/Partner/dataGetters';
import FetchLoading from '../../../../../common/parts/FetchLoading';
import { useCvsIO } from '../../../../../common/parts/ImportCsvModal';
import regularTableControllers from '../../../../../common/parts/regularTableControllers';
import AddEditTableModal from '../../../../../common/parts/AddEditTableModal';
import PartnerForm from '../../../Partners/PartnerForm';
import DashboardTabs from '../../../../../common/parts/DashboardTabs';
import UnderConstruction from '../../../../../common/parts/UnderConstruction';
import ElectByIndex from '../../../../../common/core/ElectByIndex';
import Calendar from './Calendar';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import styles from './style.module.css';
import ScreenHeader from '../../../../../common/parts/ScreenHeader';
import GlobalTask from './GlobalTask';
import CorporateContext, { useCorporateContext } from '../../CorporateContext';
import Loader from '../../../../../common/core/Loader';
import Pagination from '../../../../../common/core/Pagination';

const Tasks = () => {
  const { withRestrictions } = useRestriction();
  const navigate = useNavigate();
  const { tasks } = useContext(DataContext);
  const { departments } = useCorporateContext(CorporateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const { items, pages } = useMemo(() => {
    const pageSize = 10;
    return {
      items: tasks.data?.items || [],
      pages: {
        size: pageSize,
        current: tasks.data?.page || 1,
        items: (tasks.data?.pages || 0) * pageSize,
      },
    };
  }, [tasks.data]);

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    tasks
      ._getList({
        pageSize: pages.size,
        Page: currentPage,
        // SortBy: sortBy || sort.by,
        SortOrder: 1,
        ...(searchQuery ? { title: searchQuery } : {}),
      })
      .then(() => setIsLoading(false));
  }, [currentPage, searchQuery]);

  useEffect(() => {
    if (departments.isLoaded) return;
    departments.load();
  }, [departments.isLoaded]);

  const onPageChange = (page) => setCurrentPage(page);
  const onSearch = (query) => setSearchQuery(query);

  return (
    <div className={cn(styles.wrapper)}>
      <ScreenHeader
        className={cn(styles.header)}
        onCreate={withRestrictions(() =>
          navigate('/dashboard/company-dashboard/tasks/create')
        )}
        onSearch={onSearch}
      />

      <div className={cn(styles.line)} />

      {isLoading ? (
        <div className={cn(styles.loader)}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={cn(styles.tasks)}>
            {items.map((task) => (
              <GlobalTask
                key={task.id}
                className={cn(styles.task)}
                task={task}
              />
            ))}
          </div>

          {pages.items > pages.size && (
            <Pagination
              className={cn(styles.pagination)}
              current={currentPage}
              total={pages.items}
              pageSize={pages.size}
              onChange={onPageChange}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Tasks;
