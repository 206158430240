import React from "react";
import {PulseLoader} from "react-spinners/dist";

const FetchLoading = (props) => {
  return (
    <div 
      style={{
        display: "flex",
        marginTop: "50px",
        justifyContent: "center",
        paddingLeft: '1rem',
      }}
    >
      <PulseLoader
        color={'#554590'}
        size={8}
      />
    </div>
  );
};

export default FetchLoading;
