import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import { useTranslation } from '../../../../../../../contexts/LocaleContext';
import Button from '../../../../../../common/core/Button';
import Input from '../../../../../../SignIn/Input';
import InputPlaceholder from '../../../../../../SignIn/InputPlaceholder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCircle,
  faClose,
  faSpinner,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Form } from 'informed';
import moment from 'moment';
import Modal from '../Modal';
import DataContext from '../../../../../../../contexts/DataContext';

const DeleteTaskModal = React.forwardRef(
  ({ className, task, onDeleted = () => {}, children }, ref) => {
    const { t } = useTranslation();
    const { tasks } = useContext(DataContext);
    const [isDisabled, setIsDisabled] = useState(false);

    const onClick = async () => {
      setIsDisabled(true);
      await tasks.remove(task.id);
      setIsDisabled(false);
      ref.current.close({ immediately: true });
      onDeleted(task);
    };

    return (
      <Modal
        ref={ref}
        className={className}
        disabled={isDisabled}
        title={t('Remove task')}
        buttons={[
          {
            color: '#D92D15',
            title: (
              <>
                {t('Delete')}{' '}
                {isDisabled ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    widht={16}
                    pulse
                    style={{ marginLeft: 5 }}
                  />
                ) : (
                  <FontAwesomeIcon icon={faTrash} width={16} />
                )}
              </>
            ),
            disabled: isDisabled,
            onClick: onClick,
          },
        ]}
      >
        <p className={styles.description}>
          {t(
            'Are you sure you want to delete this task? This will delete this task permanently.'
          )}
        </p>
        {children}
      </Modal>
    );
  }
);

export default DeleteTaskModal;
