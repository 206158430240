import React, { useCallback, useContext, useState } from "react";
import styles from './style.module.css';
import Wrapper from "../../core/Wrapper";
import useEscape from "../../../Dashboard/Assistant/useEscape";
import { useDropzone } from "react-dropzone";
import Image from "react-bootstrap/Image";
import cn from 'classnames';
import Button from "../../core/Button";
import useWaiting from "../../hooks/useWaiting";
import { useTranslation } from "../../../../contexts/LocaleContext";
import Compressor from 'compressorjs'
import { AVATAR_COMPRESSION } from "../../../../config";
import TouchableOpacity from "../../core/TouchableOpacity";
import { useNetwork } from "../../../../contexts/NetworkContext";
import TabContext, { TAB_PATHS } from "../../../../contexts/TabContext";

export function useUploadAvatarModal(onSave = async () => { }) {
  const [isUploadAvatarModalOpen, setIsUploadAvatarModalOpen] = useState(false);
  const openAvatarModal = () => {
    setIsUploadAvatarModalOpen(true);
  };
  const avatarModal = isUploadAvatarModalOpen && (
    <UploadAvatarModal
      onClose={() => setIsUploadAvatarModalOpen(false)}
      onSave={onSave}
    />
  );

  return { openAvatarModal, avatarModal };
}

const UploadAvatarModal = ({ onClose, onSave = async () => { } }) => {
  const { t } = useTranslation()
  const {onLogout} = useNetwork();
  const containerRef = useEscape(styles.container, onClose);

  const [previewSrc, setPreviewSrc] = useState(null);
  const [file, setFile] = useState(null);

  const [waiting, uploadAvatar] = useWaiting(onSave);

  const onSaveClick = async () => {
    if (!file) return;
    new Compressor(file, {
      maxHeight: AVATAR_COMPRESSION.MAX_DIMS,
      maxWidth: AVATAR_COMPRESSION.MAX_DIMS,
      quality: AVATAR_COMPRESSION.QUALITY,
      success: async (compressedFile) => {
        await uploadAvatar(compressedFile)
        onClose()
      }
    })
  };


  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    if (file) {
      setFile(file);
      preview(file);
    } else {
      // error
    }
  }, []);

  const preview = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/*',
    //...
  });

  const { navigate } = useContext(TabContext);


  const onSettingsIconClick = () => {
    navigate(TAB_PATHS.SETTINGS);
  };

  return (
    <Wrapper
      className={styles.container}
      ref={containerRef}
    >
      <div className={styles.box}>

        <div className={styles.header}>
          {t('upload_avatar')}
        </div>

        <div className={styles.main}>

          <Preview
            imageSrc={previewSrc}
          />

          <div className={styles.rightSideContainer}>

            <div {...getRootProps({
              className: cn(
                styles.uploadBox,
                isDragAccept && styles.uploadBoxAccept,
                isDragReject && styles.uploadBoxReject,
              )
            })}>

              <input {...getInputProps({ className: styles.input })} required />
              <span>
                {
                  isDragReject ? t('you_can_upload_only_images') :
                    t("drag_profile_picture")
                }
              </span>
            </div>
            <div className={styles.buttonsContainer}>

              <Button
                waiting={waiting}
                title={t('save')}
                type={'submit'}
                width={120}
                className={styles.leftButton}
                onClick={onSaveClick}
              />


              <Button title={t('cancel')} type={'button'} color={'#C9CBCA'} className={styles.rightButton} width={120} onClick={onClose} />
            </div>
          </div>
        </div>

      </div>

    </Wrapper>
  );
};

const Preview = ({ imageSrc }) => {
  return (
    <div
      className={styles.avatarContainer}
      style={{
        border: imageSrc ? '1px solid rgba(204, 204, 204, 0.1)' : 'none',
      }}
    >
      {imageSrc ? (
        <Image
          fluid
          className={styles.avatarImage}
          src={imageSrc}
        />
      ) : (
        <AvatarPlaceholder />
      )}
    </div>
  );
};

export const AvatarPlaceholder = ({ fontSize = 75, className, ...props }) => (
  <div className={cn(styles.avatarPlaceholder, className)} {...props}>
    <i
      style={{ fontSize }}
      className={cn("fas fa-camera", styles.avatarPlaceholderIcon)}
    />
  </div>
);

export default UploadAvatarModal;
