import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DashboardTabs from '../../../common/parts/DashboardTabs';
import ElectByIndex from '../../../common/core/ElectByIndex';
import Wrapper from '../../../common/core/Wrapper';
import CustomersB2B from './CustomersB2B';
import CustomersB2P from './CustomersB2P';
import ModalB2B from './ModalB2B';
import ModalB2P from './ModalB2P';
import Enum from '../../../../utils/enum';
import Activities from './Activities';
import { isEmpty } from '../../../../utils/StringUtils';
import DataContext from '../../../../contexts/DataContext';
import regularTableControllers from '../../../common/parts/regularTableControllers';
import { useCvsIO } from '../../../common/parts/ImportCsvModal';
import { useTranslation } from '../../../../contexts/LocaleContext';
import GuideContext from '../../../Onboarding';
import { useRestriction } from '../../../../contexts/Restriction/RestrictionContext';

const [ADD_NEW_B2B, ADD_NEW_B2P, ACTIVITY] = Enum(3);

const Customers = () => {
  const [modalIndex, setModalIndex] = useState(null);
  const { customersB2B, customersB2P } = useContext(DataContext);
  const { t } = useTranslation();
  const { setPage, markDone } = useContext(GuideContext);
  const { withRestrictions } = useRestriction();
  const params = useParams();

  useEffect(() => {
    setPage('customers');
  }, []);

  useEffect(() => {
    if (!customersB2P.isLoaded) return;
    if (!params.type || !params.id) return;

    const id = params.id;
    const type = params.type.toUpperCase();
    const getCustomer =
      type === 'B2B' ? customersB2B.getItemById : customersB2P.getItemById;
    const editCustomer = type === 'B2B' ? openB2BEditModal : openB2PEditModal;

    getCustomer(id).then((customer) => {
      if (customer?.id) editCustomer(customer);
    });
  }, [params, customersB2P.isLoaded]);

  const submitCreateB2B = (formState) => {
    withRestrictions(async (props) => {
      markDone('add customer');
      await customersB2B.create(props);
    })({
      ...formState,
    });
    closeModal();
  };

  const submitCreateB2P = (formState) => {
    withRestrictions(async (props) => {
      markDone('add customer');
      await customersB2P.create(props);
    })({
      ...formState,
    });
    closeModal();
  };

  const submitEditB2B = (formState) => {
    withRestrictions(async (props) => {
      await customersB2B.update(props);
    })({
      ...formState,
      id: editingB2BValues.id,
    });
    closeModal();
  };

  const submitEditB2P = (formState) => {
    withRestrictions(async (props) => {
      await customersB2P.update(props);
    })({
      ...formState,
      id: editingB2PValues.id,
    });
    closeModal();
  };

  const [activityModal, setActivityModal] = useState(null);
  useEffect(() => {
    if (activityModal !== null) {
      setModalIndex(ACTIVITY);
    }
  }, [activityModal]);

  const createActivityModal = ({ customerId }) => {
    setActivityModal(
      <Activities customerId={customerId} onClose={closeModal} />
    );
  };

  const openB2BAddNewModal = () => {
    setEditingB2BValues({});
    setModalIndex(ADD_NEW_B2B);
  };

  const openB2PAddNewModal = () => {
    setEditingB2PValues({});
    setModalIndex(ADD_NEW_B2P);
  };

  const [editingB2BValues, setEditingB2BValues] = useState({});
  const [editingB2PValues, setEditingB2PValues] = useState({});

  const openB2BEditModal = (customer) => {
    setEditingB2BValues(customer);
    setModalIndex(ADD_NEW_B2B);
  };

  const openB2PEditModal = (customer) => {
    setEditingB2PValues(customer);
    setModalIndex(ADD_NEW_B2P);
  };

  const closeModal = () => {
    setEditingB2BValues({});
    setEditingB2PValues({});
    setModalIndex(null);
  };

  const MODALS = [
    <ModalB2B
      onSubmit={isEmpty(editingB2BValues) ? submitCreateB2B : submitEditB2B}
      close={closeModal}
      editingValues={editingB2BValues}
    />,
    <ModalB2P
      onSubmit={isEmpty(editingB2PValues) ? submitCreateB2P : submitEditB2P}
      close={closeModal}
      editingValues={editingB2PValues}
    />,
    activityModal,
  ];

  const {
    importCsvModal: importCsvModalB2B,
    importCSV: importCSVB2B,
    exportCSV: exportCSVB2B,
    exportWaiting: exportWaitingB2B,
  } = useCvsIO(
    customersB2B,
    'Name,Activities,Address,Email,Phone,Bank Details,What Does Their Company Do?,What Does Their Company Need?,Person Who Decide, What Did They Took Last Time?, Keywords'
  );

  const {
    importCsvModal: importCsvModalB2P,
    importCSV: importCSVB2P,
    exportCSV: exportCSVB2P,
    exportWaiting: exportWaitingB2P,
  } = useCvsIO(
    customersB2P,
    'Name,Activities,Address,Email,Phone,Bank Details,What Does He/She Do?(Profession),Facebook Profile,Date of Birth,What Does He/She Bought ?, Keywords'
  );

  const TABS = [
    {
      name: t('customers').toUpperCase() + ' B2B',
      content: (
        <CustomersB2B
          createActivityModal={createActivityModal}
          openEditModal={openB2BEditModal}
        />
      ),
      controllers: regularTableControllers({
        addNew: withRestrictions(openB2BAddNewModal),
        importCSV: withRestrictions(importCSVB2B),
        exportCSV: exportCSVB2B,
        exportWaiting: exportWaitingB2B,
        t,
      }),
    },
    {
      name: t('customers').toUpperCase() + ' B2C',
      content: (
        <CustomersB2P
          createActivityModal={createActivityModal}
          openEditModal={openB2PEditModal}
        />
      ),
      controllers: regularTableControllers({
        addNew: withRestrictions(openB2PAddNewModal),
        importCSV: withRestrictions(importCSVB2P),
        exportCSV: exportCSVB2P,
        exportWaiting: exportWaitingB2P,
        t,
      }),
    },
  ];

  const contents = TABS.map((item) => <Wrapper>{item.content}</Wrapper>);

  const main = (
    <DashboardTabs tabs={TABS} readyContents={contents} startTabIndex={0} />
  );

  return (
    <>
      <ElectByIndex index={modalIndex} defaultComponent={main}>
        {MODALS}
      </ElectByIndex>
      {importCsvModalB2B}
      {importCsvModalB2P}
    </>
  );
};

export default Customers;
