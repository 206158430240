import Row from "react-bootstrap/Row";
import React, { useContext } from "react";
import cn from 'classnames';
import styles from './style.module.css';
import TouchableOpacity from "../../core/TouchableOpacity";
import {saveAs} from 'file-saver';
import Col from "react-bootstrap/Col";
import FetchLoading from "../FetchLoading";
import {useTranslation} from "../../../../contexts/LocaleContext";

export const Item = ({item, onClick, onRemove}) => {
  return (
    <Col md={4} lg={3} xl={2} className={styles.itemContainer}>
      <TouchableOpacity
        className={cn(styles.item)}
        onClick={onClick}
      >
        <div className={cn(styles.preview)}>
          <button className={cn(styles.buttonRemove)} type="button" onClick={onRemove}>
            <i className="fas fa-times" />
          </button>

          <i className="fas fa-file-pdf" />
        </div>
        <div className={cn(styles.header)}>
          <div>{item.name}</div>
          <div className={styles.date}>{item.date}</div>
        </div>
      </TouchableOpacity>
    </Col>
  );
};

const CataloguesTab = ({items, loaded, cataloguesData, removeFile}) => {
  const {t} = useTranslation()
  const save = async (id) => {
    const file = await cataloguesData.getItemById(id);
    const name = items.find((item) => id === item.id).name;
    saveAs(file, name);
  };

  return loaded ? (
    <Row className={styles.container}>
      {items.length > 0 ? items.map(item => (
        <Item
          item={item}
          onClick={() => save(item.id)}
          onRemove={(e) => {
            e.stopPropagation();
            removeFile(item.id);
          }}
        />
      )) : (
        <div className={styles.no}>
          {t("you_have_no_catalogues_yet")}
        </div>
      )}
    </Row>
  ) : (
    <FetchLoading/>
  );
};

export default CataloguesTab;
